<template>
  <div class="home">
    <RepAuditoria titulo="Inconsistencias Forma Pago" kind="FORMAPAGO"/>
  </div>
</template>

<script>

import RepAuditoria from "./ReporteAuditoria";

export default {
  components: {
    RepAuditoria
  }
};
</script>