<template>
    <div class="home">
      <InconsistenciasOnDemand titulo="CFDIs Relacionados (NO Sustituidos) Cancelados" filtro="REPORT-NO-SUSTITUIDOS-CANCELADOS" descripcionTitulo="Todos los CFDI relacionados que tengan relación diferente a 04 sin embargo están cancelados." />
    </div>
  </template>
  
  <script>
  
  import InconsistenciasOnDemand from "./InconsistenciasOnDemand";
  
  export default {
    components: {
        InconsistenciasOnDemand
    }
  };
  </script>