<template>
  <div class="home">
    <ClientVendors titulo="Reporte de Clientes" contexto="E" liga="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-clientes"/>
  </div>
</template>

<script>

import ClientVendors from "./ReportCVList";

export default {
  components: {
    ClientVendors
  }
};
</script>