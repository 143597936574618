<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">


        <v-dialog v-model="dialogMensaje" max-width="500px">
          <v-card>
            <v-card-title>
              <span>Solicitudes pendientes de {{ periodoSolicitudes }}</span>
            </v-card-title>
            <v-card-text>
              <li v-for="item in pendientesText" :key="item">{{ item }}</li>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogMensaje = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogAyuda" persistent max-width="1200px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogAyuda = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Ayuda</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <br />
              <div class="subtitle-1">
                1.-Cuando la cantidad de Emitidos y Recibidos coinciden en el
                SAT y XData.
                <v-icon large color="green"> mdi-flag </v-icon>
                <br />
                <br />
                2.-Cuando la cantidad de Emitidos y Recibidos no coinciden en el
                SAT y XData. Bandera en color rojo.
                <v-icon large color="red"> mdi-flag </v-icon>

              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialogAyuda = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Respuesta Conciliados</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Periodo</th>
                      <th>Respuesta</th>
                      <th>Mensajes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemC in itemsConciliados" :key="itemC.date" class="altura">
                      <td style="white-space: nowrap">{{ itemC.date }}</td>
                      <td>{{ itemC.result ? "Éxito" : "Fracaso" }}</td>
                      <td>{{ itemC.msg }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDetalles" persistent max-width="500px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDetalles = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalles {{ selectedMonth }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Emitidos</th>
                      <th>Recibidos</th>
                      <th>Emitidos en SAT</th>
                      <th>Recibidos en SAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ selectedE }}</td>
                      <td>{{ selectedR }}</td>
                      <td>{{ emitidos }}</td>
                      <td>{{ recibidos }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDetalles = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>
            Sincronización SAT

            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="second" large @click="showDialogAyuda = true" class="mx-2">mdi-chat-question
                </v-icon>
              </template>
              <span>Razones para conciliar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/sincronizaci%C3%B3n-sat" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col class="d-flex px-10" cols="12" md="2" justify="left">
                <v-select v-on:change="changePeriodo" :items="itemsSelect" v-model="periodoModel" label="Período">
                </v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <h3>{{ periodoModel }}</h3>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-for="item in mesesItems" :key="item.name" cols="12" md="3">
              <v-card class="mx-5" max-width="340" min-height="170" tile>
                <v-app-bar dark :color="item.colorCard" v-bind:class="item.opacity">
                  <v-toolbar-title>{{ item.titulo }}</v-toolbar-title>
                </v-app-bar>
                <v-card-text>
                  
                  <div>
                  <!--  <b>Fecha de Último Chequeo:</b>
                    {{
          item.fechaConciliacion != ""
            ? item.fechaConciliacion
            : "No Conciliada"
        }}<br /> -->
                    <b>Fecha de Cierre:</b>
                    {{

            item.fecha_cierre != ""
              ? (obtenerFechaActual() == periodoActual(item) ? "Mes No Cerrado" : item.fecha_cierre)
              : "Mes No Cerrado"
          }}<br />
                  </div>
                  <div v-if="item.fechaConciliacion !== ''">
                    <b>Estado:</b>
                    <span v-if="item.ready && !item.existsOnDemand">
                      {{
          TerminadaMensaje(item)
        }}
                    </span>
                    <span v-else>
                      Solicitudes Pendientes
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="ObtenerMensajePendientes(item)" icon color="#ffcc00" v-on="on">
                            <v-icon>mdi-eye-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver Detalle</span>
                      </v-tooltip>
                    </span>

                  </div>
                  <div v-else><b>Estado:</b> No Aplica</div>

                  <div v-if="item.fechaConciliacion !== ''">
                    <b>Emitidos en Xdata:</b>{{ item.xdata_issued }} <br />
                    <b>Emitidos en SAT:</b>{{ item.issued }} <br />
                    <b>Recibidos en Xdata:</b>{{ item.xdata_received }} <br />
                    <b>Recibidos en SAT:</b>{{ item.received }} <br />
                  </div>
                  <div v-else>
                    <b>Emitidos:</b>No aplica <br />
                    <b>Emitidos en SAT:</b>No aplica <br />
                    <b>Recibidos:</b>No aplica <br />
                    <b>Recibidos en SAT:</b>No aplica <br />
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-icon v-if="item.fechaConciliacion !== ''" large :color="item.color">
                    mdi-flag
                  </v-icon>
                  <v-spacer></v-spacer>
                  <v-btn v-if="showBotonConciliar(item)" outlined color="accent-4" @click="InvocarConciliaciar(item)">
                    Conciliar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "Cancelados",
  data: () => ({
    periodoSolicitudes: "",
    pendientesText: [],
    dialogMensaje: false,
    items: [],
    periodoModel: new Date().getFullYear(),
    itemsSelect: [],
    mesesItems: [],
    showDialog: false,
    itemsConciliados: [],
    emitidos: "",
    recibidos: "",
    showDetalles: false,
    showDialogAyuda: false,
    selectedMonth: "",
    selectedE: "",
    selectedR: "",
    selectedDate: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.fillItems();
    this.ObtenerConciliacionCancelados();
  },
  methods: {

    ObtenerMensajePendientes(item) {

      this.periodoSolicitudes = item.titulo;
      var jsonData = {
        token: this.$store.state.token,
        date: item.periodo,
      };
      service
        .api()
        .ConciliacionCanceladosStatus(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.pendientesText = JSON.parse(response.payload);
            this.dialogMensaje = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });


    },
    TerminadaMensaje(item) {
      let mensaje = '';
      try {
        if (item.errores.length)
          mensaje = "Terminada con errores";
        else
          mensaje = "Terminada";
      }
      catch {
        mensaje = "Terminada";
      }


      return mensaje;
    },
    VerDetalles(mes, e, r) {
      this.selectedE = e;
      this.selectedR = r;
      var jsonData = {
        token: this.$store.state.token,
        date: this.periodoModel + "-" + mes,
      };

      service
        .api()
        .DetallesYear(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let payload = JSON.parse(response.payload);
            this.emitidos = payload.xdata_emitidos;
            this.recibidos = payload.xdata_recibidos;
            switch (mes) {
              case "01":
                this.selectedMonth = "Enero";
                break;
              case "02":
                this.selectedMonth = "Febrero";
                break;
              case "03":
                this.selectedMonth = "Marzo";
                break;
              case "04":
                this.selectedMonth = "Abril";
                break;
              case "05":
                this.selectedMonth = "Mayo";
                break;
              case "06":
                this.selectedMonth = "Junio";
                break;
              case "07":
                this.selectedMonth = "Julio";
                break;
              case "08":
                this.selectedMonth = "Agosto";
                break;
              case "09":
                this.selectedMonth = "Septiembre";
                break;
              case "10":
                this.selectedMonth = "Octubre";
                break;
              case "11":
                this.selectedMonth = "Noviembre";
                break;
              case "12":
                this.selectedMonth = "Diciembre";
                break;
            }

            this.showDetalles = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    RefrescarYear() {
      this.ObtenerConciliacionCancelados();
    },
    changePeriodo() {
      this.ObtenerConciliacionCancelados();
    },
    ObtenerConciliacionCancelados() {
      var jsonData = {
        token: this.$store.state.token,
        dateYear: this.periodoModel,
      };

      this.mesesItems = [];
      service
        .api()
        .ConciliacionCanceladosYear(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let meses = JSON.parse(response.payload);
            meses.forEach((item) => {
              let title = "";
              let noMes = item.dateMonth;
              let noMesInt = Number(item.dateMonth);
              switch (item.dateMonth) {
                case "01":
                  title = "Enero";
                  break;
                case "02":
                  title = "Febrero";
                  break;
                case "03":
                  title = "Marzo";
                  break;
                case "04":
                  title = "Abril";
                  break;
                case "05":
                  title = "Mayo";
                  break;
                case "06":
                  title = "Junio";
                  break;
                case "07":
                  title = "Julio";
                  break;
                case "08":
                  title = "Agosto";
                  break;
                case "09":
                  title = "Septiembre";
                  break;
                case "10":
                  title = "Octubre";
                  break;
                case "11":
                  title = "Noviembre";
                  break;
                case "12":
                  title = "Diciembre";
                  break;
              }

              let color = "cancelGradient";
              let opacidad = 1;
              if (item.lastDate !== "") {
                let dias = helpers.getDiferenciaFechaHoy(item.lastDate);
                if (dias >= 365) opacidad = 1;
                else opacidad = dias / 365;
              } else {
                opacidad = 1;
              }

              let classOp = this.getOpacity(opacidad);
              if (!item.isReady && !item.enable) color = "noDocs";

              var mesData = {
                titulo: title,
                mes: noMes,
                noMes: noMesInt,
                fechaConciliacion: item.lastDate,
                ready: item.isReady,
                enable: item.enable,
                existsOnDemand: item.existsOnDemand,
                colorCard: color,
                opacity: classOp,
                issued: item.issued,
                received: item.received,
                fecha_cierre: item.fecha_cierre,
                xdata_issued: item.xdata_issued,
                xdata_received: item.xdata_received,
                periodo: this.periodoModel + '-' + item.dateMonth,
                color:
                  item.issued == item.xdata_issued &&
                    item.received == item.xdata_received
                    ? "green"
                    : "red"

              };
              this.mesesItems.push(mesData);
            });
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getOpacity(op) {
      let OpText = "";
      if (op >= 0 && op < 0.2) OpText = "op1";
      if (op >= 0.2 && op < 0.4) OpText = "op2";
      if (op >= 0.4 && op < 0.6) OpText = "op3";
      if (op >= 0.6 && op < 0.8) OpText = "op4";
      if (op >= 0.8 && op <= 1) OpText = "op5";
      return OpText;
    },
    fillItems() {
      var i;
      for (i = this.periodoModel - 5; i <= this.periodoModel; i++)
        this.itemsSelect.push(i);
    },
    showBotonConciliar(item) {

      let actual = this.obtenerFechaActual();
      let current = this.periodoModel + "-" + item.mes;

      if (actual == current)
        return false;

      return this.conDescargaMasiva && (item.ready && item.color === 'red' && item.fecha_cierre !== '' && !item.existsOnDemand);
    },
    InvocarConciliaciar(item) {


      var jsonData = {
        token: this.$store.state.token,
        date: item.periodo,
      };
      //alert(JSON.stringify(jsonData));
      service
        .api()
        .ConciliacionCanceladosAsk(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.ObtenerConciliacionCancelados();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    obtenerFechaActual() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      return year + "-" + month;
    },

    periodoActual(item) {
      return this.periodoModel + "-" + item.mes;
    }

  },
  computed: {
    conDescargaMasiva() {
      return this.$store.state.hasAutSatDownload;
    },
    mesActual() {
      var d = new Date();
      var n = d.getMonth();
      return n + 1;
    },
    anioActual() {
      var d = new Date();
      var n = d.getFullYear();
      return n;
    }
  },
};
</script>

<style scoped>
.op1 {
  opacity: 0.3;
}

.op2 {
  opacity: 0.5;
}

.op3 {
  opacity: 0.7;
}

.op4 {
  opacity: 0.9;
}

.op5 {
  opacity: 1;
}
</style>
