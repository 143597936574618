<template>
  <div class="py-10">
    <v-container fluid class="px-4">
      <v-row justify="center">
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                      class="ml-auto mr-3">
                      <v-icon>mdi-download-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Seleccionados</span>
                </v-tooltip>

                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>
              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                    errorMessageIn
                    }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{
                    okMessageIn
                    }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                              hide-details></v-checkbox>
                          </th>

                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Contexto</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Estado de Pago</th>
                          <th>Método de Pago</th>
                          <th>Mensaje</th>
                          <th>Disponible</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            <v-checkbox v-model="selectedItemsDescarga" :value="item" color="primary"
                              hide-details></v-checkbox>
                          </td>

                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{
                              item.commands.context == "E"
                                ? "Emitido"
                            : "Recibido"
                            }}
                          </td>
                          <td>
                            {{ item.commands.dateInit }}
                          </td>
                          <td>
                            {{ item.commands.dateEnd }}
                          </td>
                          <td>
                            {{ item.commands.taxid }}
                          </td>
                          <td>
                            {{ item.commands.paymentStatus }}
                          </td>
                          <td>
                            {{ item.commands.paymentMethod }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{ item.ready ? "Si" : "No" }}
                          </td>
                          <td>
                            {{
                              item.ready
                                ? item.result
                                  ? "Exitoso"
                            : "Fracasado"
                            : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarCrearSolicitud">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" justify="center">
                  <date-range-picker ref="dateRange" v-model="selectedDatesIn"
                    :fecha-limite-inferior="limiteInferior"></date-range-picker>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="rfcSearchIn" counter="0" :label="labelRfc" maxlength="13" class="my-1">
                  </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                  <v-select :items="itemsSelect" v-model="tipoDocIn" label="Tipo de Comprobante" outlined></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                  <v-select :items="itemsObjetoImp" v-model="filtroObjetoImpIn" label="Objeto Impuesto"
                    outlined></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                  <v-select :items="itemsOrigen" v-model="contextoIn" label="Contexto" outlined></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="noIdentificacionIn" counter="0" label="No. Identificacion" class="my-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="claveProdServIn" counter="0" label="Clave Prod. Serv." class="my-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="claveUnidadIn" counter="0" label="Clave Unidad" class="my-1"></v-text-field>
                </v-col>
                <v-col md="6" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>

              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">
                Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Reporte General de Conceptos
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-general-de-conceptos"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>

          </h3>
          <h4 v-if="showSugerencia">{{ mensajeSugerencia }}</h4>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates" fecha-limite-inferior="2021-01-01"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" :label="labelRfc" maxlength="13" class="my-1">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoDoc"
                  label="Tipo de Comprobante" outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select :items="itemsObjetoImp" v-model="filtroObjetoImp" label="Objeto Impuesto" outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="noIdentificacion" counter="0" label="No. Identificacion"
                  class="min-width-control">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="claveProdServ" counter="0" label="Clave Prod. Serv."
                  class="min-width-control"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="claveUnidad" counter="0" label="Clave Unidad"
                  class="min-width-control"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="getReport" :disabled="!showSearchButton">
                      Mostrar Listado</v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Mostrar Listado de Documentos</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">
                      Reportes Bajo Demanda
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ conceptosPagina }} registros de
              {{ totalConceptos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteConceptosCSV" v-on="on"
                  :disabled="totalConceptos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min="1" :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>



            <wj-flex-grid :itemsSource="hideColsGridData" :autoGenerateColumns="false" :allowAddNew="false"
              :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
              :validateEdits="false" :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">



              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'Folio_Fiscal'" width="2*" :isReadOnly="true" />


              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Razón Social'" :binding="'nombre'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>

              <wj-flex-grid-column :header="'Clave Prod/Serv'" :binding="'ClaveProdServ'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Clave Prod/Serv Desc'" :binding="'ClaveProdServDesc'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Clave Unidad'" :binding="'ClaveUnidad'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Clave Unidad Desc'" :binding="'ClaveUnidadDesc'" :isReadOnly="true" />



              <wj-flex-grid-column :header="'No. Identificación'" :binding="'NoIdentificacion'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Unidad'" :binding="'Unidad'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descripción'" :binding="'Descripcion'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Moneda'" :binding="'Moneda'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'TipoCambio'" :binding="'TipoCambio'" width="2*" format="c6"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Cantidad'" :binding="'Cantidad'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'ValorUnitario'" :binding="'ValorUnitario'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Importe'" :binding="'Importe'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Objeto Impuesto'" :binding="'ObjetoImp'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>




          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";

import Vue from "vue";
import "@grapecity/wijmo.vue2.grid";
import * as wjcGrid from "@grapecity/wijmo.grid";
import "@grapecity/wijmo.vue2.grid.filter";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGridFilter from "@grapecity/wijmo.grid.filter";
import "@grapecity/wijmo.vue2.grid.grouppanel";


import store from "../../store/store";
import helpers from "../../scripts/helpers.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "Conceptos",
  components: {
    DateRangePicker
  },
  data: () => ({

    selectedItemsDescarga: [],
    allSelected: false,

    filtroObjetoImpIn: "Seleccionar Todos",
    filtroObjetoImp: "Seleccionar Todos",
    itemsObjetoImp: ["Seleccionar Todos", "01", "02", "03", "04", "05"],
    mensajeSugerencia: "",
    particionConceptos: "",
    selectedDates: [],
    selectedDatesIn: [],
    selectedRows: [],
    contexto: "Emitidos",
    contextoIn: "Emitidos",
    statusCancel: "Todos",
    claveProdServ: "",
    claveUnidad: "",
    noIdentificacion: "",
    claveProdServIn: "",
    claveUnidadIn: "",
    noIdentificacionIn: "",
    showDialogEliminarReport: false,
    showDialogCrear: false,
    showDialogReports: false,
    goPage: 1,
    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    hideColsGridData: null,



    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    conceptosPagina: 0,
    totalConceptos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    tipoDoc: "Seleccionar Todos",
    tipoDocIn: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso", "Traslado"],
    csvData: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    rfcEClass: false,
    tipo_comprobanteClass: false,
    tipo_claveProdServClass: false,
    tipo_claveUnidadClass: false,
    rfcSearch: "",
    rfcSearchIn: "",
    itemDesc: "",
    descripcionIn: "",
    itemsGrandes: [],
    headers: [
      { text: "RFC", value: "rfc", align: "start", sortable: false },
      {
        text: "Razón Social",
        value: "nombre",
        align: "start",
        sortable: false,
      },
      {
        text: "Tipo Comprobante",
        value: "tipo_comprobante",
        align: "start",
        sortable: false,
      },
      {
        text: "Clave Prod/Serv",
        value: "ClaveProdServ",
        align: "start",
        sortable: false,
      },
      {
        text: "Clave Prod/Serv Desc",
        value: "ClaveProdServDesc",
        align: "start",
        width: "300px",
        sortable: false,
      },
      {
        text: "Clave Unidad",
        value: "ClaveUnidad",
        align: "start",
        sortable: false,
      },
      {
        text: "Clave Unidad Desc",
        value: "ClaveUnidadDesc",
        align: "start",
        sortable: false,
      },
      {
        text: "No. Identificacion",
        value: "NoIdentificacion",
        align: "start",
        sortable: false,
      },
      { text: "Unidad", value: "Unidad", align: "start", sortable: false },
      {
        text: "Descripción",
        value: "Descripcion",
        align: "start",
        sortable: false,
      },
      { text: "Moneda", value: "Moneda", align: "start", sortable: false },
      { text: "Tipo Cambio", value: "TipoCambio", sortable: false },
      { text: "Cantidad", value: "Cantidad", align: "right", sortable: false },
      { text: "Valor Unitario", value: "ValorUnitario", sortable: false },
      { text: "Importe", value: "Importe", align: "right", sortable: false },
      {
        text: "Descuento",
        value: "Descuento",
        align: "right",
        sortable: false,
      },
      // {
      //   text: "Cancelado",
      //   value: "cancelado",
      //   align: "start",
      //   sortable: false,
      // },
      { text: " ", value: "", align: "start" },
      { text: " ", value: "", align: "start" },
    ],
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);

    let particion = this.$store.state.partitionSchema;
    if (particion != null)
      this.particionConceptos = particion.conceptos;

  },
  methods: {
    cerrarCrearSolicitud() {
      this.nombre = "";
      this.descripcionIn = "";
      this.selectedDatesIn = [];
      this.$refs.dateRange.limpiarFechas();
      this.contextoIn = "Emitidos";
      this.rfcSearchIn = "";
      this.showDialogCrear = false;
    },

    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }

      grid.columns[grid.columns.length - 1].width = 100;


    },


    getObjetoImp() {

      if (this.filtroObjetoImp == "Seleccionar Todos")
        return "";
      else
        return this.filtroObjetoImp;
    },
    getObjetoImpIn() {

      if (this.filtroObjetoImpIn == "Seleccionar Todos")
        return "";
      else
        return this.filtroObjetoImpIn;
    },

    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalConceptos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalConceptos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalConceptos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalConceptos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalConceptos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      let canceladoTmp = "";
      this.selectedRows = [];

      switch (this.statusCancel) {
        case "Cancelado":
          canceladoTmp = "1";
          break;
        case "No Cancelado":
          canceladoTmp = "0";
          break;
        default:
          canceladoTmp = "";
      }

      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        claveProdServ: this.claveProdServ,
        claveUnidad: this.claveUnidad,
        numeroIdentificacion: this.noIdentificacion,
        cancelados: canceladoTmp,
        docType: this.getTipoDoc(),
        objetoImp: this.getObjetoImp()
      };
      service
        .api()
        .ReporteConceptos(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);

            let i = 0;
            payload.forEach(function (element) {
              element.id = ++i;
            });

            this.items = payload;
            this.hideColsGridData = new wjcCore.CollectionView(this.items, {
              sortDescriptions: ["Folio_Fiscal"],
              groupDescriptions: ["Folio_Fiscal"]
            });

            this.conceptosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      let canceladoTmp = "";

      switch (this.statusCancel) {
        case "Cancelado":
          canceladoTmp = "1";
          break;
        case "No Cancelado":
          canceladoTmp = "0";
          break;
        default:
          canceladoTmp = "";
      }

      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        rfc: this.rfcSearch,
        claveProdServ: this.claveProdServ,
        claveUnidad: this.claveUnidad,
        numeroIdentificacion: this.noIdentificacion,
        cancelados: canceladoTmp,
        docType: this.getTipoDoc(),
        objetoImp: this.getObjetoImp()
      };

      service
        .api()
        .ReporteConceptosCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalConceptos = payload;
            this.totalPaginas = Math.ceil(this.totalConceptos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConceptosCSV() {
      let canceladoTmp = "";

      switch (this.statusCancel) {
        case "Cancelado":
          canceladoTmp = "1";
          break;
        case "No Cancelado":
          canceladoTmp = "0";
          break;
        default:
          canceladoTmp = "";
      }

      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        rfc: this.rfcSearch,
        claveProdServ: this.claveProdServ,
        claveUnidad: this.claveUnidad,
        numeroIdentificacion: this.noIdentificacion,
        cancelados: canceladoTmp,
        docType: this.getTipoDoc(),
        objetoImp: this.getObjetoImp()
      };

      service
        .api()
        .ReporteConceptosCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            JSONToCSV(
              this.csvData,
              "Reporte_General_Conceptos_" + helpers.dateNowCSV(),
              true
            );
          } else {

            this.csvData = [];
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      alert(columnName);
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();
      this.rfcRClass = false;
      this.tipo_comprobanteClass = false;
      this.tipo_claveProdServClass = false;
      this.tipo_claveUnidadClass = false;

      switch (columnName) {
        case "entidad.rfc":
          this.rfcRClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "ClaveProdServ":
          this.tipo_claveProdServClass = true;
          break;
        case "ClaveUnidad":
          this.tipo_claveUnidadClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoDocIn() {
      let firstLetter = this.tipoDocIn.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
        case "N":
          tipo = "Nómina";
          break;
      }
      return tipo;
    },
    retrieveXML(item) {
      let string_fecha_emision = item.nfecha_emision + "";
      let fecha_emision =
        string_fecha_emision.substring(0, 4) +
        "-" +
        string_fecha_emision.substring(4, 6) +
        "-" +
        string_fecha_emision.substring(6, 8);

      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substring(0, 1),
        uuid: item.Folio_Fiscal,
        issuedDate: fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      let string_fecha_emision = item.nfecha_emision + "";
      let fecha_emision =
        string_fecha_emision.substring(0, 4) +
        "-" +
        string_fecha_emision.substring(4, 6) +
        "-" +
        string_fecha_emision.substring(6, 8);

      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R") par = item.tipo + "||" + item.rfc;
      else par = item.tipo + "||";
      let parameters = par + "|" + item.Folio_Fiscal + "|" + fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 3000);
    },

    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .ReporteConceptoDeleteReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);

            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },


    DescargarReporte(itemId) {
      return new Promise((resolve, reject) => {
        const jsonData = {
          id: itemId,
          token: this.$store.state.token,
        };

        service
          .api()
          .ReporteConceptoRetrieveReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.retrieveReporte(response.payload);
              resolve(response.payload); // Resolver con el payload
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
              reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
            }
          })
          .catch((error) => {
            reject(error); // Rechazar si ocurre un error en el servicio
          });
      });
    },
    descargarMultiple() {
      this.descargarTodosEnParalelo(this.selectedItemsDescarga);

    },
    async descargarTodosEnParalelo(items) {
      try {
        const promesas = items.map((item) => this.DescargarReporte(item.id));
        await Promise.all(promesas);
        console.log("Todas las descargas han finalizado.");
      } catch (error) {
        console.error("Error en una o más descargas:", error);
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItemsDescarga = [...this.itemsGrandes];
      } else {
        this.selectedItemsDescarga = [];
      }
    },




    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },
    ObtenerReportes() {
      this.showDialogReports = true;
      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .ReporteConceptoRetrieveGrandes(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    requestItems() {
      this.showDialogCrear = false;

      var jsonData = {
        description: this.descripcionIn,
        token: this.$store.state.token,
        dateInit: this.selectedDatesIn[0],
        dateEnd: this.selectedDatesIn[1],
        context: this.contextoIn.substring(0, 1),
        rfc: this.rfcSearchIn,
        claveProdServ: this.claveProdServIn,
        claveUnidad: this.claveUnidadIn,
        numeroIdentificacion: this.noIdentificacionIn,
        cancelados: "",
        docType: this.getTipoDocIn(),
        objetoImp: this.getObjetoImpIn()

      };
      this.showDialogReports = false;
      service
        .api()
        .ReporteConceptoGrande(jsonData)
        .then((response) => {
          this.cerrarCrearSolicitud();
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
          this.descripcionIn = "";
          this.selectedDatesIn[0] = "";
          this.selectedDatesIn[1] = "";
          this.rfcSearchIn = "";
          this.claveProdServIn = "";
          this.claveUnidadIn = "";
          this.noIdentificacionIn = "";
          this.showDialogReports = true;
        });





    },
  },
  computed: {


    mostrarDescargarSeleccionado() {
      return this.selectedItemsDescarga.length > 0;
    },
    limiteInferior() {
      return "2019-01-01";
    },
    showSugerencia() {

      if (this.particionConceptos == null || this.particionConceptos == "")
        return false;

      switch (this.particionConceptos) {
        case "MES":
          this.mensajeSugerencia = "Se debe realizar la solicitud con rango del mismo mes";
          break;
        case "DIA":
          this.mensajeSugerencia = "Se debe realizar la solicitud con rango del mismo día";
          break;
      }

      return true;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto.substring(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substring(0, 1) === "R";
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    labelRfc() {
      if (this.contexto.substring(0, 1) === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    showCreateButton() {
      return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
        this.contextoIn = "Recibidos";
      }

      return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

tr.v-data-table__selected {
  background: #3c8539 !important;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}

.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
