<template>
  <div class="home">
    <Retenciones titulo="Retenciones - Recibidas Canceladas" contexto="R" esCancelado=true />
  </div>
</template>

<script>

import Retenciones from "./Retenciones.vue";

export default {
  components: {
    Retenciones
  }
};
</script>