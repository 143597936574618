<template>
  <div class="home">
    <ListasNegras origen="0" titulo="" />
  </div>
</template>

<script>

import ListasNegras from "./BlackListReport";

export default {
  components: {
    ListasNegras
  },
   created() {
    this.$store.commit("setBlRoute", "/ListasNegras/ListaNegra");
  },
};
</script>