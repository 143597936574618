<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">

        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" pa="6" md="6" justify="center">
                  <v-menu v-model="menuIn" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateIn" label="Fecha Inicial*" prepend-icon="mdi-calendar-month" readonly
                        v-bind="attrs" v-on="on" :disabled="dateTs.length > 0"></v-text-field>
                    </template>
                    <v-date-picker :min="fechaInicial" v-model="dateIn" @change="cambio = true" locale="es"
                      @input="menuIn = false" :disabled="dateTs.length > 0"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" pa="6" md="6" justify="center">
                  <v-menu v-model="menuIn2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateIn2" label="Fecha Final*"
                        :disabled="dateTs.length > 0 || dateIn.length == 0" prepend-icon="mdi-calendar-month" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="dateIn" v-model="dateIn2" @change="cambio = true" locale="es"
                      @input="menuIn2 = false" :disabled="dateTs.length > 0"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field :disabled="dateTs.length > 0" v-model="rfcSearchIn" @change="cambio = true" counter="0"
                    :label="labelRfc" maxlength="13" class="my-1"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="4">
                  <v-select :items="itemsSelect" v-model="tipoDocIn" label="Tipo de Comprobante" outlined
                    :disabled="dateTs.length > 0"></v-select>
                </v-col>
                <v-col md="6" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogReports" persistent>
          <v-col v-if="itemsGrandes.length > 0" cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>
              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{ errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Contexto</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Estado de Pago</th>
                          <th>Método de Pago</th>
                          <th>Mensaje</th>
                          <th>Disponible</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{
                              item.commands.context == "E"
                              ? "Emitido"
                              : "Recibido"
                            }}
                          </td>
                          <td>
                            {{ item.commands.dateInit }}
                          </td>
                          <td>
                            {{ item.commands.dateEnd }}
                          </td>
                          <td>
                            {{ item.commands.taxid }}
                          </td>
                          <td>
                            {{ item.commands.paymentStatus }}
                          </td>
                          <td>
                            {{ item.commands.paymentMethod }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{ item.ready ? "Si" : "No" }}
                          </td>
                          <td>
                            {{
                              item.ready
                              ? item.result
                                ? "Exitoso"
                                : "Fracasado"
                              : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>

        <v-dialog v-model="showDialogStatus" persistent max-width="700px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarStatus">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Estado</th>
                      <th>Cancelable</th>
                      <th>Estado Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ selectedStatus.estado }}</td>
                      <td>{{ selectedStatus.cancelable }}</td>
                      <td>{{ selectedStatus.estado_cancelacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>{{ titulo }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/cfdi-emitidos-no-cancelados" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col cols="12" pa="4" md="2" class="mx-2" justify="center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Fecha Inicial*" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on" :disabled="dateTs.length > 0"></v-text-field>
                  </template>
                  <v-date-picker :min="fechaInicial" v-model="date" @change="cambio = true" locale="es"
                    @input="menu = false" :disabled="dateTs.length > 0"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" pa="4" md="2" justify="center">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date2" label="Fecha Final*" :disabled="dateTs.length > 0 || date.length == 0"
                      prepend-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="date" v-model="date2" @change="cambio = true" locale="es" @input="menu2 = false"
                    :disabled="dateTs.length > 0"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field :disabled="dateTs.length > 0" v-model="rfcSearch" @change="cambio = true" counter="0"
                  :label="labelRfc" maxlength="13" class="my-1"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-select :items="itemsSelect" v-model="tipoDoc" label="Tipo de Comprobante" outlined
                  :disabled="dateTs.length > 0"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" class="mx-2" justify="center">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar Listado
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" class="mx-2" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes Solicitados
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="verLimpiarFiltros" cols="12" md="1" class="mx-2" justify="center">
                <v-btn color="primary" white large v-on:click="CleanFilters">Limpiar Filtros</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card v-if="showCantidades">
            <v-row>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Cantidad Ingresos: {{ totalIngresos }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text v-if="totalIngresos > 0" class="text--primary">
                    <h2 v-if="!verIngreso">
                      <v-chip class="ma-2" color="primary" @click="getReportConciliatorioImporte('I')">
                        Calcular Importe
                      </v-chip>
                    </h2>
                    <div v-else>
                      <v-simple-table>
                        <tr>
                          <td>
                            <h3>Total</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Subtotal</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.subtotal) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Retenciones</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total_retenciones) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Traslados</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total_traslados) }} MXN
                          </td>
                        </tr>
                      </v-simple-table>
                    </div>
                  </v-card-text>
                  <v-card-text v-else class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Cantidad Egresos: {{ totalEgresos }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text v-if="totalEgresos > 0" class="text--primary">
                    <h2 v-if="!verEgreso">
                      <v-chip class="ma-2" color="primary" @click="getReportConciliatorioImporte('E')">
                        Calcular Importe
                      </v-chip>
                    </h2>
                    <div v-else>
                      <v-simple-table>
                        <tr>
                          <td>
                            <h3>Total</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Subtotal</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.subtotal) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Retenciones</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total_retenciones) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Traslados</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total_traslados) }} MXN
                          </td>
                        </tr>
                      </v-simple-table>
                    </div>
                  </v-card-text>
                  <v-card-text v-else class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Cantidad Traslados: {{ totalTraslados }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h2>No aplica</h2>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" title>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Cantidad Pagos: {{ totalPagos }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h2>No aplica</h2>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                  :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min="1" :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
               :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'receptor_rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'" width="4*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor_nombre'" width="4*"
                :isReadOnly="true" />


              <wj-flex-grid-column :header="'Versión'" :binding="'version'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Retención ISR'" :binding="'retencion_isr'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IVA'" :binding="'retencion_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IEPS'" :binding="'retencion_ieps'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IVA'" :binding="'traslado_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IEPS'" :binding="'traslado_ieps'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Retenciones'" :binding="'total_retenciones'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Traslados'" :binding="'total_traslados'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                 
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>
          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
  name: "Conciliatorio",
  props: ["titulo", "contexto", "esCancelado"],
  components: {
    Dialogo
  },
  data: () => ({
    itemFind: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    showDialogEliminarReport: false,
    showDialogReports: false,
    showDialogCrear: false,
    errorMessageIn: "",
    okMessageIn: "",
    descripcionIn: "",
    itemDesc: "",
    alert_errorIn: false,
    alert_okIn: false,
    itemsGrandes: [],
    verIngreso: false,
    verEgreso: false,
    date: "",
    date2: "",
    showCantidades: false,
    menu: false,
    menu2: false,
    dateIn: "",
    dateIn2: "",
    menuIn: false,
    menuIn2: false,
    rfcSearchIn: "",
    tipoDocIn: "Seleccionar Todos",
    showDialog: false,
    showDialogStatus: false,
    goPage: 1,
    selectedStatus: {
      estado: "",
      cancelable: "",
      estado_cancelacion: "",
    },
    cambio: false,
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalTraslados: 0,
    totalPagos: 0,
    ImporteIngresos: 0,
    ImporteEgresos: 0,
    ImporteTraslados: 0,
    ImportePagos: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    tipoDoc: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso", "Traslado", "Pago"],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    verLimpiarFiltros: false,
    itemSelected: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    rfcSearch: "",
    dateTs: "",
    importesCantidad: {
      total: 0,
      subtotal: 0,
      total_retenciones: 0,
      total_traslados: 0
    },
    importesCantidadE: {
      total: 0,
      subtotal: 0,
      total_retenciones: 0,
      total_traslados: 0
    }
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    let dataCronologia = this.$store.state.fromCrono;
    let dataInterval = this.$store.state.dateInterval;

    if (dataCronologia === null && dataInterval === null) {
      this.$store.commit("setBlData", null);
      return;
    }

    if (dataCronologia !== null) {
      this.rfcSearch = dataCronologia.rfc;
      this.date = dataCronologia.year + "-01-01";
      this.date2 = dataCronologia.year + "-12-31";
      this.$store.commit("setFromCrono", null);
    }

    if (dataInterval !== null) {
      //alert(JSON.stringify(dataInterval));
      if (dataInterval.rfc !== null) {
        this.rfcSearch = dataInterval.rfc;
      }
      this.date = dataInterval.dateInit;
      this.date2 = dataInterval.dateEnd;
      this.dateTs = dataInterval.dateTs;
      this.tipoDoc =
        this.$store.state.tipoDocTs === ""
          ? "Seleccionar Todos"
          : this.$store.state.tipoDocTs;
      this.$store.commit("setTipoDocTs", "");
      this.$store.commit("setDateInterval", null);
      this.verLimpiarFiltros = true;
    }

    this.getReport();
    this.getReportConciliatorioCantidades();
  },
  methods: {
    initGrid: function (grid) {

      for (let i = 1; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
        grid.columns[0].width = 20;
      }

      grid.columns[0].width = 100;


      grid.columns[grid.columns.length - 1].width = 200;


    },
    CleanFilters() {
      this.dateTs = "";
      this.verLimpiarFiltros = false;
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    DescargarReporte(itemId) {
      var jsonData = {
        id: itemId,
        token: this.$store.state.token,
      };

      service
        .api()
        .ReporteConciliatorioRetrieveReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.retrieveReporte(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .ReporteConciliatorioDeleteReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ObtenerReportes() {
      this.showDialogReports = true;
      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .ReporteConciliatorioRetrieveGrandes(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);

          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto,
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 3000);
    },

    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },
    verMasDatos(id) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: id,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFind = payload;
            this.openDialog();
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: "RFC Emisor",
        NombreE: "Razon Social Emisor",
        RfcR: "RFC Receptor",
        NombreR: "Razon Social Receptor",
        CRegimen: "Código Regimen",
        NRegimen: "Regimen",
        ResidenciaR: "Residencia Receptor",
        CUsoCfdiR: "Código Uso CFDI",
        NUsoCfdiR: "Uso CFDI",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Serie: "Serie",
        Folio: "Folio",
        LugarExpedicion: "Lugar de Expedicion",
        MetodoPago: "Método Pago",
        CFormaPago: "Código Forma de Pago",
        NFormaPago: "Forma de Pago",
        Moneda: "Moneda",
        TipoCambio: "Tipo Cambio",
        SubTotal: "Subtotal",
        Descuento: "Descuento",
        RetencionISR: "Retención ISR",
        RetencionIVA: "Retención IVA",
        RetencionIEPS: "Retención IEPS",
        TrasladoIVA: "Traslado IVA",
        TrasladoIEPS: "Traslado IEPS",
        TotalRetenciones: "Total Retenciones",
        TotalTraslados: "Total Traslados",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.emisor_rfc),
          NombreE: helpers
            .ConvertToEmpty(item.emisor_nombre)
            .replace(/,/g, " "),
          RfcR: helpers.ConvertToEmpty(item.receptor_rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor_nombre),
          CRegimen: helpers.ConvertToEmpty(item.regimen),
          NRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.regimen))
            .replace(/,/g, " "),
          ResidenciaR: helpers.ConvertToEmpty(item.residencia),
          CUsoCfdiR: helpers.ConvertToEmpty(item.usocfdi),
          NUsoCfdiR: helpers
            .UsoCFDI(helpers.ConvertToEmpty(item.usocfdi))
            .replace(/,/g, " "),
          Tipo: helpers.ConvertToEmpty(item.tipo),
          TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
          NFormaPago: helpers
            .FormaPago(helpers.ConvertToEmpty(item.forma_pago))
            .replace(/,/g, " "),
          Moneda: helpers.ConvertToEmpty(item.moneda),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          Descuento: helpers.ConvertToEmpty(item.descuento),
          RetencionISR: helpers.ConvertToEmpty(item.retencion_isr),
          RetencionIVA: helpers.ConvertToEmpty(item.retencion_iva),
          RetencionIEPS: helpers.ConvertToEmpty(item.retencion_ieps),
          TrasladoIVA: helpers.ConvertToEmpty(item.traslado_iva),
          TrasladoIEPS: helpers.ConvertToEmpty(item.traslado_ieps),
          TotalRetenciones: helpers.ConvertToEmpty(item.total_retenciones),
          TotalTraslados: helpers.ConvertToEmpty(item.total_traslados),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.pac),
        });
      });

      let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Tipo Comprobante": helpers.ConvertToNoAplica(this.tipoDoc),
        Cancelados: this.esCancelado === 'true',
        "Fecha Final": this.date2,
        "Fecha Inicio": this.date,
        "Fecha de Solicitud": helpers.FechaActual(),
        Reporte:
          "Listado Conciliatorio " +
          (this.contexto === "E" ? "Emitidos" : "Recibidos"),
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Conciliatorio_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.limit = this.pageSize;
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
      this.verIngreso = false;
      this.verEgreso = false;
      this.getReportConciliatorioCantidades();
    },
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        onlyCancelled: this.esCancelado === 'true',
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        dateTs: this.dateTs,
      };
      service
        .api()
        .ReporteConciliatorio(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        context: this.contexto,
        onlyCancelled: this.esCancelado === 'true',
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        dateTs: this.dateTs,
      };

      service
        .api()
        .ReporteConciliatorioCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConciliatorioCSV() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        onlyCancelled: this.esCancelado === 'true',
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        dateTs: this.dateTs,
      };

      service
        .api()
        .ReporteConciliatorioCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido");
          }
        });
    },
    ConsultarEstadoDoc(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo,
        uuid: item.id,
        issuedDate: item.fecha_emision,
        issuer: item.emisor_rfc,
        receiver: item.receptor_rfc,
        total: item.total,
      };

      service
        .api()
        .Consult(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.selectedStatus = JSON.parse(response.payload);
            this.itemSelected = item;
            this.showDialogStatus = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    requestItems() {
      let statusPago = "";
      this.showDialogCrear = false;

      var jsonData = {
        description: this.descripcionIn,
        dateInit: this.dateIn,
        dateEnd: this.dateIn2,
        context: this.contexto.substring(0, 1),
        taxid: this.rfcSearchIn,
        token: this.$store.state.token,
        docType: this.getTipoDocIn(),
        onlyCancelled: this.esCancelado === 'true',
        dateTs: this.dateTs,
      };
      this.showDialogReports = false;
      service
        .api()
        .ReporteConciliatorioGrande(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
          this.showDialogReports = false;
          this.descripcionIn = "";
        });


    },

    getReportConciliatorioImporte(tipoDoc) {

      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        onlyCancelled: this.esCancelado === 'true',
        rfc: this.rfcSearch,
        docType: tipoDoc,
        dateTs: this.dateTs,
        dateTs1: "",
      };

      service
        .api()
        .ReporteConciliatorioTotalesPorTipo(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            switch (tipoDoc) {
              case "I":
                this.verIngreso = true;
                this.importesCantidad = JSON.parse(response.payload);
                //this.ImporteIngresos = response.payload;
                break;
              case "E":
                this.verEgreso = true;
                this.importesCantidadE = JSON.parse(response.payload);
                //this.ImporteEgresos = response.payload;
                break;
              case "P":
                //this.totalPagos = item.cantidad;
                //this.ImportePagos = item.importe;
                break;
              case "T":
                //this.totalTraslados = item.cantidad;
                //this.ImporteTraslados = item.importe;
                break;
            }
          }
        });
    },

    getReportConciliatorioCantidades() {
      this.totalIngresos = 0;
      this.totalEgresos = 0;
      this.totalPagos = 0;
      this.totalTraslados = 0;

      this.ImporteIngresos = 0;
      this.ImporteEgresos = 0;
      this.ImportePagos = 0;
      this.ImporteTraslados = 0;

      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        onlyCancelled: this.esCancelado === 'true',
        rfc: this.rfcSearch,
        dateTs: this.dateTs,
        docType: this.getTipoDoc(),
      };

      service
        .api()
        .ReporteConciliatorioConteoPorDoc(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.showCantidades = true;
            let data = JSON.parse(response.payload);
            data.forEach((item) => {
              switch (item.tipo_comprobante) {
                case "I":
                  this.totalIngresos = item.cantidad;
                  //this.ImporteIngresos = item.importe;
                  break;
                case "E":
                  this.totalEgresos = item.cantidad;
                  //this.ImporteEgresos = item.importe;
                  break;
                case "P":
                  this.totalPagos = item.cantidad;
                  //this.ImportePagos = item.importe;
                  break;
                case "T":
                  this.totalTraslados = item.cantidad;
                  //this.ImporteTraslados = item.importe;
                  break;
              }
            });
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    cerrarStatus() {
      this.showDialogStatus = false;
      if (this.selectedStatus.estado == "Cancelado") this.getReport();
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoDocIn() {
      let firstLetter = this.tipoDocIn.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
  },
  computed: {
    itemsRegimenes() {
      let itemsReg = helpers.RegimenesConcat;
      let nuevoObjeto = { codigo: "0", name: "Seleccionar Todos" };
      itemsReg.unshift(nuevoObjeto);
      return itemsReg;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto === "E";
    },
    esRecibido() {
      return this.contexto === "R";
    },

    esCan() {
      return this.esCancelado === "true";
    },
    showSearchButton() {
      return this.date.length > 0 && this.date2.length > 0;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },
    labelRfc() {
      if (this.contexto === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    showCreateButton() {
      return this.descripcionIn.length > 0;
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
