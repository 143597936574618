<template>
    
    <div class="py-10">
        <v-container fluid class="px-10">
            <estatusdoc ref="estatusd" ></estatusdoc>
            <v-row>
                
                <v-col cols="12">
                    <h3>Relaciones no permitidas en CFDIs de Pago

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                                    href="https://soporte.mysuitemex.com/portal/es/kb/articles/asociaciones-no-permitidas"
                                    target="_blank">
                                    <v-icon>mdi-help-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Manual</span>
                        </v-tooltip>
                    </h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="12" md="4" class="px-2 mx-md-10">
                                <date-range-picker v-model="selectedDates"></date-range-picker>
                            </v-col>

                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto"
                                    @change="items = []" label="Contexto" outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-btn color="primary" white large v-on:click="getReport"
                                    :disabled="!showSearchButton">Mostrar
                                    Listado</v-btn>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} pagos de
                            {{ totalDocumentos }}</v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>
                        <v-tooltip top class="d-none d-sm-flex">
                            <template class="d-none d-sm-flex" v-slot:activator="{ on }">
                                <v-icon class="d-none d-sm-flex" @click="getReportePaymentNFCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span class="d-none d-sm-flex">Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details
                                class="ma-3 anchoP"></v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                                        color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2"
                                color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>


                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :frozenColumns="4" :initialized="initGrid" :allowResizing="'None'">


                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Folio Fiscal DR'" :binding="'id_documento'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Serie DR'" :binding="'serie'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio DR'" :binding="'folio'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Metodo de Pago DR'" :binding="'metodo_pago_dr'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda DR'" :binding="'moneda_dr'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio DR'" :binding="'tipo_cambio_dr'" width="2*"
                                format="c2" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Importe Pagado DR'" :binding="'imp_pagado'" width="2*"
                                format="c2" :isReadOnly="true" />


                            <wj-flex-grid-column :header="' '" width="6*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML del CFDI de Pago</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF del CFDI de Pago</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Estado del Documento</span>
                                    </v-tooltip>

                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>



                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Estatusdoc from '../Componentes/EstatusDocumento.vue';

export default {
    name: "PaymentNotAllowed",
    components: {
        DateRangePicker,
        Estatusdoc
    },
    data: () => ({
        selectedDates: [],
        selectedRows: [],
        bKey: 0,
        contexto: "Emitidos",
        goPage: 1,
        limiteDias: 10,
        showDialog: false,
        showDialogPagos: false,
        folioDoc: "",
        modal: false,




        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        rfcClass: false,
        fechaEClass: true,
        itemSelected: {
            id: "",
            emisor: {
                nombre: "",
                regimen: "",
                rfc: "",
            },
            receptor: {
                nombre: "",
                residencia: "",
                rfc: "",
                usocfdi: "",
            },
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
        },
        folioSelected: "",
        contextSelected: "",
        rfcSelected: "",
        itemSelectedPago: [{
            cfdi_pago_folio_fiscal: "",
            cfdi_pago_fecha_emision: "",
            pago_fecha_pago: "",
            pago_moneda: "",
            pago_tipo_cambio: 0,
            pago_monto: 0,
            rel_serie: "",
            rel_folio: "",
            rel_moneda: "",
            rel_tipo_cambio: 0,
            rel_num_parcialidad: 0,
            rel_imp_pagado: 0,
            rel_imp_saldo_anterior: 0,
            rel_imp_saldo_insoluto: 0,
        },],
    }),
    methods: {
        openDialog() {
            this.$refs.estatusd.showDialogStatus = true;
        },
        ConsultarEstadoDoc(item) {
            let contextoEstado = "";
            let rfcE = "";
            let rfcR = "";
            let fechaE = item.fecha_emision;
            let uuid = item.id;
            this.openDialog();
            if(this.esEmitido){
                contextoEstado = "E";
                rfcE = this.$store.state.entity;
                rfcR = item.rfc;
             
            }
            else
            {
                contextoEstado = "R";
                rfcE = item.rfc;
                rfcR = this.$store.state.entity;
            }
            this.$refs.estatusd.ConsultarEstadoDoc(contextoEstado, uuid, fechaE, rfcE, rfcR, 0);

        },
        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }

            grid.columns[grid.columns.length - 1].width = 120;


        },

        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (item.tipo === "R") par = "R||" + item.rfc;
            else par = item.tipo + "||";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },
        verMasDatos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id_pago,
                issuedDate: item.fecha_emision.substr(0, 10),
            };
            service
                .api()
                .CFDIDetail(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.itemSelected = JSON.parse(response.payload);
                        this.showDialog = true;
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        verPagos(item) {
            this.itemSelectedPago = [];
            var jsonData = {
                token: this.$store.state.token,
                uuid_rel: item.id,
                uuid_payment: item.id_pago,
                payment_issuedDate: item.fecha_emision.substr(0, 10),
            };
            service
                .api()
                .ReportePagosyRel(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.itemSelectedPago = JSON.parse(response.payload);
                        this.showDialogPagos = true;
                        this.folioSelected = item.id_pago;
                        this.contextSelected = item.tipo;
                        this.rfcSelected = item.rfc;
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        exportExcel() {
            var headers = {
                FolioFiscal: "Folio Fiscal CFDI",
                FechaEmision: "Fecha Emisión",
                RFC: "RFC",
                Nombre: "Razon Social",
                FechaP: "Fecha Pago",
                MonedaP: "Moneda Pago",
                TipoCambio: "Tipo Cambio Pago",
                Monto: "Monto",
                FolioFiscalP: "Folio Fiscal Documento Relacionado",
                Serie: "Serie",
                Folio: "Folio",
                MetodoPago: "Metodo Pago",
                MonedaDr: "Moneda",
                TipoCambio: "Tipo Cambio",
                NoParcialidad: "Numero Parcialidad",
                ImpPagado: "Importe Pagado",
                ImpSaldoAnterior: "Importe Saldo Anterior",
                ImpSaldoInsoluto: "Importe Saldo Insoluto",
            };
            var itemsFormatted = [];
            this.csvData.forEach((item) => {
                itemsFormatted.push({
                    FolioFiscal: helpers.ConvertToEmpty(item.id),
                    FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
                    RFC: helpers.ConvertToEmpty(item.rfc),
                    Nombre: helpers.ConvertToEmpty(item.nombre),
                    FechaP: helpers.ConvertToEmpty(item.fecha_pago),
                    MonedaP: helpers.ConvertToEmpty(item.moneda_p),
                    TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio_p),
                    Monto: helpers.ConvertToEmpty(item.monto),
                    FolioFiscalP: helpers.ConvertToEmpty(item.id_documento),
                    Serie: helpers.ConvertToEmpty(item.serie),
                    Folio: helpers.ConvertToEmpty(item.folio),
                    MetodoPago: helpers.ConvertToEmpty(item.metodo_pago_dr),
                    MonedaDr: helpers.ConvertToEmpty(item.moneda_dr),
                    TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio_dr),
                    NoParcialidad: helpers.ConvertToEmpty(item.num_parcialidad),
                    ImpPagado: helpers.ConvertToEmpty(item.imp_pagado),
                    ImpSaldoAnterior: helpers.ConvertToEmpty(item.imp_saldo_anterior),
                    ImpSaldoInsoluto: helpers.ConvertToEmpty(item.imp_saldo_insoluto),
                });
            });

            this.csvDataT = itemsFormatted;
            var fileTitle =
                "Listado_Asociaciones_No_Permitidas_" +
                helpers.dateNowCSV().replace(/-/g, "_");
            helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
        },
        start() {
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.date);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
        },
        getReporteItems() {
            this.items = [];
            this.selectedRows = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
            };
            service
                .api()
                .ReportePaymentNotAllowed(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;

                        this.doctosPagina = this.items.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
            };

            service
                .api()
                .ReportePaymentNotAllowedCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReportePaymentNFCSV() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
            };

            service
                .api()
                .ReportePaymentNotAllowedCSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        this.exportExcel();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },

        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.fechaEClass = false;
            this.rfcClass = false;

            switch (columnName) {
                case "rfc":
                    this.rfcClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
            }
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
    },
    computed: {
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto.substr(0, 1) === "E";
        },
        esRecibido() {
            return this.contexto.substr(0, 1) === "R";
        },

        esCan() {
            return this.esCancelado === "true";
        },
        showSearchButton() {
            return this.selectedDates.length > 1;
        },
        labelRfc() {
            if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        }
    },
};
</script>

<style scoped>
.altura {
    height: 50px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}


.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}


.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}


.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
}
</style>
