<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>Parámetros de Auditoría</h3>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="950" max-height="950">
            <v-card-title>
              <v-toolbar>
                <v-toolbar-title class="d-none d-sm-flex"
                  >Emitidos</v-toolbar-title
                >
                <v-spacer class="d-none d-sm-flex"></v-spacer>

                <v-toolbar-items class="pa-3">
                  <v-btn
                    color="primary"
                    white
                    large
                    v-on:click="ActualizarEmitidos"
                    :disabled="!showEmitidosButton"
                    >Actualizar Emitidos</v-btn
                  >
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-row>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="monedasE"
                  :items="itemsMonedas"
                  item-text="name"
                  item-value="codigo"
                  label="Monedas"
                  multiple
                  v-on:change="cambioMonedasE = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ monedasE.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="regimenesE"
                  :items="itemsRegimenes"
                  item-text="name"
                  item-value="codigo"
                  label="Regímenes Fiscales"
                  multiple
                  v-on:change="cambioRegimenesE = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ regimenesE.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="formasPagoE"
                  :items="itemsFormaPago"
                  item-text="name"
                  item-value="codigo"
                  label="Formas de Pago"
                  multiple
                  v-on:change="cambioFormasPagoE = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ formasPagoE.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- <v-col md="3"></v-col>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="usosCfdiE"
                  :items="itemsUsoCFDI"
                  item-text="name"
                  item-value="codigo"
                  label="Uso CFDI"
                  multiple
                  v-on:change="cambioUsosCfdiE = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ usosCfdiE.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col> -->
              <v-col md="3"></v-col>
              <v-col class="d-flex" md="9">
                <v-text-field
                  v-model="cpTextE"
                  label="Lugar Expedición (Código Postal)"
                ></v-text-field>
              </v-col>
              <v-col md="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      md="2"
                      fab
                      dark
                      color="primary"
                      v-on:click="AgregarCP('E')"
                      v-on="on"
                      v-show="cpTextE != ''"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar Código Postal a Lista</span>
                </v-tooltip>
              </v-col>
              
              <v-col class="d-flex" md="5">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(item, index) in cpE"
                        :key="item"
                        class="alturaCP"
                      >
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                fab
                                dark
                                x-small
                                color="nopagadas"
                                v-on:click="EliminarCpE(index)"
                                v-on="on"
                              >
                                <v-icon dark> mdi-minus </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar Código Postal</span>
                          </v-tooltip>
                        </td>
                        <td>{{ item }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="950">
            <v-card-title>
              <v-toolbar>
                <v-toolbar-title class="d-none d-sm-flex"
                  >Recibidos</v-toolbar-title
                >
                <v-spacer class="d-none d-sm-flex"></v-spacer>

                <v-toolbar-items class="pa-3">
                  <v-btn
                    color="primary"
                    white
                    large
                    v-on:click="ActualizarRecibidos"
                    :disabled="!showRecibidosButton"
                    >Actualizar Recibidos</v-btn
                  >
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-row>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="monedasR"
                  :items="itemsMonedas"
                  item-text="name"
                  item-value="codigo"
                  label="Monedas"
                  multiple
                  v-on:change="cambioMonedasR = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ monedasR.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col>
               <v-col class="d-flex" md="9">
                <v-select
                  v-model="regimenesR"
                  :items="itemsRegimenes"
                  item-text="name"
                  item-value="codigo"
                  label="Regimenes Fiscales"
                  multiple
                  v-on:change="cambioRegimenesR = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ regimenesR.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col> 
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="formasPagoR"
                  :items="itemsFormaPago"
                  item-text="name"
                  item-value="codigo"
                  label="Formas de Pago"
                  multiple
                  v-on:change="cambioFormasPagoR = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ formasPagoR.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col>
              <v-col class="d-flex" md="9">
                <v-select
                  v-model="usosCfdiR"
                  :items="itemsUsoCFDI"
                  item-text="name"
                  item-value="codigo"
                  label="Uso CFDI"
                  multiple
                  v-on:change="cambioUsosCfdiR = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ usosCfdiR.length - 4 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col md="3"></v-col>
              <!-- <v-col class="d-flex" md="3">
                <v-text-field
                  v-model="cpTextR"
                  label="Código Postal"
                ></v-text-field>
              </v-col>
              <v-col md="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      md="2"
                      fab
                      dark
                      color="primary"
                      v-on:click="AgregarCP('R')"
                      v-on="on"
                      v-show="cpTextR != ''"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar Codigo Postal a Lista</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex" md="5"> </v-col>
              <v-col class="d-flex" md="5">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(item, index) in cpR"
                        :key="item"
                        class="alturaCP"
                      >
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                fab
                                dark
                                x-small
                                color="nopagadas"
                                v-on:click="EliminarCpR(index)"
                                v-on="on"
                              >
                                <v-icon dark> mdi-minus </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar Código Postal</span>
                          </v-tooltip>
                        </td>
                        <td>{{ item }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "Validaciones",
  data: () => ({
    cpTextR: "",
    cpTextE: "",
    
    
    
    
    usosCfdiE: [],
    regimenesE: [],
    monedasE: [],
    formasPagoE: [],
    formasPagoR: [],
    usosCfdiR: [],
    regimenesR: [],
    monedasR: [],
    cpE: [],
    cpR: [],
    cambioUsosCfdiE: false,
    cambioRegimenesE: false,
    cambioMonedasE: false,
    cambioFormasPagoE: false,
    cambioUsosCfdiR: false,
    cambioRegimenesR: false,
    cambioMonedasR: false,
    cambioFormasPagoR: false,
    cambioCpE: false,
    cambioCpR: false,
    formasPagoR: "",
    rfc: "",
    itemsUsoCFDI: helpers.UsosCFDIsConcat,
    itemsRegimenes: helpers.RegimenesConcat,
    itemsMonedas: helpers.MonedasConcat,
    itemsFormaPago: helpers.FormasPagoConcat,
    idUsosCfdiE: "",
    idRegimenesE: "",
    idMonedasE: "",
    idFormasPagoE: "",
    idUsosCfdiR: "",
    idRegimenesR: "",
    idMonedasR: "",
    idFormasPagoR: "",
    idCpE: "",
    idCpR: "",
    cpText: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
    this.rfc = this.$store.state.entity;
    this.Obtener();
  },
  methods: {
    EliminarCpE(index) {
      this.cambioCpE = true;
      this.cpE.splice(index, 1);
    },
    EliminarCpR(index) {
      this.cambioCpR = true;
      this.cpR.splice(index, 1);
    },
    AgregarCP(contexto) {
      if (contexto == "E") {
        this.cambioCpE = true;
        if (this.cpTextE != "")
          if (!this.cpE.includes(this.cpTextE)) this.cpE.push(this.cpTextE);
        this.cpTextE = "";
      } else {
        this.cambioCpR = true;
        if (this.cpTextR != "")
          if (!this.cpR.includes(this.cpTextR)) this.cpR.push(this.cpTextR);
        this.cpTextR = "";
      }
    },
    Obtener() {
      var jsonData = {
        token: this.$store.state.token,
      };
      service
        .api()
        .RetrieveValidaciones(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let payload = JSON.parse(response.payload);

            payload.forEach((item) => {
              let str = item.particion;
              const myArr = str.split("-");

              if (item.contexto == "E") {
                switch (myArr[1]) {
                  case "USOCFDI":
                    this.usosCfdiE = item.valor;
                    this.idUsosCfdiE = item.id;
                    break;
                  case "REGIMENFISCAL":
                    this.regimenesE = item.valor;
                    this.idRegimenesE = item.id;
                    break;
                  case "MONEDA":
                    this.monedasE = item.valor;
                    this.idMonedasE = item.id;
                    break;
                  case "FORMAPAGO":
                    this.formasPagoE = item.valor;
                    this.idFormasPagoE = item.id;
                    break;
                  case "CP":
                    this.cpE = item.valor;
                    this.idCpE = item.id;
                    break;
                }
              } else {
                switch (myArr[1]) {
                  case "USOCFDI":
                    this.usosCfdiR = item.valor;
                    this.idUsosCfdiR = item.id;
                    break;
                  case "REGIMENFISCAL":
                    this.regimenesR = item.valor;
                    this.idRegimenesR = item.id;
                    break;
                  case "MONEDA":
                    this.monedasR = item.valor;
                    this.idMonedasR = item.id;
                    break;
                  case "FORMAPAGO":
                    this.formasPagoR = item.valor;
                    this.idFormasPagoR = item.id;
                    break;
                  case "CP":
                    this.cpR = item.valor;
                    this.idCpR = item.id;
                    break;
                }
              }
            });
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    ActualizarEmitidos() {
     // if (this.cambioUsosCfdiE) this.ActualizarUsoCFDIE();

      if (this.cambioRegimenesE) this.ActualizarRegimenesE();

      if (this.cambioMonedasE) this.ActualizarMonedaE();

      if (this.cambioFormasPagoE) this.ActualizarFormaPagoE();

      if (this.cambioCpE) this.ActualizarCpE();
    },
    ActualizarRecibidos() {
      if (this.cambioUsosCfdiR) this.ActualizarUsoCFDIR();

      if (this.cambioRegimenesR) this.ActualizarRegimenesR();

      if (this.cambioMonedasR) this.ActualizarMonedaR();

      if (this.cambioFormasPagoR) this.ActualizarFormaPagoR();

      if (this.cambioCpR) this.ActualizarCpR();
    },

    ActualizarUsoCFDIE() {
      var jsonDataUsoCFDI = "";
      var nuevoUsoCFDI = false;

      if (this.idUsosCfdiE != "") {
        jsonDataUsoCFDI = {
          token: this.$store.state.token,
          partition: this.rfc + "-USOCFDI",
          id: this.idUsosCfdiE,
          context: "E",
          value: this.usosCfdiE,
        };
        nuevoUsoCFDI = false;
      } else {
        jsonDataUsoCFDI = {
          token: this.$store.state.token,
          kind: "USOCFDI",
          context: "E",
          value: this.usosCfdiE,
        };
        nuevoUsoCFDI = true;
      }

      if (nuevoUsoCFDI) {
        service
          .api()
          .AddValidaciones(jsonDataUsoCFDI)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioUsosCfdiE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataUsoCFDI)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioUsosCfdiE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarRegimenesE() {
      var jsonDataRegimenes = "";
      var nuevoRegimenes = false;
      if (this.idRegimenesE != "") {
        jsonDataRegimenes = {
          token: this.$store.state.token,
          partition: this.rfc + "-REGIMENFISCAL",
          id: this.idRegimenesE,
          context: "E",
          value: this.regimenesE,
        };
        nuevoRegimenes = false;
      } else {
        jsonDataRegimenes = {
          token: this.$store.state.token,
          kind: "REGIMENFISCAL",
          context: "E",
          value: this.regimenesE,
        };
        nuevoRegimenes = true;
      }
      if (nuevoRegimenes) {
        service
          .api()
          .AddValidaciones(jsonDataRegimenes)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioRegimenesE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataRegimenes)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioRegimenesE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarMonedaE() {
      var jsonDataMoneda = "";
      var nuevoMoneda = false;
      if (this.idMonedasE != "") {
        jsonDataMoneda = {
          token: this.$store.state.token,
          partition: this.rfc + "-MONEDA",
          id: this.idMonedasE,
          context: "E",
          value: this.monedasE,
        };
        nuevoMoneda = false;
      } else {
        jsonDataMoneda = {
          token: this.$store.state.token,
          kind: "MONEDA",
          context: "E",
          value: this.monedasE,
        };
        nuevoMoneda = true;
      }

      if (nuevoMoneda) {
        service
          .api()
          .AddValidaciones(jsonDataMoneda)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioMonedasE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataMoneda)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioMonedasE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarFormaPagoE() {
      var jsonFormaPago = "";
      var nuevoFormaPago = false;
      if (this.idFormasPagoE != "") {
        jsonFormaPago = {
          token: this.$store.state.token,
          partition: this.rfc + "-FORMAPAGO",
          id: this.idFormasPagoE,
          context: "E",
          value: this.formasPagoE,
        };
        nuevoFormaPago = false;
      } else {
        jsonFormaPago = {
          token: this.$store.state.token,
          kind: "FORMAPAGO",
          context: "E",
          value: this.formasPagoE,
        };
        nuevoFormaPago = true;
      }

      if (nuevoFormaPago) {
        service
          .api()
          .AddValidaciones(jsonFormaPago)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioFormasPagoE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonFormaPago)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioFormasPagoE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },

    ActualizarUsoCFDIR() {
      var jsonDataUsoCFDI = "";
      var nuevoUsoCFDI = false;

      if (this.idUsosCfdiR != "") {
        jsonDataUsoCFDI = {
          token: this.$store.state.token,
          partition: this.rfc + "-USOCFDI",
          id: this.idUsosCfdiR,
          context: "R",
          value: this.usosCfdiR,
        };
        nuevoUsoCFDI = false;
      } else {
        jsonDataUsoCFDI = {
          token: this.$store.state.token,
          kind: "USOCFDI",
          context: "R",
          value: this.usosCfdiR,
        };
        nuevoUsoCFDI = true;
      }

      if (nuevoUsoCFDI) {
        service
          .api()
          .AddValidaciones(jsonDataUsoCFDI)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioUsosCfdiR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataUsoCFDI)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioUsosCfdiR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarRegimenesR() {
      var jsonDataRegimenes = "";
      var nuevoRegimenes = false;
      if (this.idRegimenesR != "") {
        jsonDataRegimenes = {
          token: this.$store.state.token,
          partition: this.rfc + "-REGIMENFISCAL",
          id: this.idRegimenesR,
          context: "R",
          value: this.regimenesR,
        };
        nuevoRegimenes = false;
      } else {
        jsonDataRegimenes = {
          token: this.$store.state.token,
          kind: "REGIMENFISCAL",
          context: "R",
          value: this.regimenesR,
        };
        nuevoRegimenes = true;
      }
      if (nuevoRegimenes) {
        service
          .api()
          .AddValidaciones(jsonDataRegimenes)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.regimenesR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataRegimenes)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.regimenesR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarMonedaR() {
      var jsonDataMoneda = "";
      var nuevoMoneda = false;
      if (this.idMonedasR != "") {
        jsonDataMoneda = {
          token: this.$store.state.token,
          partition: this.rfc + "-MONEDA",
          id: this.idMonedasR,
          context: "R",
          value: this.monedasR,
        };
        nuevoMoneda = false;
      } else {
        jsonDataMoneda = {
          token: this.$store.state.token,
          kind: "MONEDA",
          context: "R",
          value: this.monedasR,
        };
        nuevoMoneda = true;
      }

      if (nuevoMoneda) {
        service
          .api()
          .AddValidaciones(jsonDataMoneda)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioMonedasR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonDataMoneda)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioMonedasR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarFormaPagoR() {
      var jsonFormaPago = "";
      var nuevoFormaPago = false;
      if (this.idFormasPagoR != "") {
        jsonFormaPago = {
          token: this.$store.state.token,
          partition: this.rfc + "-FORMAPAGO",
          id: this.idFormasPagoR,
          context: "R",
          value: this.formasPagoR,
        };
        nuevoFormaPago = false;
      } else {
        jsonFormaPago = {
          token: this.$store.state.token,
          kind: "FORMAPAGO",
          context: "R",
          value: this.formasPagoR,
        };
        nuevoFormaPago = true;
      }

      if (nuevoFormaPago) {
        service
          .api()
          .AddValidaciones(jsonFormaPago)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioFormasPagoR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonFormaPago)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioFormasPagoR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarCpE() {
      var jsonCP = "";
      var nuevoCP = false;
      if (this.idCpE != "") {
        jsonCP = {
          token: this.$store.state.token,
          partition: this.rfc + "-CP",
          id: this.idCpE,
          context: "E",
          value: this.cpE,
        };
        nuevoCP = false;
      } else {
        jsonCP = {
          token: this.$store.state.token,
          kind: "CP",
          context: "E",
          value: this.cpE,
        };
        nuevoCP = true;
      }

      if (nuevoCP) {
        service
          .api()
          .AddValidaciones(jsonCP)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioCpE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonCP)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioCpE = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
    ActualizarCpR() {
      var jsonCP = "";
      var nuevoCP = false;
      if (this.idCpR != "") {
        jsonCP = {
          token: this.$store.state.token,
          partition: this.rfc + "-CP",
          id: this.idCpR,
          context: "R",
          value: this.cpR,
        };
        nuevoCP = false;
      } else {
        jsonCP = {
          token: this.$store.state.token,
          kind: "CP",
          context: "R",
          value: this.cpR,
        };
        nuevoCP = true;
      }

      if (nuevoCP) {
        service
          .api()
          .AddValidaciones(jsonCP)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioCpR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
          .UpdateValidaciones(jsonCP)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
this.$store.commit("setAlertMessage", "Guardado correctamente");
              this.cambioCpR = false;
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },

    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    getImpuesto(value) {
      return helpers.Impuesto(helpers.ConvertToEmpty(value));
    },
    getContexto() {
      let firstLetter = this.contextDoc.substr(0, 1);
      return firstLetter;
    },
  },
  computed: {
    showEmitidosButton() {
      return (
        this.cambioUsosCfdiE ||
        this.cambioRegimenesE ||
        this.cambioMonedasE ||
        this.cambioFormasPagoE ||
        this.cambioCpE
      );
    },
    showRecibidosButton() {
      return (
        this.cambioUsosCfdiR ||
        this.cambioRegimenesR ||
        this.cambioMonedasR ||
        this.cambioFormasPagoR ||
        this.cambioCpR
      );
    },
  },
};
</script>

<style scoped>
.alturaCP {
  height: 30px;
}
</style>
