<template>
  <div class="home">
    <Conciliatorio titulo="Reporte de Ingresos y Egresos - Recibidos" contexto="R" es-cancelado="true" />
  </div>
</template>

<script>

import Conciliatorio from "./Breakdown.vue";

export default {
  components: {
    Conciliatorio
  }
};
</script>