<template>
    <v-dialog v-model="showDialogRiesgos" fullscreen persistent min-width="800px" max-width="1000px">

        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
        <v-dialog v-model="showDialogPagos" persistent width="1700px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showDialogPagos = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Pagos y sus características como documento relacionado:
                        {{ folioSelected }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <br />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th colspan="2" style="background-color: #90caf9">
                                        Datos del CFDI de Pago
                                    </th>
                                    <th colspan="4" style="background-color: #bbdefb">
                                        Datos del Pago
                                    </th>
                                    <th colspan="11" style="background-color: #e3f2fd">
                                        Datos del Documento Relacionado
                                    </th>
                                </tr>
                                <tr>
                                    <th style="white-space: nowrap">Folio Fiscal</th>
                                    <th style="white-space: nowrap">Fecha Emisión</th>
                                    <th style="white-space: nowrap">Fecha Pago</th>
                                    <th>Moneda</th>
                                    <th align="right" style="white-space: nowrap">
                                        Tipo Cambio
                                    </th>
                                    <th>Monto</th>
                                    <th>Serie</th>
                                    <th>Folio</th>
                                    <th style="white-space: nowrap">Método Pago</th>
                                    <th>Moneda</th>
                                    <th align="right" style="white-space: nowrap">
                                        Tipo Cambio
                                    </th>
                                    <th align="right" style="white-space: nowrap">
                                        Número Parcialidad
                                    </th>
                                    <th align="right" style="white-space: nowrap">
                                        Importe Pagado
                                    </th>
                                    <th align="right" style="white-space: nowrap">
                                        Saldo Anterior
                                    </th>
                                    <th align="right" style="white-space: nowrap">
                                        Saldo Insoluto
                                    </th>
                                    <th align="center" style="white-space: nowrap"></th>
                                    <th align="center" style="white-space: nowrap"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura" v-for="itemS in itemSelectedPago">
                                    <td>
                                        {{ itemS.cfdi_pago_folio_fiscal }}
                                    </td>
                                    <td>
                                        {{ itemS.cfdi_pago_fecha_emision.substring(0, 10) }}
                                    </td>

                                    <td>{{ itemS.pago_fecha_pago.substring(0, 10) }}</td>
                                    <td>{{ itemS.pago_moneda }}</td>
                                    <td align="right">
                                        {{ currencyFormat6(itemS.pago_tipo_cambio) }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemS.pago_monto) }}
                                    </td>
                                    <td>{{ itemS.rel_serie }}</td>
                                    <td>{{ itemS.rel_folio }}</td>
                                    <td>{{ itemS.rel_metodo_pago }}</td>
                                    <td>{{ itemS.rel_moneda }}</td>
                                    <td align="right">{{ itemS.rel_tipo_cambio }}</td>
                                    <td align="right">{{ itemS.rel_num_parcialidad }}</td>
                                    <td align="right">
                                        {{ currencyFormat(itemS.rel_imp_pagado) }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemS.rel_imp_saldo_anterior) }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemS.rel_imp_saldo_insoluto) }}
                                    </td>
                                    <td class="pa-0 md-0">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="retrievePagoXML(itemS)" icon color="blue" v-on="on">
                                                    <v-icon>mdi-xml</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar XML del CFDI de pago</span>
                                        </v-tooltip>
                                    </td>
                                    <td class="pa-0 md-0">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="retrievePagoPDF(itemS)" icon color="red" v-on="on">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar PDF del CFDI de pago</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDialogPagos = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card v-if="showExt">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>CFDIs de Pago extemporáneos</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsEx.length > 0">
                <wj-flex-grid :itemsSource="itemsEx" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
                    :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
                    :initialized="initGrid" :allowResizing="'None'">


                    <wj-flex-grid-column :header="'Folio Fiscal del Comprobante de Pago'" :binding="'id'" width="2*"
                        :isReadOnly="true" />


                    <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                        :isReadOnly="true" />
                    <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*" :isReadOnly="true" />
                    <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                        :isReadOnly="true" />
                    <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                        :isReadOnly="true" />



                    <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                        :isReadOnly="true" />
                    <wj-flex-grid-column :header="'Fecha Pago'" :binding="'fecha_pago'" width="2*" :isReadOnly="true" />

                    <wj-flex-grid-column :header="'Monto Pago'" :binding="'monto'" width="2*" format="c2"
                        :isReadOnly="true" />
                    <wj-flex-grid-column :header="'Moneda Pago'" :binding="'moneda_p'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="'Tipo Cambio Pago'" :binding="'tipo_cambio_p'" width="2*" format="n6"
                        :isReadOnly="true" />
                    <wj-flex-grid-column :header="'Cantidad Documentos'" :binding="'cantidad_docs'" width="2*"
                        :isReadOnly="true" />


                    <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                        <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                        <v-icon>mdi-eye-plus-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver Más</span>
                            </v-tooltip>
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                        <v-icon>mdi-xml</v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar XML</span>
                            </v-tooltip>
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                        <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar PDF</span>
                            </v-tooltip>


                        </wj-flex-grid-cell-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>

            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>
        <v-card v-if="showMetodo">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>CFDIs con Inconsistencias entre Método y Forma de Pago</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsMetodo.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <wj-flex-grid :itemsSource="itemsMetodo" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :frozenColumns="2" :initialized="initGrid" :allowResizing="'None'">

                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" cssClass="main-column" />
                            <wj-flex-grid-column :header="'Forma de Pago'" width="2*" :isReadOnly="true"
                                cssClass="main-column">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getFormaPago(cell.item.forma_pago) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor_rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor_nombre'" width="4*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>

                    </v-card>
                    <br />
                </v-col>
            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>

        <v-card v-if="showInsoluto">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Saldo pendiente en CFDIs con método de pago PPD</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsInsoluto.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <wj-flex-grid :itemsSource="itemsInsoluto" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGridInso" :allowResizing="'None'">

                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total MXN'" :binding="'totalmxn'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Retención ISR'" :binding="'retencion_isr'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Retención IVA'" :binding="'retencion_iva'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Retención IEPS'" :binding="'retencion_ieps'" width="2*"
                                format="c2" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Traslado IVA'" :binding="'traslado_iva'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Traslado IEPS'" :binding="'traslado_ieps'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Importe Pagado MXN'" :binding="'importe_pagado_mxn'" width="2*"
                                format="c2" :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Pendiente MXN'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-chip :color="getColor(cell.item.estado)" dark>{{
                                        currencyFormat(calculatePendiente(cell.item))
                                    }}</v-chip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>



                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Información de Pagos'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                                    <table v-if="cell.item.pagos.length > 3">
                                        <tbody>
                                            <tr class="borde">
                                                <th class="text-no-wrap caption">
                                                    <strong>Folio de Pago</strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Fecha Emisión P&nbsp;</strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Fecha de Pago </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Moneda P </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Tipo Cambio P </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Monto P </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Importe Pagado </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Saldo Anterior </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Saldo Insoluto </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Moneda DR </strong>
                                                </th>
                                                <th class="text-no-wrap caption">
                                                    <strong>Tipo Cambio DR </strong>
                                                </th>
                                                <th class="text-no-wrap caption"> <strong>Diferencia Cambiaria</strong></th>
                                            </tr>
                                            <template>
                                                <tr class="altura caption" :key="index"
                                                    v-for="(pago, index) in JSON.parse(cell.item.pagos)" :style="{
                                                        backgroundColor: pago.cancelado
                                                            ? '#FBE9E7'
                                                            : 'transparent',
                                                    }">
                                                    <td class="text-no-wrap" style="width: 30%">
                                                        {{ pago.id }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="center">
                                                        {{ pago.fecha_emision.substring(0, 10) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="center">
                                                        {{ pago.fecha_pago.substring(0, 10) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 10%" align="center">
                                                        {{ pago.moneda_p }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 10%" align="right">
                                                        {{ pago.tipo_cambio_p }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="right">
                                                        {{ currencyFormat(pago.monto) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="right">
                                                        {{ currencyFormat(pago.imp_pagado) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="right">
                                                        {{ currencyFormat(pago.imp_saldo_anterior) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 20%" align="right">
                                                        {{ currencyFormat(pago.imp_saldo_insoluto) }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 10%" align="center">
                                                        {{ pago.moneda_dr }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 5%" align="right">
                                                        {{ pago.tipo_cambio_dr }}
                                                    </td>
                                                    <td class="text-no-wrap" style="width: 5%" align="right">
                                                        {{ currencyFormat(pago.diferencia_cambiaria) }}
                                                    </td>

                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>



                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                        </wj-flex-grid>
                    </v-card>
                    <br />
                </v-col>
            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>

        <v-card v-if="showCanceladosPago">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Facturas canceladas que están en Comprobantes de Pago</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsCancelPagos.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>




                        <wj-flex-grid :itemsSource="itemsCancelPagos" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGridCancelPagos" :allowResizing="'None'">


                            <wj-flex-grid-column :header="'Folio Fiscal'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ cell.item.id.split("|")[0] }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Folio Fiscal del Comprobante de Pago'" width="2*"
                                :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ cell.item.id.split("|")[1] }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>


                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión del CFDI de Pago'" :binding="'fecha_emision_p'"
                                width="2*" :isReadOnly="true" />


                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatosCancelPagos(cell.item)" icon color="#4aa832"
                                                v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXMLCancelPago(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDFCancelPagos(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verPagos(cell.item)" icon color="green" v-on="on">
                                                <v-icon>mdi-cash-multiple</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver pagos y sus características como documento
                                            relacionado</span>
                                    </v-tooltip>

                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>

                    </v-card>
                    <br />
                </v-col>
            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>

        <v-card v-if="showNotFound">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>CFDIs de Pago con documento relacionado inexistente</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsNotFound.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>

                        <wj-flex-grid :itemsSource="itemsNotFound" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGrid" :allowResizing="'None'">

                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal del Comprobante de Pago'" :binding="'id_pago'"
                                width="2*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id_pago)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXMLNotFound(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDFNotFound(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>

                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>

                    </v-card>
                    <br />
                </v-col>
            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>


        <v-card v-if="showConciliatorio">

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialogRiesgos">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ tituloConciliatorio }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemsConciliatorio.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos }}
                        </v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>

                        <wj-flex-grid :itemsSource="itemsConciliatorio" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">


                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor_rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor_nombre'" width="4*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="'Versión'" :binding="'version'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Retención ISR'" :binding="'retencion_isr'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Retención IVA'" :binding="'retencion_iva'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Retención IEPS'" :binding="'retencion_ieps'" width="2*"
                                format="c2" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Traslado IVA'" :binding="'traslado_iva'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Traslado IEPS'" :binding="'traslado_ieps'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total Retenciones'" :binding="'total_retenciones'" width="2*"
                                format="c2" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total Traslados'" :binding="'total_traslados'" width="2*"
                                format="c2" :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>

                    </v-card>
                    <br />
                </v-col>
            </v-card-text>
            <v-card-text v-else>
                <br /><br />
                <h3>{{ mensajeDocs }}</h3>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="closeDialogRiesgos">Cerrar</v-btn>
            </v-card-actions>



        </v-card>
    </v-dialog>
</template>
<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';



export default {
    name: "RiesgosFiscalesComponent",
    components: {
        Dialogo
    },
    props: {
        parametros: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            tipoConciliatorio: "",
            offest: 0,
            limit: 50,
            pageSize: "50",
            noPagina: 1,
            totalPaginas: 1,
            doctosPagina: 0,
            totalDocumentos: 0,
            paginations: ["25", "50", "100", "150", "200", "500"],
            currentOrder: "DESC",
            currentOrderColumn: "nfecha_emision",
            dateOne: "",
            dateTwo: "",
            contextReport: "",
            itemFind: {
                id: "",
                emisor_rfc: "",
                emisor_nombre: "",
                regimen: "",
                receptor_nombre: "",
                residencia: "",
                receptor_rfc: "",
                usocfdi: "",
                fecha_cancelacion: "",
                fecha_carga: "",
                fecha_emision: "",
                fecha_timbre: "",
                folio: "",
                forma_pago: "",
                descuento: 0,
                lugar_expedicion: "",
                metodo_pago: "",
                moneda: "",
                serie: "",
                subtotal: 0,
                tipo: "",
                tipo_cambio: 1,
                tipo_comprobante: "",
                total: 0,
                total_retenciones: 0,
                total_traslados: 0,
                retencion_isr: 0,
                retencion_iva: 0,
                retencion_ieps: 0,
                traslado_iva: 0,
                traslado_ieps: 0,
            },
            itemsEx: [],
            itemsMetodo: [],
            itemsInsoluto: [],
            itemsCancelPagos: [],
            itemsNotFound: [],
            showDialogRiesgos: false,
            showExt: false,
            showMetodo: false,
            showInsoluto: false,
            showCanceladosPago: false,
            date: "",
            contexto: "",
            mensajeDocs: "Cargando Documentos...",
            itemSelectedPago: {},
            folioSelected: "",
            contextSelected: "",
            rfcSelected: "",
            showDialogPagos: false,
            showNotFound: false,

            showConciliatorio: false,
            tituloConciliatorio: "",
            itemsConciliatorio: []

        };
    },
    methods: {


        verPagos(item) {
            this.itemSelectedPago = [];
            var jsonData = {
                token: this.$store.state.token,
                uuid_rel: item.id.split("|")[0],
                uuid_payment: item.id.split("|")[1],
                payment_issuedDate: item.fecha_emision_p.substr(0, 10),
            };
            service
                .api()
                .ReportePagosyRel(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.itemSelectedPago = JSON.parse(response.payload);
                        this.showDialogPagos = true;
                        this.folioSelected = item.id.split("-")[1];
                        this.contextSelected = item.tipo;
                        this.rfcSelected = item.rfc;
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "N":
                    tipo = "Nomina";
                    break;
            }
            return tipo;
        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReportRiesgos();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReportRiesgos();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReportRiesgos();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReportRiesgos();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReportRiesgos();
            else this.noPagina = this.goPage = actualPage;

        },
        changePagination() {
            if (this.showMetodo) {
                this.getReportMetodo();
            }
            else if (this.showCanceladosPago) {
                this.getReporteItemsCancelPagos();
            }
        },

        getReportRiesgos() {
            if (this.showMetodo) {
                this.getReporteItemsMetodo();
            }
            else if (this.showInsoluto) {
                this.getReporteItemsInsoluto();
            }
            else if (this.showCanceladosPago) {
                this.getReporteItemsCancelPagos();
            }
            else if (this.showCanceladosPago) {
                this.getReporteItemsNotFound();
            }
            else if (this.showConciliatorio) {


                switch (tipo) {

                    case "1":
                        this.getItemsConciliatorioSustituidosNo();

                        break;
                    case "2":

                        this.getItemsConciliatorioSustituidos();
                        break;
                    case "3":

                        this.getItemsConciliatorioRelacionados();
                        break;

                }

              
            }
        },
        closeDialogRiesgos() {
            this.mensajeDocs = "Cargando Documentos...";
            this.showExt = false;
            this.showMetodo = false;
            this.showInsoluto = false;
            this.showDialogRiesgos = false;
            this.showCanceladosPago = false;
            this.showNotFound = false;

        },
        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }


            grid.columns[grid.columns.length - 1].width = 200;


        },

        initGridInso: function (grid) {
            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }
            for (let i = 0; i < grid.rows.length; i++) {
                grid.autoSizeRows(i);
            }


            grid.columns[grid.columns.length - 2].width = 120;
            grid.columns[grid.columns.length - 1].width += 80;

        },
        initGridCancelPagos: function (grid) {

            grid.columns[grid.columns.length - 1].width = 200;

        },
        getReporteItems(dateParameter, contexto) {

            this.contextReport = contexto;
            let dateIn = dateParameter + "";
            //alert(dateIn.length);
            if (dateIn) {

                let dateInit = "";
                let dateEnd = "";
                if (dateIn.length < 5) {
                    dateInit = dateIn + "-01-01";
                    dateEnd = dateIn + "-12-31";
                }
                else {
                    dateInit = dateIn + "-01";
                    dateEnd = dateIn + "-31";
                }


                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.itemsEx = [];
                var jsonData = {
                    token: this.$store.state.token,
                    dateInit: dateInit,
                    dateEnd: dateEnd,
                    context: contexto,
                    rfc: "",
                };
                service
                    .api()
                    .ReportePaymentEx(jsonData)
                    .then((response) => {
                        if (response.result) {
                            let payload = JSON.parse(response.payload);
                            this.itemsEx = payload;
                            this.showDialogRiesgos = true;
                        } else {
                            this.mensajeDocs = "Sin documentos";

                        }
                    });
            }
            else
                this.mensajeDocs = "Sin documentos";
        },


        getReportNotFound(dateParameter, contexto) {
            let dateIn = dateParameter + "";
            if (dateIn) {

                this.contextReport = contexto;
                if (dateIn.length < 5) {
                    this.dateOne = dateIn + "-01-01";
                    this.dateTwo = dateIn + "-12-31";
                }
                else {
                    this.dateOne = dateIn + "-01";
                    this.dateTwo = dateIn + "-31";
                }
                this.goPage = 1;
                this.offest = 0;
                this.noPagina = 1;
                this.limit = this.pageSize;
                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.getTotalCountNotFound();
            }
            else
                this.mensajeDocs = "Sin documentos";
        },
        getReporteItemsNotFound() {
            this.itemsNotFound = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                dateTs: ""
            };
            service
                .api()
                .ReportePaymentNotFound(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsNotFound = payload;

                        this.doctosPagina = this.itemsNotFound.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountNotFound() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                rfc: this.rfcSearch,
                dateTs: this.dateTs
            };

            service
                .api()
                .ReportePaymentNotFoundCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItemsNotFound();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },



        getReportConciliatorio(dateParameter, contexto, tipo) {
            let dateIn = dateParameter + "";
            if (dateIn) {

                this.contextReport = contexto;
                if (dateIn.length < 5) {
                    this.dateOne = dateIn + "-01-01";
                    this.dateTwo = dateIn + "-12-31";
                }
                else {
                    this.dateOne = dateIn + "-01";
                    this.dateTwo = dateIn + "-31";
                }
                this.goPage = 1;
                this.offest = 0;
                this.noPagina = 1;
                this.limit = this.pageSize;
                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.tipoConciliatorio = tipo;


                switch (tipo) {

                    case "1":
                        this.getTotalCountConciliatorioSustituidosNo();

                        break;
                    case "2":

                        this.getTotalCountConciliatorioSustituidos();
                        break;
                    case "3":

                        this.getTotalCountConciliatorioRelacionados();
                        break;

                }


            }
            else
                this.mensajeDocs = "Sin documentos";
        },
        getItemsConciliatorioSustituidosNo() {
            this.itemsConciliatorio = [];

            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_SustituidosNoCancelados: true

            };


            service
                .api()
                .ReporteConciliatorio(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsConciliatorio = payload;

                        this.doctosPagina = this.itemsConciliatorio.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountConciliatorioSustituidosNo() {

            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_SustituidosNoCancelados: true

            };


            service
                .api()
                .ReporteConciliatorioCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getItemsConciliatorioSustituidosNo();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },

        getItemsConciliatorioSustituidos() {
            this.itemsConciliatorio = [];


            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_NoSustituidosCancelados: true

            };


            service
                .api()
                .ReporteConciliatorio(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsConciliatorio = payload;

                        this.doctosPagina = this.itemsConciliatorio.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountConciliatorioSustituidos() {

            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_NoSustituidosCancelados: true

            };


            service
                .api()
                .ReporteConciliatorioCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getItemsConciliatorioSustituidos();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },

        getItemsConciliatorioRelacionados() {
            this.itemsConciliatorio = [];

            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_RelacionadosInexistentes: true

            };



            service
                .api()
                .ReporteConciliatorio(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsConciliatorio = payload;

                        this.doctosPagina = this.itemsConciliatorio.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountConciliatorioRelacionados() {

            let jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                rfc: "",
                dateTs: "",
                dateTs1: "",
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: "",
                rf_RelacionadosInexistentes: true

            };


            service
                .api()
                .ReporteConciliatorioCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getItemsConciliatorioRelacionados();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },


        getReportMetodo(dateParameter, contexto) {
            let dateIn = dateParameter + "";
            if (dateIn) {

                this.contextReport = contexto;
                if (dateIn.length < 5) {
                    this.dateOne = dateIn + "-01-01";
                    this.dateTwo = dateIn + "-12-31";
                }
                else {
                    this.dateOne = dateIn + "-01";
                    this.dateTwo = dateIn + "-31";
                }

                this.goPage = 1;
                this.offest = 0;
                this.noPagina = 1;
                this.limit = this.pageSize;
                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.getTotalCountMetodo();
                if (this.cambio) {
                    this.cambio = false;
                }
            }
            else
                this.mensajeDocs = "Sin documentos";
        },
        getReporteItemsMetodo() {

            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.itemsMetodo = [];


            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                docType: "",
            };
            service
                .api()
                .MismatchPaymentMethodsList(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsMetodo = payload;

                        this.doctosPagina = this.itemsMetodo.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });



        },

        getTotalCountMetodo() {


            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                context: this.contextReport,
                rfc: "",
                docType: "",
            };

            service
                .api()
                .MismatchPaymentMethodsCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItemsMetodo();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });


        },

        getReportInsoluto(dateParameter, contexto) {
            this.mensajeDocs = "Cargando Documentos, este reporte incluye procesamiento que puede tardar, por favor espere...";
            let dateIn = dateParameter + "";
            if (dateIn) {

                this.contextReport = contexto;
                if (dateIn.length < 5) {
                    this.dateOne = dateIn + "-01-01";
                    this.dateTwo = dateIn + "-12-31";
                }
                else {
                    this.dateOne = dateIn + "-01";
                    this.dateTwo = dateIn + "-31";
                }
                this.goPage = 1;
                this.offest = 0;
                this.limit = this.pageSize;
                this.noPagina = 1;
                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.getTotalCountInsoluto();
            }
            else
                this.mensajeDocs = "Sin documentos";
        },
        getReporteItemsInsoluto() {

            this.itemsInsoluto = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                paymentStatus: "NP",
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                uuid: "",
                dateTs: "",
                onlyWithPayments: false,
                paymentMethod: "1",
                docType: ""
            };
            service
                .api()
                .ReporteIncome(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsInsoluto = payload;

                        this.doctosPagina = this.itemsInsoluto.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountInsoluto() {
            this.itemsInsoluto = [];

            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                paymentStatus: "NP",
                rfc: "",
                uuid: "",
                dateTs: "",
                onlyWithPayments: false,
                paymentMethod: "1",
                docType: ""
            };

            service
                .api()
                .ReporteIncomeCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItemsInsoluto();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },

        getReportancelPagos(dateParameter, contexto) {
            let dateIn = dateParameter + "";
            if (dateIn) {

                this.contextReport = contexto;
                if (dateIn.length < 5) {
                    this.dateOne = dateIn + "-01-01";
                    this.dateTwo = dateIn + "-12-31";
                }
                else {
                    this.dateOne = dateIn + "-01";
                    this.dateTwo = dateIn + "-31";
                }
                this.goPage = 1;
                this.offest = 0;
                this.noPagina = 1;
                this.limit = this.pageSize;
                this.$store.commit("setAlertError", false);
                this.errorMessage = "";
                this.getTotalCountCancelPagos();
            } else
                this.mensajeDocs = "Sin documentos";
        },

        getReporteItemsCancelPagos() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: "",
                dateTs: ""
            };
            service
                .api()
                .ReporteRelCancelled(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemsCancelPagos = payload;
                        this.doctosPagina = this.itemsCancelPagos.length.toString();

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCountCancelPagos() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.dateOne,
                dateEnd: this.dateTwo,
                context: this.contextReport,
                rfc: "",
                dateTs: ""
            };

            service
                .api()
                .ReporteRelCancelledCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItemsCancelPagos();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },


        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(id) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: id,
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },

        verMasDatosCancelPagos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id.split("|")[0],
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },

        retrieveXMLCancelPago(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contextReport,
                uuid: item.id.split("|")[0],
                issuedDate: item.fecha_emision_rel,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDFCancelPagos(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextReport === "R")
                par =
                    this.contextReport +
                    "|" +
                    helpers.ConvertToEmpty(item.pac).trim() +
                    "|" +
                    item.rfc;
            else
                par =
                    this.contextReport + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
            let parameters =
                par + "|" + item.id.split("|")[0] + "|" + item.fecha_emision_rel;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);

                this.$store.commit("setOverlay", false);
            }, 3000);
        },


        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contextReport,
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextReport.substr(0, 1) === "R")
                par =
                    this.contextReport.substr(0, 1) +
                    "|" +
                    helpers.ConvertToEmpty(item.pac).trim() +
                    "|" +
                    item.rfc;
            else
                par =
                    this.contextReport.substr(0, 1) + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        retrieveXMLNotFound(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contextReport,
                uuid: item.id_pago,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDFNotFound(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextReport.substr(0, 1) === "R")
                par =
                    this.contextReport.substr(0, 1) +
                    "|" +
                    helpers.ConvertToEmpty(item.pac).trim() +
                    "|" +
                    item.rfc;
            else
                par =
                    this.contextReport.substr(0, 1) + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
            let parameters = par + "|" + item.id_pago + "|" + item.fecha_emision;
            let urlPDF = "";


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },
        retrievePDFPago(item, pago) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.esRecibido) par = "R||" + this.selectedRfc;
            else par = "E||";
            let parameters = par + "|" + pago.id + "|" + pago.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);

                this.$store.commit("setOverlay", false);
            }, 2000);
        },
        getFormaPago(formaP) {
            return helpers.FormaPago(formaP);
        },
        getColor(estado) {
            if (estado === "P") return "pagadas";
            else if (estado === "NP") return "nopagadas";
            else if (estado === "SP") return "sobrepagadas";
        },
        calculatePendiente(item) {

            if (Number(item.totalmxn - item.importe_pagado_mxn) <= 0)
                return 0;

            return Number(item.totalmxn - item.importe_pagado_mxn);
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },

        retrievePagoXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contextSelected,
                uuid: item.cfdi_pago_folio_fiscal,
                issuedDate: item.pago_fecha_pago,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePagoPDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextSelected === "R") par = "R||" + this.selectedRfc;
            else par = "E||";
            let parameters =
                par + "|" + item.cfdi_pago_folio_fiscal + "|" + item.pago_fecha_pago;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 2000);
        },

    },
    computed: {
        esEmitido() {
            return this.contextReport.substr(0, 1) === "E";
        },
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esRecibido() {
            return this.contextReport.substring(0, 1) === "R";
        },
    },
};
</script>

<style scoped>
.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

.main-column,
.main-column.wj-alt {
    font-weight: bolder;
    color: blue;
    background: lightskyblue;
}
</style>