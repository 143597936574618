<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog v-model="showDialog" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Cambio de Contraseña</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="py-3">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="passActual"
                    label="Conseña Actual"
                    outlined
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7"></v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="nuevoPass"
                    label="Nueva Contaseña"
                    outlined
                    type="password"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="confirmacion"
                    label="Confirmación Contaseña"
                    outlined
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                        color="primary"
                        text
                        large
                        v-on:click="changeUserPass"
                        :disabled="!showChangeButton"
                        >Cambiar Contraseña</v-btn
                      >
              <v-btn color="danger" text @click="showDialog = false">
                Cerrrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>Mi Perfil</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    :value="getRFC"
                    label="RFC"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :value="getRazonSocial"
                    label="Razón Social"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :value="getUsuario"
                    label="Usuario"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col v-if="conF" cols="12" md="4">
                  <v-text-field
                    :value="getFielExpirationDate"
                    label="Caducidad FIEL"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox v-model="conF" label="FIEL" disabled></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox
                    v-model="conDM"
                    label="Servicio Descarga Masiva"
                    disabled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox
                    v-model="esAdministrador"
                    label="Administrador"
                    disabled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox
                    v-model="esNomina"
                    label="Nomina"
                    disabled
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showDialog = true">
                Cambiar Contraseña
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../scripts/helpers.js";
import service from "../scripts/services.js";

export default {
  name: "Perfil",
  data: () => ({
    modal: false,
    
    
    
    
    showDialog: false,
    passActual: "",
    nuevoPass: "",
    confirmacion: ""    
  }),
  created() {
    this.$store.commit("setDateInterval", null);
  },
  methods: {
    changeUserPass() {
      this.showDialog = false;

      var jsonData = {
        previous_password: this.passActual,
        new_password: this.nuevoPass,
        new_confirmation_password: this.confirmacion,
        token: this.$store.state.token,
      };
      service
        .api()
        .ChangePass(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Contraseña cambiada con éxito");
            
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          this.passActual = "";
          this.nuevoPass = "";
          this.confirmacion = "";
        });
    },
  },
  computed: {
    conF() {
      return this.$store.state.hasFiel;
    },
    conDM() {
      return this.$store.state.hasAutSatDownload;
    },
    esAdministrador() {
      return this.$store.state.isAdministrator;
    },
    esNomina() {
      return this.$store.state.isAdministrator;
    },
    esInvoice() {
      return this.$store.state.isInvoice;
    },
    getUsuario() {
      return this.$store.state.usuario;
    },
    getRazonSocial() {
      return this.$store.state.userLongName;
    },
    getRFC() {
      return this.$store.state.entity;
    },
    getFielExpirationDate() {
      return helpers.formatFecha(this.$store.state.fielExpirationDate);
    },
    showChangeButton() {
      return (
        this.passActual.length > 0 &&
        this.nuevoPass.length > 0 &&
        this.confirmacion.length > 0
      ) &&  this.nuevoPass.length == this.confirmacion.length;
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}
</style>
