<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row>

        <v-col cols="12" md="4">
          <h3>Coincidencias Lista Negra 69B

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/coincidencias-listas-negras-69b"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12" class="d-flex" md="2" lg="1">
          <v-btn color="primary" white large @click="exportPDF" width="200" v-if="mostrarBotonPdf">
            <v-icon dark> mdi-file-pdf-box </v-icon>Exportar en PDF</v-btn>
          <div v-else class="text-center">
            <v-chip class="ma-2 pa-2" color="red" text-color="white" label large>
              Sin coincidencias
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" md="6">
          <p>
            Procedimiento: se buscaron las coincidencias en la lista del
            artículo 69 de los RFC de emisor y RFC de receptor en todos los
            comprobantes fiscales no cancelados de esta empresa (de clientes y
            proveedores).
          </p>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
      <v-row v-if="mostrar">
        <v-col cols="12" md="2">
          <h4>Fecha Solicitud: {{ fechaMins }}</h4>
        </v-col>
        <v-col cols="12" md="10"> </v-col>
        <v-col cols="12" md="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Desde {{ fechaInicialReport }}<br />
                    Hasta {{ fechaActual }}
                  </th>
                  <th class="text-left">Cantidad de comprobantes revisados</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Comprobantes a Clientes</td>
                  <td>{{ countE }}</td>
                </tr>
                <tr>
                  <td>Comprobantes de Proveedores</td>
                  <td>{{ countR }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <br />
        <!-- <v-col cols="12" v-if="itemsR.length > 0">
          <h4>Situación en clientes</h4>
        </v-col> -->

        <!-- <v-col cols="12" md="6" v-if="itemsSituationE.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">RFC</th>
                  <th class="text-left">Razón social</th>
                  <th class="text-left">Situación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsSituationE" :key="item.id">
                  <td>{{ item.taxID }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.situation }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col> -->
        <!-- <br /> -->
        <v-col cols="12" v-if="itemsE.length > 0">
          <h4>Coincidencias encontradas en clientes</h4>
        </v-col>

        <v-col cols="12" md="6" v-if="itemsE.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Año</th>
                  <th class="text-left">RFC</th>
                  <th class="text-left">Razón social</th>
                  <th class="text-left">Cantidad de comprobantes</th>
                  <th class="text-left">Sumatoria en MXN</th>
                  <th class="text-left">Situación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsE" :key="item.id">
                  <td>{{ item.anno }}</td>
                  <td>{{ item.rfc }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.cantidad }}</td>
                  <td>$ {{ currencyFormat(item.total) }}</td>
                  <td>
                    {{ getSituacionE(item.rfc) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <br />
        <!-- <v-col cols="12" v-if="itemsE.length > 0">
          <h4>Situación de proveedores</h4>
        </v-col> -->
        <!-- <v-col cols="12" md="6" v-if="itemsSituationR.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">RFC</th>
                  <th class="text-left">Razón social</th>
                  <th class="text-left">Situación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsSituationR" :key="item.id">
                  <td>{{ item.taxID }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.situation }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <br /> -->
        <v-col cols="12" v-if="itemsR.length > 0">
          <h4>Coincidencias encontradas en proveedores</h4>
        </v-col>
        <v-col cols="12" md="6" v-if="itemsR.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Año</th>
                  <th class="text-left">RFC</th>
                  <th class="text-left">Razón social</th>
                  <th class="text-left">Cantidad de comprobantes</th>
                  <th class="text-left">Sumatoria en MXN</th>
                  <th class="text-left">Situación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsR" :key="item.id">
                  <td>{{ item.anno }}</td>
                  <td>{{ item.rfc }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.cantidad }}</td>
                  <td>$ {{ currencyFormat(item.total) }}</td>
                  <td>
                    {{ getSituacionR(item.rfc) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ListasNegras",
  data: () => ({
    modal: false,




    tipoDoc: "Emitidos",
    lnSelect: ["Emitidos", "Recibidos"],
    itemsE: [],
    itemsSituationE: [],
    itemsSituationR: [],
    countE: 0,
    itemsR: [],
    countR: 0,
    fechaActual: "",
    fechaMins: "",
    fechaActualReal: "",
    mostrar: false,
    rfc: "",
    razonSocial: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.fechaActualReal = helpers.FechaActualReal();
    this.fechaActual = helpers.FechaActualSinHoras();
    this.fechaMins = helpers.FechaActual();
    this.$store.commit("setOverlay", true);
    this.getReport("E").then(() => {
      this.getReportSituation("E").then(() => {
        // this.itemsSituationE = this.itemsSituationE.filter((ar) =>
        //   this.itemsE.find((rm) => rm.rfc === ar.taxID)
        // );
        //alert(this.itemsSituationE.filter((ar) => ar.taxID == "CGM100714S59")[0].situation);
        this.getReport("R").then(() => {
          this.getReportSituation("R");
          // .then(() => {
          //   this.itemsSituationR = this.itemsSituationR.filter((ar) =>
          //     this.itemsR.find((rm) => rm.rfc === ar.taxID)
          //   );
          // });
        });
        setTimeout(() => {
          this.$store.commit("setOverlay", false);
        }, 15000);
      });
    });
    this.razonSocial = this.$store.state.userLongName;
    this.rfc = this.$store.state.entity;
  },
  methods: {
    exportPDF() {
      let sitE = this.itemsSituationE;
      let sitR = this.itemsSituationR;
      var inicio = `<ResumenBL fecha="${this.fechaActualReal}" rfc="${this.rfc}" nombre="${this.razonSocial}">
	<Resumen desde="${this.fechaInicialReport}" hasta="${this.fechaActual}">
		<ComprobantesClientes>${this.countE}</ComprobantesClientes>
		<ComprobantesProveedores>${this.countR}</ComprobantesProveedores>
    <Clientes>`;

      var clientesList = "";
      this.itemsE.forEach(function (valor) {
        if (sitE.length === 0) return "";

        let situC = sitE.filter((ar) => ar.taxID == valor.rfc)[0].situation;
        clientesList += `<Elemento anno="${valor.anno}" rfc="${valor.rfc
          }" nombre="${valor.nombre}" cantidad="${valor.cantidad}" total="${valor.total
          }" situacion="${situC}"></Elemento>`;
      });

      inicio += clientesList + `</Clientes><Proveedores>`;

      var proveedoresList = "";
      this.itemsR.forEach(function (valor) {
        if (sitR.length === 0) return "";

        let situP = sitR.filter((ar) => ar.taxID == valor.rfc)[0].situation;
        proveedoresList += `<Elemento anno="${valor.anno}" rfc="${valor.rfc
          }" nombre="${valor.nombre}" cantidad="${valor.cantidad}" total="${valor.total
          }" situacion="${situP}"></Elemento>`;
      });

      inicio += proveedoresList + `</Proveedores></Resumen></ResumenBL>`;
      this.getPdf(inicio);
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    cFormat(value) {
      return helpers.formatCommas(value);
    },

    async getPdf(xml) {
      var jsonData = {
        xml: helpers.Base64Encode(xml),
        token: this.$store.state.token,
        templateID: "RG:ListasNegras69.xfd",
      };

      return await service
        .api()
        .GetBLPDF(jsonData)
        .then((response) => {
          if (response.result) {

            helpers.ToPDF(response.payload, "CoincidenciasListasNegras69B_" + this.fechaMins + ".pdf");
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    async getReportSituation(contexto) {
      var jsonData = {
        token: this.$store.state.token,
        context: contexto,
      };

      return await service
        .api()
        .BlackListSituationList(jsonData)
        .then((response) => {
          if (response.result) {
            if (contexto === "E")
              this.itemsSituationE = JSON.parse(response.payload);
            else this.itemsSituationR = JSON.parse(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    async getReport(contexto) {
      var jsonData = {
        token: this.$store.state.token,
        context: contexto,
      };

      return await service
        .api()
        .BlackListInfo(jsonData)
        .then((response) => {
          if (response.result) {
            this.getReportCount(contexto);
            if (contexto === "E") this.itemsE = JSON.parse(response.payload);
            else this.itemsR = JSON.parse(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReportCount(contexto) {
      var jsonData = {
        token: this.$store.state.token,
        context: contexto,
      };

      service
        .api()
        .BlackListInfoCount(jsonData)
        .then((response) => {
          if (response.result) {
            if (contexto === "E") this.countE = Number(response.payload);
            else this.countR = Number(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          if (contexto !== "E") this.mostrar = true;
        });
    },

    getSituacionR(value) {
      if (this.itemsSituationR.length === 0) return "";

      return this.itemsSituationR.filter((ar) => ar.taxID == value)[0]
        .situation;
    },

    getSituacionE(value) {
      if (this.itemsSituationE.length === 0) return "";

      return this.itemsSituationE.filter((ar) => ar.taxID == value)[0]
        .situation;
    },
  },
  components: {},
  computed: {
    mostrarBotonPdf() {
      return this.itemsE.length > 0 || this.itemsR.length > 0;
    },
    fechaInicialReport() {
      let y = new Date();
      let n = y.getFullYear();
      var d = new Date(n, 0, 1);
      d.setFullYear(d.getFullYear() - 5);
      let localISOTime = d.toISOString().slice(0, -1);
      return localISOTime.substring(0, 10).replace("T", " ");

    },
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.normal {
  font-weight: regular;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}
</style>
