<template>


    <v-card>
        <v-dialog v-model="showDialogZips" persistent max-width="900px">
            <v-card dense>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showDialogZips = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Archivos ZIP en la Descarga</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                                class="ml-auto mr-3">
                                <v-icon>mdi-download-multiple-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Descargar Seleccionados</span>
                    </v-tooltip>

                </v-toolbar>
                <v-card-text>
                    <br />
                    <p>Al solicitar una descarga masiva, se crearán carpetas comprimidas en formato ZIP según la
                        cantidad de CFDI's
                        incluidos en tu solicitud. Cada carpeta puede contener hasta 5,000 CFDI con sus representaciones
                        gráficas.</p>
                    <v-divider class="mt-12"></v-divider>
                    <v-simple-table class="ma-3" v-if="itemsZip.length > 0" dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <!-- Checkbox en el encabezado -->
                                    <th>
                                        <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                                            hide-details></v-checkbox>
                                    </th>
                                    <th>Archivo Zip</th>
                                    <th>Fecha de Creación </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in itemsZip" :key="item.id" class="altura">
                                    <!-- Checkbox para cada fila -->
                                    <td>
                                        <v-checkbox v-model="selectedItems" :value="item" color="primary"
                                            hide-details></v-checkbox>
                                    </td>
                                    <td>
                                        {{ descargaSelected.description + " " + item.order + " de " + totalZips }}
                                    </td>
                                    <td>{{ restarSeisHoras(item.zipCreationDateUTC) }}</td>
                                    <td class="pa-0 md-0">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="descargarZip(item)" icon color="#4aa832" v-on="on">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogFiltros" persistent max-width="900px">
            <v-card class="pa-4">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Filtros Utilizados en la Descarga</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark @click="showDialogFiltros = false" icon v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Fecha Inicial</td>
                                <td>{{ selectedFilters.dateInit }}</td>
                            </tr>
                            <tr>
                                <td>Fecha Final</td>
                                <td>{{ selectedFilters.dateEnd }}</td>
                            </tr>
                            <tr>
                                <td>Contexto</td>
                                <td>{{ parseContext(selectedFilters.context) }}</td>
                            </tr>
                            <tr>
                                <td>{{ selectedFilters.context == "E" ? "Rfc Receptor" : "Rfc Emisor" }}</td>
                                <td>{{ selectedFilters.rfc || "" }}</td>
                            </tr>
                            <tr>
                                <td>Tipo Comprobante</td>
                                <td>{{ parseTipoComprobante(selectedFilters.docType) || "" }}</td>
                            </tr>
                            <tr>
                                <td>Retenciones</td>
                                <td>{{ selectedFilters.retenciones || "" }}</td>
                            </tr>
                            <tr>
                                <td>Traslados</td>
                                <td>{{ selectedFilters.traslados || "" }}</td>
                            </tr>
                            <tr>
                                <td>Tipo Factor</td>
                                <td>{{ selectedFilters.tipo_factor || "" }}</td>
                            </tr>
                            <tr>
                                <td>Emisor Régimen Fiscal</td>
                                <td>{{ selectedFilters.emisor_regimen_fiscal || "" }}</td>
                            </tr>
                            <tr>
                                <td>Receptor Régimen Fiscal</td>
                                <td>{{ selectedFilters.receptor_regimen_fiscal || "" }}</td>
                            </tr>
                            <tr>
                                <td>Serie</td>
                                <td>{{ selectedFilters.serie || "" }}</td>
                            </tr>
                            <tr>
                                <td>Folio</td>
                                <td>{{ selectedFilters.folio || "" }}</td>
                            </tr>
                            <tr>
                                <td>Complementos</td>
                                <td>{{ selectedFilters.complementos || "" }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>
        </v-dialog>
        <v-toolbar dark color="primary">
            <v-toolbar-title>Descargas Masivas Solicitadas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-btn @click="refrescarReportes" icon v-on="on">
                        <v-icon>mdi-cached</v-icon>
                    </v-btn>
                </template>
                <span>Refrescar Tabla</span>
            </v-tooltip>

            <v-tooltip right v-if="mostrarCerrar">
                <template v-slot:activator="{ on }">
                    <v-btn icon dark @click="$emit('closeDialog')" icon v-on="on">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Cerrar</span>
            </v-tooltip>
        </v-toolbar>

        <v-row>
            <v-col cols="12">
                <v-simple-table height="600" v-if="items.length > 0" fixed-header dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Fecha Inicial</th>
                                <th>Fecha Final</th>
                                <th>Fecha Solicitud</th>
                                <th>Status del Proceso</th>
                                <th style="text-align: right;">Dias Restantes Descarga</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id" class="altura">
                                <td>{{ item.description }}</td>
                                <td>{{ item.filters.dateInit }}</td>
                                <td>{{ item.filters.dateEnd }}</td>
                                <td>{{ restarSeisHoras(item.requestDateUTC) }}</td>
                                <td>{{ calcularStatus(item) }}</td>
                                <td style="text-align: right;">{{ diasRestantes(restarSeisHoras(item.requestDateUTC)) }}
                                </td>
                                <td class="pa-0 md-0">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verFiltros(item)" icon color="green" v-on="on">
                                                <v-icon>mdi-filter-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Filtros Utilizados </span>
                                    </v-tooltip>
                                </td>
                                <td class="pa-0 md-0">
                                    <v-tooltip left v-if="verBoton(item)">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verZips(item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-folder-zip-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Zips</span>
                                    </v-tooltip>
                                </td>
                                <td class="pa-0 md-0">
                                    <v-tooltip left v-if="verBoton(item)">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verLogs(item)" icon color="secundary" v-on="on">
                                                <v-icon>mdi-touch-text-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargas CSV de Logs </span>
                                    </v-tooltip>
                                </td>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import service from "../../scripts/services.js";
import helpers from "../../scripts/helpers.js";

export default {
    name: 'DescargaMasivasList',
    data() {
        return {
            items: [],
            itemsZip: [],
            itemsLogs: [],
            showDialogZips: false,
            showDialogFiltros: false,
            selectedFilters: {},
            descargaSelected: {},
            totalZips: 0,
            selectedItems: [],
            allSelected: false,
        };
    },
    methods: {
        GetDescargas() {

            try {
                var jsonData = {
                    token: this.$store.state.token,
                };

                service
                    .api()
                    .DescargaMasivaReportes(jsonData)
                    .then((response) => {

                        this.$store.commit("setOverlay", false);
                        if (response.result) {

                            this.items = JSON.parse(response.payload);

                        } else {

                            this.items = [];
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", response.errorMessage);
                        }
                    });

            } catch (error) {
                this.$store.commit("setOverlay", false);
                console.error("Error al obtener reportes:", error);
            }
        },
        refrescarReportes() {
            this.GetDescargas();
        },
        verLogs(item) {
            try {
                var jsonData = {
                    idRequest: item.id,
                    token: this.$store.state.token,
                };

                service
                    .api()
                    .DescargaMasivaLogs(jsonData)
                    .then((response) => {
                        if (response.result) {
                            this.$store.commit("setOverlay", false);
                            this.itemsLogs = JSON.parse(response.payload);
                            this.exportExcel();

                        } else {
                            this.itemsZip = [];
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", "No se encuentra registro de log");
                        }
                    });

            } catch (error) {
                console.error("Error al obtener reportes:", error);
            }

        },
        verFiltros(item) {
            this.selectedFilters = item.filters;
            this.showDialogFiltros = true;
        },
        exportExcel() {
            var headers = {
                Id: "Id",
                Rfc: "RFC",
                Tipo: "Tipo",
                Mensaje: "Mensaje",
                IdZip: "IdZip",
                uuid: "UUID",
                FechaLog: "Fecha Log",

            };
            var itemsFormatted = [];
            this.itemsLogs.forEach((item) => {
                itemsFormatted.push({
                    Id: helpers.ConvertToEmpty(item.id),
                    Rfc: helpers.ConvertToEmpty(item.entity),
                    Tipo: helpers
                        .ConvertToEmpty(item.processType)
                        .replace(/,/g, " "),
                    Mensaje: helpers.ConvertToEmpty(item.message),
                    IdZip: helpers.ConvertToEmpty(item.objDetail.idZip),
                    uuid: helpers.ConvertToEmpty(item.objDetail.uuid),
                    FechaLog: helpers.ConvertToEmpty(restarSeisHoras(item.logDateUTC))
                });
            });



            var fileTitle =
                "Logs_Descarga_Masiva_Zips_" +
                helpers.dateNowCSV().replace(/-/g, "_");
            helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
        },
        verZips(item) {
            try {
                this.descargaSelected = item;
                var jsonData = {
                    idRequest: item.id,
                    token: this.$store.state.token,
                };

                service
                    .api()
                    .DescargaMasivaObtenerZips(jsonData)
                    .then((response) => {
                        if (response.result) {
                            this.$store.commit("setOverlay", false);
                            this.itemsZip = JSON.parse(response.payload);
                            this.showDialogZips = true;
                            this.allSelected = false;
                            this.selectedItems = [];
                            this.totalZips = this.calcularNumeroZips(item);

                        } else {
                            this.itemsZip = [];
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", "Aún no hay archivos zip disponibles");
                        }
                    });

            } catch (error) {
                console.error("Error al obtener reportes:", error);
            }
        },
        async descargarTodosEnParalelo(items) {
            try {
                const promesas = items.map((item) => this.descargarZip(item));
                await Promise.all(promesas);
                console.log("Todas las descargas han finalizado.");
            } catch (error) {
                console.error("Error en una o más descargas:", error);
            }
        },

        descargarZip(item) {
            return new Promise((resolve, reject) => {
                try {
                    const jsonData = {
                        idRequest: item.idRequest,
                        idZip: item.id,
                        token: this.$store.state.token,
                    };

                    service
                        .api()
                        .DescargaMasivaDescargarZip(jsonData)
                        .then((response) => {
                            if (response.result) {
                                this.$store.commit("setOverlay", true);
                                let urlZip = response.payload;

                                if (navigator.userAgent.indexOf("Firefox") > -1) {
                                    window.open(urlZip, "_blank");
                                } else {
                                    helpers.openNewBackgroundTab(urlZip);
                                }

                                setTimeout(() => {
                                    this.$store.commit("setAlertOk", false);
                                    this.$store.commit("setOverlay", false);
                                }, 3000);

                                resolve(); // Marca la promesa como resuelta
                            } else {
                                this.$store.commit("setAlertOk", false);
                                this.$store.commit("setAlertError", true);
                                this.$store.commit("setAlertMessage", response.errorMessage);
                                reject(new Error(response.errorMessage)); // Marca la promesa como rechazada
                            }
                        })
                        .catch((error) => {
                            reject(error); // Rechaza la promesa si ocurre un error
                        });
                } catch (error) {
                    reject(error); // Rechaza la promesa si hay una excepción
                }
            });
        },

        parseContext(context) {
            return context === 'E' ? 'Emitido' : 'Recibido';
        },
        parseTipoComprobante(tipo) {
            let tipoDoc = "";
            switch (tipo) {
                case "I":
                    tipoDoc = "Ingreso";
                    break;
                case "E":
                    tipoDoc = "Egreso";
                    break;
                case "N":
                    tipoDoc = "Nómina";
                    break;
                case "T":
                    tipoDoc = "Traslado";
                    break;
                case "P":
                    tipoDoc = "Pago";
                    break;

            }
            return tipoDoc;
        },
        calcularStatus(item) {
            let message = "";

            if (item.ready) {

                if (item.totalDocsInQuery == 0)
                    message = "Terminado (Sin resultados)";
                else
                    message = "Terminado";
            }
            else if (!item.ready && !item.initDateUTC)
                message = "En Cola";
            else
                message = "Procesando";

            return message;
        },
        verBoton(item) {

            let message = false;

            if (item.ready) {

                if (item.totalDocsInQuery == 0)
                    message = false;
                else
                    message = true;
            }
            else if (!item.ready && !item.initDateUTC)
                message = false;
            else
                message = true;

            return message;
        },
        formatKey(key) {
            // Convierte claves a un formato más legible
            return key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
        },
        calcularNumeroZips(item) {
            return Math.ceil((item.totalDocsInQuery - item.totalOwnDocsInQuery) / 5000) + Math.ceil(item.totalOwnDocsInQuery / 5000)
        },
        toggleSelectAll() {
            if (this.allSelected) {
                this.selectedItems = [...this.itemsZip];
            } else {
                this.selectedItems = [];
            }
        },
        // Obtiene las filas seleccionadas
        descargarMultiple() {
            this.descargarTodosEnParalelo(this.selectedItems);

        },
        diasRestantes(fechaSolicitud) {
            const MILISEGUNDOS_POR_DIA = 1000 * 60 * 60 * 24;

            // Convierte la fecha de solicitud a un objeto Date
            const fechaInicial = new Date(fechaSolicitud);

            // Suma 31 días a la fecha de solicitud
            const fechaFinal = new Date(fechaInicial.getTime() + (31 * MILISEGUNDOS_POR_DIA));

            // Calcula la diferencia de tiempo entre la fecha final y la fecha actual
            const fechaActual = new Date();
            const diferenciaTiempo = fechaFinal - fechaActual;

            // Convierte la diferencia de tiempo a días
            const diasRestantes = Math.ceil(diferenciaTiempo / MILISEGUNDOS_POR_DIA);

            // Si ya pasó el periodo de 31 días, devuelve 0
            return diasRestantes > 0 ? diasRestantes : 0;
        },
        restarSeisHoras(fechaString) {
            // Convertir la cadena a un objeto Date
            const fecha = new Date(fechaString);

            // Verificar si la fecha es válida
            if (isNaN(fecha)) {
                throw new Error("La fecha proporcionada no es válida");
            }

            // Restar 6 horas
            fecha.setHours(fecha.getHours() - 6);

            // Formatear la fecha a yyyy-MM-dd HH:mm:ss
            const anio = fecha.getFullYear();
            const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Mes comienza en 0
            const dia = String(fecha.getDate()).padStart(2, '0');
            const horas = String(fecha.getHours()).padStart(2, '0');
            const minutos = String(fecha.getMinutes()).padStart(2, '0');
            const segundos = String(fecha.getSeconds()).padStart(2, '0');

            return `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

        }

    },
    computed: {
        mostrarDescargarSeleccionado() {
            return this.selectedItems.length > 0;
        },
        mostrarCerrar() {
            return this.$store.state.isDescargaMasiva === true;
        }
    },
    watch: {
        // Sincroniza el estado del checkbox del encabezado con las filas seleccionadas
        selectedItems: {
            handler(selected) {
                this.allSelected = selected.length === this.itemsZip.length;
            },
            deep: true,
        },
    }

};
</script>
<style>
.v-list-item-title {
    font-weight: bold;
}
</style>