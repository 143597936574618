import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
             primary: '#1185ab',  
             second: '#113145', 
             pagadas: '#3E632F',
             parcialmente: '#E8B768',
             nopagadas: '#FF8E73',
             sobrepagadas: '#A83432',
             buscar:  '#85AFFF',
             danger: '#E82C0C',
             cancelados: '#D69813',
             disable: '#808080',    
             cancelGradient: "#0099BD", 
             noDocs: "#BEC4CF",
             rowSel: "#C9EAF0"      
          },
        },
      },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
});
