<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row>
        
        <v-col cols="12">
          <h3>Cambios desde su visita anterior</h3>
        </v-col>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-dialog v-model="cambiarFechasDialog" persistent max-width="700px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cambiarFechasDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Cambiar Fechas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="4" class="mx-2" justify="center">
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="date" label="Fecha Inicial" prepend-icon="mdi-calendar-month" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" locale="es" @input="menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="4" justify="center">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="date2" label="Fecha Final" prepend-icon="mdi-calendar-month" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" locale="es" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="getReporteItemsFechas">Mostrar Listado</v-btn>
              <v-btn color="blue darken-1" text @click="cambiarFechasDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-col cols="12" md="3">
          <v-card class="mx-5" max-width="400" tile>
            <v-app-bar dark color="second">
              <v-toolbar-title>Intervalo de Fechas</v-toolbar-title>
            </v-app-bar>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-5" max-width="400" tile>
            <v-app-bar dark color="primary">
              <v-toolbar-title>Fecha Inicial: {{ this.fechaInicial }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-5" max-width="400" tile>
            <v-app-bar dark color="primary">
              <v-toolbar-title>Fecha Final: {{ this.fechaFinal }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" md="3">
          <v-card class="mx-5" max-width="400" tile>
            <v-app-bar dark color="primary">
              <div class="my-2 text-xs-center">
                <v-btn
                  color="dark"
                  @click="cambiarFechasDialog = true"
                  text
                  >Cambiar Fechas</v-btn
                >
              </div>
            </v-app-bar>
          </v-card>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card v-if="items.length > 0">
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left body-1">Año</th>
                    <th class="text-left body-1">Mes</th>
                    <th class="text-left body-1">Contexto</th>
                    <th class="text-left body-1" width="50%">Cambio</th>
                    <th class="text-left body-1">Revisar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.name" class="altura" :class="selectedRows.indexOf(index) > -1 ? 'rowSel' : ''"
                  @click="rowClicked(index)"
>
                    <td>{{ item.year }}</td>
                    <td>{{ obtenerMes(item.month) }}</td>
                    <td>{{ item.contexto == "E" ? "Emitido" : "Recibido" }}</td>
                    <td>{{ item.label }}</td>
                    <td>
                      <v-chip v-if="item.ligas == 1 || item.ligas == 19" class="ma-2" color="primary" outlined @click="
                        goFor(
                          1,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-outline</v-icon>
                        Reporte Conciliatorio Emitidos
                      </v-chip>
                      <v-chip v-if="
                        item.ligas == 2 ||
                        item.ligas == 18 ||
                        item.ligas == 19 ||
                        item.ligas == 20
                      " class="ma-2" color="primary" outlined @click="
                          goFor(
                            2,
                            item.year,
                            item.month,
                            item.fecha,
                            item.contexto
                          )
                        ">
                        <v-icon left>mdi-file-cancel-outline</v-icon>
                        Facturas canceladas que están en Comprobantes de Pago
                      </v-chip>
                      <v-chip v-if="item.ligas == 3" class="ma-2" color="primary" outlined @click="
                        goFor(
                          3,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-file-chart-outline</v-icon>
                        Análisis de CFDI vs Comprobantes de Pago Emitidos
                      </v-chip>
                      <v-chip v-if="item.ligas == 4" class="ma-2" color="primary" outlined @click="
                        goFor(
                          4,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-file-cancel-outline</v-icon>
                        Documentos relacionados no encontrados
                      </v-chip>
                      <v-chip v-if="item.ligas == 5" class="ma-2" color="primary" outlined @click="
                        goFor(
                          5,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-outline</v-icon>
                        Reporte Conciliatorio Retenciones Emitidas
                      </v-chip>
                      <v-chip v-if="item.ligas == 6" class="ma-2" color="primary" outlined @click="
                        goFor(
                          6,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-remove-outline</v-icon>
                        Reporte Conciliatorio Retenciones Emitidas Canceladas
                      </v-chip>
                      <v-chip v-if="item.ligas == 7" class="ma-2" color="primary" outlined @click="
                        goFor(
                          7,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Reporte Conciliatorio Retenciones Recibidas
                      </v-chip>
                      <v-chip v-if="item.ligas == 8" class="ma-2" color="primary" outlined @click="
                        goFor(
                          8,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-remove</v-icon>
                        Reporte Conciliatorio Retenciones Recibidas Canceladas
                      </v-chip>
                      <v-chip v-if="item.ligas == 9 || item.ligas == 20" class="ma-2" color="primary" outlined @click="
                        goFor(
                          9,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Reporte Conciliatorio Recibidos
                      </v-chip>
                      <v-chip v-if="item.ligas == 10" class="ma-2" color="primary" outlined @click="
                        goFor(
                          10,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-file-chart</v-icon>
                        Análisis de CFDI vs Comprobantes de Pago Recibidos
                      </v-chip>
                      <v-chip v-if="item.ligas == 11" class="ma-2" color="primary" outlined @click="
                        goFor(
                          11,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-remove-outline</v-icon>
                        Reporte Conciliatorio Emitidos Cancelados
                      </v-chip>
                      <v-chip v-if="item.ligas == 12" class="ma-2" color="primary" outlined @click="
                        goFor(
                          12,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box-remove</v-icon>
                        Reporte Conciliatorio Recibidos Cancelados
                      </v-chip>
                      <v-chip v-if="item.ligas == 13" class="ma-2" color="primary" outlined @click="
                        goFor(
                          13,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-file-chart</v-icon>
                        Reporte de Nómina
                      </v-chip>
                      <v-chip v-if="item.ligas == 14 || item.ligas == 2" class="ma-2" color="primary" outlined @click="
                        goFor(
                          14,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Reporte Conciliatorio Emitidos Cancelados
                      </v-chip>
                      <v-chip v-if="item.ligas == 15 || item.ligas == 18" class="ma-2" color="primary" outlined @click="
                        goFor(
                          15,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Reporte Conciliatorio Recibidos Cancelados
                      </v-chip>
                      <v-chip v-if="item.ligas == 16" class="ma-2" color="primary" outlined @click="
                        goFor(
                          16,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Análisis de CFDI vs Comprobantes de Pago Emitidos con
                        Pagos
                      </v-chip>
                      <v-chip v-if="item.ligas == 17" class="ma-2" color="primary" outlined @click="
                        goFor(
                          17,
                          item.year,
                          item.month,
                          item.fecha,
                          item.contexto
                        )
                      ">
                        <v-icon left>mdi-text-box</v-icon>
                        Análisis de CFDI vs Comprobantes de Pago Recibidos con
                        Pagos
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <br /><br />
          <p class="text-h4 text-center" v-if="items.length == 0">
            Sin cambios para mostrar
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "Metricas",
  data: () => ({
    selectedRows: [],
    date: "",
    date2: "",
    menu: false,
    menu2: false,
    cambiarFechasDialog: false,
    modal: false,
    
    
    
    
    items: [],
    fechaInicial: "",
    fechaFinal: "",
    titulo: "",
  }),
  created() {
    this.getReporteItems();
    this.$store.commit("setDateInterval", null);
    this.$store.commit("setBlData", null);
  },
  methods: {
    rowClicked(index) {
      this.selectedRows = [];
      this.toggleSelection(index);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    getReporteItemsFechas() {
      this.cambiarFechasDialog = false;

      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
      };
      service
        .api()
        .SearchMetricsByDate(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            let data = payload.cambios;
            let fInicial = payload.fecha_inicial;
            let fFinal = payload.fecha_final;

            this.fechaInicial =
              fInicial.substr(0, 4) +
              "-" +
              fInicial.substr(4, 2) +
              "-" +
              fInicial.substr(6, 2);
            this.fechaFinal =
              fFinal.substr(0, 4) +
              "-" +
              fFinal.substr(4, 2) +
              "-" +
              fFinal.substr(6, 2);

            data.forEach((element) => {
              let str = element.id.split("-");
              let year = "";
              let month = "";
              let contexto = "";
              let tipoMetric = "";

              if (str[0].length == 1) {
                contexto = str[0];
                year = str[1];
                month = str[2];
              } else {
                contexto = "E";
                year = str[0];
                month = str[1];
              }

              let etiqueta = "";
              let ligasL = 0;

              tipoMetric = this.includeLink(element.id);

              switch (tipoMetric) {
                case "PAGOS-RELATED-INCOME":
                  etiqueta =
                    "Como resultado de la importación al sistema de al menos un documento de pago que cambia los importes pagados en el mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 16;
                  } else ligasL = 17;
                  // Reporte CFDI vs PAgos
                  break;
                case "CANCELADOS-RELCANCELLED":
                  etiqueta =
                    "Se canceló al menos un documento de ingreso o egreso del mes señalado en esta línea que se encontraba como documento relacionado en Comprobantes de Pago.";
                  if (contexto === "E") {
                    ligasL = 2;
                  } else ligasL = 18;
                  // Reporte Relacionados Cancelados
                  break;
                case "CANCELADOS-INCOME":
                  etiqueta =
                    "Se canceló al menos un documento de ingreso o egreso del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 11;
                  } else ligasL = 12;
                  //Reporte Conciliatorio
                  //Reporte CFDI vs Pagos
                  break;
                case "NUEVOS-INCOME":
                  etiqueta =
                    "Se importó al sistema al menos un documento de ingreso o egreso del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 3;
                  } else ligasL = 10;
                  //Reporte Conciliatorio
                  //Reporte CFDI vs Pagos
                  break;
                case "NUEVOS-PAGOS":
                  etiqueta =
                    "Se importó al sistema al menos un Comprobante de Pago en el mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 1;
                  } else ligasL = 9;
                  //Reporte Conciliatorio
                  break;
                case "NUEVOS-NOTINPAYMENTS":
                  etiqueta =
                    "Se importó al menos un Comprobante de Pago en donde uno o más documentos relacionados no se encuentra en el Sistema.";
                  ligasL = 4;
                  //Reporte Not in payments
                  break;
                case "NUEVOS-RELCANCELLED":
                  etiqueta =
                    "Se importó al menos un documento del mes señalado en la línea que ya se encuentra cancelado.";
                  if (contexto === "E") {
                    ligasL = 19;
                  } else ligasL = 20;
                  //Reporte relacionados cancelados
                  break;
                case "NUEVAS-RETENCIONES":
                  etiqueta =
                    "Se importó al menos una retención del mes señalado en la línea.";
                  if (contexto === "E") {
                    ligasL = 5;
                  } else ligasL = 7;
                  //Reporte relacionados cancelados
                  break;
                case "CANCELADAS-RETENCIONES":
                  etiqueta =
                    "Se importó al menos una retención del mes señalado en la línea que ya se encuentra cancelada.";
                  if (contexto === "E") {
                    ligasL = 6;
                  } else ligasL = 8;
                  //Reporte relacionados cancelados
                  break;
                case "NOMINA":
                  etiqueta =
                    "Se importó o se canceló al menos un comprobante de nómina del mes señalado en esta línea.";
                  ligasL = 13;
                  // Reporte Nomina
                  break;
                case "CANCELADOS-PAGOS":
                  etiqueta =
                    "Se canceló al menos un documento de pago del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 14;
                  } else ligasL = 15;
                  break;
              }

              var jsonItem = {
                year: year,
                month: month,
                contexto: contexto,
                label: etiqueta,
                ligas: ligasL,
                fecha: element.fecha,
              };

              this.items.push(jsonItem);
            });

            this.doctosPagina = this.items.length.toString();
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          if (this.items == [])
            this.titulo = "Sin cambios desde su visita anterior";
        });
      this.date = "";
      this.date2 = "";
      this.menu = "";
      this.men2 = "";
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
      };
      service
        .api()
        .SearchMetrics(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            let data = payload.cambios;
            this.fechaInicial = payload.fecha_inicial.substring(0, 10);
            this.fechaFinal = payload.fecha_final.substring(0, 10);

            data.forEach((element) => {
              let str = element.id.split("-");
              let year = "";
              let month = "";
              let contexto = "";
              let tipoMetric = "";

              if (str[0].length == 1) {
                contexto = str[0];
                year = str[1];
                month = str[2];
              } else {
                contexto = "E";
                year = str[0];
                month = str[1];
              }

              let etiqueta = "";
              let ligasL = 0;

              tipoMetric = this.includeLink(element.id);

              switch (tipoMetric) {
                case "PAGOS-RELATED-INCOME":
                  etiqueta =
                    "Como resultado de la importación al sistema de al menos un documento de pago que cambia los importes pagados en el mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 16;
                  } else ligasL = 17;
                  // Reporte CFDI vs PAgos
                  break;
                case "CANCELADOS-RELCANCELLED":
                  etiqueta =
                    "Se canceló al menos un documento de ingreso o egreso del mes señalado en esta línea que se encontraba como documento relacionado en Comprobantes de Pago.";
                  if (contexto === "E") {
                    ligasL = 2;
                  } else ligasL = 18;
                  // Reporte Relacionados Cancelados
                  break;
                case "CANCELADOS-INCOME":
                  etiqueta =
                    "Se canceló al menos un documento de ingreso o egreso del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 11;
                  } else ligasL = 12;
                  //Reporte Conciliatorio
                  //Reporte CFDI vs Pagos
                  break;
                case "NUEVOS-INCOME":
                  etiqueta =
                    "Se importó al sistema al menos un documento de ingreso o egreso del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 3;
                  } else ligasL = 10;
                  //Reporte Conciliatorio
                  //Reporte CFDI vs Pagos
                  break;
                case "NUEVOS-PAGOS":
                  etiqueta =
                    "Se importó al sistema al menos un Comprobante de Pago en el mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 1;
                  } else ligasL = 9;
                  //Reporte Conciliatorio
                  break;
                case "NUEVOS-NOTINPAYMENTS":
                  etiqueta =
                    "Se importó al menos un Comprobante de Pago en donde uno o más documentos relacionados no se encuentra en el Sistema.";
                  ligasL = 4;
                  //Reporte Not in payments
                  break;
                case "NUEVOS-RELCANCELLED":
                  etiqueta =
                    "Se importó al menos un documento del mes señalado en la línea que ya se encuentra cancelado.";
                  if (contexto === "E") {
                    ligasL = 19;
                  } else ligasL = 20;
                  //Reporte relacionados cancelados
                  break;
                case "NUEVAS-RETENCIONES":
                  etiqueta =
                    "Se importó al menos una retención del mes señalado en la línea.";
                  if (contexto === "E") {
                    ligasL = 5;
                  } else ligasL = 7;
                  //Reporte relacionados cancelados
                  break;
                case "CANCELADAS-RETENCIONES":
                  etiqueta =
                    "Se importó al menos una retención del mes señalado en la línea que ya se encuentra cancelada.";
                  if (contexto === "E") {
                    ligasL = 6;
                  } else ligasL = 8;
                  //Reporte relacionados cancelados
                  break;
                case "NOMINA":
                  etiqueta =
                    "Se importó o se canceló al menos un comprobante de nómina del mes señalado en esta línea.";
                  ligasL = 13;
                  // Reporte Nomina
                  break;
                case "CANCELADOS-PAGOS":
                  etiqueta =
                    "Se canceló al menos un documento de pago del mes señalado en esta línea.";
                  if (contexto === "E") {
                    ligasL = 14;
                  } else ligasL = 15;
                  break;
              }

              var jsonItem = {
                year: year,
                month: month,
                contexto: contexto,
                label: etiqueta,
                ligas: ligasL,
                fecha: element.fecha,
              };

              this.items.push(jsonItem);
            });

            this.doctosPagina = this.items.length.toString();
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          if (this.items == [])
            this.titulo = "Sin cambios desde su visita anterior";
        });
    },
    obtenerMes(mes) {
      let nombreMes = "";
      let month = parseInt(mes);
      switch (month) {
        case 1:
          nombreMes = "Enero";
          break;
        case 2:
          nombreMes = "Febrero";
          break;
        case 3:
          nombreMes = "Marzo";
          break;
        case 4:
          nombreMes = "Abril";
          break;
        case 5:
          nombreMes = "Mayo";
          break;
        case 6:
          nombreMes = "Junio";
          break;
        case 7:
          nombreMes = "Julio";
          break;
        case 8:
          nombreMes = "Agosto";
          break;
        case 9:
          nombreMes = "Septiembre";
          break;
        case 10:
          nombreMes = "Octubre";
          break;
        case 11:
          nombreMes = "Noviembre";
          break;
        case 12:
          nombreMes = "Diciembre";
          break;
      }
      return nombreMes;
    },
    goFor(path, itemYear, itemMonth, fechaTs, origen) {
      let ruta = "";
      this.$store.commit("setTipoDocTs", "");
      let tipo = "";
      switch (path) {
        case 1:
          tipo = "E";
          ruta = "/conciliatorio/emitidos";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
        case 2:
          ruta = "/Pagos/RelCancelados";
          break;
        case 3:
          tipo = "E";
          ruta = "/Income/Emitidos";
          break;
        case 4:
          ruta = "/Pagos/NoEncontrados";
          break;
        case 5:
          tipo = "E";
          ruta = "/retenciones/emitidas";
          break;
        case 6:
          tipo = "E";
          ruta = "/retenciones/emitidas/canceladas";
          break;
        case 7:
          tipo = "R";
          ruta = "/retenciones/recibidas";
          break;
        case 8:
          tipo = "R";
          ruta = "/retenciones/recibidas/canceladas";
          break;
        case 9:
          tipo = "R";
          ruta = "/conciliatorio/recibidos";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
        case 10:
          tipo = "R";
          ruta = "/Income/Recibidos";
          break;
        case 11:
          ruta = "/conciliatorio/cancelados";
          break;
        case 12:
          ruta = "/conciliatorio/cancelados";
          break;
        case 13:
          tipo = "E";
          ruta = "/Nomina/ReporteNomina";
          break;
        case 14:
          ruta = "/conciliatorio/cancelados";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
        case 15:
          ruta = "/conciliatorio/cancelados";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
        case 16:
          tipo = "E";
          ruta = "/Income/Emitidos";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
        case 17:
          tipo = "R";
          ruta = "/Income/Recibidos";
          this.$store.commit("setTipoDocTs", "Pago");
          break;
      }

      if (tipo === 'E') {
        if (!this.allowIssued) {

          this.alert_errorR = true;
          this.errorMessageR = "No cuenta con permiso para ver documentos Emitidos";
          setTimeout(() => {
            this.alert_errorR = false;
            this.errorMessageR = "";
          }, 10000);
          return;
        }
      }
      if (tipo === 'R') {
        if (!this.allowReceived) {

          this.alert_errorR = true;
          this.errorMessageR = "No cuenta con permiso para ver documentos Recibidos";
          setTimeout(() => {
            this.alert_errorR = false;
            this.errorMessageR = "";
          }, 10000);
          return;
        }
      }

      this.setPeriodo(itemYear, itemMonth, origen);

      this.$router.push({
        path: ruta,
      });
    },
    includeLink(element) {
      let tipoM = "";
      if (element.includes("PAGOS-RELATED-INCOME")) {
        tipoM = "PAGOS-RELATED-INCOME";
      } else if (element.includes("CANCELADOS-RELCANCELLED")) {
        tipoM = "CANCELADOS-RELCANCELLED";
      } else if (element.includes("CANCELADOS-INCOME")) {
        tipoM = "CANCELADOS-INCOME";
      } else if (element.includes("NUEVOS-INCOME")) {
        tipoM = "NUEVOS-INCOME";
      } else if (element.includes("NUEVOS-PAGOS")) {
        tipoM = "NUEVOS-PAGOS";
      } else if (element.includes("NUEVOS-NOTINPAYMENTS")) {
        tipoM = "NUEVOS-NOTINPAYMENTS";
      } else if (element.includes("NUEVOS-RELCANCELLED")) {
        tipoM = "NUEVOS-RELCANCELLED";
      } else if (element.includes("NUEVAS-RETENCIONES")) {
        tipoM = "NUEVAS-RETENCIONES";
      } else if (element.includes("CANCELADAS-RETENCIONES")) {
        tipoM = "CANCELADAS-RETENCIONES";
      } else if (element.includes("NOMINA")) {
        tipoM = "NOMINA";
      } else if (element.includes("CANCELADOS-PAGOS")) {
        tipoM = "CANCELADOS-PAGOS";
      }

      return tipoM;
    },

    setPeriodo(periodYear, periodMonth, origen) {
      let today = new Date();
      let LastDay = helpers.LastDayMonth(periodYear, periodMonth);
      if (
        periodYear == today.getFullYear() &&
        periodMonth == today.getMonth() + 1
      ) {
        LastDay = today.getDate();
      }

      var interval = {
        dateInit: periodYear + "-" + periodMonth + "-01",
        dateEnd:
          periodYear +
          "-" +
          periodMonth +
          "-" +
          (LastDay < 10 ? "0" + LastDay : LastDay),
        dateTs: this.fechaInicial.substring(0, 10),
        contexto: origen,
      };
      this.$store.commit("setDateInterval", interval);
    },
  },
  computed: {
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}
</style>
