<template>
    <div class="home">
      <InconsistenciasOnDemand titulo="CFDIs Relacionados que NO existen en XDATA" filtro="REPORT-RELACIONADOS-INEXISTENTES" descripcionTitulo="Reporte de todos los CFDI relacionados que no existen en XDATA" />
    </div>
  </template>
  
  <script>
  
  import InconsistenciasOnDemand from "./InconsistenciasOnDemand";
  
  export default {
    components: {
        InconsistenciasOnDemand
    }
  };
  </script>