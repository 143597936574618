<template>
    <div class="home">
      <InconsistenciasOnDemand titulo="CFDIs Relacionados Sustituidos que no han Sido Cancelados" filtro="REPORT-SUSTITUIDOS-NO-CANCELADOS" descripcionTitulo="Reporte de los CFDI Relacionados con relación 04 (por sustitución) que no se encuentran cancelados." />
    </div>
  </template>
  
  <script>
  
  import InconsistenciasOnDemand from "./InconsistenciasOnDemand";
  
  export default {
    components: {
        InconsistenciasOnDemand
    }
  };
  </script>