<template>
    <div class="py-10 mt-5">
        <v-container fluid class="px-10">
            <v-row justify="center">
                <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
                <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogEliminarReport = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Confirmación Eliminar Reporte:
                                {{ this.itemDesc }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <br />
                            <h3>Este reporte sera eliminado permanentemente</h3>
                            <h3>¿Está seguro de continuar con esta acción?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
                            <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showDialogReports" persistent>
                    <v-col cols="12">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="showDialogReports = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="ObtenerReportes" icon v-on="on">
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refrescar Tabla</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark v-show="mostrarDescargarSeleccionado"
                                            @click="descargarMultiple" v-on="on" class="ml-auto mr-3">
                                            <v-icon>mdi-download-multiple-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descargar Seleccionados</span>
                                </v-tooltip>
                                <v-btn @click="showDialogCrear = true" large color="second">
                                    <v-icon>mdi-plus</v-icon>Solicitar Reporte
                                </v-btn>
                            </v-toolbar>
                            <v-row>
                                <v-col cols="12">
                                    <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                                        errorMessageIn
                                    }}
                                    </v-alert>
                                    <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{
                                        okMessageIn
                                    }}</v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table height="600" fixed-header dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <v-checkbox v-model="allSelected" @change="toggleSelectAll"
                                                            color="primary" hide-details></v-checkbox>
                                                    </th>

                                                    <th>Descripción</th>
                                                    <th>Fecha Solicitud</th>
                                                    <th>Contexto</th>
                                                    <th>Fecha Inicial</th>
                                                    <th>Fecha Final</th>
                                                    <th>RFC</th>
                                                    <th>Mensaje</th>
                                                    <th>Disponible</th>
                                                    <th>Resultado</th>

                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                                    <td>
                                                        <v-checkbox v-model="selectedItemsDescarga" :value="item"
                                                            color="primary" hide-details></v-checkbox>
                                                    </td>

                                                    <td>
                                                        {{ item.description }}
                                                    </td>
                                                    <td>
                                                        {{ item.requestDate }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            item.commands.context == "E"
                                                                ? "Emitido"
                                                                : "Recibido"
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{ item.commands.dateInit }}
                                                    </td>
                                                    <td>
                                                        {{ item.commands.dateEnd }}
                                                    </td>
                                                    <td>
                                                        {{ item.commands.taxid }}
                                                    </td>
                                                    <td>
                                                        {{ item.message }}
                                                    </td>
                                                    <td>
                                                        {{ item.ready ? "Si" : "No" }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            item.ready
                                                                ? item.result
                                                                    ? "Exitoso"
                                                                    : "Fracasado"
                                                                : "Procesando"
                                                        }}
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready && item.result">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="DescargarReporte(item.id)" icon
                                                                    color="#4aa832" v-on="on">
                                                                    <v-icon>mdi-download</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Descargar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td class="pa-0 md-0">
                                                        <v-tooltip left v-if="item.ready">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="ConfirmarEliminarReporte(item)" icon
                                                                    color="red" v-on="on">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar Reporte</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-dialog>
                <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="cerrarCrearSolicitud">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" justify="center">
                                    <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="rfcSearchIn" counter="0" :label="labelRfc" class="my-1"
                                        maxlength="13">
                                    </v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" md="4">
                                    <v-select :items="itemsOrigen" v-model="contextoIn" label="Contexto" outlined
                                        :disabled="dateTs.length > 0"></v-select>
                                </v-col>
                                <v-col md="6" justify="center">
                                    <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1"
                                        maxlength="100">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="mt-12"></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">
                                Solicitar Reporte
                            </v-btn>
                            <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-row>
            <v-row>

                <v-col cols="12">
                    <h3>Análisis de Comprobantes de Pago vs CFDI por Fecha de Emisión
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                                    href="https://soporte.mysuitemex.com/portal/es/kb/articles/analisis-de-comprobantes-de-pago-vs-cfdi"
                                    target="_blank">
                                    <v-icon>mdi-help-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Manual</span>
                        </v-tooltip>

                    </h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="12" md="4" class="px-2 mx-md-10">
                                <date-range-picker v-model="selectedDates"
                                    :disabled="dateTs.length > 0"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-text-field class="min-width-control-xl" v-model="rfcSearch" counter="0"
                                    :label="labelRfc" maxlength="13" :disabled="dateTs.length > 0"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto"
                                    label="Contexto" outlined :disabled="dateTs.length > 0"
                                    @change="items = []"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">
                                    Mostrar Listado</v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                                        <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">
                                            Reportes Bajo Demanda
                                        </v-btn>
                                    </template>
                                    <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
                            {{ totalDocumentos }}</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-tooltip top class="d-none d-sm-flex">
                            <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span class="d-none d-sm-flex">Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min="1" :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>

                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">



                            <wj-flex-grid-column :header="'Folio Fiscal de Pago'" :binding="'id'" width="4*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Fecha Emisión CFDI Pago'" :binding="'fecha_emision'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Pago'" :binding="'fecha_pago'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda Pago'" :binding="'moneda_p'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio Pago'" :binding="'tipo_cambio_p'" width="2*"
                                format="c2" :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Total Pagado'" :binding="'monto'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total Pagado MXN'" :binding="'total_pagado_mxn'" width="2*"
                                format="c2" :isReadOnly="true" />




                            <wj-flex-grid-column :header="'Número de Operación'" :binding="'num_operacion'" width="2*"
                                :isReadOnly="true" />


                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Información de Documentos Relacionados'" width="2*"
                                :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                                    <table style="width:100%">
                                        <tbody>
                                            <tr class="borde">
                                                <th></th>
                                                <th class="text-left body-1" style="white-space: nowrap">
                                                    Folio Fiscal
                                                </th>
                                                <th class="text-left body-1">
                                                    Serie
                                                </th>
                                                <th class="text-left body-1">
                                                    Folio
                                                </th>
                                                <th class="text-left body-1" style="white-space: nowrap">
                                                    Número Parcialidad
                                                </th>
                                                <th class="text-right body-1" style="white-space: nowrap">
                                                    Importe Saldo Anterior
                                                </th>
                                                <th class="text-right body-1" style="white-space: nowrap">
                                                    Importe Saldo Insoluto
                                                </th>

                                                <th class="text-left body-1" style="white-space: nowrap">
                                                    Metodo Pago
                                                </th>
                                                <th class="text-left body-1" style="white-space: nowrap">
                                                    Moneda
                                                </th>
                                                <th class="text-right body-1" style="white-space: nowrap">
                                                    Tipo Cambio
                                                </th>
                                                <th class="text-right body-1" style="white-space: nowrap">
                                                    Importe Pagado
                                                </th>
                                                <th class="text-right body-1" style="white-space: nowrap">
                                                    Importe Pagado MXN
                                                </th>
                                            </tr>
                                            <template>

                                                <tr class="altura caption" :key="index"
                                                    v-for="(dr, index) in cell.item.documentos_relacionados">
                                                    <td class="pa-0 md-0">

                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn @click="verMasDatos(dr.id_documento)" icon
                                                                    color="#4aa832" v-on="on">
                                                                    <v-icon>mdi-eye-plus-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Ver Más</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td style="white-space: nowrap">{{ dr.id_documento }}</td>
                                                    <td>{{ dr.serie }}</td>
                                                    <td>{{ dr.folio }}</td>
                                                    <td align="right">{{ dr.num_parcialidad }}</td>
                                                    <td align="right">{{
                                                        currencyFormat(dr.imp_saldo_anterior)
                                                    }}</td>
                                                    <td align="right">{{
                                                        currencyFormat(dr.imp_saldo_insoluto)
                                                    }}</td>
                                                    <td>{{ dr.metodo_pago_dr }}</td>
                                                    <td>
                                                        {{ dr.moneda_dr }}
                                                    </td>
                                                    <td align="right">
                                                        {{ dr.tipo_cambio_dr }}
                                                    </td>
                                                    <td align="right">
                                                        {{ currencyFormat(dr.imp_pagado) }}
                                                    </td>
                                                    <td align="right">
                                                        {{ currencyFormat(dr.imp_pagado * dr.tipo_cambio_dr) }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>


                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>

                        </wj-flex-grid>


                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
    name: "PaymentVsCfdi",
    components: {
        DateRangePicker,
        Dialogo

    },
    data: () => ({

        selectedItemsDescarga: [],
        allSelected: false,


        selectedDates: [],
        selectedDatesIn: [],
        selectedRows: [],
        descripcionIn: "",
        itemDesc: "",
        conPago: false,
        conPago: false,
        eliminarId: "",
        showDialogEliminarReport: false,
        showDialogCrear: false,
        showDialogReports: false,
        contexto: "Emitidos",
        contextoIn: "Emitidos",
        metodoPago: "",
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },

        metodosPago: [
            {
                opcion: "Todos",
                valor: "",
            },
            {
                opcion: "Solo PPD",
                valor: "1",
            },
            {
                opcion: "Solo PUE",
                valor: "2",
            },
            {
                opcion: "Solo PUE sin Pagos",
                valor: "3",
            },
            {
                opcion: "Solo PUE con Pagos",
                valor: "4",
            },
        ],
        goPage: 1,
        tipoPago: "Seleccionar Todos",
        itemsSelect: ["Seleccionar Todos", "Pagado", "No Pagado", "Sobre Pagado"],
        showDialog: false,
        folioDoc: "",
        modal: false,




        errorMessageIn: "",
        okMessageIn: "",
        alert_errorIn: false,
        alert_okIn: false,
        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        itemsGrandes: [],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        rfcRClass: false,
        serieClass: false,
        fechaEClass: true,
        rfcEClass: false,
        folioClass: false,
        monedaClass: false,
        tipo_comprobanteClass: false,
        metodo_pagoClass: false,
        importe_pagado_mxnClass: false,
        itemSelected: [
            {
                cfdi_pago_folio_fiscal: "",
                cfdi_pago_fecha_emision: "",
                pago_fecha_pago: "",
                pago_moneda: "",
                pago_tipo_cambio: 0,
                pago_monto: 0,
                rel_serie: "",
                rel_folio: "",
                rel_metodo_pago: "",
                rel_moneda: "",
                rel_tipo_cambio: 0,
                rel_num_parcialidad: 0,
                rel_imp_pagado: 0,
                rel_imp_saldo_anterior: 0,
                rel_imp_saldo_insoluto: 0,
            },
        ],
        folioSelected: "",
        serieSelected: "",
        serieSelected: "",
        rfcSearch: "",
        selectedRfc: "",
        dateTs: "",
        showDialogRelacionados: false,
        itemsRel: [],
        verLimpiarFiltros: false,
        rfcSearchIn: "",
    }),
    created() {
        this.$store.commit("setIsRetencion", false);
        this.$store.commit("setBlData", null);

        this.$store.commit("setAlertError", false);
        this.errorMessage = "";

        let dataInterval = this.$store.state.dateInterval;

        if (dataInterval === null) return;

        this.verLimpiarFiltros = true;
        this.selectedDates[0] = dataInterval.dateInit;
        this.selectedDates[1] = dataInterval.dateEnd;
        this.dateTs = dataInterval.dateTs;
        this.conPago = this.$store.state.tipoDocTs === "Pago";
        this.$store.commit("setTipoDocTs", "");
        this.$store.commit("setDateInterval", null);

        this.getTotalCount();
    },
    methods: {
        cerrarCrearSolicitud() {
            this.descripcionIn = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.contextoIn = "Emitidos";
            this.rfcSearchIn = "";
            this.showDialogCrear = false;
        },


        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(id) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: id,
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },

        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }

            for (let i = 0; i < grid.rows.length; i++) {
                grid.autoSizeRows(i);
            }

            grid.columns[grid.columns.length - 2].width = 150;
            grid.columns[grid.columns.length - 1].width += 60;


        },
        ConRelacionados(item) {

            if (item.documentos_relacionados !== null) {
                if (item.documentos_relacionados.length > 0)
                    return true;
            }

            return false;

        },
        rowClicked(row) {
            this.selectedRows = [];
            this.toggleSelection(row.id);
            console.log(row);
        },
        toggleSelection(keyID) {
            if (this.selectedRows.includes(keyID)) {
                this.selectedRows = this.selectedRows.filter(
                    (selectedKeyID) => selectedKeyID !== keyID
                );
            } else {
                this.selectedRows.push(keyID);
            }
        },
        CleanFilters() {
            this.dateTs = "";
            this.verLimpiarFiltros = false;
        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contexto.substring(0, 1),
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.esRecibido) par = "R||" + item.rfc;
            else par = "E||";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertError", false);
                this.$store.commit("setAlertOk", true);
                this.okMessage = "";
                this.$store.commit("setOverlay", false);
            }, 2000);
        },
        retrievePagoXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.esEmitido ? "E" : "R",
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePagoPDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.esRecibido) par = "R||" + this.selectedRfc;
            else par = "E||";
            let parameters =
                par +
                "|" +
                item.id +
                "|" +
                item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 2000);
        },
        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        verPagos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid_rel: item.id,
                issuedDate: item.fecha_emision.substr(0, 10),
            };
            service
                .api()
                .IncomePagosyRel(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.folioSelected = item.folio;
                        this.serieSelected = item.serie;
                        this.itemSelected = JSON.parse(response.payload);
                        this.showDialog = true;
                        this.selectedRfc = item.rfc;
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        exportExcel() {
            var headers = {
                Id: "Folio Fiscal Pago",
                FechaPago: "Fecha de Pago",
                Rfc: this.esEmitido ? "RFC Receptor" : "RFC Emisor",
                Nombre: this.esEmitido
                    ? "Razon Social Receptor"
                    : "Razon Social Emisor",
                Tipo: "Contexto",
                Serie: "Serie",
                Folio: "Folio",
                MonedaP: "Moneda Pago",
                TipoCambioP: "Tipo Cambio Pago",
                MontoP: "Monto Pago",
                ImporteP: "Importe Pagado",
                NumOperacion: "Número Operación",
                NumParcialidad: "Número Parcialidad",
                ImportePagado: "Importe Pagado MXN",
                ImporteSaldoAnterior: "Importe Saldo Anterior",
                ImporteSaldoInsoluto: "Importe Saldo Insoluto",

                FolioFiscal: "Folio Fiscal CFDI",
                FechaEmision: "Fecha Emisión CFDI",
                MetodoPago: "Método Pago CFDI",
                Moneda: "Moneda CFDI",
                TipoCambio: "Tipo Cambio CFDI",
                Monto: "Monto",
                MontoMXN: "Monto MXN",
            };

            var itemsFormatted = [];

            this.csvData.forEach((item) => {
                let contentRow = {
                    Id: helpers.ConvertToEmpty(item.id),
                    FechaPago: helpers.ConvertToEmpty(item.fecha_pago),
                    Rfc: helpers.ConvertToEmpty(item.rfc),
                    Nombre: helpers.ConvertToEmpty(item.nombre),
                    Tipo: this.esEmitido ? "Emitido" : "Recibido",
                    Serie: helpers.ConvertToEmpty(item.serie),
                    Folio: helpers.ConvertToEmpty(item.folio),
                    MonedaP: helpers.ConvertToEmpty(item.moneda_p),
                    TipoCambioP: helpers.ConvertToEmpty(item.tipo_cambio_p),
                    MontoP: helpers.ConvertToEmpty(item.imp_pagado),
                    ImporteP: helpers.ConvertToEmpty(item.tipo_cambio_p * item.imp_pagado),
                    NumOperacion: helpers.ConvertToEmpty(item.num_operacion),
                    NumParcialidad: helpers.ConvertToEmpty(item.num_parcialidad),
                    ImportePagado: helpers.ConvertToEmpty(item.imp_pagado),
                    ImporteSaldoAnterior: helpers.ConvertToEmpty(item.imp_saldo_anterior),
                    ImporteSaldoInsoluto: helpers.ConvertToEmpty(item.imp_saldo_insoluto),

                    FolioFiscal: helpers.ConvertToEmpty(item.id_documento),
                    FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
                    MetodoPago: helpers.ConvertToEmpty(item.metodo_pago_dr),
                    Moneda: helpers.ConvertToEmpty(item.moneda_dr),
                    TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio_dr),
                    Monto: helpers.ConvertToEmpty(item.monto),
                    MontoMXN: helpers.ConvertToEmpty(item.monto * item.tipo_cambio_dr)

                };
                itemsFormatted.push(contentRow);

            });

            this.csvDataT = itemsFormatted;
            var fileTitle =
                "Listado_ComprobantesPago_CFDIs_" +
                helpers.dateNowCSV().replace(/-/g, "_");
            helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.date);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.limit = this.pageSize;
            this.noPagina = 1;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
        },
        ConfirmarEliminarReporte(item) {
            this.eliminarId = item.id;
            this.itemDesc = item.description;
            this.showDialogEliminarReport = true;
        },
        EliminarReporte() {
            var jsonData = {
                id: this.eliminarId,
                token: this.$store.state.token,
            };
            this.showDialogEliminarReport = false;
            service
                .api()
                .ReportePaymentDeleteReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Eliminado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);
                        if (this.itemsGrandes.length == 1)
                            this.itemsGrandes = [];
                        else
                            this.ObtenerReportes();
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },

        DescargarReporte(itemId) {
            return new Promise((resolve, reject) => {
                const jsonData = {
                    id: itemId,
                    token: this.$store.state.token,
                };

                service
                    .api()
                    .ReportePaymentRetrieveReport(jsonData)
                    .then((response) => {
                        if (response.result) {
                            this.$store.commit("setOverlay", false);
                            this.retrieveReporte(response.payload);
                            resolve(response.payload); // Resolver con el payload
                        } else {
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", response.errorMessage);
                            reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
                        }
                    })
                    .catch((error) => {
                        reject(error); // Rechazar si ocurre un error en el servicio
                    });
            });
        },
        descargarMultiple() {
            this.descargarTodosEnParalelo(this.selectedItemsDescarga);

        },
        async descargarTodosEnParalelo(items) {
            try {
                const promesas = items.map((item) => this.DescargarReporte(item.id));
                await Promise.all(promesas);
                console.log("Todas las descargas han finalizado.");
            } catch (error) {
                console.error("Error en una o más descargas:", error);
            }
        },
        toggleSelectAll() {
            if (this.allSelected) {
                this.selectedItemsDescarga = [...this.itemsGrandes];
            } else {
                this.selectedItemsDescarga = [];
            }
        },
        ObtenerReportes() {
            this.showDialogReports = true;
            var jsonData = {
                token: this.$store.state.token,
            };

            service
                .api()
                .ReportePaymentRetrieveGrandes(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsGrandes = JSON.parse(response.payload);
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                });
        },
        requestItems() {
            this.showDialogCrear = false;

            var jsonData = {
                description: this.descripcionIn,
                token: this.$store.state.token,
                dateInit: this.selectedDatesIn[0],
                dateEnd: this.selectedDatesIn[1],
                context: this.contextoIn.substring(0, 1),
                taxid: this.rfcSearchIn,

            };
            this.showDialogReports = false;
            service
                .api()
                .ReportePaymentGrande(jsonData)
                .then((response) => {
                    this.cerrarCrearSolicitud();
                    if (response.result) {
                        this.alert_okIn = true;
                        this.okMessageIn = "Reporte Solicitado Correctamente";
                        setTimeout(() => {
                            this.alert_okIn = false;
                            this.okMessageIn = "";
                        }, 10000);
                    } else {
                        this.alert_errorIn = true;
                        this.errorMessageIn = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorIn = false;
                            this.errorMessageIn = "";
                        }, 10000);
                    }
                    this.showDialogReports = true;
                    this.descripcionIn = "";
                    this.selectedDatesIn[0] = "";
                    this.selectedDatesIn[1] = "";
                });


        },
        getReporteItems() {
            this.selectedRows = [];
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: this.rfcSearch
            };
            service
                .api()
                .ReportePaymentVsCFDI(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;

                        this.doctosPagina = this.items.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                rfc: this.rfcSearch,
            };

            service
                .api()
                .ReportePaymentVsCFDICount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReporteConciliatorioCSV() {


            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                rfc: this.rfcSearch,
            };

            service
                .api()
                .ReportePaymentVsCFDICSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        this.exportExcel();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },
        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.rfcRClass = false;
            this.serieClass = false;
            this.fechaEClass = false;
            this.rfcEClass = false;
            this.folioClass = false;
            this.monedaClass = false;
            this.tipo_comprobanteClass = false;
            this.metodo_pagoClass = false;
            this.importe_pagado_mxnClass = false;

            switch (columnName) {
                case "receptor.rfc":
                    this.rfcRClass = true;
                    break;
                case "serie":
                    this.serieClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
                case "emisor.rfc":
                    this.rfcEClass = true;
                    break;
                case "folio":
                    this.folioClass = true;
                    break;
                case "moneda":
                    this.monedaClass = true;
                    break;
                case "tipo_comprobante":
                    this.tipo_comprobanteClass = true;
                    break;
                case "metodo_pago":
                    this.metodo_pagoClass = true;
                    break;
                case "importe_pagado_mxn":
                    this.importe_pagado_mxnClass = true;
                    break;
            }
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
        ConsultarDocsRelacionados(item) {
            var jsonData = {
                token: this.$store.state.token,
                initYear: item.fecha_emision.substr(0, 4),
                uuid: item.id,
            };

            service
                .api()
                .RelacionadosConceptoDocs(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.showDialogRelacionados = true;
                        this.itemSelected = item;
                        this.itemsRel = JSON.parse(response.payload);
                        if (response.payload == "[]") {
                            this.showDialogRelacionados = false;
                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", "El o los documentos relacionados a los que se hacen referencia no se encuentran en la base de datos");
                        }
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
    },
    computed: {
        mostrarDescargarSeleccionado() {
            return this.selectedItemsDescarga.length > 0;
        },
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto.substring(0, 1) === "E";
        },
        esRecibido() {
            return this.contexto.substring(0, 1) === "R";
        },

        esCan() {
            return this.esCancelado === "true";
        },
        showSearchButton() {
            return this.selectedDates.length > 0;
        },
        showCreateButton() {
            return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
        },

        showSearchButtonGUID() {
            return this.guidSearch.length > 0;
        },
        showTotalRel() {
            var dateInitY = Number(this.selectedDates[0].substr(0, 4));
            var dateEndY = Number(this.selectedDates[1].substr(0, 4));
            return dateInitY >= 2021 && dateEndY >= 2021;
        },
        labelRfc() {
            if (this.contexto.substring(0, 1) === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        }
    },
};
</script>

<style scoped>
.altura {
    vertical-align: top;
    padding-top: 10px;
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}

.borde th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    background-color: #113145;
    color: white;
}

.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}


.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
}
</style>
