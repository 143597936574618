<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row justify="center"> </v-row>
      <v-row>

        <v-col cols="12">
          <h3>CONTRATO DE LICENCIA DE USO DEL PROGRAMA DE CÓMPUTO MYSUITE XDATA</h3>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-2">
            <v-card-text>
              <v-row>
                <v-col cols="1"></v-col>
                <v-col v-if="pdfPreview.length > 0" cols="10" justify="center">
                  <embed :src="'data:application/pdf;base64,' + pdfPreview" type="application/pdf" width="100%"
                    height="600px" />

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="mostrarDatosFIEL" cols="12">
          <v-card class="pa-2">
            <v-row class="py-3">
              <v-col cols="12" md="3" justify="center">
                <v-file-input accept=".cer" label="Selecciona el archivo .cer" outlined v-model="cert"></v-file-input>
              </v-col>
              <v-col cols="12" md="3" justify="center">
                <v-file-input accept=".key" label="Selecciona el archivo .key" outlined v-model="key"></v-file-input>
              </v-col>
              <v-col cols="12" md="3" justify="center">
                <v-text-field v-model="pass" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'" label="FIEL Contraseña" counter
                  @click:append="show1 = !show1"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn color="primary" white large @click="signLegalContract" :disabled="!showSaveButton">{{
                      labelFirmar }}</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import helpers from "../scripts/helpers.js";
import service from "../scripts/services.js";
import { JSONToCSV } from "../scripts/helpers.js";

export default {
  name: "Contrato",

  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    date2: "",

    certFielString: "",
    keyFielsString: "",
    passFielString: "",
    pdfPreview: "",
    search: "",
    landscape: false,
    razonSocial: "",
    cert: null,
    key: null,
    pass: "",
    show1: false,
    showConfirmButton: false,
    contrato: "",
    signInfo: "",
    accepted: false,
    uuid: "",
    templateId: 0,
    singContract: "",
    mostrarDatosFIEL: false,
  }),
  created() {
    this.certFielString = this.$store.state.certFiel;
    this.keyFielsString = this.$store.state.keyFiel;
    this.passFielString = this.$store.state.passFiel;
    this.razonSocial =
      this.$store.state.userLongName + " ( " + this.$store.state.entity + " )";



    if (this.$store.state.idContratoZoho) {
      this.getContractZohoInfo();
    }
    else {
      this.getContractInfo();
    }
    //if (this.$store.state.newContractSign) {
    //  this.getContractInfoV2();
    //}
    //else {
    //  this.getContractInfo();
    // }
  },
  methods: {
    getContractZohoInfo() {
      let jsonData = {
        idContrato: this.$store.state.idContratoZoho,
        token: this.$store.state.token,
      };
      service
        .api()
        .ContractArchivo(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setOverlay", false);
            let pLoad = JSON.parse(response.payload);
            if (this.$store.state.firmadoZoho) {
              this.pdfPreview = pLoad.documentoFirmado.archivo;
              this.mostrarDatosFIEL = false;
            }
            else {
              this.pdfPreview = pLoad.plantilla.archivo;
              this.mostrarDatosFIEL = true;
            }


          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });

    },
    getContractInfo() {
      let jsonData = {
        cert: "",
        key: "",
        password: "",
        token: this.$store.state.token,
      };
      service
        .api()
        .RetrieveLegalDoc(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let pLoad = JSON.parse(response.payload);
            let legal = JSON.parse(pLoad.LegalDocument);
            if (legal[0].Documento != null) {
              this.pdfPreview = legal[0].Documento;
              this.mostrarDatosFIEL = false;
            }
            else {
              this.getNotSignedPDF();
              this.mostrarDatosFIEL = true;
            }



          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });

    },
    getContractInfoV2() {
      this.getNotSignedPDF();
      this.mostrarDatosFIEL = true;
    },
    getNotSignedPDF() {
      let jsonData =
      {
        token: this.$store.state.token,
        accountName: this.$store.state.userLongName,
        md_content: ""
      };
      service
        .api()
        .LegalDocRetrieveNotSignedPDF(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.pdfPreview = response.payload;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    signLegalContract() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      var jsonData = {};
      let cerFile = "";
      let keyFile = "";
      this.toBase64(this.cert).then((customJsonFile) => {
        cerFile = customJsonFile;
        this.toBase64(this.key).then((customJsonFile) => {
          keyFile = customJsonFile;


          if (this.$store.state.idContratoZoho) {
            jsonData = {
              cert: cerFile,
              key: keyFile,
              password: this.pass,
              accountName: this.$store.state.userLongName,
              pdfContent: this.pdfPreview,
              token: this.$store.state.token,
              internalID: this.$store.state.idContratoZoho
            };

            service
              .api()
              .SignZohoPdf(jsonData)
              .then((response) => {
                if (response.result) {
                  this.$store.commit("setAlertOk", true);
                  this.$store.commit("setAlertError", false);
                  this.$store.commit("setAlertMessage", "Contrato Firmado. Fiel Almacenada");
                  this.$store.commit("setOverlay", false);
                  this.$store.commit("setMostrarHeader", true);
                  this.$store.commit("setShowDrawer", true);
                  this.$store.commit("setIsContractAccepted", true);
                  this.mostrarDatosFIEL = false;
                  this.certFielString = cerFile;
                  this.keyFielsString = keyFile;
                  this.passFielString = this.pass;
                  //  this.FielManagerSave();
                  let pLoad = JSON.parse(response.payload);
                  this.pdfPreview = pLoad.contenido_firmado;

                  this.firmarZohoContrat(pLoad.contenido_firmado, pLoad.uuid);
                  this.cert = null;
                  this.key = null;
                  this.pass = "";
                } else {
                  this.$store.commit("setAlertOk", false);
                  this.$store.commit("setAlertError", true);
                  this.$store.commit("setAlertMessage", response.errorMessage);
                }
              });
          }
          else {

            jsonData = {
              cert: cerFile,
              key: keyFile,
              password: this.pass,
              accountName: this.$store.state.userLongName,
              md_content: "",
              token: this.$store.state.token,
            };

            service
              .api()
              .SignPdf(jsonData)
              .then((response) => {
                if (response.result) {
                  this.$store.commit("setAlertOk", true);
                  this.$store.commit("setAlertError", false);
                  this.$store.commit("setAlertMessage", "Contrato Firmado. Fiel Almacenada");
                  this.$store.commit("setOverlay", false);
                  this.$store.commit("setMostrarHeader", true);
                  this.$store.commit("setShowDrawer", true);
                  this.$store.commit("setIsContractAccepted", true);
                  this.mostrarDatosFIEL = false;
                  this.certFielString = cerFile;
                  this.keyFielsString = keyFile;
                  this.passFielString = this.pass;
                  //  this.FielManagerSave();
                  let pLoad = JSON.parse(response.payload);
                  this.pdfPreview = pLoad.contenido_firmado;

                  this.cert = null;
                  this.key = null;
                  this.pass = "";
                } else {
                  this.$store.commit("setAlertOk", false);
                  this.$store.commit("setAlertError", true);
                  this.$store.commit("setAlertMessage", response.errorMessage);
                }
              });
          }
        });
      });

    },
    firmarZohoContrat(contenidoFirmado, uuid) {
      let jsonData =
      {
        idContrato: this.$store.state.idContratoZoho,
        token: this.$store.state.token,
        nombre: uuid + ".pdf",
        archivo: contenidoFirmado
      };
      service
        .api()
        .ZohoFirmarContrato(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.$store.commit("setFirmadoZoho", true);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "Error almacenando contrato. " + response.errorMessage);
          }
        });
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },
  },
  computed: {
    conFiel() {
      return this.$store.state.hasFiel;
    },
    showSaveButton() {
      return this.cert != null && this.key != null && this.pass.length > 0;
    },
    labelFirmar() {
      return this.conFiel ? "Guardar" : "Firmar";
    },
    showSaveButton() {
      return this.cert != null && this.key != null && this.pass.length > 0;
    },
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}
</style>
