<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">

        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                      class="ml-auto mr-3">
                      <v-icon>mdi-download-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Seleccionados</span>
                </v-tooltip>

                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>

              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                    errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{
                    okMessageIn
                    }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                              hide-details></v-checkbox>
                          </th>

                          <th>Nombre</th>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Contexto</th>
                          <th>Tipo Comprobante</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Mensaje</th>
                          <th>Disponible</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            <v-checkbox v-model="selectedItemsDescarga" :value="item" color="primary"
                              hide-details></v-checkbox>
                          </td>

                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{
                              JSON.parse(item.commands).context == "E" ? "Emitido" :
                            "Recibido"
                            }}
                          </td>
                          <td>
                            {{ parseDocType(JSON.parse(item.commands).docType) }}
                          </td>
                          <td style="white-space: nowrap">
                            {{ JSON.parse(item.commands).dateInit }}
                          </td>
                          <td style="white-space: nowrap">
                            {{ JSON.parse(item.commands).dateEnd }}
                          </td>
                          <td>
                            {{ JSON.parse(item.commands).taxid }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{ item.ready ? "Si" : "No" }}
                          </td>
                          <td>
                            {{
                              item.ready
                                ? item.result
                                  ? "Exitoso"
                            : "Fracasado"
                            : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>



        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarCrearSolicitud">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" justify="center">
                  <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" class="my-1" maxlength="13">
                  </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="itemsSelect" v-model="tipoDocIn" label="Tipo de Comprobante" outlined>
                  </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="6">
                  <v-select :items="itemsOrigen" v-model="contextoIn" label="Contexto" outlined>
                  </v-select>
                </v-col>
                <v-col md="10" justify="center">
                  <v-text-field v-model="nombreIn" counter="0" label="Nombre" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
                <v-col md="10" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar
                Reporte
              </v-btn>
              <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="showDialogStatus" persistent max-width="700px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarStatus">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Estado</th>
                      <th>Cancelable</th>
                      <th>Estado Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ selectedStatus.estado }}</td>
                      <td>{{ selectedStatus.cancelable }}</td>
                      <td>{{ selectedStatus.estado_cancelacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <tr>
                    <td>
                      <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                        <p class="display-1 text--primary">Emisor</p>
                        <div class="body-1">
                          Razon Social: {{ itemSelected.emisor_nombre }}
                        </div>
                        <div class="body-1">
                          RFC: {{ itemSelected.emisor_rfc }}
                        </div>
                        <div class="body-1">
                          Regimen: {{ itemSelected.regimen }}
                        </div>
                      </v-card>
                    </td>
                    <td>
                      <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                        <p class="display-1 text--primary">Receptor</p>
                        <div class="body-1">
                          Razon Social: {{ itemSelected.receptor_nombre }}
                        </div>
                        <div class="body-1">
                          RFC: {{ itemSelected.receptor_rfc }}
                        </div>
                        <div class="body-1">
                          Residencia: {{ itemSelected.residencia }}
                        </div>
                        <div class="body-1">
                          Uso CFDI: {{ itemSelected.usocfdi }}
                        </div>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </v-simple-table>
              <br />
              <h3>Folio Fiscal: {{ itemSelected.id }}</h3>
              <br />

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th>Tipo Comprobante</th>
                      <th>Método de Pago</th>
                      <th>Forma de Pago</th>
                      <th>Lugar Expedición</th>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <!-- <th>Fecha Cancelación</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ itemSelected.serie }}</td>
                      <td>{{ itemSelected.folio }}</td>
                      <td>
                        {{ getTipoComprobante(itemSelected.tipo_comprobante) }}
                      </td>
                      <td>{{ itemSelected.metodo_pago }}</td>
                      <td>{{ itemSelected.forma_pago }}</td>
                      <td>{{ itemSelected.lugar_expedicion }}</td>
                      <td>{{ itemSelected.fecha_emision }}</td>
                      <td>{{ itemSelected.fecha_timbre }}</td>
                      <!-- <td>
                        {{ itemSelected.fecha_cancelacion }}
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="font-weight-black">Totales:</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">Tipo Cambio</th>
                      <th class="text-left">Moneda</th>
                      <th class="text-right">Total Retenciones</th>
                      <th class="text-right">Total Traslados</th>
                      <th class="text-right">Subtotal</th>
                      <th class="text-right">Descuento</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td align="right">
                        {{ itemSelected.tipo_cambio }}
                      </td>
                      <td>{{ itemSelected.moneda }}</td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_retenciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_traslados) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.subtotal) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.descuento) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>{{ titulo }}


            <v-tooltip v-if="kind == 'REGIMENFISCAL'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-regimen-fiscal"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
            <v-tooltip v-if="kind == 'USOCFDI'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-en-uso-de-cfdi"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
            <v-tooltip v-if="kind == 'CP'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-lugar-de-expedicion"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
            <v-tooltip v-if="kind == 'FORMAPAGO'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-en-forma-pago"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
            <v-tooltip v-if="kind == 'MONEDA'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/inconsistencias-en-moneda" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>


          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" :label="labelRfc" maxlength="13" class="my-1">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoDoc"
                  label="Tipo de Comprobante" outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto"
                  outlined>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar
                  Listado</v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes
                      Bajo Demanda
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="verLimpiarFiltros" cols="12" md="1">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn color="primary" white large v-on:click="CleanFilters">Limpiar Filtros</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                  :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">


              <wj-flex-grid-column v-if="kind == 'REGIMENFISCAL' && esEmitido" :header="'Regímen Fiscal Emisor'"
                :binding="'regimen'" width="4*" :isReadOnly="true" cssClass="main-column" />
              <wj-flex-grid-column v-if="kind == 'REGIMENFISCAL' && !esEmitido" :header="'Regímen Fiscal Receptor'"
                :binding="'receptor_regimen'" width="4*" :isReadOnly="true" cssClass="main-column" />



              <wj-flex-grid-column v-if="kind == 'USOCFDI'" :header="'Uso CFDI'" width="2*" :isReadOnly="true"
                cssClass="main-column">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.usocfdi + "-" + getUsoCfdi(cell.item.usocfdi) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>


              <wj-flex-grid-column v-if="kind == 'CP'" :header="'Lugar Expedición'" :binding="'lugar_expedicion'"
                width="4*" :isReadOnly="true" cssClass="main-column" />


              <wj-flex-grid-column v-if="kind == 'FORMAPAGO'" :header="'Forma de Pago'" :binding="'forma_pago'"
                width="4*" :isReadOnly="true" cssClass="main-column" />

              <wj-flex-grid-column v-if="kind == 'MONEDA'" :header="'Moneda'" :binding="'moneda'" :isReadOnly="true"
                cssClass="main-column" />


              <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true"
                class="main-column" />
              <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor_rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'" width="4*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor_nombre'" width="4*"
                :isReadOnly="true" />


              <wj-flex-grid-column v-if="kind != 'REGIMENFISCAL'" :header="'Regímen Fiscal'" :binding="'regimen'"
                width="4*" :isReadOnly="true" />



              <wj-flex-grid-column v-if="kind != 'USOCFDI'" :header="'Uso CFDI'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.usocfdi + "-" + getUsoCfdi(cell.item.usocfdi) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>


              <wj-flex-grid-column v-if="kind != 'CP'" :header="'Lugar Expedición'" :binding="'lugar_expedicion'"
                width="4*" :isReadOnly="true" />


              <wj-flex-grid-column v-if="kind != 'FORMAPAGO'" :header="'Forma de Pago'" :binding="'forma_pago'"
                width="4*" :isReadOnly="true" />

              <wj-flex-grid-column v-if="kind != 'MONEDA'" :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />


              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Retención ISR'" :binding="'retencion_isr'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IVA'" :binding="'retencion_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IEPS'" :binding="'retencion_ieps'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IVA'" :binding="'traslado_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IEPS'" :binding="'traslado_ieps'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Retenciones'" :binding="'total_retenciones'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Traslados'" :binding="'total_traslados'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Consultar Estado del Documento</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>





          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "RepAuditoria",
  props: ["titulo", "kind"],
  components: {
    DateRangePicker
  },
  data: () => ({


    selectedItemsDescarga: [],
    allSelected: false,

    onDemandType: "",
    showDialogEliminarReport: false,
    showDialogReports: false,
    showDialogCrear: false,
    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    itemsGrandes: [],
    selectedDatesIn: [],
    rfcSearchIn: "",
    descripcionIn: "",
    nombreIn: "",
    itemDesc: "",
    tipoDocIn: "Seleccionar Todos",
    contextoIn: "Emitidos",



    selectedDates: [],
    selectedRows: [],
    contexto: "Emitidos",
    boolCancelado: false,
    esCancelado: "No",
    itemsCancelado: ["No", "Si"],
    showCantidades: false,
    showDialog: false,
    showDialogStatus: false,
    goPage: 1,
    selectedStatus: {
      estado: "",
      cancelable: "",
      estado_cancelacion: "",
    },
    cambio: false,
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalTraslados: 0,
    totalPagos: 0,
    ImporteIngresos: 0,
    ImporteEgresos: 0,
    ImporteTraslados: 0,
    ImportePagos: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    tipoDoc: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso", "Traslado", "Pago"],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    verLimpiarFiltros: false,
    itemSelected: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    rfcSearch: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);

    switch (this.kind) {
      case "REGIMENFISCAL":
        this.onDemandType = "REPORT-AUDITREGIMENFISCAL";
        break;
      case "USOCFDI":
        this.onDemandType = "REPORT-AUDIT-USOCFDI";
        break;
      case "CP":
        this.onDemandType = "REPORT-AUDIT-CP";
        break;
      case "FORMAPAGO":
        this.onDemandType = "REPORT-AUDIT-FORMAPAGO";
        break;
      case "MONEDA":
        this.onDemandType = "REPORT-AUDIT-MONEDA";
        break;

    }
  },
  methods: {
    getTipoDocIn() {
      let firstLetter = this.tipoDocIn.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    parseDocType(tipoDoc) {

      return helpers.parseDoctype(tipoDoc);

    },
    cerrarCrearSolicitud() {
      this.nombreIn = "";
      this.descripcionIn = "";
      this.selectedDatesIn = [];
      this.$refs.dateRange.limpiarFechas();
      this.contextoIn = "Emitidos";
      this.tipoDocIn = "Seleccionar Todos",
        this.rfcSearch = "";
      this.relacionesIn = "";
      this.showDialogCrear = false;
    },

    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }





      grid.columns[grid.columns.length - 1].width = 200;


    },
    CleanFilters() {
      this.verLimpiarFiltros = false;
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substr(0, 1),
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
    verMasDatos(item) {
      this.itemSelected = item;
      this.showDialog = true;
    },
    getFormaPago(codigo) {
      return helpers.FormaPago(codigo);
    },
    getUsoCfdi(codigo) {
      return helpers.UsoCFDI(codigo);
    },
    getRegimen(codigo) {
      return helpers.RegimenFiscal(codigo);
    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: "RFC Emisor",
        NombreE: "Razon Social Emisor",
        RfcR: "RFC Receptor",
        NombreR: "Razon Social Receptor",
        CRegimen: "Código Regimen Emisor",
        NRegimen: "Regimen Fiscal Emisor",
        RCRegimen: "Código Regimen Receptor",
        RRegimen: "Regimen Fiscal Receptor",
        ResidenciaR: "Residencia Receptor",
        CUsoCfdiR: "Código Uso CFDI",
        NUsoCfdiR: "Uso CFDI",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Serie: "Serie",
        Folio: "Folio",
        LugarExpedicion: "Lugar de Expedicion",
        MetodoPago: "Método Pago",
        CFormaPago: "Código Forma de Pago",
        NFormaPago: "Forma de Pago",
        Moneda: "Moneda",
        Regimen: "Regímen Fiscal",
        UsoCFDI: "Uso CFDI",
        LugarExpedicion: "Lugar Expedición",
        TipoCambio: "Tipo Cambio",
        SubTotal: "Subtotal",
        Descuento: "Descuento",
        RetencionISR: "Retención ISR",
        RetencionIVA: "Retención IVA",
        RetencionIEPS: "Retención IEPS",
        TrasladoIVA: "Traslado IVA",
        TrasladoIEPS: "Traslado IEPS",
        TotalRetenciones: "Total Retenciones",
        TotalTraslados: "Total Traslados",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.emisor_rfc),
          NombreE: helpers
            .ConvertToEmpty(item.emisor_nombre)
            .replace(/,/g, " "),
          RfcR: helpers.ConvertToEmpty(item.receptor_rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor_nombre),
          CRegimen: helpers.ConvertToEmpty(item.regimen),
          NRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.regimen))
            .replace(/,/g, " "),
          RCRegimen: helpers.ConvertToEmpty(item.receptor_regimen),
          RRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.receptor_regimen))
            .replace(/,/g, " "),
          ResidenciaR: helpers.ConvertToEmpty(item.residencia),
          CUsoCfdiR: helpers.ConvertToEmpty(item.usocfdi),
          NUsoCfdiR: helpers
            .UsoCFDI(helpers.ConvertToEmpty(item.usocfdi))
            .replace(/,/g, " "),
          Tipo: helpers.ConvertToEmpty(item.tipo == "E" ? "Emitido" : "Recibido"),
          TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
          NFormaPago: helpers
            .FormaPago(helpers.ConvertToEmpty(item.forma_pago))
            .replace(/,/g, " "),
          Moneda: helpers.ConvertToEmpty(item.moneda),

          Regimen: helpers.ConvertToEmpty(item.regimen),
          UsoCFDI: helpers.ConvertToEmpty(item.usocfdi),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),

          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          Descuento: helpers.ConvertToEmpty(item.descuento),
          RetencionISR: helpers.ConvertToEmpty(item.retencion_isr),
          RetencionIVA: helpers.ConvertToEmpty(item.retencion_iva),
          RetencionIEPS: helpers.ConvertToEmpty(item.retencion_ieps),
          TrasladoIVA: helpers.ConvertToEmpty(item.traslado_iva),
          TrasladoIEPS: helpers.ConvertToEmpty(item.traslado_ieps),
          TotalRetenciones: helpers.ConvertToEmpty(item.total_retenciones),
          TotalTraslados: helpers.ConvertToEmpty(item.total_traslados),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.pac),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Inconsistencia_" +
        this.kind +
        "_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.limit = this.pageSize;
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.selectedDates[0]);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];

      // let boolCancel = "";

      // switch (this.esCancelado) {
      //   case "Todos":
      //     boolCancel = "";
      //     break;
      //   case "Si":
      //     boolCancel = "true";
      //     break;
      //   case "No":
      //     boolCancel = "false";
      //     break;
      // }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substr(0, 1),
        onlyCancelled: this.boolCancelado,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        kind: this.kind,
      };
      service
        .api()
        .AuditList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      // let boolCancel = "";

      // switch (this.esCancelado) {
      //   case "Todos":
      //     boolCancel = "";
      //     break;
      //   case "Si":
      //     boolCancel = "true";
      //     break;
      //   case "No":
      //     boolCancel = "false";
      //     break;
      // }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        context: this.contexto.substr(0, 1),
        onlyCancelled: this.boolCancelado,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        kind: this.kind,
      };

      service
        .api()
        .AuditCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConciliatorioCSV() {
      // let boolCancel = "";

      // switch (this.esCancelado) {
      //   case "Todos":
      //     boolCancel = "";
      //     break;
      //   case "Si":
      //     boolCancel = "true";
      //     break;
      //   case "No":
      //     boolCancel = "false";
      //     break;
      // }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substr(0, 1),
        onlyCancelled: this.boolCancelado,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        kind: this.kind,
      };

      service
        .api()
        .AuditCsv(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido");
          }
        });
    },
    ConsultarEstadoDoc(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo,
        uuid: item.id,
        issuedDate: item.fecha_emision,
        issuer: item.emisor_rfc,
        receiver: item.receptor_rfc,
        total: item.total,
      };

      service
        .api()
        .Consult(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.selectedStatus = JSON.parse(response.payload);
            this.itemSelected = item;
            this.showDialogStatus = true;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    cerrarStatus() {
      this.showDialogStatus = false;
      if (this.selectedStatus.estado == "Cancelado") this.getReport();
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },


    DescargarReporte(itemId) {
      return new Promise((resolve, reject) => {
        const jsonData = {
          id: itemId,
          token: this.$store.state.token,
        };

        service
          .api()
          .RetrieveOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.retrieveReporte(response.payload);
              resolve(response.payload); // Resolver con el payload
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
              reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
            }
          })
          .catch((error) => {
            reject(error); // Rechazar si ocurre un error en el servicio
          });
      });
    },
    descargarMultiple() {
      this.descargarTodosEnParalelo(this.selectedItemsDescarga);

    },
    async descargarTodosEnParalelo(items) {
      try {
        const promesas = items.map((item) => this.DescargarReporte(item.id));
        await Promise.all(promesas);
        console.log("Todas las descargas han finalizado.");
      } catch (error) {
        console.error("Error en una o más descargas:", error);
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItemsDescarga = [...this.itemsGrandes];
      } else {
        this.selectedItemsDescarga = [];
      }
    },

    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: this.onDemandType
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    requestItems() {
      this.showDialogCrear = false;
      this.showDialogReports = false;
      var jsonData = {
        reportType: this.onDemandType,
        name: this.nombreIn,
        description: this.descripcionIn,
        commands: JSON.stringify({ dateInit: this.selectedDatesIn[0], dateEnd: this.selectedDatesIn[1], context: this.contextoIn.substr(0, 1), docType: this.getTipoDocIn(), rfc: this.rfcSearch, kind: this.kind }),
        token: this.$store.state.token,
      };

      service
        .api()
        .RequestOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);

          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
          this.descripcionIn = "";
        });


      this.nombreIn = "";
      this.descripcionIn = "";
      this.selectedDatesIn = [];
      this.$refs.dateRange.limpiarFechas();
      this.contextoIn = "Emitidos";
      this.tipoDocIn = "Seleccionar Todos",
        this.rfcSearch = "";
      this.relacionesIn = "";
      this.showDialogReports = true;


    },
    ObtenerReportes() {

      var jsonData = {
        token: this.$store.state.token,
        reportType: this.onDemandType
      };

      service
        .api()
        .RetrieveOnDemandReports(jsonData)
        .then((response) => {
          this.showDialogReports = true;
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;

            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
  },
  computed: {
    mostrarDescargarSeleccionado() {
      return this.selectedItemsDescarga.length > 0;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto.substr(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substr(0, 1) === "R";
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },
    labelRfc() {
      if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    },
    showCreateButton() {
      return this.selectedDatesIn.length > 0 && this.descripcionIn.length > 0;
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

.main-column,
.main-column.wj-alt {
  font-weight: bolder;
  color: blue;
  background: lightskyblue;
}
</style>
