export function getData() {
 
  let data = [
    {
      anno: '2018',
      mes: '01-Enero',
      contexto: 'Emitidos',
      riesgo: 'SALDO INSOLUTO',
      montoRiesgo: 99223278.00376366,
    },
    
  ];
return data;
}