<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>Montos por Impuestos Facturados

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/montos-por-impuestos-facturados"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="12" class="text-justify">
          Este análisis se solicita por mes.
Muestra la sumatoria de cada tipo de impuesto que aparezca en los CFDI del período solicitado en los CFDI de ingreso o egreso. 
El análisis se presenta dividido en impuestos federales, locales, trasladados y retenidos.
Los comprobantes cancelados no se toman en cuenta para este cálculo.
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      type="month"
                      label="Período"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      locale="es"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    min="2020/12"
                    v-model="date"
                    locale="es"
                    type="month"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select
                  :items="itemsOrigen"
                  v-model="contexto"
                  label="Contexto"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn
                      color="primary"
                      white
                      large
                      v-on:click="ObtenerMontos"
                      >Obtener Montos</v-btn
                    >
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="11"></v-col>
        <v-col cols="12" md="1" v-show="showExportExcel">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn large class="mx-2" icon v-on="on" @click="exportExcel">
                <v-icon color="second"> mdi-file-export </v-icon>
              </v-btn>
            </template>
            <span>Exportar a CSV</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="verLocales" cols="12" md="6">
          <v-card>
           <v-card-title>Monto de Impuestos Locales Trasladados </v-card-title>
           <v-card-subtitle>{{currencyFormat(impuestosLocalTraslados)}}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col v-if="verLocales" cols="12" md="6">
          <v-card>
            <v-card-title>Monto de Impuestos Locales Retenidos </v-card-title>
            <v-card-subtitle>{{currencyFormat(impuestosLocalRetenidos)}}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col v-if="traslados.length > 0" cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="550">
            <v-card-title>Traslados </v-card-title>
            <v-simple-table height="600" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Importe</th>
                    <th class="text-right">Base</th>
                    <th>Impuesto</th>
                    <th>Tipo Factor</th>
                    <th class="text-right">Tasa o Cuota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in traslados" :key="item.id" class="altura">
                    <td align="right">
                      {{ currencyFormat(item.Importe) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.Base) }}
                    </td>
                    <td>
                      {{ getImpuesto(item.Impuesto) }}
                    </td>
                    <td>
                      {{ item.TipoFactor }}
                    </td>
                    <td align="right">
                      {{ taxFormat(item.TasaOCuota) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col v-if="retenciones.length > 0" cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="550">
            <v-card-title>Retenciones </v-card-title>
            <v-simple-table height="600" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Importe</th>
                    <th class="text-right">Base</th>
                    <th>Impuesto</th>
                    <th>Tipo Factor</th>
                    <th class="text-right">Tasa o Cuota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in retenciones" :key="item.id" class="altura">
                    <td align="right">
                      {{ currencyFormat(item.Importe) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.Base) }}
                    </td>
                    <td>
                      {{ getImpuesto(item.Impuesto) }}
                    </td>
                    <td>
                      {{ item.TipoFactor }}
                    </td>
                    <td align="right">
                      {{ taxFormat(item.TasaOCuota)}}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "MontoImpuestos",
  data: () => ({
    
    
    
    
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    contexto: "Emitidos",
    retenciones: [],
    traslados: [],
    impuestosLocalTraslados: 0,
    impuestosLocalRetenidos: 0,
    verLocales: false
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
  },
  methods: {
    ObtenerMontos() {
      var jsonData = {
        token: this.$store.state.token,
        dateYear: this.date.substr(0, 4),
        dateMonth: this.date.substr(5, 2),
        context: this.getContexto(),
      };

      service
        .api()
        .TaxAnalysis(jsonData)
        .then((response) => {
          if (response.result) {
            this.verLocales = true;
            this.$store.commit("setOverlay", false);
            let payload = JSON.parse(response.payload);
            this.traslados = payload.listaTraslados;
            this.retenciones = payload.listaRetenciones;
            this.impuestosLocalTraslados = payload.impuestosLocales.total_traslados;
            this.impuestosLocalRetenidos = payload.impuestosLocales.total_retenidos;
            
            if (this.traslados.length === 0 && this.retenciones.length === 0) {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No se encontraron traslados ni retenciones");
            }
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    taxFormat(value) {
      return helpers.formatTax(value);
    },
    getImpuesto(value)
    {
      return helpers.Impuesto(helpers.ConvertToEmpty(value))
    },
    getContexto() {
      let firstLetter = this.contexto.substr(0, 1);
      return firstLetter;
    },
    exportExcel() {
      var headers = {
        TipoImpuesto: "Tipo",
        E: "Importe",
        Impuesto: "Impuesto",
        Tasa: "Tasa o Cuota",
        Base: "Base",
        Factor: "Tipo Factor",
      };
      var itemsFormatted = [];
     if(this.traslados == null && this.retenciones == null)
      return;

    if(this.traslados != null)
      this.traslados.forEach((item) => {
        itemsFormatted.push({
          TipoImpuesto: "TRASLADO",
          E: helpers.ConvertToEmpty(item.Importe),
          Impuesto:  helpers.Impuesto(helpers.ConvertToEmpty(item.Impuesto)),
          Tasa: helpers.ConvertToEmpty(item.TasaOCuota),
          Base: helpers.ConvertToEmpty(item.Base),
          Factor: helpers.ConvertToEmpty(item.TipoFactor),
        });
      });

    if(this.retenciones != null)
      this.retenciones.forEach((item) => {
        itemsFormatted.push({
          TipoImpuesto: "RETENCION",
          E: helpers.ConvertToEmpty(item.Importe),
          Impuesto:  helpers.Impuesto(helpers.ConvertToEmpty(item.Impuesto)),
          Tasa: helpers.ConvertToEmpty(item.TasaOCuota),
          Base: helpers.ConvertToEmpty(item.Base),
          Factor: helpers.ConvertToEmpty(item.TipoFactor),
        });
      });
      itemsFormatted.push({
          TipoImpuesto: "IMPUESTO LOCAL TRASLADADO",
          E: this.impuestosLocalTraslados,
          Impuesto:  " ",
          Tasa: " ",
          Base: " ",
          Factor: " ",
        });
        itemsFormatted.push({
          TipoImpuesto: "IMPUESTO LOCAL RETENIDO",
          E: this.impuestosLocalRetenidos,
          Impuesto:  " ",
          Tasa: " ",
          Base: " ",
          Factor: " ",
        });
      var fileTitle =
        "Listado_Montos_Impuestos_Periodo_" +
        this.date.substr(0, 7) +
        "_Solicitado_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
  },
  computed: {
    showExportExcel() {
      return this.retenciones.length > 0 || this.traslados.length > 0;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
       if (this.allowIssued)
         elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

        if(!this.allowIssued)
        {
          this.contexto = "Recibidos";
        }

        return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
