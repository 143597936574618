<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <estatusdoc ref="estatusd"></estatusdoc>
      <v-row justify="center">
        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>CFDIs de Pago extemporáneos

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/analisis-de-extemporaneidad-en-comprobantes-de-pago"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-menu v-model="menu" :nudge-right="40" transition="scale-transition" offset-y locale="es"
                  min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" type="month" label="Período" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on" locale="es"></v-text-field>
                  </template>
                  <v-date-picker :min="fechaInicial" v-model="date" locale="es" type="month" @input="menu = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" :label="labelRfc" maxlength="13"
                  class="min-width-control-xl">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select :items="itemsOrigen" v-model="contexto" @change="items = []" label="Contexto" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar
                      Listado</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ totalDocumentos }} Pagos extemporáneos</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <v-tooltip top class="d-none d-sm-flex">
              <template class="d-none d-sm-flex" v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReportePaymentExCSV" v-on="on"
                  :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span class="d-none d-sm-flex">Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>



            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :initialized="initGrid" :allowResizing="'None'">


              <wj-flex-grid-column :header="'Folio Fiscal del Comprobante de Pago'" :binding="'id'" width="2*"
                :isReadOnly="true" />


              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*" :isReadOnly="true" />



              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Pago'" :binding="'fecha_pago'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Monto Pago'" :binding="'monto'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Moneda Pago'" :binding="'moneda_p'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Cambio Pago'" :binding="'tipo_cambio_p'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Cantidad Documentos'" :binding="'cantidad_docs'" width="2*"
                :isReadOnly="true" />


              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Consultar Estado del Documento</span>
                  </v-tooltip>

                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>





          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';
import Estatusdoc from '../Componentes/EstatusDocumento.vue';

export default {
  name: "PaymentEx",
  components: {
    Dialogo,
        Estatusdoc
  },

  data: () => ({
    itemFind: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    contexto: "Emitidos",
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    showDialog: false,
    folioDoc: "",
    modal: false,




    search: "",
    totalDocumentos: 0,
    items: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcClass: false,
    fechaEClass: true,
    itemSelected: {
      id: "",
      regimen: "",
      emisor_rfc: "",
      emisor_nombre: "",
      receptor_rfc: "",
      receptor_nombre: "",
      residencia: "",
      usocfdi: "",
      tipo: "",
      nfecha_emision: 0,
      fecha_cancelacion: null,
      tipo_comprobante: "",
      tipo_cambio: 0,
      serie: "",
      folio: "",
      descuento: 0,
      total: 0,
      subtotal: 0,
      moneda: "",
      fecha_emision: "",
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
      metodo_pago: "",
      forma_pago: "",
      lugar_expedicion: "",
      pac: "",
      total_retenciones: 0,
      total_traslados: 0,
    },
    rfcSearch: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);


  },
  methods: {

    openDialogConsult() {
            this.$refs.estatusd.showDialogStatus = true;
        },
        ConsultarEstadoDoc(item) {
          
            let contextoEstado = "";
            let rfcE = "";
            let rfcR = "";
            let fechaE = item.fecha_emision;
            let uuid = item.id;
            this.openDialogConsult();
            if(this.esEmitido){
                contextoEstado = "E";
                rfcE = this.$store.state.entity;
                rfcR = item.rfc;
             
            }
            else
            {
                contextoEstado = "R";
                rfcE = item.rfc;
                rfcR = this.$store.state.entity;
            }
            this.$refs.estatusd.ConsultarEstadoDoc(contextoEstado, uuid, fechaE, rfcE, rfcR, 0);

        },
    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }


      grid.columns[grid.columns.length - 1].width = 200;


    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substr(0, 1),
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (this.contexto.substr(0, 1) === "R")
        par =
          this.contexto.substr(0, 1) +
          "|" +
          helpers.ConvertToEmpty(item.pac).trim() +
          "|" +
          item.rfc;
      else
        par =
          this.contexto.substr(0, 1) + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },

    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },
    verMasDatos(item) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: item.id,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFind = payload;
            this.openDialog();
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

    },
    exportExcel() {
      var headers = {
        RfcE: this.esEmitido ? "RFC Emisor" : "RFC Receptor",
        Rfc: this.esEmitido ? "RFC Receptor" : "RFC Emisor",
        Nombre: this.esEmitido
          ? "Razon Social Receptor"
          : "Razon Social Emisor",
        UUID: "Folio Fiscal",
        FechaEmision: "Fecha Emisión",
        FechaPago: "Fecha Pago",
        Monto: "Monto Pago",
        Moneda: "Moneda Pago",
        TipoCambio: "Tipo Cambio",
        CantidadDocumentos: "Cantidad Documentos",
      };
      var itemsFormatted = [];
      this.items.forEach((item) => {
        let par = item.particion.split("-");
        itemsFormatted.push({
          RfcE: helpers.ConvertToEmpty(par[0]),
          Rfc: helpers.ConvertToEmpty(item.rfc),
          Nombre: helpers.ConvertToEmpty(item.nombre),
          UUID: helpers.ConvertToEmpty(item.id),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaPago: helpers.ConvertToEmpty(item.fecha_pago),
          Monto: helpers.ConvertToEmpty(item.monto),
          Moneda: helpers.ConvertToEmpty(item.moneda_p),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio_p),
          CantidadDocumentos: helpers.ConvertToEmpty(item.cantidad_docs),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Pagos_Extemporaneidad_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    getReport() {
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getReporteItems();
    },
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        context: this.contexto.substr(0, 1),
        rfc: this.rfcSearch,
      };
      service
        .api()
        .ReportePaymentEx(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;
            this.totalDocumentos = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReportePaymentExCSV() {
      this.exportExcel();
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.fechaEClass = false;
      this.rfcClass = false;

      switch (columnName) {
        case "rfc":
          this.rfcClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
      }
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
  },
  computed: {
    showSearchButton() {
      return this.date.length > 0;
    },
    labelRfc() {
      if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    esEmitido() {
      return this.contexto.substr(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substr(0, 1) === "R";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    }
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.normal {
  font-weight: regular;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
