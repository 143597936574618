<template>
  <div class="home">
    <Conciliatorio titulo="Conciliatorio - Emitidos Cancelados" contexto="E" es-cancelado=true />
  </div>
</template>

<script>

import Conciliatorio from "./Conciliatorio.vue";

export default {
  components: {
    Conciliatorio
  }
};
</script>