<template>
  <div class="home">
    <Retenciones titulo="Retenciones - Recibidas" contexto="R"/>
  </div>
</template>

<script>

import Retenciones from "./RetencionesAll";

export default {
  components: {
    Retenciones
  }
};
</script>