<template>
  <v-container fluid class="my-10">
    <v-row>

      <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
        <v-card dense>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogEliminarReport = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Confirmación Eliminar Reporte:
              {{ this.itemDesc }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <br />
            <h3>Este reporte sera eliminado permanentemente</h3>
            <h3>¿Está seguro de continuar con esta acción?</h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
            <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-col cols="12">

      </v-col>
      <v-col cols="12">
        <h3>Antigüedad de saldos
          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/antiguedad-de-saldos" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>

          
        </h3>
      </v-col>
      <v-col cols="11">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="11">
        <v-card class="paddingCard">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsIntervalo" v-model="intervalo" item-text="name"
                  item-value="value" label="Intervalo" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="1" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="1" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" label="RFC" class="min-width-control-xl"
                  maxlength="13"></v-text-field>
              </v-col>

              <v-col class="d-flex px-10" cols="12" md="2">
                <v-select class="ml-2" :items="itemsSelect" v-model="periodoModel">
                </v-select>
              </v-col>
              <v-col class="d-flex px-10" cols="12" md="2" justify="left">

              <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="!showCreateButton" color="primary" white large v-on="on" v-on:click="requestItems">Solicitar
                      Reporte</v-btn>
                  </template>
                  <span>Solicitar reporte sin limite documentos</span>
                </v-tooltip>
              </v-col>

            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
      <v-col cols="11">
        <v-card>
          <v-toolbar dark color="primary">

            <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn @click="ObtenerReportes" icon v-on="on">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refrescar Tabla</span>
            </v-tooltip>
            <v-spacer></v-spacer> 
<v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                      class="ml-auto mr-3">
                      <v-icon>mdi-download-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Seleccionados</span>
                </v-tooltip>

          </v-toolbar>

          <v-row>
            <v-col cols="12">
            </v-col>
            <v-col cols="12">
              <v-simple-table height="600" v-if="itemsGrandes.length > 0" fixed-header dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                            <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                              hide-details></v-checkbox>
                          </th>

                      <th>Descripción</th>
                      <th>Fecha Solicitud</th>
                      <th>Contexto</th>
                      <th>Intervalo</th>
                      <th>RFC</th>
                      <th>Año</th>
                      <th>Mensaje</th>
                      <th>Disponible</th>
                      <th>Resultado</th>

                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                      <td>
                            <v-checkbox v-model="selectedItemsDescarga" :value="item" color="primary"
                              hide-details></v-checkbox>
                          </td>


                      <td>
                        {{ item.description }}
                      </td>
                      <td>
                        {{ item.requestDate }}
                      </td>
                      <td>
                        {{
                           JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido"
                        }}
                      </td>
                      <td>
                        {{ parseInterval(JSON.parse(item.commands).interval) }}
                      </td>
                      <td>
                        {{ JSON.parse(item.commands).rfc }}
                      </td>
                      <td>
                        {{ JSON.parse(item.commands).year }}
                      </td>
                      <td>
                        {{ item.message }}
                      </td>
                      <td>
                        {{ item.ready ? "Si" : "No" }}
                      </td>
                      <td>
                        {{
                          item.ready
                          ? item.result
                            ? "Exitoso"
                            : "Fracasado"
                          : "Procesando"
                        }}
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="item.ready && item.result">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar Reporte</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="item.ready">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Reporte</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>


    </v-row>
  </v-container>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";

export default {
  name: "AgeAnalysis",

  data: () => ({

    selectedItemsDescarga: [],
    allSelected: false,
    showDialogEliminarReport: false,
    showDialogCrear: false,
    periodoModel: "Seleccione Año",
    itemsSelect: [],
    itemsGrandes: [],
    descripcionIn: "",
    itemDesc: "",
    contexto: "Emitidos",
    modal: false,
    search: "",
    items: [],
    landscape: false,
    title: "",
    rfcSearch: "",
    intervalo: "",
    itemsIntervalo: [{ "name": "Sin Intervalo", "value": "" }, { "name": "Hasta 30 días", "value": "1" }, { "name": "Mayor de 30 días y hasta 60", "value": "2" },
    { "name": "Mayor de 60 días y hasta 90", "value": "3" }, { "name": "Mayor de 90 días", "value": "4" }],
  }),
  created() {
    this.fillItems();
    this.ObtenerReportes();
  },

  methods: {

    parseInterval(item) {
      let inter = "";

      switch (item) {
        case "1":
          inter = "Hasta 30 días";
          break;
        case "2":
          inter = "Mayor de 30 días y hasta 60";
          break;
        case "3":
          inter = "Mayor de 60 días y hasta 90";
          break;
        case "4":
          inter = "Mayor de 90 días";
          break;
      }
      return inter;

    },
    fillItems() {
      let yearActual = new Date().getFullYear();
      var i;
      this.itemsSelect.push("Seleccione Año");
      for (i = yearActual ; i >= (yearActual - 5); i--)
        this.itemsSelect.push(i.toString());
    },
    requestItems() {
      this.showDialogCrear = false;



      let yearReport = this.periodoModel == "Seleccione Año" ? "" : this.periodoModel.toString();


      var jsonData = {
        reportType: 'REPORT-AGE-ANALYSIS',
        name: "",
        description: this.descripcionIn,
        commands: JSON.stringify({ interval: this.intervalo, context: this.contexto.substr(0, 1), rfc: this.rfcSearch, year: yearReport, rfc: this.rfcSearch }),
        token: this.$store.state.token,
      };

      service
        .api()
        .RequestOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            

            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertMessage", "Reporte solicitado con exito");

          } else {

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);

          
          }
          this.descripcionIn = "";
        });


      this.nombreIn = "";
      this.descripcionIn = "";
      this.dateIn = "";
      this.dateIn2 = "";
      this.contextoIn = "Emitidos";
      this.tipoDocIn = "Seleccionar Todos",
        this.rfcSearch = "";
      this.showDialogReports = true;


    },
    ObtenerReportes() {
      var jsonData = {
        token: this.$store.state.token,
        reportType: 'REPORT-AGE-ANALYSIS'
      };

      service
        .api()
        .RetrieveOnDemandReports(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);

          } else {
            this.itemsGrandes = [];
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: 'REPORT-AGE-ANALYSIS'
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertMessage", "Reporte Eliminado Correctamente");
            this.ObtenerReportes();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    DescargarReporte(itemId) {
  return new Promise((resolve, reject) => {
    const jsonData = {
      id: itemId,
      token: this.$store.state.token,
    };

    service
      .api()
      .RetrieveOnDemandReport(jsonData)
      .then((response) => {
        if (response.result) {
          this.$store.commit("setOverlay", false);
          this.retrieveReporte(response.payload);
          resolve(response.payload); // Resolver con el payload
        } else {
          this.$store.commit("setAlertOk", false);
          this.$store.commit("setAlertError", true);
          this.$store.commit("setAlertMessage", response.errorMessage);
          reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
        }
      })
      .catch((error) => {
        reject(error); // Rechazar si ocurre un error en el servicio
      });
  });
},
descargarMultiple() {
      this.descargarTodosEnParalelo(this.selectedItemsDescarga);

    },
    async descargarTodosEnParalelo(items) {
      try {
        const promesas = items.map((item) => this.DescargarReporte(item.id));
        await Promise.all(promesas);
        console.log("Todas las descargas han finalizado.");
      } catch (error) {
        console.error("Error en una o más descargas:", error);
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItemsDescarga = [...this.itemsGrandes];
      } else {
        this.selectedItemsDescarga = [];
      }
    },

    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },

  },
  computed: {
    mostrarDescargarSeleccionado() {
      return this.selectedItemsDescarga.length > 0;
    },
    showCreateButton() {
      return this.descripcionIn.length > 0;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    },
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
