<template>
    <div class="py-10">
        <v-container fluid class="px-10">
            <v-row>
                <v-col cols="12">
                    <h3>Posible duplicidad por Todo el CFDI</h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="2">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="200px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="firstDate" label="Fecha Inicial*"
                                            prepend-icon="mdi-calendar-month" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker :min="fechaInicial" v-model="firstDate" @change="handleFirstDateChange"
                                        locale="es" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="2">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="200px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="secondDate" label="Fecha Final*"
                                            prepend-icon="mdi-calendar-month" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker :min="firstDate" :max="maxSecondDate" v-model="secondDate" locale="es"
                                        :disabled="!firstDate" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="d-flex" cols="12" md="1" justify="center">
                                <v-btn class="float-right" color="primary" white large v-on:click="ObtenerTotales"
                                    :disabled="!mostrarResumen">Mostrar
                                    Resumen</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-row v-if="VerCantidades">

                        <v-col cols="12" md="6">
                            <v-card class="mx-auto pa-2 ma-2" min-height="550">
                                <v-card-title>Emitidos </v-card-title>
                                <v-simple-table height="600" fixed-header dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-right">Documentos</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="altura">
                                                <td>
                                                    Sin Importar
                                                </td>
                                                <td align="right">
                                                    {{ cantidadEmitidos }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadEmitidos > 0" color="primary"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Emitidos', 'Sin Importar')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr class="altura">
                                                <td>
                                                    Revisado por el Cliente
                                                </td>
                                                <td align="right">
                                                    {{ cantidadEmitidosTrue }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadEmitidosTrue > 0" color="pagadas"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Emitidos', 'Revisado')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr class="altura">
                                                <td>
                                                    Pendiente de Revisión
                                                </td>
                                                <td align="right">
                                                    {{ cantidadEmitidosFalse }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadEmitidosFalse > 0" color="parcialmente"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Emitidos', 'Pendientes de Revisión')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card class="mx-auto pa-2 ma-2" min-height="550">
                                <v-card-title>Recibidos </v-card-title>
                                <v-simple-table height="600" fixed-header dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-right">Documentos</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="altura">
                                                <td>
                                                    Sin Importar
                                                </td>
                                                <td align="right">
                                                    {{ cantidadRecibidos }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadRecibidos > 0" color="primary"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Recibidos', 'Sin Importar')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr class="altura">
                                                <td>
                                                    Revisado por el Cliente
                                                </td>
                                                <td align="right">
                                                    {{ cantidadRecibidosTrue }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadRecibidosTrue > 0" color="pagadas"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Recibidos', 'Revisado')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr class="altura">
                                                <td>
                                                    Pendiente de Revisión
                                                </td>
                                                <td align="right">
                                                    {{ cantidadRecibidosFalse }}
                                                </td>
                                                <td>
                                                    <v-btn v-if="cantidadRecibidosFalse > 0" color="parcialmente"
                                                        class="ma-2 white--text"
                                                        @click="IrAListado('Recibidos', 'Pendientes de Revisión')">
                                                        Ver Listado
                                                        <v-icon right dark>
                                                            mdi-format-list-bulleted
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                    </v-row>





                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue'; //

export default {
    name: "ResumenDuplicados",
    data: () => ({
        selectedDatesIn: [],
        showDialog: false,
        showDialogConfirmar: false,

        date: new Date().getFullYear() + "-01-01",
        menu: false,
        date2: helpers.FechaActualSinHoras(),
        menu2: false,
        cantidadEmitidos: 0,
        totalEmitidos: 0,
        cantidadRecibidos: 0,
        totalRecibidos: 0,

        cantidadEmitidosTrue: 0,
        totalEmitidosTrue: 0,
        cantidadRecibidosTrue: 0,
        totalRecibidosTrue: 0,

        cantidadEmitidosFalse: 0,
        totalEmitidosFalse: 0,
        cantidadRecibidosFalse: 0,
        totalRecibidosFalse: 0,

        menu1: false,
        menu2: false,
        firstDate: "",
        secondDate: "",



        VerCantidades: false

    }),

    created() {
        let objetoResumen = this.$store.state.genericObject;
        if (objetoResumen !== null) {
            this.firstDate = objetoResumen.dateInit;
            this.secondDate = objetoResumen.dateEnd;
            this.$store.commit("setGenericObject", null);
        }
        //this.ObtenerTotales();
    },

    methods: {

        handleFirstDateChange() {
            this.secondDate = this.firstDate; // Asignar la primera fecha a la segunda
        },
        IrAListado(tipo, marca) {

            let pathL = "/conciliatorio/DuplicadosCadenaOriginal";
            this.$store.commit("setGenericObject", {
                dateInit: this.firstDate,
                dateEnd: this.secondDate,
                contexto: tipo,
                marca: marca
            });
            setTimeout(() => {
                this.$router.push({
                    path: pathL,
                });
            }, 1000);
        },

        ObtenerTotales() {

            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.firstDate,
                dateEnd: this.secondDate,
                context: 'E',
                rfc: "",
                docType: ""
            };



            //Emitidos sin marca
            service
                .api()
                .ReporteConciliatorioDuplicadoTotalDataV2(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);

                        this.cantidadEmitidos = payload.TotalCount;
                       // this.totalEmitidos = payload.TotalAmount;

                        this.$set(jsonData, 'context', 'R');


                        //Recibidos sin marca
                        service
                            .api()
                            .ReporteConciliatorioDuplicadoTotalDataV2(jsonData)
                            .then((response) => {
                                if (response.result) {
                                    this.VerCantidades = true;
                                    let payload = JSON.parse(response.payload);

                                    this.cantidadRecibidos = payload.TotalCount;
                         //         this.totalRecibidos = payload.TotalAmount;

                                    this.$set(jsonData, 'context', 'E');
                                    this.$set(jsonData, 'withCompanyMark', true);




                                    //EmitidosTrue
                                    service
                                        .api()
                                        .ReporteConciliatorioDuplicadoTotalDataV2(jsonData)
                                        .then((response) => {
                                            if (response.result) {
                                                this.VerCantidades = true;
                                                let payload = JSON.parse(response.payload);

                                                this.cantidadEmitidosTrue = payload.TotalCount;
                                        //      this.totalEmitidosTrue = payload.TotalAmount;
                                                this.cantidadEmitidosFalse = this.cantidadEmitidos - this.cantidadEmitidosTrue;

                                                this.$set(jsonData, 'context', 'R');

                                                //RecibidosTrue
                                                service
                                                    .api()
                                                    .ReporteConciliatorioDuplicadoTotalDataV2(jsonData)
                                                    .then((response) => {
                                                        if (response.result) {
                                                            this.VerCantidades = true;
                                                            let payload = JSON.parse(response.payload);

                                                            this.cantidadRecibidosTrue = payload.TotalCount;
                                                          //this.totalRecibidosTrue = payload.TotalAmount;
                                                          this.cantidadRecibidosFalse = this.cantidadRecibidos - this.cantidadRecibidosTrue;

                                                           // this.$set(jsonData, 'context', 'E');
                                                           // this.$set(jsonData, 'withCompanyMark', false);

                                                        } else {
                                                            if (
                                                                response.errorMessage ===
                                                                "No se encontraron resultados con los criterios utilizados."
                                                            ) {
                                                                this.showDialog = false;
                                                                this.getReport();
                                                                return;
                                                            }

                                                            this.$store.commit("setAlertOk", false);
                                                            this.$store.commit("setAlertError", true);
                                                            this.$store.commit("setAlertMessage", response.errorMessage);
                                                            this.itemsRel = [];
                                                        }
                                                    });

                                            } else {
                                                if (
                                                    response.errorMessage ===
                                                    "No se encontraron resultados con los criterios utilizados."
                                                ) {
                                                    this.showDialog = false;
                                                    this.getReport();
                                                    return;
                                                }

                                                this.$store.commit("setAlertOk", false);
                                                this.$store.commit("setAlertError", true);
                                                this.$store.commit("setAlertMessage", response.errorMessage);
                                                this.itemsRel = [];
                                            }
                                        });

                                } else {
                                    if (
                                        response.errorMessage ===
                                        "No se encontraron resultados con los criterios utilizados."
                                    ) {
                                        this.showDialog = false;
                                        this.getReport();
                                        return;
                                    }

                                    this.$store.commit("setAlertOk", false);
                                    this.$store.commit("setAlertError", true);
                                    this.$store.commit("setAlertMessage", response.errorMessage);
                                    this.itemsRel = [];
                                }
                            });



                    } else {
                        if (
                            response.errorMessage ===
                            "No se encontraron resultados con los criterios utilizados."
                        ) {
                            this.showDialog = false;
                            this.getReport();
                            return;
                        }

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                        this.itemsRel = [];
                    }
                });
        },
        getTipoDoc() {
            let firstLetter = this.tipoDoc.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.date);
            this.getReporteItems();
        }

    },
    computed: {
        mostrarResumen() {
            return this.firstDate.length > 1;
        },
        esEmitido() {
            return this.contexto.substr(0, 1) === "E";
        },
        fechaInicial() {
            return '2023-09';
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued) elements.push("Emitidos");
            if (this.allowReceived) elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
        labelRfc() {
            if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },

        minSecondDate() {
            return this.firstDate;
        },
        maxSecondDate() {
            if (this.firstDate) {
                const year = this.firstDate.substr(0, 4);
                return year + "-12-31";
            }
            return null;
        }
    },
};
</script>

<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}

.marDerecho {
    margin-right: 15px;
    margin-left: 15px;
}

.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}
</style>
