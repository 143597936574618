<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog
          v-model="dialogFull"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogFull = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title
                >Resultado de la comparación <br />{{
                  tituloAnalisis
                }}</v-toolbar-title
              >
              <!-- <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2" dark icon v-on="on" @click="exportExcel">
                    <v-icon dark> mdi-file-export </v-icon>
                  </v-btn>
                </template>
                <span>Exportar a CSV</span>
              </v-tooltip> -->
            </v-toolbar>
            <v-card>
              <v-simple-table fixed-header dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Folio Fiscal</th>
                      <th>Fecha Emisión</th>
                      <th>Cancelado</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="itemR in itemsResult"
                      :key="itemR.id"
                      class="altura"
                    >
                      <td>
                        {{ itemR.id }}
                      </td>
                      <td>
                        {{ itemR.fecha_emision }}
                      </td>
                      <td>
                        {{ itemR.cancelado == "0" ? "No" : "Si" }}
                      </td>
                      <td>
                        {{ itemR.observaciones }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Solicitar conciliación</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="nombre"
                    counter="0"
                    label="Descripción de la Conciliación"
                    maxlength="100"
                    class="my-1"
                  ></v-text-field>
                </v-col>
                <v-col class="mx-2" cols="12" md="4" justify="center">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="100px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Fecha Inicial"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="fechaInicial"
                      v-model="date"
                      @change="cambio = true"
                      locale="es"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="mx-2" cols="12" md="4" justify="center">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="100px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Fecha Final"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="fechaInicial"
                      v-model="date2"
                      @change="cambio = true"
                      locale="es"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="itemsContexto"
                    v-model="contexto"
                    label="Contexto"
                    outlined
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :items="itemsType"
                    v-model="analType"
                    item-value="value"
                    item-text="name"
                    label="Tipo Análisis"
                    outlined
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="8">
                  <v-file-input
                    @change="getFileName"
                    accept=".csv"
                    label="Selecciona el archivo .csv (Hasta 3MB)"
                    outlined
                    v-model="archivo"
                  ></v-file-input>
                  <v-tooltip 
                    right
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2" v-on="on" fab dark small color="primary" href="https://mysuiteanalytics.blob.core.windows.net/archivos/Ejemplo.csv?sv=2020-08-04&st=2021-12-01T19%3A44%3A00Z&se=2029-12-14T19%3A44%3A00Z&sr=b&sp=r&sig=oWkXDZIOnBWJiy108u%2FCyd3vtPkVZUQN0iq9t67z%2Fxs%3D" target="_blank">
                        <v-icon dark> mdi-file-question-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar Ejemplo</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-on:click="SolicitarComparacion"
                :disabled="!showCreateButton"
                >Solicitar Conciliación</v-btn
              >
              <v-btn color="danger" text @click="showDialogCrear = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        
        <v-col cols="12" md="11">
          <h3>Conciliación ERP

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/conciliación-erp" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>

        <v-col cols="12" class="text-justify">
          La conciliación con el ERP consiste en comparar una lista de los
          comprobantes de una empresa, obtenida de su sistema administrativo,
          con la lista de comprobantes almacenados en el SAT para el mismo
          período. XDATA encuentra las diferencias entre ambos conjuntos.
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col cols="12" md="1" class="pa-20 ma-10">
                <v-btn
                  color="primary"
                  white
                  large
                  @click="showDialogCrear = true"
                  >Solicitar Conciliación</v-btn
                >
              </v-col>
              <v-col cols="12" md="1" lg="1" class="hidden-sm-and-down"></v-col>
              <v-col cols="12" md="1" class="pa-20 ma-10">
                <v-btn color="primary" white large v-on:click="ObtenerAnalisis"
                  >Actualizar Listado</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12"></v-col>
        <v-col v-if="items.length > 0" cols="12">
          <v-card>
            <v-simple-table height="600" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Descripción Conciliación</th>
                    <th>Nombre Archivo</th>
                    <th>Fecha Solicitud</th>
                    <th>Fecha Inicial</th>
                    <th>Fecha Final</th>
                    <th>Tipo Analisis</th>
                    <th>Resultado</th>
                    <th>Contexto</th>
                    <th>Mensaje</th>
                    <th>Estado</th>
                    <!-- <th></th> -->
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id" class="altura">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{
                        item.commands.fileName.substr(
                          37,
                          item.commands.fileName.length - 1
                        )
                      }}
                    </td>
                    <td style="white-space: nowrap">
                      {{ item.requestDate }}
                    </td>
                    <td style="white-space: nowrap">
                      {{ item.commands.dateInit }}
                    </td>
                    <td style="white-space: nowrap">
                      {{ item.commands.dateEnd }}
                    </td>
                    <td>
                      {{ getDataType(item.particion) }}
                    </td>
                    <td>
                      {{
                        item.ready ? (item.result ? "Exitoso" : "Fallido") : ""
                      }}
                    </td>
                    <td>
                      {{
                        item.commands.context == "E" ? "Emitido" : "Recibido"
                      }}
                    </td>
                    <td>
                      {{ item.message }}
                    </td>
                    <td>
                      {{ item.ready ? "Terminado" : "En Proceso" }}
                    </td>
                    <!-- <td class="pa-0 md-0">
                      <v-tooltip left v-if="item.ready && item.result && item.message != 'Sin diferencias'">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="MostrarAnalisis(item)"
                            icon
                            color="#4aa832"
                            v-on="on"
                          >
                            <v-icon>mdi-eye-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Mostrar Diferencias</span>
                      </v-tooltip>
                    </td> -->
                    <td class="pa-0 md-0">
                      <v-tooltip left v-if="item.ready && item.result">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="DescargarArchivo(item.id, item.particion)"
                            icon
                            color="primary"
                            v-on="on"
                          >
                            <v-icon>mdi-file-document</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar Archivo</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <br />
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ComparadorERP",
  data: () => ({
    
    
    
    
    date: "",
    date2: "",
    nombre: "",
    nombreArchivo: "",
    menu: false,
    menu2: false,
    items: [],
    itemsResult: [],
    idSelected: "",
    dialogFull: false,
    showDialogCrear: false,
    tituloAnalisis: "",
    archivo: null,
    contexto: "Emitidos",
    itemsContexto: ["Emitidos", "Recibidos"],
    itemsType: [
      { value: "ERP-COMPARER", name: "CFDIS" },
      { value: "ERP-COMPARER-RETENCIONES", name: "RETENCIONES" },
    ],
    itemTypeNomina: [{ value: "ERP-COMPARER-NOMINA", name: "NOMINA" }],
    analType: "ERP-COMPARER",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);

    if (this.$store.state.isPayroll) {
      this.itemsType = [...this.itemsType, ...this.itemTypeNomina];
    }

    this.ObtenerAnalisis();
  },
  methods: {
    DescargarArchivo(id, analysisType) {
      var jsonData = {
        id: id,
        analysisType: analysisType,
        token: this.$store.state.token,
      };

      service
        .api()
        .ERPCompareGetFile(jsonData)
        .then((response) => {
          if (response.result) {
            window.open(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    pad(input, length) {
      return Array(length - Math.floor(Math.log10(input))).join("0") + input;
    },

    getDataType(value) {
      let rfc = this.$store.state.entity;
      let valor = "";

      let valueSub = value.replace(rfc + "-ERP-COMPARER", "");
      switch (valueSub) {
        case "":
          valor = "CFDIS";
          break;
        case "-NOMINA":
          valor = "NOMINA";
          break;
        case "-RETENCIONES":
          valor = "RETENCIONES";
          break;
      }

      return valor;
    },

    limpiarSolicitud() {
      this.date = "";
      this.date2 = "";
      this.nombre = "";
      this.archivo = null;
      this.contexto = "Emitidos";
      this.analType = "ERP-COMPARER";
    },

    cambioTipoAnalisis() {
      this.items = [];
      this.itemsResult = [];
      this.ObtenerAnalisis();
    },
    ObtenerAnalisis() {
      var jsonData = {
        token: this.$store.state.token,
      };
      this.items = [];
      service
        .api()
        .ERPCompareRetrieve(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.items = JSON.parse(response.payload);
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    MostrarAnalisis(item) {
      let rfc = this.$store.state.entity;
      let idAnalisis = item.id;
      let analysisType = item.particion.replace(rfc + "-", "");

      this.tituloAnalisis =
        "Fecha Solicitud: " +
        item.requestDate +
        "   Fecha Inicial: " +
        item.commands.dateInit +
        "   Fecha Final: " +
        item.commands.dateEnd +
        " Contexto:" +
        (item.commands.context == "E" ? "Emitido" : "Recibido") +
        "  Tipo Analisis: " +
        this.getDataType(item.particion);
      var jsonData = {
        token: this.$store.state.token,
        analysisType: analysisType,
        id: idAnalisis,
      };

      service
        .api()
        .ERPCompareRetrieveResults(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsResult = JSON.parse(response.payload);
            this.idSelected = idAnalisis;
            this.dialogFull = true;
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    SolicitarComparacion() {
      let base64File = "";
      this.toBase64(this.archivo).then((customJsonFile) => {
        base64File = customJsonFile;
        this.showDialogCrear = false;
        var jsonData = {
          token: this.$store.state.token,
          name: this.nombre,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          fileName: this.nombreArchivo,
          fileContent: base64File,
          analysisType: this.analType,
        };

        service
          .api()
          .ERPCompareRequest(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);


              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Comparación solicitada correctamente, su comparación se mostrará aproximadamente en menos de 2 minutos en la lista");
              
              this.limpiarSolicitud();
              
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      });
    },
    exportExcel() {
      this.DescargarArchivo(this.idSelected);
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    getImpuesto(value) {
      return helpers.Impuesto(helpers.ConvertToEmpty(value));
    },
    getContexto() {
      let firstLetter = this.contextDoc.substr(0, 1);
      return firstLetter == "S" ? "" : firstLetter;
    },
    getBool(selBool) {
      let booleanVal = "";
      switch (selBool) {
        case "Si":
          booleanVal = "1";
          break;
        case "No":
          booleanVal = "0";
          break;
        default:
          booleanVal = "";
      }
      return booleanVal;
    },
    getOkBool(selBool) {
      let booleanVal = "";
      switch (selBool) {
        case "Exito":
          booleanVal = "1";
          break;
        case "Fracaso":
          booleanVal = "0";
          break;
        default:
          booleanVal = "";
      }
      return booleanVal;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          if(encoded.length > 10485760)
            reject("Archivo mayor de 3 MB");
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    getFileName(e) {
      this.nombreArchivo = e.name;
    },
  },
  computed: {
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    showCreateButton() {
      return (
        this.nombre.length > 0 &&
        this.archivo != null && (this.archivo.size / 1024 / 1024) <= 3 &&
        this.date.length > 0 &&
        this.date2.length > 0
      );
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}
</style>
