<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">

        <v-dialog v-model="showAdminTagsGroup" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showAdminTagsGroup = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Administrador de Etiquetas Multiple</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsTags" :key="item.id" class="altura">

                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="selectedTags.includes(item.Color)">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DeleteEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Etiqueta</span>
                        </v-tooltip>
                        <v-tooltip left v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="AddEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Agregar Etiqueta</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="ActualizarTagsGroup" text large>Actualizar Etiquetas</v-btn>
              <v-btn color="danger" text @click="showAdminTagsGroup = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showSelectTags" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showSelectTags = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Seleccionar Etiquetas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in findTags" :key="item.id" class="altura">
                      <td class="pa-3 ma-3">
                        <v-checkbox v-model="item.check" :color="item.Color" hide-details></v-checkbox>
                      </td>
                      <td class="pa-0 md-0">
                        <v-icon :color="item.Color">mdi-circle</v-icon>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showAdminTags" persistent min-width="800px" max-width="800px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showAdminTags = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Administrador de Etiquetas de {{ itemSelected.id }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsTags" :key="item.id" class="altura">

                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="selectedTags.includes(item.Color)">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DeleteEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Etiqueta</span>
                        </v-tooltip>
                        <v-tooltip left v-else>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="AddEtiqueta(item.Color)" icon :color="item.Color" v-on="on">
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Agregar Etiqueta</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ item.Description }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="ActualizarTags" text large>Actualizar</v-btn>
              <v-btn color="danger" text @click="showAdminTags = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogNotas" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogNotas = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Notas del Documento : {{ itemSelected.id }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="12" justify="center">
                  <v-textarea solo v-model="notaText" maxlength="160"></v-textarea>
                  <h5>Hasta 160 caracteres</h5>
                  <h4 v-if="itemSelected.datetime_nota">Última Actualización:
                    {{ itemSelected.datetime_nota }}.<br /> Usuario: {{ itemSelected.usr_nota }}</h4>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="actualizarNota" text large>{{ mensajeItem }}
                Nota</v-btn>
              <v-btn color="danger" text @click="showDialogNotas = false">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Nómina Cancelados

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/nómina-cancelados" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates" :disabled="dateTs.length > 0"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field class="min-width-control" :disabled="dateTs.length > 0" v-model="rfcSearch"
                  @change="cambio = true" counter="0" label="RFC Receptor" maxlength="13"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsNotas" v-model="tipoNotas" label="Notas" outlined
                  :disabled="dateTs.length > 0"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">

                <v-btn color="primary" white large v-on:click="showSelectTags = true">Etiquetas
                </v-btn>
                <v-icon v-for="item in findTags" :key="item.name" v-show="item.check"
                  :color="item.Color">mdi-circle</v-icon>
              </v-col>

              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar
                  Listado</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon v-show="mostrarAgignarEtiquetas" color="primary" class="ma-2 white--text"
                  @click="AsignarEtiquetas" v-on="on" large>
                  mdi-tag-plus</v-icon>
              </template>
              <span>Asignar Etiquetas</span>
            </v-tooltip>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                  :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                    color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                    color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min="1" :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="' '" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                  <v-simple-table v-if="cell.item.tags" class="ma-2">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <tr @click="AgregarEtiqueta(cell.item, cell.row.index)" v-on="on">
                          <td v-for="itemTag in cell.item.tags.split(',')" :key="itemTag.id">
                            <v-icon x-small :color="itemTag">
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                      <span>Actualizar Etiqueta(s)</span>
                    </v-tooltip>
                  </v-simple-table>
                  <v-tooltip v-else left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="AgregarEtiqueta(cell.item, cell.row.index)" icon color="green" v-on="on">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar Etiqueta(s)</span>
                  </v-tooltip>



                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'RFC'" :binding="'rfc'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Razón Social'" :binding="'nombre'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Fecha Cancelación'" :binding="'fecha_cancelacion'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Timbre'" :binding="'fecha_timbre'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />


              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'PAC'" :binding="'PAC'" width="2*" :isReadOnly="true" />

              
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">

                  <v-tooltip left v-if="cell.item.nota">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="upsertNota(cell.item, cell.row.index)" icon color="purple" v-on="on">
                        <v-icon>mdi-note-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Nota</span>
                  </v-tooltip>
                  <v-tooltip left v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="upsertNota(cell.item, cell.row.index)" icon color="green" v-on="on">
                        <v-icon small>mdi-note-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Crear Nota</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>


          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "ConciliatorioCancelados",
  components: {
    DateRangePicker
  },
  data: () => ({
    selectedItems: [],
    selectedDates: [],
    allCheckBox: false,
    batchUuids: [],
    checkboxes: [],
    checkboxAll: false,
    showAdminTagsGroup: false,
    selectedRows: [],
    selectedIndex: 0,
    showDialogNotas: false,
    notaText: "",
    verIngreso: false,
    verEgreso: false,
    showCantidades: false,
    showDialog: false,
    showDialogStatus: false,
    periodoModel: 0,
    goPage: 1,
    selectedStatus: {
      estado: "",
      cancelable: "",
      estado_cancelacion: "",
    },
    cambio: false,
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalTraslados: 0,
    totalPagos: 0,
    ImporteIngresos: 0,
    ImporteEgresos: 0,
    ImporteTraslados: 0,
    ImportePagos: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    tipoDoc: "NOMINA",
    itemsYears: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    contexto: "E",
    itemSelected: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    rfcSearch: "",
    dateTs: "",
    itemsTags: [],
    showAdminTags: false,
    selectedTags: [],
    showSelectTags: false,
    findTags: [],
    itemsNotas: ["Sin Importancia", "Con Notas", "Sin Notas"],
    tipoNotas: "Sin Importancia"

  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
    this.selectTagsCatalog();

    let dataCronologia = this.$store.state.fromCrono;
    let dataInterval = this.$store.state.dateInterval;

    if (dataCronologia === null && dataInterval === null) return;

    if (dataCronologia !== null) {
      this.rfcSearch = dataCronologia.rfc;
      this.selectedDates[0] = dataCronologia.year + "-01-01";
      this.selectedDates[1] = dataCronologia.year + "-12-31";
      this.$store.commit("setFromCrono", null);
    }

    if (dataInterval !== null) {
      //alert(JSON.stringify(dataInterval));
      this.selectedDates[0] = dataInterval.dateInit;
      this.selectedDates[1] = dataInterval.dateEnd;
      this.dateTs = dataInterval.dateTs;
      this.$store.commit("setTipoDocTs", "");
      this.$store.commit("setDateInterval", null);
    }

    this.getReport();
  },
  methods: {

    initGrid: function (grid) {

      for (let i = 1; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
      }

      grid.columns[0].width = 100;

      grid.columns[4].width = 200;
      grid.columns[5].width = 200;
      grid.columns[6].width = 200;
      

      grid.columns[grid.columns.length - 1].width = 50;

      this.selector = new Selector(grid, {
        itemChecked: () => {
          this.selectedItems = grid.rows.filter(r => r.isSelected);
        }
      });
    },


    AsignarEtiquetas() {
      this.manageTagsGroup();

    },

    manageTagsGroup() {


      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemsTags = payload;
            this.$store.commit("setOverlay", false);
            this.selectedTags = [];
            this.showAdminTagsGroup = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },



    selectTagsCatalog() {

      var jsonData = {
        token: this.$store.state.token,
      };
      this.findTags = [];
      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            let itemsIn = payload;
            let coleccion = [];
            itemsIn.forEach(function (element) {
              let tagIn = { check: false, Color: element.Color, Description: element.Description };
              coleccion.push(tagIn);
            });
            this.findTags = coleccion;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    
    upsertNota(item, index) {
      this.itemSelected = item;
      this.selectedIndex = index;
      this.notaText = "";
      if (item.hasOwnProperty('nota'))
        this.notaText = this.itemSelected.nota;

      this.showDialogNotas = true;
    },
    actualizarNota() {
      var jsonData = {
        token: this.$store.state.token,
        particion: this.itemSelected.particion,
        uuid: this.itemSelected.id,
        nota: this.notaText,
      };

      service
        .api()
        .UpsertNote(jsonData)
        .then((response) => {
          if (response.result) {
            //this.getReport();
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Nota Actualizada");

            if (this.notaText === '') {
              this.itemSelected.nota = null;
              this.itemSelected.datetime_nota = null;
              this.itemSelected.usr_nota = null;
            }
            else {
              this.itemSelected.nota = this.notaText;

              let fechaHoy = new Date()
              this.itemSelected.datetime_nota = fechaHoy.toISOString().substring(0, 10) + " " + fechaHoy.toLocaleTimeString('es-MX');
              this.itemSelected.usr_nota = this.$store.state.usuario;
            }



            this.items[this.selectedIndex] = this.itemSelected;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          this.notaText = "";
          this.showDialogNotas = false;
        });

    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: "RFC Receptor",
        NombreE: "Razon Social Receptor",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.rfc),
          NombreE: helpers
            .ConvertToEmpty(item.nombre)
            .replace(/,/g, " "),
          Tipo: this.contexto,
          TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.PAC),
        });
      });

      let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Fecha Final": this.selectedDates[1],
        "Fecha Inicio": this.selectedDates[0],
        "Fecha de Solicitud": helpers.FechaActual(),
        Reporte: "Listado Conciliatorio Nómina Cancelados",
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Conciliatorio_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.limit = this.pageSize;
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
      this.verIngreso = false;
      this.verEgreso = false;
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";
      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        tags: tagsInterno.toString(),
        note: noteInterno
      };

      this.checkboxes = [];
      let j;
      for (j = 0; j < this.items.length; j++)
        this.checkboxes[j] = false;

      service
        .api()
        .ReporteConciliatorioCancelados(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        tags: tagsInterno.toString(),
        note: noteInterno
      };

      service
        .api()
        .ReporteConciliatorioCountCancelados(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConciliatorioCSV() {
      let tagsInterno = [];
      this.findTags.forEach(function (element) {
        if (element.check)
          tagsInterno.push(element.Color);
      });

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
        tags: tagsInterno.toString(),
        note: noteInterno
      };
      service
        .api()
        .ReporteConciliatorioCSVCancelados(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    cerrarStatus() {
      this.showDialogStatus = false;
      if (this.selectedStatus.estado == "Cancelado") this.getReport();
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    manageTags() {


      var jsonData = {
        token: this.$store.state.token,
      };

      service
        .api()
        .TagCatalog(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemsTags = payload;
            this.$store.commit("setOverlay", false);
            this.showAdminTags = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    AgregarEtiqueta(item, index) {
      this.itemSelected = item;
      this.selectedIndex = index;
      if (item.tags) {
        this.selectedTags = item.tags.split(',');
      }
      else {
        this.selectedTags = [];
      }

      this.manageTags();
    },
    AddEtiqueta(color) {
      this.selectedTags.push(color);
    }
    ,
    DeleteEtiqueta(color) {

      const index = this.selectedTags.indexOf(color);
      this.selectedTags.splice(index, 1);

    },
    ActualizarTags() {
      var jsonData = {
        token: this.$store.state.token,
        particion: this.itemSelected.particion,
        uuid: this.itemSelected.id,
        tags: this.selectedTags.sort().toString(),
      };

      service
        .api()
        .TagUpsert(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Etiquetas Actualizadas");
            this.items[this.selectedIndex].tags = this.selectedTags.toString();

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
      this.showAdminTags = false;
    },
    ActualizarTagsGroup() {
      let uuidsInterno = this.selectedItems.map(({ _data }) => _data.id);

      var jsonData = {
        token: this.$store.state.token,
        particion: this.items[0].particion,
        uuids: uuidsInterno.toString(),
        tags: this.selectedTags.sort().toString(),
      };

      service
        .api()
        .TagUpsertGroup(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", response.payload);

            let i;
            for (i = 0; i < this.selectedItems.length; i++) {
              this.items[this.selectedItems[i]._idx].tags = this.selectedTags.toString();
              //  this.checkboxes[i] = false;

            }
            this.showAdminTagsGroup = false;
            this.getReporteItems();

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
      this.showAdminTags = false;
    }

  },
  computed: {
    mostrarAgignarEtiquetas() {
      return this.selectedItems.length > 0;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto === "E";
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },

    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    showNotasButton() {
      return this.notaText.length > 0;
    },
    mensajeItem() {
      return this.itemSelected.nota == null ? "Guardar" : "Actualizar";
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
