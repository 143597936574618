<template>
    <div class="py-10 mt-5">
        <v-container fluid class="px-10">
            <v-row justify="center"> </v-row>
            <v-row>

                <v-col cols="12">
                    <h3>Actualizar FIEL</h3>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                    <p v-if="conFiel" class="title">
                        Ya cuenta con FIEL almacenada
                    </p>
                </v-col>

                <v-col cols="12">
                    <v-card class="pa-2">
                        <v-card-title>Datos FIEL</v-card-title>
                        <v-row class="py-3">
                            <v-col cols="12" md="3" justify="center">
                                <v-file-input accept=".cer" label="Selecciona el archivo .cer" outlined
                                    v-model="cert"></v-file-input>
                            </v-col>
                            <v-col cols="12" md="3" justify="center">
                                <v-file-input accept=".key" label="Selecciona el archivo .key" outlined
                                    v-model="key"></v-file-input>
                            </v-col>
                            <v-col cols="12" md="2" justify="center">
                                <v-text-field v-model="pass" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'" label="FIEL Contraseña" counter
                                    @click:append="show1 = !show1"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn color="primary" white large @click="guardarFiel"
                                    :disabled="!showSaveButton">Guardar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
    
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import {
    JSONToCSV
} from "../../scripts/helpers.js";

export default {
    name: "GuardarFiel",

    data: () => ({
        date: new Date().toISOString().substr(0, 10),
        date2: "",

        search: "",
        landscape: false,
        razonSocial: "",
        cert: null,
        key: null,
        pass: "",
        show1: false,
        pdfPreview: "",
        certFielString: "",
        keyFielsString: "",
        passFielString: "",
        conPreviaFiel: false,
        content: "",
        plantillaLegal: "",
        docPdf: "",
        firmado: false
    }),
    created() {
        this.$store.commit("setBlData", null);
        this.razonSocial =
            this.$store.state.userLongName + " ( " + this.$store.state.entity + " )";

    },
    methods: {
        ObtenerPDF() {
            helpers.ToPDF(this.docPdf, "ContratoFIELMySuiteXData.pdf");
        },
        guardarFiel() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            var jsonData = {};
            let cerFile = "";
            let keyFile = "";
            this.toBase64(this.cert).then((customJsonFile) => {
                cerFile = customJsonFile;
                this.toBase64(this.key).then((customJsonFile) => {
                    keyFile = customJsonFile;
                    jsonData = {
                        token: this.$store.state.token,
                        cert: cerFile,
                        key: keyFile,
                        password: this.pass,
                        content: "",
                    };

                    service
                        .api()
                        .SaveFiel(jsonData)
                        .then((response) => {
                            if (response.result) {
                                this.$store.commit("setAlertOk", true);
                                this.$store.commit("setAlertError", false);
                                this.$store.commit("setHasFiel", true);
                                this.$store.commit("setAlertMessage", "Fiel Almacenada");
                                this.$store.commit("setOverlay", false);
                                this.certFielString = cerFile;
                                this.keyFielsString = keyFile;
                                this.passFielString = this.pass;
                            } else {
                                this.$store.commit("setAlertOk", false);
                                this.$store.commit("setAlertError", true);
                                this.$store.commit("setAlertMessage", response.errorMessage);
                            }
                        });
                });
            });

        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
                    if (encoded.length % 4 > 0) {
                        encoded += "=".repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                };
                reader.onerror = (error) => reject(error);
            });
        },
        irMenu() {
            this.$router.push({
                path: "/home",
            });
        }
      
    },
    computed: {
        conFiel() {
            return this.$store.state.hasFiel;
        },
        showSaveButton() {
            return this.cert != null && this.key != null && this.pass.length > 0;
        },
        parseContent() {
            let parseado = helpers.Base64Decode(this.contentFiel).replaceAll("{{date}}", this.date).replaceAll("{{razonSocial}}", this.razonSocial);
            return parseado;
        },
        getFielExpirationDate() {
            return helpers.formatFecha(this.$store.state.fielExpirationDate);
        },
    },
};
</script>
    
<style scoped>
textarea {
    resize: none;
}

.contFiel {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.divFiel {
    display: flex;
    flex-wrap: wrap;
}
</style>
    