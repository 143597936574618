<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row>
        
        <v-col cols="12">
          <h3>Resumen de la cuenta</h3>
        </v-col>
        <v-divider></v-divider>

        <v-col>
          <!-- <v-card> v-if="items.length > 0"> -->
            <v-card class="paddingCard"> 
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center body-1" rowspan="2">Año</th>
                    <th class="text-center body-1" colspan="2">Emitidos CFDI</th>
                    <th class="text-center body-1" colspan="2">Recibidos CFDI</th>
                    <th class="text-center body-1" colspan="2">Emitidos Retenciones</th>
                    <th class="text-center body-1" colspan="2">Recibidos Retenciones</th>
                    <th class="text-center body-1" colspan="2">Nómina</th>
                  </tr>
                  <tr>
                    
                    <th class="text-right body-1">Vigentes</th>
                    <th class="text-right body-1">Cancelados</th>
                    <th class="text-right body-1">Vigentes</th>
                    <th class="text-right body-1">Cancelados</th>
                    <th class="text-right body-1">Vigentes</th>
                    <th class="text-right body-1">Cancelados</th>
                    <th class="text-right body-1">Vigentes</th>
                    <th class="text-right body-1">Cancelados</th>
                    <th class="text-right body-1">Vigentes</th>
                    <th class="text-right body-1">Cancelados</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.anno" class="altura">
                    <td>{{ item.anno }}</td>

                    <td class="text-right body-1">{{ cFormat(item.emitidos_vigentes) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.emitidos_cancelados) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.recibidos_vigentes) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.recibidos_cancelados) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.retenciones_emitidas_vigentes) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.retenciones_emitidas_cancelados) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.retenciones_recibidas_vigentes) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.retenciones_recibidas_cancelados) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.nomina_vigentes) }}</td>
                    <td class="text-right body-1">{{ cFormat(item.nomina_cancelados) }}</td>
                    
                    
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <br /><br />
          <p class="text-h4 text-center" v-if="items.length == 0">
            Sin cambios para mostrar
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "Resumen",
  data: () => ({
    
    
    
    
    items: []    
  }),
  created() {
    this.getReporteItems();
    this.$store.commit("setDateInterval", null);
    this.$store.commit("setBlData", null);
  },
  methods: {
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
      };
      service
        .api()
        .ReporteConciliatorioResumen(jsonData)
        .then((response) => {
          if (response.result) {
            this.items = JSON.parse(response.payload);
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          if (this.items == [])
            this.titulo = "Sin cambios desde su visita anterior";
        });
    },
     cFormat(value) {
      return helpers.formatCommas(value);
    }
  },
  computed: {},
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}
</style>
