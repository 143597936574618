<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row justify="center"> </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>Listado Deudores/Acreedores
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/listado-deudores-acreedores" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>

          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4" class="px-2 mx-md-10">
                      <date-range-picker v-model="selectedDates" ></date-range-picker>
                    </v-col>
                    <v-col cols="12" md="2" class="px-2 mx-md-10">
                      <v-text-field v-model="serie"  
                        label="Serie" maxlength="18" class="my-1 min-width-control"></v-text-field>
                    </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select
                class="min-width-control-xl"
                  :items="itemsOrigen"
                  v-model="contexto"
                  label="Contexto"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-btn
                  color="primary"
                  white
                  large
                  v-on:click="getReport"
                  :disabled="!showSearchButton"
                  >Mostrar Listado</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex"
              >{{ conceptosPagina }} registros de
              {{ totalRelated }}</v-toolbar-title
            >
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon
                  class="d-none d-sm-flex"
                  @click="getReporteProdServCSV"
                  v-on="on"
                  :disabled="totalRelated > 5000"
                  color="second"
                  >mdi-file-export</v-icon
                >
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select
                v-model="pageSize"
                v-on:change="changePagination"
                :items="paginations"
                menu-props="auto"
                label="Total de Registros"
                hide-details
                class="ma-3 anchoP"
              ></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="d-none d-sm-flex mr-2"
                    @click="previous"
                    v-on="on"
                    large
                    color="second"
                    >mdi-arrow-up-bold-circle-outline</v-icon
                  >
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable"
                >mdi-arrow-up-bold-circle-outline</v-icon
              >

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="d-none d-sm-flex ml-2"
                    @click="next"
                    v-on="on"
                    large
                    color="second"
                    >mdi-arrow-down-bold-circle-outline</v-icon
                  >
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable"
                >mdi-arrow-down-bold-circle-outline</v-icon
              >

              <v-btn text>
                <v-text-field
                  type="number"
                   min=1
                   :max="totalPaginas"
                  v-model="goPage"
                  class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"
                ></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
               :allowResizing="'None'">

              <wj-flex-grid-column :header="'RFC'" :binding="'rfc'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Nombre'" :binding="'nombre'" width="6*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Importe(MXN)'" :binding="'importe'" width="2*" format="c2"
                :isReadOnly="true" />


              </wj-flex-grid-column>
            </wj-flex-grid>



          
          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
export default {
  name: "DeudaReport",
  components: {
    DateRangePicker    
  },
  data: () => ({
    modal: false,
    selectedDates: [],
    serie: "",
    
    
    search: "",
    items: [],
    landscape: false,
    goPage: 1,
    offest: 0,
    limit: 50,
    pageSize: "500",
    noPagina: 1,
    totalPaginas: 1,
    totalRelated: 0,
    paginations: ["100", "200", "500"],
    contexto: "Emitidos"
  }),
  methods: {
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalRelated / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalRelated % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalRelated % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
     this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substring(0,1),
        offset: this.offest,
        limit: this.limit,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        serie: this.serie
      };
      service
        .api()
        .ReporteDeudaList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.conceptosPagina = this.items.length.toString();
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substring(0,1),
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        serie: this.serie
      };

      service
        .api()
        .ReporteDeudaCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);

            if(payload == "0")
            {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No existen documentos con los criterios solicitados");
            }

            this.totalRelated = payload;
            this.totalPaginas = Math.ceil(this.totalRelated / this.pageSize);
            this.getReporteItems();
          } else {
             this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    exportExcel() {
      var headers = {
        Rfc: "RFC",
        Nombre: "Nombre",
        Importe: "Importe",
      };
      var itemsFormatted = [];
      this.items.forEach((item) => {
        itemsFormatted.push({
           Rfc: helpers.ConvertToEmpty(item.rfc),
          Nombre: helpers.ConvertToEmpty(item.nombre),
          Importe: helpers.ConvertToEmpty(item.importe),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Reporte_Deudores_Periodo" +
        "_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    getReporteProdServCSV(tipo) {
      if (this.totalPaginas == 1) {
        this.exportExcel();
      } else {
        var jsonData = {
          token: this.$store.state.token,
          context: this.contexto.substring(0,1),
          dateInit: this.selectedDates[0],
          dateEnd: this.selectedDates[1],
          serie: this.serie
        };

        service
          .api()
          .ReporteDeudaCsv(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.items = JSON.parse(response.payload);
              JSONToCSV(
                this.items,
                "Reporte_Deudores_Periodo" +
                  "_" +
                  helpers.dateNowCSV().replace(/-/g, "_"),
                true
              );

              this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Archivo CSV generado exitosamente");
              
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
  },
  computed: {
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
       if (this.allowIssued)
         elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

        if(!this.allowIssued)
        {
          this.contexto = "Recibidos";
        }

        return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}


.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
