var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-10"},[_c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}}),_c('v-row',{attrs:{"justify":"center"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.titulo)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.token.length),expression:"$store.state.token.length"}],attrs:{"icon":"","color":"primary","href":_vm.liga,"target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}])},[_c('span',[_vm._v("Manual")])])],1)]),_c('v-col',[_c('v-card',{staticClass:"paddingCard"},[_c('v-row',[_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"month","label":"Período","prepend-icon":"mdi-calendar-month","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"min-width-control-xl",attrs:{"min":_vm.fechaInicial,"locale":"es","type":"month"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"px-2 mx-md-10",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","white":"","large":""},on:{"click":_vm.getReport}},[_vm._v("Mostrar Listado")])],1)],1)],1)],1)],1),(_vm.items.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"d-none d-sm-flex"},[_vm._v(_vm._s(_vm.conceptosPagina)+" registros de "+_vm._s(_vm.totalRelated))]),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-tooltip',{staticClass:"d-none d-sm-flex",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"d-none d-sm-flex",attrs:{"disabled":_vm.totalRelated > 5000,"color":"second"},on:{"click":_vm.getReporteProdServCSV}},on),[_vm._v("mdi-file-export")])]}}],null,false,2603017052)},[_c('span',[_vm._v("Exportar tabla a CSV. Hasta 5,000 registros")])]),_c('v-toolbar-items',{staticClass:"pa-3"},[_c('v-select',{staticClass:"ma-3 anchoP",attrs:{"items":_vm.paginations,"menu-props":"auto","label":"Total de Registros","hide-details":""},on:{"change":_vm.changePagination},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}}),(_vm.hasPrevious)?_c('v-tooltip',{staticClass:"d-none d-sm-flex",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"d-none d-sm-flex mr-2",attrs:{"large":"","color":"second"},on:{"click":_vm.previous}},on),[_vm._v("mdi-arrow-up-bold-circle-outline")])]}}],null,false,1889385010)},[_c('span',[_vm._v("Página anterior")])]):_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"disable"}},[_vm._v("mdi-arrow-up-bold-circle-outline")]),(_vm.hasNext)?_c('v-tooltip',{staticClass:"d-none d-sm-flex",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"d-none d-sm-flex ml-2",attrs:{"large":"","color":"second"},on:{"click":_vm.next}},on),[_vm._v("mdi-arrow-down-bold-circle-outline")])]}}],null,false,2938497933)},[_c('span',[_vm._v("Página siguiente")])]):_c('v-icon',{staticClass:"ml-2",attrs:{"large":"","color":"disable"}},[_vm._v("mdi-arrow-down-bold-circle-outline")]),_c('v-btn',{attrs:{"text":""}},[_c('v-text-field',{staticClass:"mr-1 mb-0 anchoGuia",attrs:{"type":"number","min":"1","max":_vm.totalPaginas},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToPage($event)}},model:{value:(_vm.goPage),callback:function ($$v) {_vm.goPage=$$v},expression:"goPage"}}),_c('span',{staticClass:"tamPagina"},[_vm._v("/"+_vm._s(_vm.totalPaginas))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('wj-flex-grid',{attrs:{"itemsSource":_vm.items,"autoGenerateColumns":false,"allowAddNew":false,"allowDelete":false,"allowPinning":'SingleColumn',"newRowAtTop":false,"showMarquee":true,"validateEdits":false,"allowResizing":'None',"initialized":_vm.initGrid}},[(_vm.contexto == 'E')?_c('wj-flex-grid-column',{attrs:{"header":'Cliente',"binding":'Cliente',"width":"2*","isReadOnly":true}}):_c('wj-flex-grid-column',{attrs:{"header":'Proveedor',"binding":'Proveedor',"width":"2*","isReadOnly":true}}),_c('wj-flex-grid-column',{attrs:{"header":'Nombre',"binding":'Nombre',"width":"6*","isReadOnly":true}}),_c('wj-flex-grid-column',{attrs:{"header":'Cantidad CFDI\'s',"binding":'CantidadCFDIs',"width":"2*","isReadOnly":true}}),_c('wj-flex-grid-column',{attrs:{"header":' ',"width":"2*","isReadOnly":true}},[_c('wj-flex-grid-cell-template',{attrs:{"cellType":"Cell"},scopedSlots:_vm._u([{key:"default",fn:function(cell){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"#4aa832"},on:{"click":function($event){return _vm.verMasDatos(cell.item)}}},on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalle")])])]}}],null,false,155694262)})],1)],1)],1),_c('br')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }