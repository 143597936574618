var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-10"},[_c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Parámetros de Auditoría")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-auto pa-2 ma-2",attrs:{"min-height":"950","max-height":"950"}},[_c('v-card-title',[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"d-none d-sm-flex"},[_vm._v("Emitidos")]),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-toolbar-items',{staticClass:"pa-3"},[_c('v-btn',{attrs:{"color":"primary","white":"","large":"","disabled":!_vm.showEmitidosButton},on:{"click":_vm.ActualizarEmitidos}},[_vm._v("Actualizar Emitidos")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsMonedas,"item-text":"name","item-value":"codigo","label":"Monedas","multiple":""},on:{"change":function($event){_vm.cambioMonedasE = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.monedasE.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.monedasE),callback:function ($$v) {_vm.monedasE=$$v},expression:"monedasE"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsRegimenes,"item-text":"name","item-value":"codigo","label":"Regímenes Fiscales","multiple":""},on:{"change":function($event){_vm.cambioRegimenesE = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.regimenesE.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.regimenesE),callback:function ($$v) {_vm.regimenesE=$$v},expression:"regimenesE"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsFormaPago,"item-text":"name","item-value":"codigo","label":"Formas de Pago","multiple":""},on:{"change":function($event){_vm.cambioFormasPagoE = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.formasPagoE.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.formasPagoE),callback:function ($$v) {_vm.formasPagoE=$$v},expression:"formasPagoE"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-text-field',{attrs:{"label":"Lugar Expedición (Código Postal)"},model:{value:(_vm.cpTextE),callback:function ($$v) {_vm.cpTextE=$$v},expression:"cpTextE"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.cpTextE != ''),expression:"cpTextE != ''"}],staticClass:"mx-2",attrs:{"md":"2","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.AgregarCP('E')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Agregar Código Postal a Lista")])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"md":"5"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.cpE),function(item,index){return _c('tr',{key:item,staticClass:"alturaCP"},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","x-small":"","color":"nopagadas"},on:{"click":function($event){return _vm.EliminarCpE(index)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Código Postal")])])],1),_c('td',[_vm._v(_vm._s(item))])])}),0)]},proxy:true}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-auto pa-2 ma-2",attrs:{"min-height":"950"}},[_c('v-card-title',[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"d-none d-sm-flex"},[_vm._v("Recibidos")]),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-toolbar-items',{staticClass:"pa-3"},[_c('v-btn',{attrs:{"color":"primary","white":"","large":"","disabled":!_vm.showRecibidosButton},on:{"click":_vm.ActualizarRecibidos}},[_vm._v("Actualizar Recibidos")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsMonedas,"item-text":"name","item-value":"codigo","label":"Monedas","multiple":""},on:{"change":function($event){_vm.cambioMonedasR = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.monedasR.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.monedasR),callback:function ($$v) {_vm.monedasR=$$v},expression:"monedasR"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsRegimenes,"item-text":"name","item-value":"codigo","label":"Regimenes Fiscales","multiple":""},on:{"change":function($event){_vm.cambioRegimenesR = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.regimenesR.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.regimenesR),callback:function ($$v) {_vm.regimenesR=$$v},expression:"regimenesR"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsFormaPago,"item-text":"name","item-value":"codigo","label":"Formas de Pago","multiple":""},on:{"change":function($event){_vm.cambioFormasPagoR = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.formasPagoR.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.formasPagoR),callback:function ($$v) {_vm.formasPagoR=$$v},expression:"formasPagoR"}})],1),_c('v-col',{attrs:{"md":"3"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"9"}},[_c('v-select',{attrs:{"items":_vm.itemsUsoCFDI,"item-text":"name","item-value":"codigo","label":"Uso CFDI","multiple":""},on:{"change":function($event){_vm.cambioUsosCfdiR = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.usosCfdiR.length - 4)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.usosCfdiR),callback:function ($$v) {_vm.usosCfdiR=$$v},expression:"usosCfdiR"}})],1),_c('v-col',{attrs:{"md":"3"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }