<template>
  <v-container fluid class="my-10">
    <v-row>

      <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
        <v-card dense>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showDialogEliminarReport = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Confirmación Eliminar Reporte:
              {{ this.itemDesc }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <br />
            <h3>Este reporte sera eliminado permanentemente</h3>
            <h3>¿Está seguro de continuar con esta acción?</h3>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
            <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-col cols="12">

      </v-col>
      <v-col cols="12">
        <h3>Integración IVA-SAT

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                href="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-de-integracion-iva-sat"
                target="_blank">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>Manual</span>
          </v-tooltip>
        </h3>
      </v-col>
      <v-col cols="11">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="11">
        <v-card class="paddingCard">
          <v-card-text>
            <v-row>
              <v-col cols="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates" fecha-limite-inferior="2021-01-01"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select :items="tiposDoc" v-model="tipoDoc" label="Tipo Comprobante" outlined item-text="opcion"
                  item-value="valor"></v-select>
              </v-col>
              <v-col cols="12" md="1" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto"
                  outlined>
                </v-select>
              </v-col>
              <v-col class="d-flex px-10" cols="12" md="2">

                <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="!showCreateButton" color="primary" white large v-on="on"
                      v-on:click="requestItems">Solicitar
                      Reporte</v-btn>
                  </template>
                  <span>Solicitar reporte sin limite documentos</span>
                </v-tooltip>
              </v-col>

            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
      <v-col cols="11">
        <v-card>
          <v-toolbar dark color="primary">

            <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn @click="ObtenerReportes" icon v-on="on">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refrescar Tabla</span>
            </v-tooltip>
            <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                      class="ml-auto mr-3">
                      <v-icon>mdi-download-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Seleccionados</span>
                </v-tooltip>
          </v-toolbar>

          <v-row>
            <v-col cols="12">
            </v-col>
            <v-col cols="12">
              <v-simple-table height="600" v-if="itemsGrandes.length > 0" fixed-header dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                            <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                              hide-details></v-checkbox>
                          </th>

                      <th>Descripción</th>
                      <th>Fecha Solicitud</th>
                      <th>Fecha Inicial</th>
                      <th>FechaFinal</th>
                      <th>Contexto</th>
                      <th>Tipo de Documento</th>
                      <th>Notas</th>
                      <th>Mensaje</th>
                      <th>Disponible</th>
                      <th>Resultado</th>

                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                      <td>
                            <v-checkbox v-model="selectedItemsDescarga" :value="item" color="primary"
                              hide-details></v-checkbox>
                          </td>

                      <td>
                        {{ item.description }}
                      </td>
                      <td>
                        {{ item.requestDate }}
                      </td>
                      <td>
                        {{ JSON.parse(item.commands).dateInit }}
                      </td>
                      <td>
                        {{ JSON.parse(item.commands).dateEnd }}
                      </td>
                      <td>
                        {{
                          JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido"
                        }}
                      </td>
                      <td>
                        {{ parseTipoDoc(JSON.parse(item.commands).docType) }}
                      </td>
                      <td>
                        {{ JSON.parse(item.commands).notes }}
                      </td>
                      <td>
                        {{ item.message }}
                      </td>
                      <td>
                        {{ item.ready ? "Si" : "No" }}
                      </td>
                      <td>
                        {{
                          item.ready
                            ? item.result
                              ? "Exitoso"
                              : "Fracasado"
                            : "Procesando"
                        }}
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="item.ready && item.result">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar Reporte</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left v-if="item.ready">
                          <template v-slot:activator="{ on }">
                            <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar Reporte</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>


    </v-row>
  </v-container>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "ReporteNominaDesglose",
  components: {
    DateRangePicker
  },
  data: () => ({
    selectedItemsDescarga: [],
    allSelected: false,

    selectedDates: [],
    showDialogEliminarReport: false,
    showDialogCrear: false,
    periodoModel: new Date().getFullYear(),
    itemsSelect: [],
    itemsGrandes: [],
    descripcionIn: "",
    itemDesc: "",
    contexto: "Emitidos",
    modal: false,
    search: "",
    items: [],
    landscape: false,
    title: "",
    showCantidades: false,
    menu: false,
    menu2: false,
    tipoDoc: "",
    tiposDoc: [
      {
        opcion: "Todos",
        valor: "",
      },
      {
        opcion: "Ingreso",
        valor: "I",
      },
      {
        opcion: "Egreso",
        valor: "E",
      }
    ],
    itemsNotas: ["Sin Importancia", "Con Notas", "Sin Notas"],
    tipoNotas: "Sin Importancia",
  }),
  created() {
    this.fillItems();
    this.ObtenerReportes();
  },

  methods: {

    fillItems() {
      var i;
      for (i = this.periodoModel - 5; i <= this.periodoModel; i++)
        this.itemsSelect.push(i);
    },
    requestItems() {
      this.showDialogCrear = false;

      let noteInterno = "";

      switch (this.tipoNotas.substr(0, 5)) {
        case "Con N":
          noteInterno = "1";
          break;
        case "Sin N":
          noteInterno = "0";
          break;
        default:
          noteInterno = "";
      }


      var jsonData = {
        reportType: 'REPORT-INTEGRACION-IVASAT',
        name: "",
        description: this.descripcionIn,
        commands: JSON.stringify({ dateInit: this.selectedDates[0], dateEnd: this.selectedDates[1], context: this.contexto.substr(0, 1), docType: this.tipoDoc, notes: noteInterno }),
        token: this.$store.state.token,
      };

      service
        .api()
        .RequestOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertMessage", "Reporte solicitado con exito");

          } else {

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
          this.descripcionIn = "";
        });


      this.nombreIn = "";
      this.descripcionIn = "";
      this.dateIn = "";
      this.dateIn2 = "";
      this.contextoIn = "Emitidos";
      this.tipoDocIn = "Seleccionar Todos",
        this.rfcSearch = "";
      this.showDialogReports = true;


    },
    ObtenerReportes() {
      var jsonData = {
        token: this.$store.state.token,
        reportType: 'REPORT-INTEGRACION-IVASAT'
      };

      service
        .api()
        .RetrieveOnDemandReports(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);

          } else {
            this.itemsGrandes = [];
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: 'REPORT-INTEGRACION-IVASAT'
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertMessage", "Reporte Eliminado Correctamente");

            this.ObtenerReportes();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    DescargarReporte(itemId) {
      return new Promise((resolve, reject) => {
        const jsonData = {
          id: itemId,
          token: this.$store.state.token,
        };

        service
          .api()
          .RetrieveOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.retrieveReporte(response.payload);
              resolve(response.payload); // Resolver con el payload
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
              reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
            }
          })
          .catch((error) => {
            reject(error); // Rechazar si ocurre un error en el servicio
          });
      });
    },
    descargarMultiple() {
      this.descargarTodosEnParalelo(this.selectedItemsDescarga);

    },
    async descargarTodosEnParalelo(items) {
      try {
        const promesas = items.map((item) => this.DescargarReporte(item.id));
        await Promise.all(promesas);
        console.log("Todas las descargas han finalizado.");
      } catch (error) {
        console.error("Error en una o más descargas:", error);
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItemsDescarga = [...this.itemsGrandes];
      } else {
        this.selectedItemsDescarga = [];
      }
    },



    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },
    parseTipoDoc(tipoD) {
      let tipoIn = "";
      switch (tipoD) {
        case "I":
          tipoIn = "Ingreso";
          break;
        case "E":
          tipoIn = "Egreso";
          break;
      }
      return tipoIn;
    }


  },
  computed: {
    mostrarDescargarSeleccionado() {
      return this.selectedItemsDescarga.length > 0;
    },
    showCreateButton() {
      return this.selectedDates.length > 1 && this.descripcionIn.length > 0;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    },

    fechaInicial() {
      return helpers.getFechaLimite(true);
    },
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
