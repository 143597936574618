<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
        <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Documentos posiblemente duplicados por Cadena Original</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2" dark icon v-on="on" @click="exportExcel">
                    <v-icon dark> mdi-file-export </v-icon>
                  </v-btn>
                </template>
                <span>Exportar a CSV</span>
              </v-tooltip>
              <v-btn color="darken-1" text @click="closeDialog">Cerrar</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Folio Fiscal</th>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th v-if="esRecibido">RFC Emisor</th>
                      <th v-if="esRecibido">Razon Social Emisor</th>
                      <th v-if="esEmitido">RFC Receptor</th>
                      <th v-if="esEmitido">Razon Social Receptor</th>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <th>Tipo Comprobante</th>
                      <th align="right">Subtotal</th>
                      <th align="right">Total</th>
                      <th align="right">Descuento</th>
                      <th>Moneda</th>
                      <th align="right">Total Retenciones</th>
                      <th align="right">Total Traslados</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsRel" :key="item.id" class="altura">
                      <td style="white-space: nowrap">{{ item.id }}</td>
                      <td style="white-space: nowrap">{{ item.serie }}</td>
                      <td style="white-space: nowrap">{{ item.folio }}</td>
                      <td v-if="esRecibido">{{ item.emisor.rfc }}</td>
                      <td v-if="esRecibido">{{ item.emisor.nombre }}</td>
                      <td v-if="esEmitido">{{ item.receptor.rfc }}</td>
                      <td v-if="esEmitido">{{ item.receptor.nombre }}</td>
                      <td style="white-space: nowrap">{{ formatFecha(item.fecha_emision) }}</td>
                      <td style="white-space: nowrap">{{ formatFecha(item.fecha_timbre) }}</td>
                      <td style="white-space: nowrap">
                        {{ item.tipo_comprobante == "I" ? "Ingreso" : "Egreso" }}
                      </td>
                      <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                      <td align="right">{{ currencyFormat(item.total) }}</td>
                      <td align="right">{{ currencyFormat(item.descuento) }}</td>
                      <td>{{ item.moneda }}</td>
                      <td align="right">{{ currencyFormat(item.total_retenciones) }}</td>
                      <td align="right">{{ currencyFormat(item.total_traslados) }}</td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="verMasDatosCFDI(item.id)" icon color="#4aa832" v-on="on">
                              <v-icon>mdi-eye-plus-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver Más</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                              <v-icon>mdi-xml</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar XML</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                              <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar PDF</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-4">
                        <v-select :items="itemsDuplicado" item-text="name" item-value="value"
                          @change="CambiarEstado(item)" v-model="item.duplicado_cad" label="Estado" outlined
                          return-object></v-select>
                      </td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogConfirmar" persistent max-width="400px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogConfirmar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>¿Confirma que desea quitar la marca de duplicado?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Eliminar">Aceptar</v-btn>
              <v-btn color="blue darken-1" text @click="showDialogConfirmar = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Comprobantes con posible duplicidad por Todo el CFDI
          </h3>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-2">
            <v-row>
              <v-col cols="12" pa="4" md="2" class="mx-2" justify="center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Fecha Inicial*" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="fechaInicial" v-model="date" @change="cambio = true" locale="es"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" pa="4" md="2" justify="center">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date2" label="Fecha Final*" prepend-icon="mdi-calendar-month" readonly
                      :disabled="date.length == 0" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="date" v-model="date2" @change="cambio = true" locale="es"
                    @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="d-flex" cols="12" md="2" lg="1">
                <v-select :items="itemsOrigen" v-model="contexto" label="Contexto" @change="cambioOrigen" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model="rfcSearch" counter="0" v-on:input="putUpper" :label="labelRfc" maxlength="13"
                  class="my-1"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-select :items="itemsSelect" v-model="tipoDoc" label="Tipo de Comprobante" outlined></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-select :items="itemsEstado" v-model="itemEstado" label="Estado de Revisión" outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" pa="4">
                <v-btn color="primary" white large v-on:click="getReport">Mostrar Listado</v-btn>
              </v-col>
              <v-col cols="12" md="2" pa="4">
                <v-btn color="primary" white large v-on:click="IrAResumen">Volver al Resumen</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ conceptosPagina }} registros de
              {{ totalRelated }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteProdServCSV" v-on="on" :disabled="totalRelated > 5000"
                  color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                    color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                    color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>



            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">



              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor'" width="2*" :isReadOnly="true" />


              <wj-flex-grid-column :header="'Posibles Duplicados'" :binding="'cantidad'" width="4*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Revisados'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ (cell.item.dpositivo == null || cell.item.dnegativo == null)  ? 0 : (cell.item.dpositivo + cell.item.dnegativo) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.tipo_comprobante == "I" ? "Ingreso" : "Egreso" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Traslados'" :binding="'total_traslados'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Retenciones'" :binding="'total_retenciones'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Estado de Revisión'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ (cell.item.cantidad == (cell.item.dpositivo + cell.item.dnegativo)) ? "Revisado" : "Con
                                      pendientes de revisión" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>


              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Detalle</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>



          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';

export default {
  name: "Duplicados",
  components: {
    Dialogo
  },
  data: () => ({
    showDialog: false,
    showDialogConfirmar: false,
    selectedRows: [],
    date: "",
    menu: false,
    date2: "",
    menu2: false,
    tipoDoc: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso"],
    itemEstado: "Sin Importar",
    itemsEstado: ["Revisado", "Pendientes de Revisión", "Sin Importar"],
    tipoDocDup: "",
    itemsDuplicado: [{ "name": "Duplicado", "value": true }, { "name": "No duplicado", "value": false }, { "name": "No Revisado", "value": null }],
    modal: false,
    contexto: "Emitidos",
    rfcSearch: "",
    itemFind: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },



    search: "",
    items: [],
    landscape: false,
    goPage: 1,
    offest: 0,
    limit: 50,
    pageSize: "500",
    noPagina: 1,
    totalPaginas: 1,
    totalRelated: 0,
    paginations: ["100", "200", "500"],
    context: "",
    itemsRel: [],
    selectedItem: {},
    selectedId: 0,
    cambioDupli: false,
    marca: 0,
  }),


  created() {

    let objetoResumen = this.$store.state.genericObject;

    if (objetoResumen !== null) {
      this.date = objetoResumen.dateInit;
      this.date2 = objetoResumen.dateEnd;
      this.contexto = objetoResumen.contexto;
      this.itemEstado = objetoResumen.marca;
      this.getReport();
    }

    this.$store.commit("setGenericObject", null);
  },


  methods: {
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        Serie: "Serie",
        Folio: "Folio",
        RfcE: "RFC Emisor",
        NombreE: "Razon Social Emisor",
        RfcR: "RFC Receptor",
        NombreR: "Razon Social Receptor",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        TipoComprobante: "Tipo Comprobante",
        SubTotal: "Subtotal",
        TipoCambio: "Tipo Cambio",
        Total: "Total",
        Descuento: "Descuento",
        Moneda: "Moneda",
        TotalRet: "Total Retenciones",
        TotalTras: "Total Traslados",
        Tipo: "Contexto", 
      };

      var itemsFormatted = [];

      this.itemsRel.forEach((item) => {
        let par = item.particion.split("-");
        let contentRow = {
          UUID: helpers.ConvertToEmpty(item.id),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          RfcE: helpers.ConvertToEmpty(item.emisor.rfc),
          NombreE: helpers.ConvertToEmpty(item.emisor.nombre),
          RfcR: helpers.ConvertToEmpty(item.receptor.rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor.nombre),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          TipoComprobante: helpers.ConvertToEmpty(
            this.getTipoComprobante(item.tipo_comprobante)
          ),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          Total: helpers.ConvertToEmpty(item.total),
          Descuento: helpers.ConvertToEmpty(item.descuento), 
          Moneda: helpers.ConvertToEmpty(item.moneda),
          TotalRet: helpers.ConvertToEmpty(item.total_retenciones),
          TotalTras: helpers.ConvertToEmpty(item.total_traslados),
          Tipo: this.esEmitido ? "Emitido" : "Recibido",
        };

          itemsFormatted.push(contentRow);
        
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_CFDI_Posible_Duplicidad_Cadena_Original_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },

    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }

      grid.columns[grid.columns.length - 1].width = 80;


    },
    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },

    verMasDatosCFDI(id) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: id,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFind = payload;
            this.openDialog();
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

    },
    formatFecha(fecha) {

      return helpers.formatFecha(fecha);
    },

    putUpper() {
      this.rfcSearch = this.rfcSearch.toUpperCase();
    },
    cambioOrigen() {

      this.items = [];
    },
    IrAResumen() {

      let pathL = "/conciliatorio/ResumenDuplicadosCadenaOriginal";
      this.$store.commit("setGenericObject", {
        dateInit: this.date,
        dateEnd: this.date2,
      });
      setTimeout(() => {
        this.$router.push({
          path: pathL,
        });
      }, 1000);
    },
    ConfirmarEliminar(id) {
      this.selectedId = id;
      this.showDialogConfirmar = true;
    },
    closeDialog() {
      this.showDialog = false;

      if (this.cambioDupli) this.getReport();
    },
    rowClicked(index) {
      this.selectedRows = [];
      this.toggleSelection(index);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },

    CambiarEstado(item) {

      var jsonData = {
        token: this.$store.state.token,
        companyMark: item.duplicado_cad.value,
        uuid: item.id,
        issuedDate: item.fecha_emision
      };

      service
        .api()
        .ReporteConciliatorioSetMarkDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            //this.$set(this.itemsRel[index], 'e_duplicado', item.e_duplicado);
            this.cambioDupli = true;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });

    },
    verMasDatos(item) {
      this.selectedItem = item;
      this.itemsRel = [];
      var jsonData = {
        token: this.$store.state.token,
        //particion: item.particion,
        hash: item.cadenaOriginal,
        dateInit: this.date,
        dateEnd: this.date2,
        top: item.cantidad
      };

      service
        .api()
        .ReporteConciliatorioDetallesDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            let itemsRelIn = JSON.parse(response.payload);

            if (itemsRelIn.length > 0) {
              let i;
              for (i = 0; i < itemsRelIn.length; i++) {
                switch (itemsRelIn[i].duplicado_cad) {
                  //[{ "name": "Duplicado", "value": true }, { "name": "No duplicado", "value": false }, { "name": "No Revisado", "value": null }],
                  case true:
                    let temp1 = { "name": "Duplicado", "value": true };
                    this.$set(itemsRelIn[i], 'duplicado_cad', temp1);
                    break;
                  case false:
                    let temp2 = { "name": "No duplicado", "value": false };
                    this.$set(itemsRelIn[i], 'duplicado_cad', temp2);
                    break;
                  default:
                    let temp3 = { "name": "No Revisado", "value": null };
                    this.$set(itemsRelIn[i], 'duplicado_cad', temp3);
                    break;
                }
              }

              this.itemsRel = itemsRelIn;
              this.showDialog = true;
            }
          } else {
            if (
              response.errorMessage ===
              "No se encontraron resultados con los criterios utilizados."
            ) {
              this.showDialog = false;
              this.getReport();
              return;
            }

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
            this.itemsRel = [];
          }
        });
    },

    Eliminar() {
      var jsonData = {
        token: this.$store.state.token,
        context: this.selectedItem.tipo,
        docType: this.selectedItem.tipo_comprobante,
        moneda: this.selectedItem.moneda,
        rfc:
          this.selectedItem.tipo == "E"
            ? this.selectedItem.receptor
            : this.selectedItem.emisor,
        tipoCambio: this.selectedItem.tipo_cambio,
        total: this.selectedItem.total,
        subTotal: this.selectedItem.subtotal,
        descuento: this.selectedItem.descuento,
        totalTraslados: this.selectedItem.total_traslados,
        totalRetenciones: this.selectedItem.total_retenciones,
        nfechaEmision: this.selectedItem.nfecha_emision,
        uuid: this.selectedId,
      };

      service
        .api()
        .ReporteConciliatorioEliminarDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            this.cambioDupli = true;

            this.showDialogConfirmar = false;

            this.verMasDatos(this.selectedItem);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalRelated / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalRelated % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalRelated % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.cambioDupli = false;
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.selectedRows = [];
      this.getTotalCount();
    },

    getReporteItems() {
      this.items = [];
      this.selectedRows = [];

      let jsonData = {}

      if (this.itemEstado === "Sin Importar") {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          offset: this.offest,
          limit: this.limit,
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
        };
      }
      else {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          offset: this.offest,
          limit: this.limit,
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
          withCompanyMark: this.itemEstado === "Revisado"
        };
      }


      service
        .api()
        .ReporteConciliatorioDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.conceptosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];

      let jsonData = {};

      if (this.itemEstado === "Sin Importar") {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
        };
      }
      else {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
          withCompanyMark: this.itemEstado === "Revisado"
        };
      }
      service
        .api()
        .ReporteConciliatorioCountDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalRelated = payload;
            this.totalPaginas = Math.ceil(this.totalRelated / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteProdServCSV(tipo) {
      let jsonData = {};

      if (this.itemEstado === "Sin Importar") {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
        };
      }
      else {
        jsonData = {
          token: this.$store.state.token,
          dateInit: this.date,
          dateEnd: this.date2,
          context: this.contexto.substr(0, 1),
          rfc: this.rfcSearch,
          docType: this.getTipoDoc(),
          withCompanyMark: this.itemEstado === "Revisado"
        };
      }

      service
        .api()
        .ReporteConciliatorioCSVDuplicadoV2(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.items = JSON.parse(response.payload);
            JSONToCSV(
              this.items,
              "Reporte_Duplicado" +
              this.date +
              "_" +
              helpers.dateNowCSV().replace(/-/g, "_"),
              true
            );


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Archivo CSV generado exitosamente");


          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substring(0, 1),
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");


      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    esEmitido() {
      return this.contexto.substr(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substr(0, 1) === "R";
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    fechaInicial() {
      return '2023-09';
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued) elements.push("Emitidos");
      if (this.allowReceived) elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    },
    labelRfc() {
      if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.marDerecho {
  margin-right: 15px;
  margin-left: 15px;
}
</style>
