<template>
       <v-img
      src="../../assets/MYSuite-XData.png"
    >
     
    </v-img>
</template>
<script>
export default {
    name: 'Background',
    mounted(){             
       this.$store.commit("setShowDrawer",true);
    }
}
</script>