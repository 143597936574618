<template>
    <div class="py-10">
        <v-container fluid class="px-4">

            <dialogoRiesgos ref="dialogRiesgos" :parametros="parametrosRiesgos"></dialogoRiesgos>

            <dialogo ref="dialog" :itemFind="itemFind"></dialogo>

            <v-dialog v-model="showDataSetRiesgos" fullscreen :scrim="false" transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="showDataSetRiesgos = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ riesgoSeleccionado }} - {{ contextoSeleccionado }} <br />{{ yearSelected }} - {{
                            mesSeleccionado }}</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-btn @click="reporteEspecifico" large color="second">
                            <v-icon>mdi--ballot-outline</v-icon>Reporte Detallado
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-col cols="12">
                            <v-toolbar>
                                <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos
                                }}
                                </v-toolbar-title>
                                <v-spacer class="d-none d-sm-flex"></v-spacer>

                                <v-toolbar-items class="pa-3">
                                    <v-select v-model="pageSize" v-on:change="start" :items="paginations" menu-props="auto"
                                        label="Total de Registros" hide-details class="ma-3 anchoP">
                                    </v-select>
                                    <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                                color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                        </template>
                                        <span>Página anterior</span>
                                    </v-tooltip>
                                    <v-icon v-else large class="mr-2"
                                        color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                                    <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                                                color="second">
                                                mdi-arrow-down-bold-circle-outline</v-icon>
                                        </template>
                                        <span>Página siguiente</span>
                                    </v-tooltip>
                                    <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                                    </v-icon>

                                    <v-btn text>
                                        <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                            class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                        <span class="tamPagina">/{{ totalPaginas }}</span>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <wj-flex-grid :itemsSource="itemsUniqueData" :autoGenerateColumns="false"
                                    :allowAddNew="false" :allowDelete="false" :allowPinning="'SingleColumn'"
                                    :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
                                    :initialized="initGridUnique" :allowResizing="'None'">

                                    <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'folio_fiscal'" width="3*"
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column :header="'Serie'" :binding="'serie'" 
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column :header="'Folio'" :binding="'folio'" 
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column v-if="contextSelected == 'R'" :header="'Emisor RFC'" :binding="'emisor_rfc'" 
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column v-if="contextSelected == 'R'" :header="'Emisor Razón Social'" :binding="'emisor_nombre'"
                                        width="4*" :isReadOnly="true" />

                                    <wj-flex-grid-column v-if="contextSelected == 'E'" :header="'Receptor RFC'" :binding="'receptor_rfc'" 
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column v-if="contextSelected == 'E'" :header="'Receptor Razón Social'" :binding="'receptor_nombre'"
                                        width="4*" :isReadOnly="true" />
                                    <wj-flex-grid-column :header="'Monto del Riesgo(MXN)'" :binding="'monto'"  width="1*"
                                        format="c2" :isReadOnly="true" />
                                    <wj-flex-grid-column :header="'Tipo Comprobante'" :isReadOnly="true" width="1*">
                                        <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                            <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                        </wj-flex-grid-cell-template>
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'"  width="1*"
                                        :isReadOnly="true" />
                                    <wj-flex-grid-column :header="' '" width="2*" :isReadOnly="true">
                                        <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="verMasDatos(cell.item.folio_fiscal)" icon color="#4aa832"
                                                        v-on="on">
                                                        <v-icon>mdi-eye-plus-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ver Más</span>
                                            </v-tooltip>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                        <v-icon>mdi-xml</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar XML</span>
                                            </v-tooltip>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                        <v-icon>mdi-file-pdf-box</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Descargar PDF</span>
                                            </v-tooltip>
                                        </wj-flex-grid-cell-template>
                                    </wj-flex-grid-column>

                                </wj-flex-grid>
                            </v-card>
                            <br />
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="showDataSetRiesgos = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>




            <v-dialog v-model="verRiesgos" persistent max-width="900px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="verRiesgos = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Descripción de los Riesgos</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <br />
                        <v-simple-table fixed-header dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Riesgo</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in itemsRiesgos" :key="item.id" class="altura">
                                        <td>
                                            {{ item.id }}
                                        </td>
                                        <td>
                                            {{ item.description }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="showDialogCrear = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Solicitar Otro Punto de Medición</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col md="8" justify="center">
                                <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1"
                                    maxlength="100">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="mt-12"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="SolicitarReporte" text large :disabled="!showCreateButton">Solicitar
                            Reporte
                        </v-btn>
                        <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row>

                <v-col cols="12">
                    <h2>Análisis de los Riesgos Fiscales (MXN)</h2>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="12" md="3" class="px-2 mx-md-10">
                                <v-select class="ml-2" label="Vistas" :items="itemsSelect" item-value="value"
                                    v-model="tipoVista" v-on:change="changeVista">
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="4" class="px-2 mx-md-10">
                                <v-select :items="itemsPuntos" item-text="name" item-value="value"
                                    label="Otros puntos de medición" v-model="puntoSelected" @change="CambiarPunto" outlined
                                    return-object></v-select>
                            </v-col>

                            <v-col cols="12" md="4" class="text-right">

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2" outlined fab color="second" v-on="on" @click="obtenerRiesgos">
                                            <v-icon>mdi-help </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descripción de los Riesgos</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2" outlined fab color="primary" v-on="on"
                                            @click="showDialogCrear = true">
                                            <v-icon>mdi-plus-thick </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Solicitar Otro Punto de Medición</span>
                                </v-tooltip>


                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2" outlined fab color="pagadas" v-on="on"
                                            @click="DescargarReporte">
                                            <v-icon>mdi-download </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descargar Punto de Medición (csv)</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2" outlined fab color="red" v-on="on" @click="exportGrid">
                                            <v-icon>mdi-file-pdf-box</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descargar Tabla en PDF</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2" outlined fab color="secundary" v-on="on" @click="exportChart">
                                            <v-icon>mdi-chart-bar</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descargar Gráfica en Imagen (jpeg)</span>
                                </v-tooltip>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12">


                    <div class="pivot-grid-container">
                        <h3 class="ma-2">Total de Riesgo</h3>
                        <wj-pivot-grid :items-source="ngPanelTotal"></wj-pivot-grid>
                    </div>
                    <wj-pivot-chart :items-source="ngPanel" :show-title="false" show-legend="Auto" :showTitle="false"
                        :legendPosition="'Bottom'" :initialized="initializePivotChart.bind(this)">

                    </wj-pivot-chart>

                    <div class="pivot-grid-container">
                        <wj-pivot-grid :items-source="ngPanel" :initialized="initializePivotGrid"
                            :formatItem='formatItem'></wj-pivot-grid>
                    </div>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>
    
<script>
import * as wjCore from '@grapecity/wijmo';
wjCore.setLicenseKey('xdata.mysuitecfdi.com,128859229951414#B0swIZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUv2EZqJ6RypUauVlb0pldUhFdZN4N4Y5cykWWDFWMBVDZSlUU6YTOyQUYw4kW8UTdM9EM4QDcsFTeSdUYpZ6d9IUVil5M8ZHZWVlQ5JkdRJTUidWOWZjc5pUdRtmRkN7KzJVaNNGS8ckbIl4R9klZK3UQZJWYJFzN52yK6kHW8RFb4smQKZTeIdzVTRDRyN5aidkb8I7UQBFa6sSVpFTOHt4SmVEcsVVYGNVctdHeHdGZqlTaLNHbIF5bTF4V5g5YMJFTMJzYoZTW5Ejc4d6cvFzb9kTRFR5dFRzbvAHRodDdPlGZx2yNiRTN4NDWid4bwVVeBllYERTbaVnSxp4R09GUvFmMQRVcYNVT8gWSxp4d5gTYRlmbP54diJXWoh5cGdDaN3CMZNzd9Q7LQlkSC3yaxcme9E7b7M7YVlVZjtGOUl4Z7kzai56MZFXOGZWZ0lWRxpkI0IyUiwiI4QjN6AzQDZjI0ICSiwyM4YjM5kTOwgTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISMxkDM7ADI4AzNwMjMwIjI0ICdyNkIsISbvNmLpRmZjVGdpV7c95mLhRXYkhnI0IyctRkIsIiVDBSREBSQTByUFNUSWJVRTBSRUlUVTlVTiojIh94QiwiI4EDNxUTO9IjM9UDO8ITMiojIklkIs4XXbpjInxmZiwiIyY7MyAjMiojIMVYd');


import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.input';
import '@grapecity/wijmo.vue2.grid';
import '@grapecity/wijmo.vue2.olap';
import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
import * as wjcOlap from '@grapecity/wijmo.olap';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjChart from '@grapecity/wijmo.chart';


import { WjFlexGrid, WjFlexGridColumn } from '@grapecity/wijmo.vue2.grid';
import { WjPivotGrid, WjPivotPanel } from '@grapecity/wijmo.vue2.olap';


import { culture } from '@grapecity/wijmo';
import DialogoRiesgos from '../Componentes/RiesgosFiscalesTablas.vue';
culture.olap.DataView = culture.olap.Pivot = culture.olap.PivotEngine = 'es';

import { getData } from './data';
import service from "../../scripts/services.js";
import helpers from "../../scripts/helpers.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';


export default {
    name: 'RiesgoFiscal',
    components: {
        Dialogo,
        DialogoRiesgos,
        WjPivotPanel,
        WjPivotGrid,
        WjFlexGrid,
        WjFlexGridColumn
    },
    mounted: function () {
        let detailDialog = this.pivotGrid.detailDialog;
        let detailGridHost = detailDialog.hostElement.querySelector('.wj-flexgrid');
        let detailGrid = wjCore.Control.getControl(detailGridHost);

        // customize dialog content when showing it
        detailDialog.showing.addHandler((s) => {


            let partes = s.columnHeader.split(' - ');
            let headers = s.rowHeader.split('-');

            if(headers.length < 2)
            return;

            if (this.tipoVista == "Todos los Años por Mes") {

                this.yearSelected = headers[0].trim();
                this.monthSelected = headers[1].trim();
                this.mesSeleccionado = headers[2].trim();
                this.contextSelected = partes[1].substring(0, 1);
                this.periodoSelected = this.yearSelected + "-" + this.monthSelected;
                this.goPage = this.noPagina = 1;


                let minus5Year = new Date().getFullYear() - 5;
                let validateYear = parseInt(this.yearSelected, 10);

                if (validateYear < minus5Year) {
                    return;
                }

                this.contextoSeleccionado = this.contextSelected == "E" ? "Emitidos" : "Recibidos";

                if (s.columnHeader.includes('CFDIs de Pago extemporáneos')) {
                    this.riskSelected = "EXTEMPORANEOS";
                    this.riesgoSeleccionado = "CFDIs de Pago extemporáneos";
                    this.obtenerConteo();

                    sds.clear();
                    let contenidoHTML = '<h3>Cargando Información Extemporaneos</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('CFDIs con Inconsistencias entre Método y Forma de Pago')) {


                    this.riskSelected = "METODO_VS_FORMA_DE_PAGO";
                    this.riesgoSeleccionado = "CFDIs con Inconsistencias entre Método y Forma de Pago";
                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando Información Método vs Forma de pago</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('Saldo pendiente en CFDIs con método de pago PPD')) {

                    this.riskSelected = "SALDO_INSOLUTO";
                    this.riesgoSeleccionado = "Saldo pendiente en CFDIs con método de pago PPD";
                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando Saldo Insoluto</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('Facturas canceladas que están en Comprobantes de Pago')) {

                    this.riskSelected = "RELACIONADOS_CANCELADOS_EN_PAGOS";
                    this.riesgoSeleccionado = "Facturas canceladas que están en Comprobantes de Pago";
                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando Relacionados Cancelados en pagos</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('CFDIs de Pago con documento relacionado inexistente')) {


                    this.riskSelected = "INEXISTENTES_EN_PAGOS";
                    this.riesgoSeleccionado = "CFDIs de Pago con documento relacionado inexistente";
                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando CFDIs de Pago con documento relacionado inexistente</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('CFDIs sustituidos que no han sido cancelados')) {

                    this.riskSelected = "SUSTITUIDOS_NO_CANCELADOS";
                    this.riesgoSeleccionado = "CFDIs sustituidos que no han sido cancelados";

                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando CFDIs sustituidos que no han sido cancelados</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('CFDIs relacionados (no sustituidos) cancelados')) {

                    this.riskSelected = "NO_SUSTITUIDOS_CANCELADOS";
                    this.riesgoSeleccionado = "CFDIs relacionados (no sustituidos) cancelados";

                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando CFDIs relacionados (no sustituidos) cancelados</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
                else if (s.columnHeader.includes('CFDIs relacionados que no existen en XData')) {

                    this.riskSelected = "RELACIONADOS_INEXISTENTES";
                    this.riesgoSeleccionado = "CFDIs relacionados que no existen en XData";

                    this.obtenerConteo();
                    sds.clear();
                    let contenidoHTML = '<h3>Cargando CFDIs relacionados que no existen en XData</h3>';
                    this.pivotGrid.detailDialog.content = contenidoHTML;

                }
            }


        });
    },

    created() {

        this.ObtenerSource();
        this.ObtenerOtrosPuntos();

    },
    data: () => ({

        periodoSelected: "",
        goPage: 1,
        itemsUniqueData: [],
        yearSelected: 0,
        monthSelected: 0,
        mesSeleccionado: "",
        riskSelected: "",
        riesgoSeleccionado: "",
        contextSelected: "",
        contextoSeleccionado: "",
        showDataSetRiesgos: false,
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200", "500"],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },


        riesgoMapping: {
            'EXTEMPORANEOS': 'CFDIs de Pago extemporáneos',
            'METODO VS FORMA DE PAGO': 'CFDIs con Inconsistencias entre Método y Forma de Pago',
            'INEXISTENTES EN PAGOS': 'CFDIs de Pago con documento relacionado inexistente',
            'RELACIONADOS CANCELADOS EN PAGOS': 'Facturas canceladas que están en Comprobantes de Pago',
            'SALDO INSOLUTO': 'Saldo pendiente en CFDIs con método de pago PPD',
            'SUSTITUIDOS NO CANCELADOS': 'CFDIs sustituidos que no han sido cancelados',
            'NO SUSTITUIDOS CANCELADOS': 'CFDIs relacionados (no sustituidos) cancelados',
            'RELACIONADOS INEXISTENTES': 'CFDIs relacionados que no existen en XData',
        },
        parametrosRiesgos: {
            date: "2023-09",
            contexto: "E"
        },
        mostrar: false,
        dataSource: [],
        tipoVista: "Todos los Años",
        itemsSelect: ["Todos los Años", "Todos los Años desglosado por riesgo", "Todos los Años por Mes", "Año en Curso"],
        itemsGrandes: [],
        showDialogCrear: false,
        descripcionIn: "",
        itemDesc: "",
        puntoSelected: {},
        itemsPuntos: [],
        itemsRiesgos: [],
        selectedId: "",
        verRiesgos: false,
        ngPanel: new wjcOlap.PivotEngine({
            itemsSource: getData(), // raw data
            fields: [{
                binding: 'anno',
                header: 'Año',
                format: 'yyyy'
            },
            {
                binding: 'mes',
                header: 'Mes'

            },
            {
                binding: 'contexto',
                header: 'Contexto'
            },
            {
                binding: 'riesgo',
                header: 'Riesgo'
            },
            {
                binding: 'montoRiesgo',
                header: 'Monto',
                format: 'c2'
            },
            ],
            showRowTotals: 'Subtotals',
            rowFields: ['Año', 'Mes'],
            columnFields: ['Riesgo', 'Contexto'],
            valueFields: ['Monto']
        }),
        ngPanelTotal: new wjcOlap.PivotEngine({
            itemsSource: getData(), // raw data
            fields: [{
                binding: 'anno',
                header: 'Año',
                format: 'yyyy'
            },
            {
                binding: 'mes',
                header: 'Mes'
            },
            {
                binding: 'contexto',
                header: 'Contexto'
            },
            {
                binding: 'riesgo',
                header: 'Riesgo'
            },
            {
                binding: 'montoRiesgo',
                header: 'Monto',
                format: 'c2'
            },
            ],
            showRowTotals: 'Subtotals',
            rowFields: [],
            columnFields: [],
            valueFields: ['Monto']
        })
    }),
    computed: {
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        showCreateButton() {
            return this.descripcionIn.length > 0;
        },

        showTabla() {
            var actualYear = new Date().getFullYear();
            return this.dataSource.filter(c => c.anno == actualYear).length > 0;
        },
    },
    methods: {

        reporteEspecifico() {

            switch (this.riskSelected) {
                case "EXTEMPORANEOS":
                    this.$refs.dialogRiesgos.getReporteItems(this.periodoSelected, this.contextSelected);
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showExt = true;
                    break;

                case "METODO_VS_FORMA_DE_PAGO":
                    this.$refs.dialogRiesgos.getReportMetodo(this.periodoSelected, this.contextSelected);
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showMetodo = true;
                    break;

                case "SALDO_INSOLUTO":
                    this.$refs.dialogRiesgos.getReportInsoluto(this.periodoSelected, this.contextSelected);
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showInsoluto = true;
                    break;

                case "RELACIONADOS_CANCELADOS_EN_PAGOS":
                    this.$refs.dialogRiesgos.getReportancelPagos(this.periodoSelected, this.contextSelected);
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showCanceladosPago = true;
                    break;

                case "INEXISTENTES_EN_PAGOS":
                    this.$refs.dialogRiesgos.getReportNotFound(this.periodoSelected, this.contextSelected);
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showNotFound = true;
                    break;

                case "SUSTITUIDOS_NO_CANCELADOS":
                    this.$refs.dialogRiesgos.getReportConciliatorio(this.periodoSelected, this.contextSelected,"1");
                    this.$refs.dialogRiesgos.tituloConciliatorio = this.riesgoSeleccionado;
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showConciliatorio = true;
                    break;
                case "NO_SUSTITUIDOS_CANCELADOS":
                    this.$refs.dialogRiesgos.getReportConciliatorio(this.periodoSelected, this.contextSelected,"2");
                    this.$refs.dialogRiesgos.tituloConciliatorio = this.riesgoSeleccionado;
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showConciliatorio = true;
                    break;
                case "RELACIONADOS_INEXISTENTES":
                    this.$refs.dialogRiesgos.getReportConciliatorio(this.periodoSelected, this.contextSelected,"3");
                    this.$refs.dialogRiesgos.tituloConciliatorio = this.riesgoSeleccionado;
                    this.$refs.dialogRiesgos.showDialogRiesgos = true;
                    this.$refs.dialogRiesgos.showConciliatorio = true;
                    break;


            }

        },

        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                obtenerConteo()
            else this.noPagina = this.goPage = actualPage;

        },
        obtenerConteo() {
            this.offest = 0;
            this.itemsUniqueData = [];
            var jsonData = {
                token: this.$store.state.token,
                year: this.yearSelected,
                month: this.monthSelected,
                context: this.contextSelected,
                risk: this.riskSelected,
            };
            service
                .api()
                .UniqueConteo(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.totalDocumentos = response.payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.obtenerDatos();
                    } else {

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });

        },

        obtenerDatos() {
            var jsonData = {
                token: this.$store.state.token,
                year: this.yearSelected,
                month: this.monthSelected,
                context: this.contextSelected,
                risk: this.riskSelected,
                offSet: this.offest,
                limit: this.limit
            };


            service
                .api()
                .UniqueLista(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.itemsUniqueData = JSON.parse(response.payload);

                        this.doctosPagina = this.itemsUniqueData.length.toString();
                        this.showDataSetRiesgos = true;
                    } else {

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });

        },

        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.folio_fiscal,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {

            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextSelected === "R")
                par =
                    this.contextSelected +
                    "|" +
                    helpers.ConvertToEmpty(item.pac).trim() +
                    "|" +
                    item.receptor_rfc;
            else
                par =
                    this.contextSelected + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
            let parameters = par + "|" + item.folio_fiscal + "|" + item.fecha_emision;

            let urlPDF = "";


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");



            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(id) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: id,
            };

            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });

        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "N":
                    tipo = "Nomina";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
        start() {
            
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.obtenerConteo();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.obtenerDatos();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.obtenerDatos();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.obtenerDatos();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.obtenerDatos();
            else this.noPagina = this.goPage = actualPage;

        },

        initGridUnique: function (grid) {
            for (let i = 0; i < grid.columns.length; i++) {
                grid.autoSizeColumn(i);
            }

            grid.columns[0].width = 300;
            grid.columns[grid.columns.length - 1].width += 40;

        },


        async procesarDatos() {
            this.dataSource.forEach(item => {
                const oldRiesgo = item.riesgo;
                const newRiesgo = this.riesgoMapping[oldRiesgo];
                if (newRiesgo) {
                    item.riesgo = newRiesgo;
                }
            });
        },
        formatItem: function (s, e) {
            // if(e.panel == s.columnHeaders || e.panel == s.rowHeaders || e.panel == s.topLeftCells){
            if (e.panel != s.cells) {
                switch (e.cell.innerText) {
                    /*
                        case "EXTEMPORANEOS":
                            e.cell.innerText = "EXTEMPORÁNEOS";
                            break;
                        case "METODO VS FORMA DE PAGO":
                            e.cell.innerText = "MÉTODO VS FORMA DE PAGO";
                            break;
                            */
                    case "EXTEMPORANEOS":
                        e.cell.innerText = "CFDIs de Pago extemporáneos";
                        break;
                    case "METODO VS FORMA DE PAGO":
                        e.cell.innerText = "CFDIs con Inconsistencias entre Método y Forma de Pago";
                        break;
                    case "INEXISTENTES EN PAGOS":
                        e.cell.innerText = "CFDIs de Pago con documento relacionado inexistente";
                        break;
                    case "RELACIONADOS CANCELADOS EN PAGOS":
                        e.cell.innerText = "Facturas canceladas que están en Comprobantes de Pago";
                        break;
                    case "SALDO INSOLUTO":
                        e.cell.innerText = "Saldo pendiente en CFDIs con método de pago PPD";
                        break;

                    case "SUSTITUIDOS NO CANCELADOS":
                        e.cell.innerText = "CFDIs sustituidos que no han sido cancelados";
                        break;
                    case "NO SUSTITUIDOS CANCELADOS":
                        e.cell.innerText = "CFDIs relacionados (no sustituidos) cancelados";
                        break;
                    case "RELACIONADOS INEXISTENTES":
                        e.cell.innerText = "CFDIs relacionados que no existen en XData";
                        break;

                }
                //e.cell.innerText = e.cell.innerText.replace(/[aeiou]/g, this.replaceWithAccent)
            }
        },
        initializePivotGrid: function (flex) {
            this.pivotGrid = flex;
            //add custom class to the hovered row group
            flex.hostElement.addEventListener('mouseover', (e) => {
                let hti = flex.hitTest(e);
                //Apply styling on ValueField cells only
                if (hti && hti.cellType != wjGrid.CellType.Cell) {
                    this.removeCustomClass();
                    return;
                }

                //remove class from previous cell
                this.removeCustomClass();
                //add custom class to hovered cell
                this.addCustomClass(flex, hti.row, hti.col);
            });

            flex.hostElement.addEventListener('mouseleave', (e) => {
                //remove class from previous cell
                this.removeCustomClass();
            });
        },
        addCustomClass(pivotGrid, r, c) {
            let cell = pivotGrid.cells.getCellElement(r, c);
            wjCore.addClass(cell, 'customHover');
        },
        removeCustomClass() {
            let customHoverCells = document.querySelector('.customHover');
            wjCore.removeClass(customHoverCells, 'customHover');
        },

        obtenerRiesgos() {

            var jsonData = {
                token: this.$store.state.token,
            };

            service
                .api()
                .FiscalRiskCatalog(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsRiesgos = JSON.parse(response.payload);
                        this.verRiesgos = true;

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });

        },
        initializePivotChart(chart) {
            // Set the position of the legends.
            chart.legendPosition = 'Bottom';
            // Enable toggling the series by clicking on the legends.
            chart.flexChart.legendToggle = true;
            // Make the legends scrollable.
            chart.flexChart.legend.scrollbar = true;
            // Set the orientation of the legends.
            chart.flexChart.legend.orientation = 'Vertical';
            // Flags to control renaming logic of series and scroll position of the legends container.
            let seriesNameChanged = false,
                resetScrollPosition = false;
            // To store the last scroll position of the legends container.
            let lastScrollPosition = 0;
            // Handle rendering event to reset the scroll position of the legends container.
            chart.flexChart.rendering.addHandler((sender, args) => {
                if (resetScrollPosition && sender.legend.scrollbar) {
                    sender.hostElement.querySelector('foreignObject div').scrollTop =
                        lastScrollPosition;
                    resetScrollPosition = false;
                }
            });
            // Handle rendered event of the flexchart to convert the series name(only once) and to add workaround for toggling series by clicking on legends.
            chart.flexChart.rendered.addHandler((s, e) => {
                // Workaround to toggle the series when a legend is clicked and scrollbar is present in legends container.
                if (chart.flexChart.legend.scrollbar) {
                    chart.flexChart.hostElement
                        .querySelector('foreignObject div g')
                        .addEventListener(
                            'click',
                            (e) => {
                                // Get the series index that is clicked.
                                let targetSeriesIndex = chart.flexChart.series.findIndex(
                                    (item) => {
                                        return item.name == e.target.textContent;
                                    }
                                );
                                // If a series is found.
                                if (targetSeriesIndex != -1) {
                                    let targetSeries = chart.flexChart.series[targetSeriesIndex];
                                    // Store the scroll position of the legends container.
                                    lastScrollPosition =
                                        chart.flexChart.hostElement.querySelector(
                                            'foreignObject div'
                                        ).scrollTop;
                                    // Set the visibility of the series.
                                    if (
                                        targetSeries.visibility === wjChart.SeriesVisibility.Visible
                                    )
                                        chart.flexChart.series[targetSeriesIndex].visibility =
                                            wjChart.SeriesVisibility.Legend;
                                    else if (
                                        targetSeries.visibility === wjChart.SeriesVisibility.Legend
                                    ) {
                                        targetSeries.visibility = wjChart.SeriesVisibility.Visible;
                                    }

                                    // Set the flag to restore the scroll position of the legends div.
                                    resetScrollPosition = true;
                                }
                                // Prevent default behavior of toggling the series.
                                e.stopImmediatePropagation();
                            },
                            true
                        );
                }

                // To rename the series as per the getLegendText() method.
                if (!seriesNameChanged) {
                    // Get all the series of the chart.
                    let seriesCollection = chart.flexChart.series;
                    for (let index = 0; index < seriesCollection.length; index++) {
                        // Get the appropriate name for current series.
                        let name = this.getLegendText(seriesCollection[index].name);
                        // Reset the name (if a suitable corresponding name is returned by getLegendText() method).
                        if (name) {
                            seriesCollection[index].name = name;
                        }
                    }
                    // Set the flag to ensure that the renaming is done only once.
                    if (seriesCollection.length) seriesNameChanged = true;
                    // Re-render the chart to show the legends with new names.
                    if (seriesNameChanged) {
                        chart.flexChart.refresh(true);
                    }
                }
            });
            this.pivotChart = chart;
            let flexchart = chart.flexChart;
            flexchart.legendToggle = true;
        },


        getLegendText: function (text) {
            let legends = {
                'EXTEMPORANEOS; Emitidos': 'CFDIs de Pago extemporáneos - Emitidos',
                'EXTEMPORANEOS; Recibidos': 'CFDIs de Pago extemporáneos - Recibidos',
                'METODO VS FORMA DE PAGO; Emitidos': 'CFDIs con Inconsistencias entre Método y Forma de Pago - Emitidos',
                'METODO VS FORMA DE PAGO; Recibidos': 'CFDIs con Inconsistencias entre Método y Forma de Pago - Recibidos',
                'INEXISTENTES EN PAGOS; Emitidos': 'CFDIs de Pago con documento relacionado inexistente - Emitidos',
                'INEXISTENTES EN PAGOS; Recibidos': 'CFDIs de Pago con documento relacionado inexistente - Recibidos',
                'RELACIONADOS CANCELADOS EN PAGOS; Emitidos': 'Facturas canceladas que están en Comprobantes de Pago - Emitidos',
                'RELACIONADOS CANCELADOS EN PAGOS; Recibidos': 'Facturas canceladas que están en Comprobantes de Pago - Recibidos',
                'SALDO INSOLUTO; Emitidos': 'Saldo pendiente en CFDIs con método de pago PPD - Emitidos',
                'SALDO INSOLUTO; Recibidos': 'Saldo pendiente en CFDIs con método de pago PPD - Recibidos',



                'SUSTITUIDOS NO CANCELADOS; Emitidos': 'CFDIs sustituidos que no han sido cancelados - Emitidos',
                'SUSTITUIDOS NO CANCELADOS; Recibidos': 'CFDIs sustituidos que no han sido cancelados - Recibidos',
                'NO SUSTITUIDOS CANCELADOS; Emitidos': 'CFDIs relacionados (no sustituidos) cancelados - Emitidos',
                'NO SUSTITUIDOS CANCELADOS; Recibidos': 'CFDIs relacionados (no sustituidos) cancelados - Recibidos',
                'RELACIONADOS INEXISTENTES; Emitidos': 'CFDIs relacionados que no existen en XData - Emitidos',
                'RELACIONADOS INEXISTENTES; Recibidos': 'CFDIs relacionados que no existen en XData - Recibidos',
            };
            return legends[text];
        },
        ObtenerOtrosPuntos() {
            var jsonData = {
                token: this.$store.state.token,
                reportType: 'REPORT-RIESGO-FISCAL'
            };

            service
                .api()
                .RetrieveOnDemandReports(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        let itemsGrandes = JSON.parse(response.payload);
                        this.itemsPuntos = [];


                        itemsGrandes.filter(x => x.ready && x.result).forEach((itemIn) => {
                            this.itemsPuntos.push(
                                {
                                    name: itemIn.requestDate.substring(0, 10) + ' - ' + itemIn.description,
                                    value: itemIn.id
                                });
                        });

                        this.puntoSelected = this.itemsPuntos[0];

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        CambiarPunto() {

            var jsonData = {
                id: this.puntoSelected.value,
                token: this.$store.state.token,
            };

            service
                .api()
                .FiscalRiskRetrieve(jsonData)
                .then((response) => {
                    this.dataSource = response;
                    if (this.dataSource.length > 0) {
                        this.forYear();
                        this.grandTotal();
                        this.tipoVista = "Todos los Años";
                    }
                    else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "No se encontraron Resultados");
                    }
                });
        },
        changeVista() {
            switch (this.tipoVista) {
                case "Año en Curso":
                    this.nowYear();
                    break;
                case "Todos los Años por Mes":
                    this.forYearByMonth();
                    break;
                case "Todos los Años desglosado por riesgo":
                    this.forYearByRisk();
                    break;
                case "Todos los Años":
                    this.forYear();
                    break;
                case "Total":
                    this.grandTotal();
                    break;
            }

        },
        async ObtenerSource() {

            var jsonData = {
                token: this.$store.state.token,
            };

            service
                .api()
                .FiscalRiskLast(jsonData)
                .then((response) => {
                    this.dataSource = response.filter(x=>x.riesgo!=='MONTO INGRESOS' && x.riesgo!=='MONTO EGRESOS' );
                    this.procesarDatos()
                        .then(() => {
                            this.forYear();
                            this.grandTotal();
                            this.tipoVista = "Todos los Años";
                        })


                });

        },
        grandTotal() {
            this.ngPanelTotal = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 200
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: [],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });

        },
        forYear() {
            this.ngPanel = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto',
                    width: 100
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo',
                    width: 100
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: ['Año'],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });

            this.pivotChart.flexChart.tooltip.content = "<b>{seriesName}</b><br/>Valor riesgo: {value}";
            this.ngPanelTotal = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: [],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });
        },
        forYearByRisk() {
            this.ngPanel = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: ['Año'],//rowFields: ['Año', 'Mes'],
                columnFields: ['Riesgo', 'Contexto'],
                valueFields: ['Monto']
            });
            this.pivotChart.flexChart.tooltip.content = "<b>{seriesName}</b><br/>Valor riesgo: {value}";

            this.ngPanelTotal = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: [],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });
        },

        forYearByMonth() {

            this.ngPanel = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: ['Año', 'Mes'],//rowFields: ['Año', 'Mes'],
                columnFields: ['Riesgo', 'Contexto'],
                //columnFields: ['Riesgo'],
                valueFields: ['Monto']
            });

            this.ngPanelTotal = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource, // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: [],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });
        },
        nowYear() {
            var actualYear = new Date().getFullYear();
            this.ngPanel = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource.filter(c => c.anno == actualYear), // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: ['Año', 'Mes'],
                columnFields: ['Riesgo', 'Contexto'],
                valueFields: ['Monto']
            });

            this.ngPanelTotal = new wjcOlap.PivotEngine({
                itemsSource: this.dataSource.filter(c => c.anno == actualYear), // raw data
                fields: [{
                    binding: 'anno',
                    header: 'Año'
                },
                {
                    binding: 'mes',
                    header: 'Mes'
                },
                {
                    binding: 'contexto',
                    header: 'Contexto'
                },
                {
                    binding: 'riesgo',
                    header: 'Riesgo'
                },
                {
                    binding: 'montoRiesgo',
                    header: 'Monto',
                    format: 'c2',
                    width: 250
                },
                ],
                showRowTotals: 'Subtotals',
                rowFields: [],//rowFields: ['Año', 'Mes'],
                columnFields: ['Contexto'],
                valueFields: ['Monto']
            });
        },
        SolicitarReporte() {

            this.showDialogReports = false;
            var jsonData = {
                reportType: 'REPORT-RIESGO-FISCAL',
                name: "",
                description: this.descripcionIn,
                commands: "",
                token: this.$store.state.token,
            };

            service
                .api()
                .RequestOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertMessage", "Reporte solicitado con exito");
                        this.showDialogReports = true;
                    } else {

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                    this.descripcionIn = "";
                });
            this.showDialogCrear = false;
        },
        DescargarReporte() {

            var jsonData = {
                id: this.puntoSelected.value,
                token: this.$store.state.token,
                reportType: 'REPORT-RIESGO-FISCAL'
            };

            service
                .api()
                .RetrieveOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.retrieveReporte(response.payload);
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        exportGrid() {
            let imageUrl = 'https://mysuiteanalytics.z14.web.core.windows.net/logo.png';
            const today = new Date().toLocaleString('en-GB', {
                hour12: false,
            });

            let doc = new wjcPdf.PdfDocument({
                compress: true,
                header: {
                    declarative: {
                        font: new wjcPdf.PdfFont('helvetica', 14, 'normal', 'bold')
                    },
                },
                footer: {
                    declarative: {
                        text: 'Contenido generado por XDATA.' + today + '\t\t&[Page]\\&[Pages]',
                        brush: '#3173c0',
                        font: new wjcPdf.PdfFont('helvetica', 10, 'normal', 'bold')
                    }
                },
                ended: (sender, args) => {
                    wjcPdf.saveBlob(args.blob, 'TablaRiesgoFiscal.pdf');
                }
            });



            doc.drawImage(imageUrl, 150, 0, { width: 100 });
            doc.drawText(' ');

            doc.drawText(' ');
            doc.drawText('Empresa: ' + this.$store.state.userLongName + " ( " + this.$store.state.entity + " )");
            doc.drawText(' ');
            doc.drawText('Punto de Medición: ' + this.puntoSelected.name);
            doc.drawText(' ');
            doc.drawText('Vista seleccionada: ' + this.tipoVista);
            doc.drawText(' ');
            wjcGridPdf.FlexGridPdfConverter.draw(this.pivotGrid, doc, 400);
            doc.end();
        },
        exportChart() {
            this.pivotChart.saveImageToFile('RiesgoFiscalChart.jpeg');

        }

    }
}
</script>
    
<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}

tr.v-data-table__selected {
    background: #3c8539 !important;
}


.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}

.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}

body {
    margin-bottom: 24pt;
}

.pivot-grid-container {
    width: 100%;
    margin-bottom: 24pt;
}


.customHover {
    font-weight: bolder;
}

.wj-control {
    font-family: 'Calibri' !important;
}
</style>
    