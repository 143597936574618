<template>
    <v-dialog v-model="showDialogGuid" persistent min-width="800px" max-width="1000px">
        <v-dialog v-model="showDialogStatusMain" persistent max-width="700px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showDialogStatusMain = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Detalle documento : {{ itemSelectedMain.serie }} -
                        {{ itemSelectedMain.folio }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-simple-table class="ma-3">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Cancelable</th>
                                    <th>Estado Cancelación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura">
                                    <td>{{ selectedStatusMain.estado }}</td>
                                    <td>{{ selectedStatusMain.cancelable }}</td>
                                    <td>{{ selectedStatusMain.estado_cancelacion }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showExpediente" fullscreen :scrim="false" transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showExpediente = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Expediente del documento: {{ itemFind.serie }} - {{ itemFind.folio }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn text @click="ObtenerPDF">
                        Generar PDF
                    </v-btn>
                    <v-btn text @click="showExpediente = false">Cerrar</v-btn>
                </v-toolbar>

                <v-card-text>

                    <v-simple-table class="ma-3">
                        <template v-slot:default>
                            <tr>
                                <td>
                                    <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                                        <p class="display-1 text--primary">Emisor</p>
                                        <div class="body-1">
                                            Razon Social: {{ itemFind.emisor.nombre }}
                                        </div>
                                        <div class="body-1">RFC: {{ itemFind.emisor.rfc }}</div>
                                        <div class="body-1">
                                            Regimen: {{ itemFind.emisor.regimen }}
                                        </div>
                                    </v-card>
                                </td>
                                <td>
                                    <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                                        <p class="display-1 text--primary">Receptor</p>
                                        <div class="body-1">
                                            Razon Social: {{ itemFind.receptor.nombre }}
                                        </div>
                                        <div class="body-1">RFC: {{ itemFind.receptor.rfc }}</div>
                                        <div class="body-1">
                                            Residencia: {{ itemFind.receptor.residencia }}
                                        </div>
                                        <div class="body-1">
                                            Uso CFDI: {{ itemFind.receptor.usocfdi }}
                                        </div>
                                    </v-card>
                                </td>
                            </tr>
                        </template>
                    </v-simple-table>
                    <br />

                    <v-simple-table class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Folio Fiscal</th>
                                    <th>Serie</th>
                                    <th>Folio</th>
                                    <th>Tipo Comprobante</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura">
                                    <td>{{ cfdiExp.id }}</td>
                                    <td>{{ itemFind.serie }}</td>
                                    <td>{{ itemFind.folio }}</td>
                                    <td>{{ tipoComp(itemFind.tipo_comprobante) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Fecha Emisión</th>
                                    <th>Fecha Timbre</th>
                                    <th>Método de Pago</th>
                                    <th>Forma de Pago</th>
                                    <th>Lugar Expedición</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura">
                                    <td>{{ formatFecha(itemFind.fecha_emision) }}</td>
                                    <td>{{ formatFecha(itemFind.fecha_timbre) }}</td>
                                    <td>{{ itemFind.metodo_pago }}</td>
                                    <td>{{ itemFind.forma_pago }}</td>
                                    <td>{{ itemFind.lugar_expedicion }}</td>
                                    <td>{{ cfdiExp.estado == "P" ? "Pagado" : "No Pagado" }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <br />
                    <v-divider></v-divider>
                    <p class="font-weight-black">Totales:</p>
                    <v-simple-table class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Moneda</th>
                                    <th class="text-right">Tipo Cambio</th>
                                    <th class="text-right">Descuento</th>
                                    <th class="text-right">Total Retenciones</th>
                                    <th class="text-right">Total Traslados</th>
                                    <th class="text-right">Subtotal</th>
                                    <th class="text-right">Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura">
                                    <td>{{ itemFind.moneda }}</td>
                                    <td align="right">
                                        {{ itemFind.tipo_cambio }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemFind.descuento) }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemFind.total_retenciones) }}
                                    </td>
                                    <td align="right">
                                        {{ currencyFormat(itemFind.total_traslados) }}
                                    </td>

                                    <td align="right">
                                        {{ currencyFormat(itemFind.subtotal) }}
                                    </td>
                                    <td align="right">{{ currencyFormat(itemFind.total) }}</td>


                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>

                                    <th class="text-right">Retención ISR</th>
                                    <th class="text-right">Retención IVA</th>
                                    <th class="text-right">Retención IEPS</th>
                                    <th class="text-right">Traslado IVA</th>
                                    <th class="text-right">Traslado IEPS</th>
                                    <th class="text-right">Importe Pagado MXN</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr class="altura">
                                    <td align="right">{{ currencyFormat(itemFind.retencion_isr) }}</td>
                                    <td align="right">{{ currencyFormat(itemFind.retencion_iva) }}</td>
                                    <td align="right">{{ currencyFormat(itemFind.retencion_ieps) }}</td>
                                    <td align="right">{{ currencyFormat(itemFind.traslado_iva) }}</td>
                                    <td align="right">{{ currencyFormat(itemFind.traslado_ieps) }}</td>
                                    <td align="right">{{ currencyFormat(itemFind.importe_pagado_mxn) }}</td>

                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <br />
                    <v-divider></v-divider>
                    <p v-if="relatedExp.length > 0" class="font-weight-black">CFDI's Relacionados en el Documento:</p>
                    <v-simple-table v-if="relatedExp.length > 0" class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Folio Fiscal</th>
                                    <th>Tipo Comprobante</th>
                                    <th>Fecha Emisión</th>
                                    <th>Serie</th>
                                    <th>Folio</th>
                                    <th>Metodo Pago</th>
                                    <th>Moneda</th>
                                    <th class="text-right">Tipo Cambio</th>
                                    <th class="text-right">Subtotal</th>
                                    <th class="text-right">Total</th>
                                    <th>Cancelado</th>
                                    <th>Relación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in relatedExp" :key="item.name" class="altura">
                                    <td>{{ item.id }}</td>
                                    <td>{{ tipoComp(item.tipo_comprobante) }}</td>
                                    <td>{{ item.fecha_emision }}</td>
                                    <td>{{ item.serie }}</td>
                                    <td>{{ item.folio }}</td>
                                    <td>{{ item.metodo_pago }}</td>
                                    <td>{{ item.moneda }}</td>
                                    <td align="right">{{ item.tipo_cambio }}</td>
                                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                                    <td align="right">{{ currencyFormat(item.total) }}</td>
                                    <td>{{ item.cancelado ? "Si" : "No" }}</td>
                                    <td>{{ item.relacion }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <h4 v-else style="color: red;">Sin CFDI's Relacionados en el Documento</h4>
                    <br />
                    <v-divider></v-divider>
                    <p v-if="paymentsExp.length > 0" class="font-weight-black">CFDI's de Pagos Relacionados con el
                        Documento:</p>
                    <v-simple-table v-if="paymentsExp.length > 0" class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Folio Fiscal</th>
                                    <th>Fecha Emisión</th>
                                    <th>Fecha Pago</th>
                                    <th>Moneda Pago</th>
                                    <th class="text-right">Tipo Cambio Pago</th>
                                    <th class="text-right">Monto</th>
                                    <th>Cancelado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in paymentsExp" :key="item.name" class="altura">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.fecha_emision }}</td>
                                    <td>{{ item.fecha_pago }}</td>
                                    <td>{{ item.moneda_p }}</td>
                                    <td align="right">{{ item.tipo_cambio_p }}</td>
                                    <td align="right">{{ currencyFormat(item.monto) }}</td>
                                    <td>{{ item.cancelado ? "Si" : "No" }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <h4 v-else style="color: red;">Sin CFDI's de Pagos Relacionados con el Documento</h4>
                    <br />
                    <v-divider></v-divider>
                    <p v-if="whereRelatedExp.length > 0" class="font-weight-black">CFDI's de Ingreso o Egreso donde el
                        Documento
                        se encuentra Relacionado:</p>
                    <v-simple-table v-if="whereRelatedExp.length > 0" class="my-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Folio Fiscal</th>
                                    <th>Tipo Comprobante</th>
                                    <th>Fecha Emisión</th>
                                    <th>Serie</th>
                                    <th>Folio</th>
                                    <th>Metodo Pago</th>
                                    <th>Moneda</th>
                                    <th class="text-right">Tipo Cambio</th>
                                    <th class="text-right">Subtotal</th>
                                    <th class="text-right">Total</th>
                                    <th>Cancelado</th>
                                    <th>Relación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in whereRelatedExp" :key="item.name" class="altura">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.tipo_comprobante == "I" ? "Ingreso" : "Egreso" }}</td>
                                    <td>{{ item.fecha_emision }}</td>
                                    <td>{{ item.serie }}</td>
                                    <td>{{ item.folio }}</td>
                                    <td>{{ item.metodo_pago }}</td>
                                    <td>{{ item.moneda }}</td>
                                    <td align="right">{{ item.tipo_cambio }}</td>
                                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                                    <td align="right">{{ currencyFormat(item.total) }}</td>
                                    <td>{{ item.cancelado ? "Si" : "No" }}</td>
                                    <td>{{ item.relacion }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <h4 v-else style="color: red;">Sin CFDI's de Ingreso o Egreso donde el Documento se encuentra
                        Relacionado</h4>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-card v-if="itemFind.fecha_cancelacion">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogGuid = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Documento Cancelado</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="itemFind.version == '3.3'">

                <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                    <p v-if="itemFind.tipo == 'E'" class="display-1 text--primary">Receptor</p>
                    <p v-else class="display-1 text--primary">Emisor</p>
                    <div class="body-1" v-if="itemFind.tipo == 'E'">
                        Razon Social: {{ itemFind.receptor.nombre }}
                    </div>
                    <div class="body-1" v-else>
                        Razon Social: {{ itemFind.emisor.nombre }}
                    </div>
                    <div class="body-1" v-if="itemFind.tipo == 'E'">RFC: {{ itemFind.receptor.rfc }}</div>
                    <div class="body-1" v-else>
                        RFC: {{ itemFind.emisor.rfc }}
                    </div>
                </v-card>

                <br />
                <v-label>Folio Fiscal: {{ itemFind.id }}</v-label>
                <br />

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Tipo Comprobante</th>
                                <th>PAC</th>
                                <th>Fecha Emisión</th>
                                <th>Fecha Timbre</th>
                                <th>Fecha Cancelación</th>
                                <th class="text-right">Total</th>
                                <th class="text-right">Monto Retenido</th>
                                <th class="text-right">Monto Traslado</th>

                                

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="altura">
                                <td>{{ tipoComp(itemFind.tipo_comprobante) }}</td>
                                <td>{{ itemFind.pac }}</td>
                                <td>{{ formatFecha(itemFind.fecha_emision) }}</td>
                                <td>{{ formatFecha(itemFind.fecha_timbre) }}</td>
                                <td>{{ formatFecha(itemFind.fecha_cancelacion) }}</td>
                                <td align="right">{{ currencyFormat(itemFind.total) }}</td>
                                <td align="right">{{ currencyFormat(itemFind.total_retenciones) }}</td>
                                <td align="right">{{ currencyFormat(itemFind.total_traslados) }}</td>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-text v-else>

                <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                    <p v-if="itemFind.tipo == 'E'" class="display-1 text--primary">Receptor</p>
                    <p v-else class="display-1 text--primary">Emisor</p>
                    <div class="body-1">
                        Razon Social: {{ itemFind.nombre }}
                    </div>
                    <div class="body-1">RFC: {{ itemFind.rfc }}</div>
                </v-card>

                <br />
                <v-label>Folio Fiscal: {{ itemFind.id }}</v-label>
                <br />

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Tipo Comprobante</th>
                                <th>PAC</th>
                                <th>Fecha Emisión</th>
                                <th>Fecha Timbre</th>
                                <th>Fecha Cancelación</th>
                                <th class="text-right">Total</th>
                                <th class="text-right">Monto Retenido</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="altura">
                                <td>{{ tipoComp(itemFind.tipo_comprobante) }}</td>
                                <td>{{ itemFind.PAC }}</td>
                                <td>{{ formatFecha(itemFind.fecha_emision) }}</td>
                                <td>{{ formatFecha(itemFind.fecha_timbre) }}</td>
                                <td>{{ formatFecha(itemFind.fecha_cancelacion) }}</td>
                                <td align="right">{{ currencyFormat(itemFind.total) }}</td>
                                <td align="right">{{ currencyFormat(itemFind.montoRet) }}</td>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialogGuid = false">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogGuid = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Detalle documento : {{ itemFind.serie }} -
                    {{ itemFind.folio }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-simple-table class="ma-3">
                    <template v-slot:default>
                        <tr>
                            <td>
                                <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                                    <p class="display-1 text--primary">Emisor</p>
                                    <div class="body-1">
                                        Razon Social: {{ itemFind.emisor.nombre }}
                                    </div>
                                    <div class="body-1">RFC: {{ itemFind.emisor.rfc }}</div>
                                    <div class="body-1">
                                        Regimen: {{ itemFind.emisor.regimen }}
                                    </div>
                                </v-card>
                            </td>
                            <td>
                                <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                                    <p class="display-1 text--primary">Receptor</p>
                                    <div class="body-1">
                                        Razon Social: {{ itemFind.receptor.nombre }}
                                    </div>
                                    <div class="body-1">RFC: {{ itemFind.receptor.rfc }}</div>
                                    <div class="body-1">
                                        Residencia: {{ itemFind.receptor.residencia }}
                                    </div>
                                    <div class="body-1">
                                        Uso CFDI: {{ itemFind.receptor.usocfdi }}
                                    </div>
                                </v-card>
                            </td>
                        </tr>
                    </template>
                </v-simple-table>
                <br />
                <v-label>Folio Fiscal: {{ itemFind.id }}</v-label>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="retrieveXML(itemFind)" icon color="blue" v-on="on">
                            <v-icon>mdi-xml</v-icon>
                        </v-btn>
                    </template>
                    <span>Descargar XML</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="retrievePDF(itemFind)" icon color="red" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="ConsultarEstadoDoc(itemFind)" icon color="orange" v-on="on">
                            <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Consultar Estado del Documento</span>
                </v-tooltip>
                <v-btn
                    v-if="itemFind.fecha_cancelacion == null && (itemFind.tipo_comprobante == 'I' || itemFind.tipo_comprobante == 'E')"
                    @click="ConsultarExpediente(itemFind)" color="primary">
                    Expediente
                </v-btn>
                <br />

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Serie</th>
                                <th>Folio</th>
                                <th>Tipo Comprobante</th>
                                <th>Método de Pago</th>
                                <th>Forma de Pago</th>
                                <th>Lugar Expedición</th>
                                <th>Fecha Emisión</th>
                                <th>Fecha Timbre</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="altura">
                                <td>{{ itemFind.serie }}</td>
                                <td>{{ itemFind.folio }}</td>
                                <td>{{ tipoComp(itemFind.tipo_comprobante) }}</td>
                                <td>{{ itemFind.metodo_pago }}</td>
                                <td>{{ itemFind.forma_pago }}</td>
                                <td>{{ itemFind.lugar_expedicion }}</td>
                                <td>{{ formatFecha(itemFind.fecha_emision) }}</td>
                                <td>{{ formatFecha(itemFind.fecha_timbre) }}</td>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <br />
                <p class="font-weight-black">Totales:</p>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-right">Tipo Cambio</th>
                                <th class="text-left">Moneda</th>
                                <th class="text-right">Total Retenciones</th>
                                <th class="text-right">Total Traslados</th>
                                <th class="text-right">Subtotal</th>
                                <th class="text-right">Descuento</th>
                                <th class="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="altura">
                                <td align="right">
                                    {{ itemFind.tipo_cambio }}
                                </td>
                                <td>{{ itemFind.moneda }}</td>
                                <td align="right">
                                    {{ currencyFormat(itemFind.total_retenciones) }}
                                </td>
                                <td align="right">
                                    {{ currencyFormat(itemFind.total_traslados) }}
                                </td>
                                <td align="right">
                                    {{ currencyFormat(itemFind.subtotal) }}
                                </td>
                                <td align="right">
                                    {{ currencyFormat(itemFind.descuento) }}
                                </td>
                                <td align="right">{{ currencyFormat(itemFind.total) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialogGuid = false">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
    props: {
        itemFind: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDialogGuid: false,
            showDialogStatusMain: false,
            selectedStatusMain: null,
            itemSelectedMain: {
                id: "",
                emisor_rfc: "",
                emisor_nombre: "",
                regimen: "",
                receptor_nombre: "",
                residencia: "",
                receptor_rfc: "",
                usocfdi: "",
                fecha_cancelacion: "",
                fecha_carga: "",
                fecha_emision: "",
                fecha_timbre: "",
                folio: "",
                forma_pago: "",
                descuento: 0,
                lugar_expedicion: "",
                metodo_pago: "",
                moneda: "",
                serie: "",
                subtotal: 0,
                tipo: "",
                tipo_cambio: 1,
                tipo_comprobante: "",
                total: 0,
                total_retenciones: 0,
                total_traslados: 0,
                retencion_isr: 0,
                retencion_iva: 0,
                retencion_ieps: 0,
                traslado_iva: 0,
                traslado_ieps: 0,
            },
            showExpediente: false,
            cfdiExp: {},
            relatedExp: [],
            paymentsExp: [],
            whereRelatedExp: [],

        };
    },
    methods: {
        tipoComp(value) {
            return helpers.getTipoComprobante(value);
        },
        formatFecha(fecha) {
            return helpers.formatFecha(fecha);
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (item.tipo_comprobante === "R")
                par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
            else par = item.tipo + "|" + item.pac + "|";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        ConsultarEstadoDoc(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
                issuer: item.emisor.rfc,
                receiver: item.receptor.rfc,
                total: item.total,
            };

            service
                .api()
                .Consult(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.selectedStatusMain = JSON.parse(response.payload);
                        this.itemSelectedMain = item;
                        this.showDialogStatusMain = true;
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);

                    }
                });
        },
        ConsultarExpediente(item) {

            //      this.showDialogGuid = false;
            //    this.showDialogSearchGuid = false;

            var jsonData = {
                token: this.$store.state.token,
               // context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
                //issuer: item.emisor.rfc,
                //receiver: item.receptor.rfc,
                //total: item.total,
            };

            service
                .api()
                .SearchExpedient(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        let expediente = JSON.parse(response.payload);
                        this.cfdiExp = expediente.cfdi;
                        this.relatedExp = expediente.related;
                        this.paymentsExp = expediente.payments;
                        this.whereRelatedExp = expediente.whereRelated;
                        this.showExpediente = true;


                    } else {

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);

                    }


                });



        },
        ObtenerPDF() {


            this.isLoading = true;
            this.cfdiExp.owner_rfc = this.$store.state.entity;
            this.cfdiExp.owner_nombre = this.$store.state.userLongName;
            this.cfdiExp.forma_pago = this.itemFind.forma_pago;
            this.cfdiExp.fecha_timbre = this.itemFind.fecha_timbre;
            this.cfdiExp.descuento = this.itemFind.descuento;
            this.cfdiExp.total_traslados = this.itemFind.total_traslados;
            this.cfdiExp.total_retenciones = this.itemFind.total_retenciones;

            var jsonData = {
                token: this.$store.state.token,
                cfdi: this.cfdiExp,
                related: this.relatedExp,
                payments: this.paymentsExp,
                whereRelated: this.whereRelatedExp
            };
            service
                .api()
                .ExpedientPDF(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);


                        helpers.ToPDF(response.payload, this.cfdiExp.id + "_Expediente.pdf");


                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
            this.isLoading = false;
        },

    }
};
</script>