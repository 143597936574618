<template>
  <div class="home">
    <Retenciones titulo="Retenciones - Emitidas Canceladas" contexto="E" esCancelado=true />
  </div>
</template>

<script>

import Retenciones from "./Retenciones.vue";

export default {
  components: {
    Retenciones
  }
};
</script>