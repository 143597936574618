<template>
  <div class="home">
    <Relacionados titulo="Análisis de CFDI vs CFDI's Relacionados - Emitidos" contexto="E"/>
  </div>
</template>

<script>

import Relacionados from "./Relacionados";

export default {
  components: {
    Relacionados
  }
};
</script>