<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Usuario</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field v-model="usuario" counter="0" label="Usuario" class="my-1"
                      @input="checkForSpaces"></v-text-field>
                  </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="nombres" counter="0" label="Nombre(s)" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field v-model="apellidos" counter="0" label="Apellidos" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field v-model="correo" counter="0" label="Correo" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esAdmin" label="Administrador"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esFacturacion" label="Usuario Facturación"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esNomina" label="Usuario Nómina"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esVerE" label="Ver Emitidos"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esVerR" label="Ver Recibidos"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text large v-on:click="crearUsuario" :disabled="!showCreateButton">Crear
                Usuario</v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogEditar" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEditar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Editar Usuario</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field v-model="usuarioE" counter="0" label="Usuario" class="my-1" disabled></v-text-field>
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="nombresE" counter="0" label="Nombre(s)" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field v-model="apellidosE" counter="0" label="Apellidos" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field v-model="correoE" counter="0" label="Correo" class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esAdminE" label="Administrador"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esFacturacionE" label="Usuario Facturacion"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esNominaE" label="Usuario Nomina"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esVerE" label="Ver Emitidos"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="esVerR" label="Ver Recibidos"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text large v-on:click="actualizarUsuario">Actualizar Usuario</v-btn>
              <v-btn color="danger" text @click="showDialogEditar = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12" md="11">
          <h3>Administración de Usuarios
          </h3>
        </v-col>
        <v-col cols="12" md="1">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2" fab dark large color="primary" v-on="on" @click="showDialogCrear = true">
                <v-icon dark>mdi-account-plus </v-icon>
              </v-btn>

            </template>
            <span>Crear Usuario</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>




            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="'Usuario'" :binding="'user_name'" width="4*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Nombre'" :binding="'first_names'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Apellidos'" :binding="'last_names'" width="4*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Correo'" :binding="'email'" width="2*" :isReadOnly="true" />



              <wj-flex-grid-column :header="'Es Administrador'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.admin ? "Si" : "No" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Usuario Facturación'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.invoice ? "Si" : "No" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Usuario Nómina'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.payroll ? "Si" : "No" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Ver Emitidos'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.allowIssued ? "Si" : "No" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Ver Recibidos'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.allowReceived ? "Si" : "No" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>

              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="showEditUsuario(cell.item)" icon color="primary" v-on="on">
                        <v-icon>mdi-account-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Usuario</span>
                  </v-tooltip>

                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>


          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../scripts/helpers.js";
import service from "../scripts/services.js";

export default {
  name: "Conciliatorio",
  data: () => ({
    items: [],



    usuario: "",
    nombres: "",
    apellidos: "",
    correo: "",
    esAdmin: false,
    esNomina: false,
    esFacturacion: false,
    usuarioE: "",
    nombresE: "",
    apellidosE: "",
    correoE: "",
    esAdminE: false,
    esNominaE: false,
    esFacturacionE: false,
    esVerE: false,
    esVerR: false,
    showDialogCrear: false,
    showDialogEditar: false,
    selectedItem: {},
  }),
  created() {
    this.getReporteItems();
  },
  methods: {

    checkForSpaces() {
      this.usuario = this.usuario.replace(/\s/g, "");

    },
    initGrid: function (grid) {




      grid.columns[grid.columns.length - 1].width = 200;


    },
    crearUsuario() {
      var jsonData = {
        user_name: this.usuario,
        first_names: this.nombres,
        last_names: this.apellidos,
        email: this.correo,
        payroll_user: this.esNomina,
        admin_user: this.esAdmin,
        invoice_user: this.esFacturacion,
        issued_user: this.esVerE,
        received_user: this.esVerR,
        token: this.$store.state.token,
      };
      this.showDialogCrear = false;
      service
        .api()
        .CreateUser(jsonData)
        .then((response) => {
          if (response.result) {
            this.getReporteItems();


            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Usuario Creado Correctamente");
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }

          this.usuario = "";
          this.nombres = "";
          this.apellidos = "";
          this.correo = "";
          this.esAdmin = false;
          this.esFacturacionE = false;
          this.esNomina = false;
        });
    },
    showEditUsuario(item) {
      this.showDialogEditar = true;
      this.selectedItem = item;
      this.usuarioE = item.user_name;
      this.nombresE = item.first_names;
      this.apellidosE = item.last_names;
      this.correoE = item.email;
      this.esNominaE = item.payroll;
      this.esFacturacionE = item.invoice;
      this.esAdminE = item.admin;
      this.esVerE = item.allowIssued;
      this.esVerR = item.allowReceived;
    },

    actualizarUsuario() {
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      var jsonData = {
        rights: this.selectedItem.rights,
        user_name: this.usuarioE,
        first_names: this.nombresE,
        last_names: this.apellidosE,
        email: this.correoE,
        payroll_user: this.esNominaE,
        admin_user: this.esAdminE,
        invoice_user: this.esFacturacionE,
        issued_user: this.esVerE,
        received_user: this.esVerR,
        token: this.$store.state.token,
      };
      this.showDialogEditar = false;
      service
        .api()
        .EditUser(jsonData)
        .then((response) => {
          if (response.result) {
            this.getReporteItems();

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Usuario Actualizado Correctamente.");
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }

          this.usuarioE = "";
          this.nombresE = "";
          this.apellidosE = "";
          this.correoE = "";
          this.esAdminE = false;
          this.esFacturacionE = false;
          this.esNominaE = false;
          this.esVerE = false;
          this.esVerR = false;
        });
    },
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
      };
      service
        .api()
        .ShowList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
  },
  computed: {
    showCreateButton() {
      return (
        this.usuario.length > 0 &&
        this.nombres.length > 0 &&
        this.apellidos.length > 0 &&
        this.correo.length > 0
      );
    },
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}
</style>
