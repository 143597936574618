<template>
  <v-container fluid fill-height id="backg">

    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>

        <v-form>
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Autenticación</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field @keydown.space.prevent label="RFC" prepend-icon="mdi-account-box" type="text"
                v-model="loginRFC" v-on:input="putUpper" :rules="rulesRFC" maxlength="13"
                @keyup.enter="loginMethod()"></v-text-field>
              <v-text-field @keydown.space.prevent label="Usuario" prepend-icon="mdi-account-settings" type="text"
                v-model="loginUser" :rules="rulesUsuario" @keyup.enter="loginMethod()"></v-text-field>
              <v-text-field id="password" label="Contraseña" name="password" prepend-icon="mdi-account-key"
                type="password" v-model="loginPass" :rules="rulesPassword" @keyup.enter="loginMethod()"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <span style="color: red;" v-if="dialog">
                <strong>Error al Autenticar:</strong> {{ messageDialog }}

              </span>

              <v-btn color="primary" :disabled="!valid" @click="loginMethod" class="mx-1 white--text">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import service from "../scripts/services.js";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      conFiel: false,
      validRetrieve: false,
      passRetrieveDialog: false,
      dialog: false,
      messageDialog: "",


      loginRFC: "",
      loginUser: "",
      loginPass: "",
      accountName: "",
      rulesRFC: [(v) => !!v || "RFC del Proveedor es un campo requerido."],
      rulesUsuario: [(v) => !!v || "Usuario es un campo requerido."],
      rulesPassword: [(v) => !!v || "La contraseña es un campo requerido."],
      providerNameRules: [(v) => !!v || "Nombre del Proveedor es requerido"],
      contactNameRules: [
        (v) => !!v || "Nombre y Apellidos del Contacto son requeridos",
      ],
      userNamesRules: [
        (v) => !!v || "Nombre y Apellidos del Usuario son requeridos",
      ],
      contactPhoneRules: [(v) => !!v || "Teléfono de Contacto es requerido"],
      rolRules: [(v) => !!v || "El Tipo de Proveedor es requerido"],
      emailRules: [
        (v) => !!v || "Correo Electrónico es requerido",
        (v) =>
          /.+@.+/.test(v) ||
          "Debe ser una dirección de correo electrónico válido.",
      ],
    };
  },
  methods: {
    foo() {
      return true;
    },
    putUpper() {
      this.loginRFC = this.loginRFC.toUpperCase();
    },
    FielManagerRemove() {
      var jsonData = {
        Upsert: false,
        Cert: "",
        Key: "",
        Password: "",
        token: this.$store.state.token,
      };

      service
        .api()
        .ManageFiel(jsonData)
        .then((response) => {
          if (response.result) {

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "FIEL Eliminada Correctamente");
            setTimeout(() => {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setOverlay", false);
            }, 2000);
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "Error al eliminar FIEL");
            this.$store.commit("setOverlay", false);
          }
        });
    },
    loginMethod() {
      let loginJSON = {
        rfc: this.loginRFC.trim(),
        usuario: this.loginUser.trim(),
        contrasena: this.loginPass,
      };
      this.$store.commit("setOverlay", true);
      service
        .api()
        .Login(loginJSON)
        .then((response) => {
          this.$store.commit("setOverlay", false);
          this.$store.commit("setUserName", this.loginUser);
          this.HandleResponse(response.data);
        })
        .catch((err) => {
          window.console.log(err);
        });
      return true;
    },
    getAccountInfo(userToken) {
      //this.$store.commit("setShowDrawer", true);
      var jsonData = {
        token: userToken,
      };

      service
        .api()
        .AccountInfo(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let pLoad = JSON.parse(response.payload);
            this.$store.commit("setHasFiel", pLoad.HasFiel);
            this.conFiel = pLoad.HasFiel;

            this.$store.commit(
              "setFielExpirationDate",
              pLoad.FielExpirationDate
            );
            this.$store.commit("setHasAutSatDownload", pLoad.HasAutSatDownload);
            if (pLoad.HasFiel) {
              let userFielDate = new Date(pLoad.FielExpirationDate);
              let nowDate = new Date();
              if (userFielDate.getTime() < nowDate.getTime()) {
                this.FielManagerRemove();
                this.conFiel = false;

                this.$store.commit("setAlertOk", false);
                this.$store.commit("setAlertError", true);
                this.$store.commit("setAlertMessage", "FIEL vencida");

                if (this.$store.state.idContratoZoho) {
                  if (!this.$store.state.isAdministrator) {
                    this.$router.push({
                      path: "/DesacargaAutomatica/GuardarFIEL",
                    });
                  }
                  else {
                    this.$store.commit("setShowDrawer", false);
                    this.$store.commit("setToken", "");
                    this.$store.commit("setEntity", null);
                    this.$store.commit("setUserLongName", "");
                    this.$store.commit("setAlertError", true);
                    this.$store.commit("setAlertMessage", "No existe FIEL registrada para esta cuenta. Favor de subir FIEL con una cuenta administrador");

                    return;
                  }
                }
              }
              else {
                if (this.$store.state.idContratoZoho) {


                  if (this.$store.state.firmadoZoho) {
                    this.$store.commit("setShowDrawer", true);
                    this.$store.commit("setMostrarHeader", true);
                    this.$store.commit("setShowBanner", true);


                    this.$router.push({
                      path: "/Miscelanea/Cancelados",
                    });
                  }
                  else {
                    this.$store.commit("setIsContractAccepted", false);
                    this.$router.push({
                      //path: "/",
                      path: "/ContratoServicios",
                    });
                  }



                  return;
                }
              }
            }
            else {
              if (!this.$store.state.isAdministrator) {
                this.$store.commit("setShowDrawer", false);
                this.$store.commit("setToken", "");
                this.$store.commit("setEntity", null);
                this.$store.commit("setUserLongName", "");
                this.$store.commit("setAlertOk", false);

                this.$store.commit("setAlertError", true);
                this.$store.commit("setAlertMessage", "No existe FIEL registrada para esta cuenta. Favor de subir FIEL con una cuenta administrador");

                return;
              }


              if (this.$store.state.idContratoZoho) {
                this.$router.push({
                  path: "/DesacargaAutomatica/GuardarFIEL",
                });

                return;
              }

            }
            if (this.$store.state.isAdministrator) {
              this.getContractInfo(userToken);

              this.$store.commit("setShowDrawer", true);
            }
            else {
              this.$store.commit("setShowDrawer", true);
              this.$store.commit("setMostrarHeader", true);
              this.$store.commit("setShowBanner", true);
              this.$router.push({
                path: "/Miscelanea/Cancelados",
              });
            }
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getContractInfo(userToken) {

      let jsonData = {
        cert: "",
        key: "",
        password: "",
        token: userToken,
      };
      service
        .api()
        .RetrieveAlmacenarLegalDoc(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            let pLoad = JSON.parse(response.payload);
            let legal = JSON.parse(pLoad.LegalDocument);
            if (legal[0].Documento != null) {
              this.$store.commit("setIsContractAccepted", true);
              if (this.conFiel) {
                this.$store.commit("setShowDrawer", true);
                this.$store.commit("setMostrarHeader", true);
                this.$store.commit("setShowBanner", true);
                this.$router.push({
                  path: "/Miscelanea/Cancelados",
                });
              } else {
                this.$router.push({
                  path: "/DesacargaAutomatica/GuardarFIEL",
                });
              }
            }
            else {
              service
                .api()
                .RetrieveLegalDoc(jsonData)
                .then((response) => {
                  if (response.result) {
                    this.$store.commit("setOverlay", false);
                    let pLoad = JSON.parse(response.payload);
                    let legal = JSON.parse(pLoad.LegalDocument);
                    if (legal[0].Documento != null) {
                      this.$store.commit("setIsContractAccepted", true);
                      if (this.conFiel) {
                        this.$store.commit("setShowDrawer", true);
                        this.$store.commit("setMostrarHeader", true);
                        this.$store.commit("setShowBanner", true);
                        this.$router.push({
                          path: "/Miscelanea/Cancelados",
                        });
                      } else {
                        this.$router.push({
                          path: "/DesacargaAutomatica/GuardarFIEL",
                        });
                      }
                    }
                    else {
                      this.$store.commit("setIsContractAccepted", false);
                      this.$router.push({
                        //path: "/",
                        path: "/ContratoServicios",
                      });
                    }
                  } else {
                    this.$store.commit("setAlertOk", false);
                    this.$store.commit("setAlertError", true);
                    this.$store.commit("setAlertMessage", response.errorMessage);
                  }
                });
            }
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });

    },
    HandleResponse(data) {
      this.$store.commit("setOverlay", false);
      let responseJSON = null;
      responseJSON = data;
      if (responseJSON.result) {
        this.$store.commit("setToken", responseJSON.token);
        let payload = JSON.parse(responseJSON.payload);

        this.$store.commit("setEntity", payload.id);
        this.$store.commit("setUserLongName", payload.companyName);
        this.$store.commit("setAccountType", payload.accountType);
        this.$store.commit("setIsAdministrator", payload.isAdministrator);
        this.$store.commit("setIsPayroll", payload.isPayroll);
        this.$store.commit("setIsInvoice", payload.isInvoice);
        this.$store.commit("setAllowIssued", payload.allowIssued);
        this.$store.commit("setAllowReceived", payload.allowReceived);
        this.$store.commit("setPartitionSchema", payload.partitionSchema);


        if (payload.contractInfoZoho) {
          let contractInfoZoho = payload.contractInfoZoho;

          this.$store.commit("setIdContratoZoho", contractInfoZoho.idContrato);
          this.$store.commit("setFirmadoZoho", contractInfoZoho.firmado);
        }

        this.accountName = payload.companyName;
        this.getAccountInfo(responseJSON.token);
      } else {
        if (responseJSON.errorCode === 100 || responseJSON.errorCode === 101) {
          this.$router.push({
            path: "/",
          });
        } else {
          this.$store.commit("setAlertOk", false);
          this.dialog = true;
          this.messageDialog = responseJSON.errorMessage;
        }
      }
    },
  },
  computed: {
    valid() {
      return (
        this.loginPass.length < 40 &&
        this.loginPass.length > 4 &&
        this.loginUser.length > 4 &&
        this.loginRFC.length > 11
      );
    },
  },
  mounted() {
    this.$store.commit("setToken", "");
    this.$store.commit("setEntity", null);
    this.$store.commit("setOverlay", false);
    this.$store.commit("setShowDrawer", false);
    this.$store.commit("setAccountType", "");
    this.$store.commit("setUserName", "");
    this.$store.commit("setUserLongName", "");
    this.$store.commit("setFielExpirationDate", "");
    this.$store.commit("setHasFiel", false);
    this.$store.commit("setHasAutSatDownload", false);
    this.$store.commit("setIsAdministrator", false);
  },
};
</script>

<style>
#backg {
  background-image: url("../assets/MYSuite-XData.png");
  background-size: 100%;
}
</style>
