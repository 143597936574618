<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row>

        <v-col cols="12">
          <h3>Retenciones por fecha de cancelación

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/retenciones-por-fecha-de-cancelación"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
          <h5>En estos momentos el servicio del SAT de descarga masiva de comprobantes de retenciones no funciona.
            Lo hemos reportado a la mesa de ayuda del SAT y estamos a la espera de su respuesta.
            Mientras continúe esta situación, XDATA no podrá actualizar la información de comprobantes de retenciones de
            sus clientes.
            En cuanto esté corregido, en automático dicha información se actualizará y daremos aviso a nuestros
            clientes.</h5>
          <h5>
            Agradecemos su comprensión.</h5>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select :items="itemsYears" v-model="periodoModel" label="Año de emisión del comprobante"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates" :disabled="dateTs.length > 0"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" @change="items = []"
                  label="Contexto" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar
                      Listado</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteRetencionesCSV" v-on="on"
                  :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">

          
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor.nacional.rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'emisor.rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor.nacional.nombre'" width="4*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'emisor.nombre'" width="4*"
                :isReadOnly="true" />


              
              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Fecha Cancelación'" :binding="'fecha_cancelacion'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Timbre'" :binding="'fecha_timbre'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Clave Retencion'" :binding="'cve_retenc'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio Interno'" :binding="'folio_int'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Total Gravado'" :binding="'totales.gravado'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Exento'" :binding="'totales.exento'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Retenido'" :binding="'totales.retenido'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'totales.total'" width="2*" format="c2"
                :isReadOnly="true" />
                <wj-flex-grid-column :header="'PAC'" :binding="'PAC'" width="2*" :isReadOnly="true" />
                <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                  
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>




          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "Retenciones",
  components: {
    DateRangePicker
  },
  data: () => ({
    selectedDates: [],
    selectedRows: [],
    showCantidades: false,
    showDialog: false,
    showDialogStatus: false,
    periodoModel: new Date().getFullYear(),
    goPage: 1,
    selectedStatus: {
      estado: "",
      cancelable: "",
      estado_cancelacion: "",
    },
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalTraslados: 0,
    totalPagos: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    tipoDoc: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso", "Traslado", "Pago"],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    rfcSearch: "",
    dateTs: "",
    itemsYears: [],
    contexto: "Emitidos"
  }),
  created() {
    this.$store.commit("setIsRetencion", true);
    this.fillItems();
    this.$store.commit("setAlertError", false);
    this.errorMessage = "";

    let dataInterval = this.$store.state.dateInterval;

    if (dataInterval === null) return;

    this.selectedDates[0] = dataInterval.dateInit;
    this.selectedDates[1] = dataInterval.dateEnd;
    this.dateTs = dataInterval.dateTs;
    this.$store.commit("setDateInterval", null);

    this.getTotalCount();
  },
  methods: {

    initGrid: function (grid) {

      for (let i = 1; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }




      grid.columns[grid.columns.length - 1].width = 200;


    },
    fillItems() {
      var i;
      for (i = this.periodoModel - 5; i <= this.periodoModel; i++)
        this.itemsYears.push(i);
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substr(0, 1),
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R") par = item.tipo + "||" + item.emisor.rfc;
      else par = item.tipo + "||";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision + "|RET";
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
    verMasDatos(item) {
      this.itemSelected = item;
      this.showDialog = true;
    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: this.contexto.substr(0, 1) === "E" ? "RFC Receptor" : "RFC Emisor",
        NombreE: this.contexto.substr(0, 1) === "E" ? "Razon Social Receptor" : "Razon Social Emisor",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.rfc),
          NombreE: helpers
            .ConvertToEmpty(item.nombre)
            .replace(/,/g, " "),
          Tipo: this.contexto,
          TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.PAC),
        });
      });

      let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Fecha Final Cancelacion": this.selectedDates[1],
        "Fecha Inicio Cancelacion": this.selectedDates[0],
        "Año Documento": this.periodoModel,
        "Fecha de Solicitud": helpers.FechaActual(),
        "Reporte": "Listado Retenciones por Fecha de Cancelación"
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Retenciones_Conciliatorio_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.selectedDates[0]);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];

      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substr(0, 1),
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offSet: this.offest,
        limit: this.limit,
      };

      service
        .api()
        .ReporteRetencionesFechaCancelacion(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substr(0, 1),
      };

      service
        .api()
        .ReporteRetencionesCountFechaCancelacion(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteRetencionesCSV() {
      var jsonData = {
        token: this.$store.state.token,
        issuedYear: this.periodoModel,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substr(0, 1),
      };

      service
        .api()
        .ReporteRetencionesCSVFechaCancelacion(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    cerrarStatus() {
      this.showDialogStatus = false;
      if (this.selectedStatus.estado == "Cancelado") this.getReport();
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
  },
  computed: {
    mostrarAgignarEtiquetas() {
      return this.selectedItems.length > 0;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto.substr(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substr(0, 1) === "R";
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },
    labelRfc() {
      return "RFC Receptor";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
