<template>
  <div>
    <v-row>
    <v-col cols="6">
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="200px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="firstDate" label="Fecha Inicial*" prepend-icon="mdi-calendar-month" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker :min="fechaInicial" v-model="firstDate" @change="handleFirstDateChange" locale="es"
              @input="menu1 = false"></v-date-picker>
          </v-menu>
      </v-col>
      <v-col cols="6">
          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="200px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="secondDate" label="Fecha Final*" 
                prepend-icon="mdi-calendar-month" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker :min="firstDate" :max="maxSecondDate" v-model="secondDate" locale="es" :disabled="!firstDate"
              @input="menu2 = false" ></v-date-picker>
          </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    fechaLimiteInferior: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      firstDate: null,
      secondDate: null
    };
  },
  computed: {
    minSecondDate() {
      return this.firstDate;
    },
    fechaInicial() {
      return helpers.getFechaLimiteInferior(this.fechaLimiteInferior);
    },
    maxSecondDate() {
      if (this.firstDate) {
        const year = this.firstDate.substr(0, 4);
        return year + "-12-31";
      }
      return null;
    }
  },
  watch: {
    value(newValue) {
      if (newValue && newValue.length >= 2) {
        this.firstDate = newValue[0];
        this.secondDate = newValue[1];
      }
    },
    firstDate(newDate) {
      if (this.secondDate === null) {
        this.secondDate = newDate;
      }
    },
    secondDate(newDate) {
      this.$emit('input', [this.firstDate, newDate]);
    }
  },
  methods: {
    handleFirstDateChange() {
      this.secondDate = this.firstDate; // Asignar la primera fecha a la segunda
    },
    areDatesEqual() {
      return this.firstDate === this.secondDate;
    },
    limpiarFechas(){
      this.firstDate = null;
      this.secondDate =  null;
    }
  }
};
</script>