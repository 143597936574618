<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row justify="center"> </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Reporte de Precios de Productos y Servicios
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-de-precios-de-productos-y-servicios"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
          <h4 v-if="showSugerencia">{{ mensajeSugerencia }}</h4>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" pa="4" md="2" justify="center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" type="month" label="Período" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker class="min-width-control-xl" v-model="date" locale="es" min="2020/12" type="month"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="d-flex" cols="12" md="2" lg="2">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" md="1" lg="1">
              </v-col>
              <v-col cols="12" md="1" lg="1">
                <v-btn color="primary" white large v-on:click="getReport">Mostrar Listado</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ conceptosPagina }} registros de
              {{ totalConceptos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteProdServCSV" v-on="on"
                  :disabled="totalConceptos > 5000" color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                    color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                    color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>



            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :allowResizing="'None'">

              <wj-flex-grid-column :header="'Clave Prod Serv'" :binding="'ClaveProdServ'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Descripción'" :binding="'ClaveProdServDesc'" width="6*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Max Valor Unitario(MXN)'" :binding="'MaxValorUnitario'" width="2*"
                format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Min Valor Unitario(MXN)'" :binding="'MinValorUnitario'" width="2*"
                format="c2" :isReadOnly="true" />

            </wj-flex-grid>

          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";

export default {
  name: "ReporteProdServ",
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);

    let particion = this.$store.state.partitionSchema;

    if(particion != null)
      this.particionConceptos = particion.conceptos;

  },
  data: () => ({
    mensajeSugerencia: "",
    particionConceptos:"",
    selectedRows: [],
    date: new Date().toISOString().substr(0, 7),
    contexto: "Emitidos",
    menu: false,
    modal: false,




    search: "",
    items: [],
    itemsCSV: [],
    landscape: false,
    title: "",
    rfcSearch: "",
    goPage: 1,
    offest: 0,
    limit: 50,
    pageSize: "500",
    noPagina: 1,
    totalPaginas: 1,
    totalConceptos: 0,
    paginations: ["100", "200", "500"],
  }),
  methods: {
    rowClicked(index) {
      this.selectedRows = [];
      this.toggleSelection(index);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalConceptos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalConceptos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalConceptos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalConceptos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalConceptos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        context: this.contexto.substring(0, 1),
        offset: this.offest,
        limit: this.limit,
      };
      service
        .api()
        .ClaveProdServList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.conceptosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        context: this.contexto.substring(0, 1),
      };

      service
        .api()
        .ClaveProdServCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalConceptos = payload;
            this.totalPaginas = Math.ceil(this.totalConceptos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    exportExcel() {
      var headers = {
        ClaveProdServ: "ClaveProdServ",
        MaxValorUnitario: "MaxValorUnitario",
        MinValorUnitario: "MinValorUnitario",
      };
      var itemsFormatted = [];
      this.items.forEach((item) => {
        itemsFormatted.push({
          ClaveProdServ: helpers.ConvertToEmpty(item.ClaveProdServ),
          MaxValorUnitario: helpers.ConvertToEmpty(item.MaxValorUnitario),
          MinValorUnitario: helpers.ConvertToEmpty(item.MinValorUnitario),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Reporte_" +
        (this.contexto.substring(0, 1) === "E" ? "Emitidos" : "Recibidos") +
        "_Periodo_" +
        this.date +
        "_Precios_Productos_Servicios_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    getReporteProdServCSV() {
      if (this.totalPaginas == 1) {
        this.exportExcel();
      } else {
        var jsonData = {
          token: this.$store.state.token,
          dateInit: this.date + "-01",
          dateEnd: this.date + "-31",
          context: this.contexto.substring(0, 1),
        };

        service
          .api()
          .ClaveProdServCSV(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.itemsCSV = JSON.parse(response.payload);
              JSONToCSV(
                this.itemsCSV,
                "Reporte_" +
                (this.contexto.substring(0, 1) === "E" ? "Emitidos" : "Recibidos") +
                "_Periodo_" +
                this.date +
                "_Precios_Productos_Servicios_" +
                helpers.dateNowCSV().replace(/-/g, "_"),
                true
              );

              this.$store.commit("setAlertError", false);
              this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Archivo CSV generado exitosamente");


            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
  },
  computed: {
    showSugerencia() {

      if (this.particionConceptos == null || this.particionConceptos == "")
        return false;

      switch (this.particionConceptos) {
        case "MES":
          this.mensajeSugerencia = "Se debe realizar la solicitud con rango del mismo mes";
          break;
        case "DIA":
          this.mensajeSugerencia = "Se debe realizar la solicitud con rango del mismo día";
          break;
      }

      return true;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}

.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
