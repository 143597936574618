import Axios from "axios";
import router from "../router/router";
import store from "../store/store";

export default {
  api() {
    var baseUrl = "";
 //var mysuiteBaseUrl = "https://mysuiteanalyticstestapi.azurewebsites.net/api/";
  var mysuiteBaseUrl = "https://mysuiteanalyticsapi.azurewebsites.net/api/"
    //funciones privadas
    let handleTokenExpiration = function (response) {
      store.commit("setOverlay", false);
      if (!response.result) {
        if (response.errorCode === 100 || response.errorCode === 101) {
          router.push({
            path: "/"
          });
        }
      } else {
        store.commit("setToken", response.token);
      }
    };
    //funciones publicas

    return {
      MysuiteBaseUrl() {
        return mysuiteBaseUrl;
      },
      Login(bodyData) {
        return Axios.post(mysuiteBaseUrl + "Auth/Login", bodyData);
      },

      async executePostV1(bodyData, routePath) {
        var jsonResponse = {};
        await Axios.post(mysuiteBaseUrl + routePath, bodyData)
          .then(response => {
            jsonResponse = response.data;
          })
          .catch(error => {
            jsonResponse = {
              result: false,
              errorMessage: error,
              errorCode: 0
            };
          });
        return jsonResponse;
      },
      async executePost(bodyData, routePath) {
        var jsonResponse = {};
        store.commit("setOverlay", true);
        await Axios.post(mysuiteBaseUrl + routePath, bodyData)
          .then(response => {
            jsonResponse = response.data;
            handleTokenExpiration(jsonResponse);
          })
          .catch(error => {
            store.commit("setOverlay", false);
            jsonResponse = {
              result: false,
              errorMessage: error,
              errorCode: 0
            };
          });
        return jsonResponse;
      },

      async ReporteConciliatorioDuplicadoTotalData(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/TotalData');
      },
      async ReporteConciliatorioDuplicado(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Lista');
      },
      async ReporteConciliatorioCountDuplicado(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Conteo');
      },
      async ReporteConciliatorioCSVDuplicado(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/CSV');
      },
      async ReporteConciliatorioDetallesDuplicado(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Details');
      },
      async ReporteConciliatorioSetMarkDuplicado(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/SetMark');
      },


      async ReporteConciliatorioDuplicadoTotalDataV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/TotalData/V2');
      },
      async ReporteConciliatorioDuplicadoV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Lista/V2');
      },
      async ReporteConciliatorioCountDuplicadoV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Conteo/V2');
      },
      async ReporteConciliatorioCSVDuplicadoV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/CSV/V2');
      },
      async ReporteConciliatorioDetallesDuplicadoV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/Details/V2');
      },
      async ReporteConciliatorioSetMarkDuplicadoV2(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Duplicated/SetMark/V2');
      },

      async ReporteConciliatorio(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Lista');
      },
      async ReporteConciliatorioCount(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Conteo');
      },
      async ReporteConciliatorioCSV(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/CSV');
      },
      async ReporteConciliatorioConteoPorDoc(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/ConteoPorDoc');
      },
      async ReporteConciliatorioTotalesPorTipo(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/SumaPorDoc');
      },
      async ReporteConciliatorioBL(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Lista/BL');
      },
      async ReporteConciliatorioCountBL(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Conteo/BL');
      },
      async ReporteConciliatorioCSVBL(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/CSV/BL');
      },
      async ReporteConciliatorioConteoPorDocBL(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/ConteoPorDoc/BL');
      },
      async ReporteConciliatorioFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Lista/Cancelados');
      },
      async ReporteConciliatorioCountFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Conteo/Cancelados');
      },
      async ReporteConciliatorioCSVFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/CSV/Cancelados');
      },
      async ReporteConciliatorioConteoPorDocFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/ConteoPorDoc/Cancelados');
      },
      async ReporteConciliatorioCancelados(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Lista/Cancelados/Metadata');
      },
      async ReporteConciliatorioCountCancelados(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Conteo/Cancelados/Metadata');
      },
      async ReporteConciliatorioCSVCancelados(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/CSV/Cancelados/Metadata');
      },
      async ReporteConciliatorioConteoPorDocCancelados(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/ConteoPorDoc/Cancelados/Metadata');
      },
      async ReporteConciliatorioResumen(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/Resumen');
      },

      async ReporteConciliatorioGrande(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/RequestReport');
      },
      async ReporteConciliatorioRetrieveGrandes(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/RetrieveReports');
      },
      async ReporteConciliatorioRetrieveReport(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/RetrieveReport');
      },
      async ReporteConciliatorioDeleteReport(bodyData) {
        return await this.executePost(bodyData, 'Conciliatorio/DeleteReport');
      },



      async DescargaMasivaRequest(bodyData) {
        return await this.executePost(bodyData, 'DMXDATA/Request');
      },
      async DescargaMasivaReportes(bodyData) {
        return await this.executePost(bodyData, 'DMXDATA/Requests');
      },
      async DescargaMasivaLogs(bodyData) {
        return await this.executePost(bodyData, 'DMXDATA/Logs');
      },
      async DescargaMasivaObtenerZips(bodyData) {
        return await this.executePost(bodyData, 'DMXDATA/Zips');
      },
      async DescargaMasivaDescargarZip(bodyData) {
        return await this.executePost(bodyData, 'DMXDATA/Zip/Download');
      },




      async Consult(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Consult');
      },
      async NominaLista(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Lista');
      },
      async NominaCount(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Conteo');
      },
      async NominaCSV(bodyData) {
        return await this.executePost(bodyData, 'Payroll/CSV');
      },
      async NominaListaFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Lista/Cancelados');
      },
      async NominaCountFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Conteo/Cancelados');
      },
      async NominaCSVFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Payroll/CSV/Cancelados');
      },
      async NominaDetalleLista(bodyData) {
        return await this.executePost(bodyData, 'Payroll/DetailsLista');
      },
      async NominaDetalleCount(bodyData) {
        return await this.executePost(bodyData, 'Payroll/DetailsConteo');
      },
      async NominaDetalleMontos(bodyData) {
        return await this.executePost(bodyData, 'Payroll/DetailsAmount');
      },
      async NominaDetalleCSV(bodyData) {
        return await this.executePost(bodyData, 'Payroll/DetailsCSV');
      },
      async NominaDesgloseCSV(bodyData) {
        return await this.executePost(bodyData, 'Payroll/BreakDownCSV');
      },
      async NominaDetalleFile(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Detail');
      },
      async NominaTotalCashflow(bodyData) {
        return await this.executePost(bodyData, 'Payroll/Importe');
      },
      async RequestNominaReport(bodyData) {
        return await this.executePost(bodyData, 'Payroll/RequestReport');
      },
      async RetrieveNominaReports(bodyData) {
        return await this.executePost(bodyData, 'Payroll/RetrieveReports');
      },
      async RetrieveNominaReport(bodyData) {
        return await this.executePost(bodyData, 'Payroll/RetrieveReport');
      },
      async DeleteNominaReport(bodyData) {
        return await this.executePost(bodyData, 'Payroll/DeleteReport');
      },
      async RetrieveXML(bodyData) {
        return await this.executePost(bodyData, 'CFDI/XML');
      },
      async DownloadDocs(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Donwload');
      },
      async ReporteBreakdown(bodyData) {
        return await this.executePost(bodyData, 'BreakDown/Lista');
      },
      async ReporteBreakdownCount(bodyData) {
        return await this.executePost(bodyData, 'BreakDown/Conteo');
      },
      async ReporteBreakdownCSV(bodyData) {
        return await this.executePost(bodyData, 'BreakDown/CSV');
      },
      async ReportePaymentEx(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ListaEx');
      },
      async ReportePaymentNotFound(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ListaCFDINoEncontrados');
      },
      async ReportePaymentNotFoundCount(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ConteoCFDINoEncontrados');
      },
      async ReportePaymentNotFoundCSV(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/CSVCFDINoEncontrados');
      },

      async ReportePaymentVsCFDI(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ListaPagosVsCFDI');
      },
      async ReportePaymentVsCFDICount(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ConteoPagosVsCFDI');
      },
      async ReportePaymentVsCFDICSV(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/CSVPagosVsCFDI');
      },
      async ReportePaymentNotAllowed(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ListaAsociacionesNoPermitidas');
      },
      async ReportePaymentNotAllowedCount(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ConteoAsociacionesNoPermitidas');
      },
      async ReportePaymentNotAllowedCSV(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/CSVAsociacionesNoPermitidas');
      },
      async ReportePagosyRel(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/PagosyRelacionados');
      },
      async IncomePagosyRel(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/PagosyRelacionados');
      },
      async CFDIDetail(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Detail');
      },
      async ReporteCashflow(bodyData) {
        return await this.executePost(bodyData, 'FinancialAnalysis/ListaCashFlow');
      },
      async ReporteCashflowCount(bodyData) {
        return await this.executePost(bodyData, 'FinancialAnalysis/ConteoCashFlow');
      },
      async ReporteCashflowCSV(bodyData) {
        return await this.executePost(bodyData, 'FinancialAnalysis/CSVCashFlow');
      },
      async CashflowTotal(bodyData) {
        return await this.executePost(bodyData, 'FinancialAnalysis/CashFlowAmountMXN');
      },
      async ReporteIncome(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/Lista');
      },
      async ReporteIncomeCount(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/Conteo');
      },
      async ReporteIncomeCSV(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/CSV');
      },
      async ReporteIncomeGrande(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/RequestReport');
      },
      async ReporteIncomeRetrieveGrandes(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/RetrieveReports');
      },
      async ReporteIncomeRetrieveReport(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/RetrieveReport');
      },
      async ReporteIncomeDeleteReport(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/DeleteReport');
      },
      async AgeAnalysis(bodyData) {
        return await this.executePost(bodyData, 'AgeAnalysis/Lista');
      },
      async AgeAnalysisCount(bodyData) {
        return await this.executePost(bodyData, 'AgeAnalysis/Conteo');
      },
      async AgeAnalysisCSV(bodyData) {
        return await this.executePost(bodyData, 'AgeAnalysis/CSV');
      },
      async AgeAnalysisAgrupado(bodyData) {
        return await this.executePost(bodyData, 'AgeAnalysis/Agrupado');
      },
      async ReporteBlackList(bodyData) {
        return await this.executePost(bodyData, 'BlackList/Search');
      },
      async ReporteBlackListDetallado(bodyData) {
        return await this.executePost(bodyData, 'BlackList/CSV/Detail');
      },
      async ReporteBlackListOnDemand(bodyData) {
        return await this.executePost(bodyData, 'BlackList/SearchOnDemand');
      },
      async BlackListDetail(bodyData) {
        return await this.executePost(bodyData, 'BlackList/Detail');
      },
      async BlackListSetRead(bodyData) {
        return await this.executePost(bodyData, 'BlackList/SetReadStatus');
      },
      async BlackListCrono(bodyData) {
        return await this.executePost(bodyData, 'BlackList/Cronologia');
      },
      async BlackListInfo(bodyData) {
        return await this.executePostV1(bodyData, 'BlackList/BLInfo');
      },
      async BlackListInfoCount(bodyData) {
        return await this.executePostV1(bodyData, 'BlackList/BLInfoCount');
      },
      async BlackListSituationList(bodyData) {
        return await this.executePostV1(bodyData, 'BlackList/SituationList');
      },
      async ReporteRelCancelled(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ListaRelacionadosCancelados');
      },
      async ReporteRelCancelledCount(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/ConteoRelacionadosCancelados');
      },
      async ReporteRelCancelledCSV(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/CSVRelacionadosCancelados');
      },

      async ReportePaymentGrande(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/RequestReport');
      },
      async ReportePaymentRetrieveGrandes(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/RetrieveReports');
      },
      async ReportePaymentRetrieveReport(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/RetrieveReport');
      },
      async ReportePaymentDeleteReport(bodyData) {
        return await this.executePost(bodyData, 'PaymentAnalysis/DeleteReport');
      },

      async MismatchPaymentMethodsList(bodyData) {
        return await this.executePost(bodyData, 'Mismatch/Lista');
      },
      async MismatchPaymentMethodsCount(bodyData) {
        return await this.executePost(bodyData, 'Mismatch/Conteo');
      },
      async MismatchPaymentMethodsCSV(bodyData) {
        return await this.executePost(bodyData, 'Mismatch/CSV');
      },
      async SearchByGUID(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Search');
      },
      async SearchExpedient(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Expedient');
      },
      async ExpedientPDF(bodyData) {
        return await this.executePost(bodyData, 'CFDI/Expedient/PDF');
      },
      async ReporteRetenciones(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/Lista');
      },
      async ReporteRetencionesCount(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/Conteo');
      },
      async ReporteRetencionesCSV(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/CSV');
      },
      async ReporteRetencionesFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/Lista/Cancelados');
      },
      async ReporteRetencionesCountFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/Conteo/Cancelados');
      },
      async ReporteRetencionesCSVFechaCancelacion(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/CSV/Cancelados');
      },
      async SearchRetencionesByGUID(bodyData) {
        return await this.executePost(bodyData, 'Retenciones/Search');
      },
      async SearchMetrics(bodyData) {
        return await this.executePost(bodyData, 'Metrics/ChangeList');
      },
      async SearchMetricsByDate(bodyData) {
        return await this.executePost(bodyData, 'Metrics/ChangeListByDate');
      },
      async AccountInfo(bodyData) {
        return await this.executePost(bodyData, 'Auth/AccountInfo');
      },
      async ManageFiel(bodyData) {
        return await this.executePost(bodyData, 'Auth/ManageFiel');
      },
      async ChangePass(bodyData) {
        return await this.executePost(bodyData, 'User/PasswordChange');
      },
      async ShowList(bodyData) {
        return await this.executePost(bodyData, 'User/List');
      },
      async CreateUser(bodyData) {
        return await this.executePost(bodyData, 'User/Create');
      },
      async EditUser(bodyData) {
        return await this.executePost(bodyData, 'User/Update');
      },



      async Contract(bodyData) {
        return await this.executePost(bodyData, 'Zoho/Contract');
      },
      async ContractHistorico(bodyData) {
        return await this.executePost(bodyData, 'Zoho/Historico');
      },
      async ContractArchivo(bodyData) {
        return await this.executePost(bodyData, 'Zoho/Archivo');
      },
      async ZohoFirmarContrato(bodyData) {
        return await this.executePost(bodyData, 'Zoho/FirmarContrato');
      },



      async ContractInfo(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/ContractInfo');
      },
      async ContractAcceptance(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/ContractAcceptance');
      },
      async RetrieveLegalDoc(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/Retrieve');
      },
      async RetrieveAlmacenarLegalDoc(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/RetrieveV2');
      },
      async SignLegalDoc(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/Sign');
      },
      async SignPdf(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/Sign/PDF');
      },
      async SignZohoPdf(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/SignZoho/PDF');
      },
      async LegalDocRetrievePDF(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/RetrievePDF');
      },
      async LegalDocRetrieveNotSignedPDF(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/RetrieveNotSignedPDF');
      },
      async SaveFiel(bodyData) {
        return await this.executePost(bodyData, 'LegalDocs/SaveFiel');
      },
      async ReporteConceptos(bodyData) {
        return await this.executePost(bodyData, 'Concepto/Lista');
      },
      async ReporteConceptosCount(bodyData) {
        return await this.executePost(bodyData, 'Concepto/Conteo');
      },
      async ReporteConceptosCSV(bodyData) {
        return await this.executePost(bodyData, 'Concepto/CSV');
      },
      async ClaveProdServList(bodyData) {
        return await this.executePost(bodyData, 'Concepto/PriceLista');
      },
      async ClaveProdServCount(bodyData) {
        return await this.executePost(bodyData, 'Concepto/PriceConteo');
      },
      async ClaveProdServCSV(bodyData) {
        return await this.executePost(bodyData, 'Concepto/PriceCSV');
      },


      async ReporteConceptoGrande(bodyData) {
        return await this.executePost(bodyData, 'Concepto/RequestReport');
      },
      async ReporteConceptoRetrieveGrandes(bodyData) {
        return await this.executePost(bodyData, 'Concepto/RetrieveReports');
      },
      async ReporteConceptoRetrieveReport(bodyData) {
        return await this.executePost(bodyData, 'Concepto/RetrieveReport');
      },
      async ReporteConceptoDeleteReport(bodyData) {
        return await this.executePost(bodyData, 'Concepto/DeleteReport');
      },



      async RelacionadosList(bodyData) {
        return await this.executePost(bodyData, 'RelatedAnalysis/Lista');
      },
      async RelacionadosCount(bodyData) {
        return await this.executePost(bodyData, 'RelatedAnalysis/Conteo');
      },
      async RelacionadosCSV(bodyData) {
        return await this.executePost(bodyData, 'RelatedAnalysis/CSV');
      },
      async RelacionadosDocs(bodyData) {
        return await this.executePost(bodyData, 'RelatedAnalysis/Relacionados');
      },
      async RelacionadosIncomeDocs(bodyData) {
        return await this.executePost(bodyData, 'IncomeAnalysis/CFDIRelacionados');
      },
      async ReporteClientesProveedores(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/ClientsVendorsCSV');
      },
      async ReporteClientesProveedoresCount(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/ClientsVendorsConteo');
      },
      async ReporteClientesProveedoresList(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/ClientsVendorsLista');
      },
      async ReporteDeudaCsv(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/DeudaCSV');
      },
      async ReporteDeudaCount(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/DeudaConteo');
      },
      async ReporteDeudaList(bodyData) {
        return await this.executePost(bodyData, 'ReportsMisc/DeudaLista');
      },
      async ConciliacionCanceladosYear(bodyData) {
        return await this.executePost(bodyData, 'SATConciliator/Year');
      },
      async ConciliacionCanceladosAsk(bodyData) {
        return await this.executePost(bodyData, 'SATConciliator/Ask');
      },
      async ConciliacionCanceladosStatus(bodyData) {
        return await this.executePost(bodyData, 'SATConciliator/Status');
      },
      async ConciliacionCancelados(bodyData) {
        return await this.executePost(bodyData, 'ConciliatorCanceled/Year');
      },
      async ConciliacionCanceladosMes(bodyData) {
        return await this.executePost(bodyData, 'ConciliatorCanceled/Month');
      },
      async ConciliarMonth(bodyData) {
        return await this.executePost(bodyData, 'ConciliatorCanceled/ExecuteByMonth');
      },
      async ConciliarYear(bodyData) {
        return await this.executePost(bodyData, 'ConciliatorCanceled/ExecuteByYear');
      },
      async DetallesYear(bodyData) {
        return await this.executePost(bodyData, 'ConciliatorCanceled/XDataCount');
      },
      async TaxAnalysis(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/TaxAnalysis');
      },
      async RequestTaxAnalysis(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RequestTaxAnalysis');
      },
      async RetrieveTaxAnalysis(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RetrieveTaxAnalysis');
      },
      async RetrieveTaxAnalysis2(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RetrieveTaxAnalysisV2');
      },
      async RetrieveTaxAnalysisResults(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RetrieveResults');
      },
      async RequestTaxAnalysisByDate(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RequestTaxAnalysisByDate');
      },
      async RetrieveTaxAnalysisByDate(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RetrieveTaxAnalysisByDate');
      },
      async RetrieveTaxAnalysisByDate2(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/RetrieveTaxAnalysisByDateV2');
      },
      async PayrollAnalysis(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/PayrollAnalysis');
      },
      async PayrollAnalysisPago(bodyData) {
        return await this.executePost(bodyData, 'DeepAnalysis/PayrollAnalysisPago');
      },
      async RetrieveValidaciones(bodyData) {
        return await this.executePost(bodyData, 'Audit/Retrieve');
      },
      async AddValidaciones(bodyData) {
        return await this.executePost(bodyData, 'Audit/Add');
      },
      async UpdateValidaciones(bodyData) {
        return await this.executePost(bodyData, 'Audit/Update');
      },
      async AuditList(bodyData) {
        return await this.executePost(bodyData, 'Audit/Lista');
      },
      async AuditCount(bodyData) {
        return await this.executePost(bodyData, 'Audit/Conteo');
      },
      async AuditCsv(bodyData) {
        return await this.executePost(bodyData, 'Audit/CSV');
      },
      async GetBLPDF(bodyData) {
        return await this.executePost(bodyData, 'PDFConverter/PDF');
      },
      async ERPCompareRequest(bodyData) {
        return await this.executePost(bodyData, 'ERPComparerAnalysis/Request');
      },
      async ERPCompareRetrieve(bodyData) {
        return await this.executePost(bodyData, 'ERPComparerAnalysis/Retrieve');
      },
      async ERPCompareRetrieveResults(bodyData) {
        return await this.executePost(bodyData, 'ERPComparerAnalysis/RetrieveResults');
      },
      async ERPCompareGetFile(bodyData) {
        return await this.executePost(bodyData, 'ERPComparerAnalysis/RetrieveFile');
      },
      async UpsertNote(bodyData) {
        return await this.executePost(bodyData, 'Note');
      },
      async News(bodyData) {
          return await this.executePost(bodyData, 'Misc/News');
        },
      async TagUpsert(bodyData) {
        return await this.executePost(bodyData, 'Tag/Upsert');
      },
      async TagUpsertGroup(bodyData) {
        return await this.executePost(bodyData, 'Tag/BatchUpsert');
      },
      async TagCatalog(bodyData) {
        return await this.executePost(bodyData, 'Tag/Catalog');
      },
      async TagUpsertCatalog(bodyData) {
        return await this.executePost(bodyData, 'Tag/UpsertCatalog');
      },
      async RequestOnDemandReport(bodyData) {
        return await this.executePost(bodyData, 'OnDemandReports/RequestReport');
      },
      async RetrieveOnDemandReports(bodyData) {
        return await this.executePost(bodyData, 'OnDemandReports/RetrieveReports');
      },
      async RetrieveOnDemandReport(bodyData) {
        return await this.executePost(bodyData, 'OnDemandReports/RetrieveReport');
      },
      async DeleteOnDemandReport(bodyData) {
        return await this.executePost(bodyData, 'OnDemandReports/DeleteReport');
      },

      async FiscalRiskLast(bodyData) {
        return await this.executePost(bodyData, 'FiscalRisk/Last');
      },

      async FiscalRiskRetrieve(bodyData) {
        return await this.executePost(bodyData, 'FiscalRisk/Retrieve');
      },

      async FiscalRiskCatalog(bodyData) {
        return await this.executePost(bodyData, 'FiscalRisk/Catalog');
      },

      async UniqueLista(bodyData) {
        return await this.executePost(bodyData, 'UniqueDataSet/Lista');
      },
      async UniqueConteo(bodyData) {
        return await this.executePost(bodyData, 'UniqueDataSet/Conteo');
      },

    };
  }
};