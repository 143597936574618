<template>
  <div class="home">
    <Income titulo="Análisis de CFDI vs Comprobantes de Pago - Recibidos" contexto="R"/>
  </div>
</template>

<script>

import Income from "./Income";

export default {
  components: {
    Income
  }
};
</script>