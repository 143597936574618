<template>
  <div class="home">
    <Income titulo="Análisis de CFDI vs Comprobantes de Pago - Emitidos" contexto="E"/>
  </div>
</template>

<script>

import Income from "./Income";

export default {
  components: {
    Income
  }
};
</script>