<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog v-model="showDialog" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} - {{ itemSelected.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <tr>
                    <td>
                      <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                        <p class="display-1 text--primary">Emisor</p>
                        <div class="body-1">Razón social: {{ itemSelected.emisor.nombre }}</div>
                        <div class="body-1">RFC: {{ itemSelected.emisor.rfc }}</div>
                        <div class="body-1">Regimen: {{ itemSelected.emisor.regimen }}</div>
                      </v-card>
                    </td>
                    <td>
                      <v-card class="mx-auto pa-2 ma-2" max-width="344" min-width="420" min-height="190">
                        <p class="display-1 text--primary">Receptor</p>
                        <div class="body-1">Razón social: {{ itemSelected.receptor.nombre }}</div>
                        <div class="body-1">RFC: {{ itemSelected.receptor.rfc }}</div>
                        <div class="body-1">Residencia: {{ itemSelected.receptor.residencia }}</div>
                        <div class="body-1">Uso CFDI: {{ itemSelected.receptor.usocfdi }}</div>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </v-simple-table>
              <br />
              <h3>Folio Fiscal: {{ itemSelected.id }}</h3>
              <br />

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th>Tipo Comprobante</th>
                      <th>Método de Pago</th>
                      <th>Forma de Pago</th>
                      <th>Lugar Expedición</th>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <th v-show="esCan">Fecha Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ itemSelected.serie }}</td>
                      <td>{{ itemSelected.folio }}</td>
                      <td>{{ helpers.docTypeTranslate(itemSelected.tipo_comprobante) }}</td>
                      <td>{{ itemSelected.metodo_pago }}</td>
                      <td>{{ itemSelected.forma_pago }}</td>
                      <td>{{ itemSelected.lugar_expedicion }}</td>
                      <td>{{ itemSelected.fecha_emision }}</td>
                      <td>{{ itemSelected.fecha_timbre }}</td>
                      <td v-show="esCan">{{ itemSelected.fecha_cancelacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="font-weight-black">Totales:</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">Subtotal</th>
                      <th class="text-right">Descuento</th>
                      <th class="text-right">Tipo Cambio</th>
                      <th class="text-left">Moneda</th>
                      <th class="text-right">Total</th>
                      <th class="text-right">Total Retenciones</th>
                      <th class="text-right">Total Traslados</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td align="right">{{ currencyFormat(itemSelected.subtotal) }}</td>
                      <td align="right">{{ currencyFormat(itemSelected.descuento) }}</td>
                      <td align="right">{{ itemSelected.tipo_cambio }}</td>
                      <td>{{ itemSelected.moneda }}</td>
                      <td align="right">{{ currencyFormat(itemSelected.total) }}</td>
                      <td align="right">{{ currencyFormat(itemSelected.total_retenciones) }}</td>
                      <td align="right">{{ currencyFormat(itemSelected.total_traslados) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <h3>Flujo de Efectivo
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                href="https://soporte.mysuitemex.com/portal/es/kb/articles/flujo-efectivo" target="_blank">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>Manual</span>
          </v-tooltip>

        </h3>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" type="month" label="Período" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker class="min-width-control-xl" :min="fechaInicial" v-model="date" locale="es" type="month"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field class="min-width-control-xl" type="number" label="Monto Inicial del Período"
                  v-model="cantidad" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-container fluid>

                  <v-btn class="min-width-control-xl" color="primary" white large v-on:click="getReport"
                    :disabled="!showSearchButton">Mostrar Listado</v-btn>

                </v-container>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card class="pa-2" v-if="items.length > 0">
            <v-row>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>TOTAL EMITIDOS</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-html="currencyFormat(totalGralMxnE)"></v-list-item-title>
                          <v-list-item-subtitle>MXN</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>TOTAL RECIBIDOS</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-html="currencyFormat(totalGralMxnR)"></v-list-item-title>
                          <v-list-item-subtitle>MXN</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>TOTAL</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-html="currencyFormat(totalGralMxnE - totalGralMxnR)"></v-list-item-title>
                          <v-list-item-subtitle>MXN</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>TOTAL NOMINA</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-html="currencyFormat(totalNomina)"></v-list-item-title>
                          <v-list-item-subtitle>MXN</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" max-width="300" title>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>SALDO FINAL</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-html="currencyFormat(totalFinal)"></v-list-item-title>
                          <v-list-item-subtitle>MXN</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de {{ totalDocumentos
            }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteCashflowCSV" v-on="on" color="second"
                  :disabled="totalDocumentos > 5000">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Tamaño de Página" hide-details class="ma-3"></v-select>
              <v-btn icon v-on:click="start" v-show="hasPrevious">
                <v-icon color="second">mdi-arrow-collapse-left</v-icon>
              </v-btn>
              <v-btn icon v-show="hasPrevious">
                <v-icon color="second" v-on:click="previous">mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn text>{{ noPagina }} de {{ totalPaginas }}</v-btn>
              <v-btn icon>
                <v-icon color="second" v-on:click="next" v-show="hasNext">mdi-arrow-right</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
               :initialized="initGrid" :allowResizing="'None'">


              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'uuid'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'RFC'" :binding="'rfc'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Razón Social'" :binding="'nombre'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Fecha Pago'" :binding="'fecha_pago'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Contexto'" :binding="'tipo'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Comprobante'" :binding="'tipo_comprobante'" width="2*"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Monto Pago'" :binding="'monto'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Moneda'" :binding="'moneda_p'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio_p'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Monto MXN'" :binding="'total'" width="2*" format="f2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Saldo'" :binding="'cantAlDia'" width="2*" format="f2" :isReadOnly="true" />

              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>

                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>

          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "PaymentEx",
  data: () => ({
    totalGralMxnR: 0,
    totalGralMxnE: 0,
    totalNomina: 0,
    cantidad: "",
    cantidadesPorPagina: [],
    limiteDias: 10,
    date: new Date().toISOString().substr(0, 7),
    showDialog: false,
    folioDoc: "",
    modal: false,
    menu: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "ASC",
    currentOrderColumn: "fecha_pago",
    itemSelected: {
      id: "",
      emisor: {
        nombre: "",
        regimen: "",
        rfc: ""
      },
      receptor: {
        nombre: "",
        residencia: "",
        rfc: "",
        usocfdi: ""
      },
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0
    }
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
  },
  methods: {
    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
      }
    
      grid.columns[3].width = 160;
      grid.columns[4].width = 160;
      grid.columns[5].width = 120;
      grid.columns[6].width = 200;
      grid.columns[7].width = 180;     
      grid.columns[8].width = 120;
      grid.columns[9].width = 160;
      grid.columns[10].width = 220;
      

      grid.columns[grid.columns.length - 1].width = 100;

    
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo === "Emitido" ? "E" : "R",
        uuid: item.uuid,
        issuedDate: item.fecha_emision
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then(response => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "Recibido")
        par = "R|" + helpers.ConvertToEmpty(item.pac).trim() + "|" + item.rfc;
      else par = "E|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
      let parameters = par + "|" + item.uuid + "|" + item.fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");



      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
    verMasDatos(item) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: item.id,
        issuedDate: item.fecha_emision.substr(0, 10)
      };
      service
        .api()
        .CFDIDetail(jsonData)
        .then(response => {
          if (response.result) {
            this.itemSelected = JSON.parse(response.payload);
            this.showDialog = true;
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },

    exportExcel() {
      var headers = {
        Rfc: "RFC",
        Nombre: "Razón social",
        UUID: "Folio Fiscal",
        FechaEmision: "Fecha Emisión",
        FechaPago: "Fecha Pago",
        Contexto: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        Monto: "Monto Pago",
        Moneda: "Moneda Pago",
        TipoCambio: "Tipo Cambio",
        Saldo: "Saldo",
        CtaOrdenante: "Cuenta Ordenante",
        RFCEmisorCtaOr: "RFC Emisor Cuenta Ordenante",
        BancoOrdenante: "Banco Cuenta Ordenante",
        RFCCtaBen: "RFC Cuenta Beneficiario",
        CtaBen: "Cuenta Beneficiario"
      };

      let saldo = 0;
      var itemsFormatted = [];
      this.csvData.forEach(item => {
        itemsFormatted.push({
          Rfc: helpers.ConvertToEmpty(item.rfc),
          Nombre: helpers.ConvertToEmpty(item.nombre),
          UUID: helpers.ConvertToEmpty(item.id),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaPago: helpers.ConvertToEmpty(item.fecha_pago),
          Contexto: item.tipo === "E" ? "Emitido" : "Recibido",
          TipoComprobante: helpers.docTypeTranslate(item.tipo_comprobante),
          Monto: helpers.ConvertToEmpty(item.monto),
          Moneda: helpers.ConvertToEmpty(item.moneda_p),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio_p),
          Saldo: (saldo =
            Number(saldo) +
            (item.tipo === "E" ? item.monto : item.monto * -1) *
            (helpers.docTypeTranslate(item.tipo_comprobante) === "Ingreso" ? 1 : -1) *
            (item.moneda_p === "MXN" ? 1 : item.tipo_cambio_p)
          ),
          CtaOrdenante: helpers.ConvertToEmpty(item.cta_ordenante),
          RFCEmisorCtaOr: helpers.ConvertToEmpty(item.rfc_emisor_cta_ord),
          BancoOrdenante: helpers.ConvertToEmpty(item.nom_banco_ord_ext),
          RFCCtaBen: helpers.ConvertToEmpty(item.rfc_emisor_cta_ben),
          CtaBen: helpers.ConvertToEmpty(item.cta_beneficiario)
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Flujo_Efectivo_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    start() {
      this.offest = 0;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.alDia = Number(this.cantidad);
      this.getReporteItemsNext();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItemsNext();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItemsNext();
    },
    changePagination() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.cantidadesPorPagina = [];
      this.cantidadesPorPagina.push(this.cantidad);
      this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItemsNext();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
      this.getTotalesMXN();
    },
    getReporteItems() {
      this.items = [];
      let newElements = [];
      this.cantidadesPorPagina = [];
      this.cantidadesPorPagina.push(this.cantidad);
      let LastCantAlDia = this.cantidadesPorPagina[this.noPagina - 1];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        offset: this.offest,
        limit: this.limit
      };
      service
        .api()
        .ReporteCashflow(jsonData)
        .then(response => {
          if (response.result) {
            let payload = JSON.parse(response.payload);

            payload.forEach(function (entry) {
              var itemData = {
                rfc: entry.rfc,
                tipo: entry.tipo === "E" ? "Emitido" : "Recibido",
                nombre: entry.nombre,
                tipo_comprobante:
                  helpers.docTypeTranslate(entry.tipo_comprobante),
                uuid: entry.id,
                fecha_emision: entry.fecha_emision.substring(0, 10),
                fecha_pago: entry.fecha_pago.substring(0, 10),
                monto: entry.monto,
                total:
                  (entry.tipo === "E" ? entry.monto : entry.monto * -1) *
                  (entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p),
                cantAlDia: (LastCantAlDia =
                  Number(LastCantAlDia) +
                  (entry.tipo === "E" ? entry.monto : entry.monto * -1) *
                  (helpers.docTypeTranslate(entry.tipo_comprobante) === "Ingreso" ? 1 : -1) *
                  (entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p)
                ),
                moneda_p: entry.moneda_p,
                tipo_cambio_p:
                  entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p
              };
              newElements.push(itemData);
            });

            this.items = newElements;

            if (this.cantidadesPorPagina[this.noPagina] === undefined)
              this.cantidadesPorPagina.push(LastCantAlDia);

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteItemsNext() {
      this.items = [];
      let newElements = [];
      let LastCantAlDia = this.cantidadesPorPagina[this.noPagina - 1];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        offset: this.offest,
        limit: this.limit
      };
      service
        .api()
        .ReporteCashflow(jsonData)
        .then(response => {
          if (response.result) {
            let payload = JSON.parse(response.payload);

            payload.forEach(function (entry) {
              var itemData = {
                rfc: entry.rfc,
                tipo: entry.tipo === "E" ? "Emitido" : "Recibido",
                nombre: entry.nombre,
                tipo_comprobante: helpers.docTypeTranslate(entry.tipo_comprobante),
                uuid: entry.id,
                fecha_emision: entry.fecha_emision.substring(0, 10),
                fecha_pago: entry.fecha_pago.substring(0, 10),
                monto: entry.monto,
                total:
                  (entry.tipo === "E" ? entry.monto : entry.monto * -1) *
                  (entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p),
                cantAlDia: (LastCantAlDia =
                  Number(LastCantAlDia) +
                  (entry.tipo === "E" ? entry.monto : entry.monto * -1) *
                  (helpers.docTypeTranslate(entry.tipo_comprobante) === "Ingreso" ? 1 : -1) *
                  (entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p)
                ),
                moneda_p: entry.moneda_p,
                tipo_cambio_p:
                  entry.moneda_p === "MXN" ? 1 : entry.tipo_cambio_p
              };
              newElements.push(itemData);
            });

            this.items = newElements;

            if (this.cantidadesPorPagina[this.noPagina] === undefined)
              this.cantidadesPorPagina.push(LastCantAlDia);

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31"
      };

      service
        .api()
        .ReporteCashflowCount(jsonData)
        .then(response => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteCashflowCSV() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        rfc: this.rfcSearch
      };

      service
        .api()
        .ReporteCashflowCSV(jsonData)
        .then(response => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    getTotalesMXN() {
      var jsonData = {
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        token: this.$store.state.token
      };

      service
        .api()
        .CashflowTotal(jsonData)
        .then(response => {
          this.$store.commit("setOverlay", false);
          if (response.result) {
            let serializedList = [];
            let payload = JSON.parse(response.payload);
            let emitidos = 0;
            let recibidos = 0;

            payload.forEach(function (entry) {
              switch (entry.tipo) {
                case "E":
                  if (entry.tipo_comprobante == "I")
                    emitidos += entry.total_pagado_mxn;
                  else emitidos -= entry.total_pagado_mxn;
                  break;
                case "R":
                  if (entry.tipo_comprobante == "I")
                    recibidos += entry.total_pagado_mxn;
                  else recibidos -= entry.total_pagado_mxn;
                  break;
              }
            });

            this.totalGralMxnE = emitidos;
            this.totalGralMxnR = recibidos;
            this.getTotalNomina();
          } else {

            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "Error Calculando el total del período.");

          }
        });
    },
    getTotalNomina() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        rfc: ""
      };

      service
        .api()
        .NominaTotalCashflow(jsonData)
        .then(response => {
          this.$store.commit("setOverlay", false);
          if (response.result) {
            let payload = JSON.parse(response.payload);

            this.totalNomina = payload;

          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "Error Calculando el total de nomina.");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    }
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto === "E";
    },
    esRecibido() {
      return this.contexto === "R";
    },

    showSearchButton() {
      return this.cantidad.length > 0 && this.date.length > 0;
    },
    totalFinal() {
      return (
        Number(this.cantidad) +
        Number(this.totalGralMxnE) -
        Number(this.totalGralMxnR) -
        Number(this.totalNomina)
      );
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    }
  }
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.normal {
  font-weight: regular;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
