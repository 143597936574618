<template>
  <div class="home">
    <Conciliatorio titulo="Conciliatorio - Emitidos No Cancelados" contexto="E" es-cancelado=false />
  </div>
</template>

<script>

import Conciliatorio from "./Conciliatorio";

export default {
  components: {
    Conciliatorio
  }
};
</script>