<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row justify="center"> </v-row>
      <v-row>

        <v-col cols="12">
          <h3>{{ titulo }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on" :href="liga" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" type="month" label="Período" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker class="min-width-control-xl" :min="fechaInicial" v-model="date" locale="es" type="month"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport">Mostrar Listado</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ conceptosPagina }} registros de
              {{ totalRelated }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteProdServCSV" v-on="on" :disabled="totalRelated > 5000"
                  color="second">mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                    color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                    color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>
            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :allowResizing="'None'" :initialized="initGrid">

              <wj-flex-grid-column v-if="contexto == 'E'" :header="'Cliente'" :binding="'Cliente'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Proveedor'" :binding="'Proveedor'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Nombre'" :binding="'Nombre'" width="6*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Cantidad CFDI\'s'" :binding="'CantidadCFDIs'" width="2*" 
                :isReadOnly="true" />
              <wj-flex-grid-column :header="' '" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Detalle</span>
                  </v-tooltip>

                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>
          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';


import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";

export default {
  name: "ClientVendors",
  props: ["titulo", "contexto", "liga"],
  data: () => ({
    selectedRows: [],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,




    search: "",
    items: [],
    landscape: false,
    goPage: 1,
    offest: 0,
    limit: 50,
    pageSize: "500",
    noPagina: 1,
    totalPaginas: 1,
    totalRelated: 0,
    paginations: ["100", "200", "500"],
    context: ""
  }),
  methods: {

    initGrid: function (grid) {
      grid.columns[3].width = 50;
     
    },
    verMasDatos(item) {
      let ruta = "";

      if (this.contexto === "E") ruta = "/conciliatorio/emitidos";
      else ruta = "/conciliatorio/recibidos";
      let periodYear = Number(this.date.substr(0, 4));
      let periodMonth = Number(this.date.substr(5, 2));
      let today = new Date();
      let LastDay = helpers.LastDayMonth(periodYear, periodMonth);
      if (
        periodYear == today.getFullYear() &&
        periodMonth == today.getMonth() + 1
      ) {
        LastDay = today.getDate();
      }
      var interval = {
        dateInit: this.date + "-01",
        dateEnd:
          this.date +
          "-" +
          (LastDay < 10 ? "0" + LastDay : LastDay),
        dateTs: "",
        contexto: this.contexto,
        rfc: this.contexto == "E" ? item.Cliente : item.Proveedor
      };
      this.$store.commit("setDateInterval", interval);

      this.$router.push({
        path: ruta,
      });
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalRelated / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalRelated % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalRelated % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalRelated / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        context: this.contexto,
        offset: this.offest,
        limit: this.limit,
      };
      service
        .api()
        .ReporteClientesProveedoresList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.conceptosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date + "-01",
        dateEnd: this.date + "-31",
        context: this.contexto,
      };

      service
        .api()
        .ReporteClientesProveedoresCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalRelated = payload;
            this.totalPaginas = Math.ceil(this.totalRelated / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    exportExcel() {
      var headers = {
        Cliente: this.contexto == "E" ? "Cliente" : "Proveedor",
        Nombre: "Nombre",
        CantidadCFDIs: "CantidadCFDIs",
      };
      var itemsFormatted = [];
      this.items.forEach((item) => {
        itemsFormatted.push({
          ClaveProdServ: helpers.ConvertToEmpty(
            this.contexto == "E" ? item.Cliente : item.Proveedor
          ),
          MaxValorUnitario: helpers.ConvertToEmpty(item.Nombre),
          MinValorUnitario: helpers.ConvertToEmpty(item.CantidadCFDIs),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Reporte_Periodo" +
        this.date +
        "_" +
        (this.contexto === "E" ? "Clientes" : "Proveedores") +
        "_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    getReporteProdServCSV(tipo) {
      if (this.totalPaginas == 1) {
        this.exportExcel();
      } else {
        var jsonData = {
          token: this.$store.state.token,
          dateInit: this.date + "-01",
          dateEnd: this.date + "-31",
          context: this.contexto,
        };

        service
          .api()
          .ReporteClientesProveedores(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.items = JSON.parse(response.payload);
              JSONToCSV(
                this.items,
                "Reporte_Periodo" +
                this.date +
                "_" +
                (this.contexto === "E" ? "Clientes" : "Proveedores") +
                "_" +
                helpers.dateNowCSV().replace(/-/g, "_"),
                true
              );

              this.$store.commit("setAlertError", false);
              this.$store.commit("setAlertOk", true);
              this.$store.commit("setAlertMessage", "Archivo CSV generado exitosamente");

            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      }
    },
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
