<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center">


        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-show="mostrarDescargarSeleccionado" @click="descargarMultiple" v-on="on"
                      class="ml-auto mr-3">
                      <v-icon>mdi-download-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Seleccionados</span>
                </v-tooltip>
                <v-btn @click="abrirDialogCrear" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>

              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{
                    errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn
                    }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            <v-checkbox v-model="allSelected" @change="toggleSelectAll" color="primary"
                              hide-details></v-checkbox>
                          </th>

                          <th>Nombre</th>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Contexto</th>
                          <th>Mensaje</th>
                          <th>Disponible</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            <v-checkbox v-model="selectedItemsDescarga" :value="item" color="primary"
                              hide-details></v-checkbox>
                          </td>
                          <td>
                            {{ item.name }}
                          </td>
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{
                              JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido"
                            }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{ item.ready ? "Si" : "No" }}
                          </td>
                          <td>
                            {{
                              item.ready
                                ? item.result
                                  ? "Exitoso"
                                  : "Fracasado"
                                : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>



        <v-dialog v-model="showDialogCrear" persistent max-width="1000px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrear = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="6" justify="center">
                  <v-text-field v-model="nombreIn" counter="0" label="Nombre" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
                <v-col md="6" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" maxlength="13"
                    class="my-1"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="nombreSearchIn" counter="0" label="Razón Social" maxlength="250" class="my-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select class="min-width-control-xl" :items="lnSelectIn" v-model="tipoDocIn"
                    label="Origen del Comprobante" outlined></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select class="min-width-control-xl" :items="lnSelectLeida" v-model="tipoLeidaIn" label="Leída"
                    outlined @change="items = []">
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select class="min-width-control-xl" :items="lnSelectEstatus" v-model="tipoStatusIn"
                    label="Estatus en Listas Negras" outlined>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar
                Reporte
              </v-btn>
              <v-btn color="danger" text @click="showDialogCrear = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="showDialogCrono" persistent max-width="600px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogCrono = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Documentos
                {{ currentContext == "E" ? "emitidos" : "recibidos" }} por
                {{ currentRfc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-timeline>
                <v-timeline-item v-for="item in itemsCrono" :key="item.documentYear" color="second" :right="false"
                  small>
                  <v-card dense height="115">
                    <v-card-title class="second">
                      <v-icon dark size="15" class="mr-4">mdi-calendar-text</v-icon>
                      <h2 class="subtitle-2 white--text">
                        Año: {{ item.documentYear }}
                      </h2>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <span class="subtitle-2">Documentos:</span>
                          {{ item.documentQty }}
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn v-show="item.documentQty > 0" @click="goCon(currentRfc, item.documentYear)" icon
                                color="primary" v-on="on">
                                <v-icon>mdi-text-box-multiple-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Documentos Conciliatorios</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" fullscreen persistent>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarDialogo">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Información en Lista Negra</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="origen == '0'">
              <br />
              <h3>Esta Alerta será marcada como leída</h3>
              <br />





              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>RFC</th>
                      <th>Razón Social</th>
                      <th>Tipo Persona</th>
                      <th>Supuesto</th>
                      <th>Fechas Primera Publicación</th>
                      <th>Monto</th>
                      <th></th>
                      <th v-if="currentContext != ''"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemNo in itemSelected" :key="itemNo.No" class="altura">
                      <td>{{ itemNo.Id }}</td>
                      <td>{{ itemNo.RFC }}</td>
                      <td>{{ itemNo.Name }}</td>
                      <td>{{ itemNo.TipoPersona }}</td>
                      <td>{{ itemNo.Supuesto }}</td>
                      <td>{{ itemNo.FechasPrimeraPublicacion }}</td>
                      <td>{{ itemNo.Monto }}</td>
                      <td><a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Listado_Completo_69-B.csv">Listado
                          Completo</a></td>
                      <td v-if="currentContext != ''" class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="getCronologia()" icon color="#4aa832" v-on="on">
                              <v-icon>mdi-calendar-search</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver Cronología</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-text v-else>
              <br />
              <h3>Esta Alerta será marcada como leída

                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="getCronologia()" icon color="#4aa832" v-on="on">
                      <v-icon>mdi-calendar-search</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver Cronología</span>
                </v-tooltip>
              </h3>
              <br />

              <v-simple-table>
                <template v-slot:default>
                  <thead>

                    <tr>
                      <th style="white-space: nowrap">No</th>
                      <th style="white-space: nowrap">RFC</th>
                      <th style="white-space: nowrap">Razón Social</th>
                      <th style="white-space: nowrap">Situación Contribuyente</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Presunción Sat</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemNo in itemSelectedB" :key="itemNo.No" class="altura">
                      <td style="white-space: nowrap">{{ itemNo.No }}</td>
                      <td style="white-space: nowrap">{{ itemNo.RFC }}</td>
                      <td style="white-space: nowrap">{{ itemNo.Name }}</td>
                      <td style="white-space: nowrap">{{ itemNo.SituacionContribuyente }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalPresuncionSat }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>

                    <tr>

                      <th style="white-space: nowrap">Publicación Pagina Sat Presuntos</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Presunción Sat</th>
                      <th style="white-space: nowrap">Publicación Pagina Sat Presuntos</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Presunción Dof</th>
                      <th style="white-space: nowrap">Publicación Dof Presuntos</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Contribuyente Desvirtuaron Sat</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemNo in itemSelectedB" :key="itemNo.No" class="altura">

                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatPresuntos }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalPresuncionSat }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatPresuntos }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalPresuncionDof }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionDofPresuntos }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalContribuyenteDesvirtuaronSat }}
                      </td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="white-space: nowrap">Publicación Pagina Sat Desvirtuados</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Contribuyente Desvirtuaron</th>
                      <th style="white-space: nowrap">Publicación Desvirtuados</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Definitivos Sat</th>
                      <th style="white-space: nowrap">PublicaciónPaginaSatDefinitivos</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Definitivos</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemNo in itemSelectedB" :key="itemNo.No" class="altura">
                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatDesvirtuados }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalContribuyenteDesvirtuaronDof }}
                      </td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionDofDesvirtuados }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalDefinitivosSat }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatDefinitivos }}</td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalDefinitivosDof }}</td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="white-space: nowrap">Publicación Definitivos</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Sentencia Favorable Sat</th>
                      <th style="white-space: nowrap">Publicación Pagina Sat Sentencia Favorable</th>
                      <th style="white-space: nowrap">Número Fecha Oficio Global Sentencia FavorableDof</th>
                      <th style="white-space: nowrap">Publicación Sentencia Favorable</th>
                      <th></th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemNo in itemSelectedB" :key="itemNo.No" class="altura">
                      <td style="white-space: nowrap">{{ itemNo.PublicacionDofDefinitivos }} </td>
                      <td style="white-space: nowrap">{{ itemNo.NumeroFechaOficioGlobalSentenciaFavorableSat }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatSentenciaFavorable }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionPaginaSatDefinitivos }}</td>
                      <td style="white-space: nowrap">{{ itemNo.PublicacionDofSentenciaFavorable }}</td>
                      <td><span v-html="getLink(itemNo.Source1)"></span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cerrarDialogo">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <h3>Alertas Lista Negra 69{{ titulo }}

          <v-tooltip v-if="titulo == 'B'" bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                href="https://soporte.mysuitemex.com/portal/es/kb/articles/alerta-listas-negras-articulo-69b"
                target="_blank">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>Manual</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                href="https://soporte.mysuitemex.com/portal/es/kb/articles/alerta-listas-negras-articulo-69"
                target="_blank">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>Manual</span>
          </v-tooltip>
        </h3>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" label="RFC" maxlength="13" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="nombreSearch" counter="0" label="Razón Social" maxlength="250" class="my-1">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="lnSelect" v-model="tipoDoc"
                  label="Origen del Comprobante" outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="lnSelectLeida" v-model="tipoLeida" label="Leída" outlined
                  @change="items = []">
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="lnSelectEstatus" v-model="tipoStatus"
                  label="Estatus en Listas Negras" outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <div>
                  <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar
                    Listado</v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Listado de operaciones con
                      empresas en listas negras
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>

            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <v-btn v-if="tipoLeida === 'No Leída' && this.selectedItems.length > 0" v-on:click="getReadBL(true)"
              class="ma-2" outlined color="primary">Marcar Como Leídas</v-btn>
            <v-btn v-if="tipoLeida === 'Leída' && this.selectedItems.length > 0" v-on:click="getReadBL(false)"
              class="ma-2" outlined color="primary">Marcar Como NO Leídas</v-btn>
            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on" color="second">
                  mdi-file-export</v-icon>
              </template>
              <span>Exportar Reporte Detallado</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="'Leída'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.Read === 0 ? "No" : "Si" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>


              <wj-flex-grid-column :header="'RFC'" :binding="'TaxID'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Razón Social'" :binding="'Name'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Origen'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getContexto(cell.item.Kind) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>

              <wj-flex-grid-column :header="'Estatus'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ cell.item.Active ? "Vigente" : "Eliminado" }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>



              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Detalles</span>
                  </v-tooltip>



                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>

            <br />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';


import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ListasNegras",
  props: ["origen", "titulo"],
  data: () => ({
    selectedItemsDescarga: [],
    allSelected: false,

    selectedItems: [],
    selectedRows: [],
    jsonDataBL: null,
    isCheckAll: false,
    selected: [],
    filtro: "<Filters></Filters>",
    orderF: "EnrolledTimeStamp",
    showDialog: false,
    goPage: 1,
    showDialogCrono: false,
    currentContext: "",
    currentRfc: "",
    itemsCrono: [],
    modal: false,




    search: "",
    offest: 1,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    itemsCsv: [],
    tipoDoc: "Seleccionar Todas",
    tipoLeida: "No Leída",
    lnSelectLeida: ["Seleccionar Todas", "No Leída", "Leída"],
    tipoStatus: "Vigente",
    lnSelectEstatus: ["Seleccionar Todas", "Vigente", "Eliminado"],

    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    itemSelected: {
      Id: "",
      RFC: "",
      Name: "",
      TipoPersona: "",
      Supuesto: "",
      FechasPrimeraPublicacion: "",
      Monto: null,
      FechaPublicacionConMonto: null,
      SinNombre1: null,
      SinNombre2: null,
      Source1: 0,
    },
    itemSelectedB: {
      No: "",
      RFC: "",
      Name: "",
      SituacionContribuyente: "",
      NumeroFechaOficioGlobalPresuncion: "",
      PublicacionPaginaSatPresuntos: "",
      NumeroFechaOficioGlobalPresuncion1: "",
      PublicacionDofPresuntos: "",
      PublicacionPaginaSatDesvirtuados: "",
      NumeroFechaOficioGlobalContribuyenteDesvirtuado: "",
      PublicacionDofDesvirtuados: "",
      NumeroFechaOficioGlobalDefinitivos: "",
      PublicacionPaginaSatDefinitivos: "",
      PublicacionDofDefinitivos: "",
      NumeroFechaOficioGlobalSentenciaFavorable: "",
      PublicacionPaginaSatSentenciaFavorable: "",
      NumeroFechaOficioGlobalSentenciaFavorable1: "",
      PublicacionDofSentenciaFavorable: "",
      Source1: 0,
    },
    rfcSearch: "",
    nombreSearch: "",


    selectedDatesIn: [],
    showDialogEliminarReport: false,
    showDialogReports: false,
    showDialogCrear: false,
    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    itemsGrandes: [],
    itemDesc: "",

    descripcionIn: "",
    nombreIn: "",
    tipoDocIn: "Emitidos",
    tipoLeidaIn: "No Leída",
    tipoStatusIn: "Vigente",
    rfcSearchIn: "",
    nombreSearchIn: "",

    lnSelectIn: ["Emitidos",
      "Recibidos",
    ]



  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.jsonDataBL = this.$store.state.blData;

    if (this.jsonDataBL !== null) {
      this.noPagina = this.jsonDataBL.page;
      this.limit = this.jsonDataBL.pageSize;
      this.orderF = this.jsonDataBL.order;
      this.tipoLeida = this.jsonDataBL.tipoLeida;
      this.tipoDoc = this.jsonDataBL.tipoDoc;
      this.tipoStatus = this.jsonDataBL.tipoStatus;
      this.rfcSearch = this.jsonDataBL.rfcSearch;
      this.nombreSearch = this.jsonDataBL.nombreSearch;
      this.getReporteItemsBl();
    }
  },
  methods: {

    getLink(source1) {
      let link;
      switch (source1) {
        case 0:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Listado_Completo_69.csv">Listado Completo</a>';
          break;
        case 1:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Cancelados.csv">Cancelados</a>';
          break;
        case 2:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Condonadosart74CFF.csv">Condonados Multas</a>';
          break;
        case 3:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Condonadosart146BCFF.csv">Condonados Mercantil</a>';
          break;
        case 4:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Condonadosart21CFF.csv">Condonados Recargos</a>';
          break;
        case 5:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/CondonadosporDecreto.csv">Condonados Decreto</a>';
          break;
        case 6:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Condonados_07_15.csv">Condonados 2007-2015</a>';
          break;
        case 7:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Cancelados_07_15.csv">Cancelados 2007-2015</a>';
          break;
        case 8:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Retornoinversiones.csv">Retorno Inversiones</a>';
          break;
        case 9:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Exigibles.csv">Exigibles</a>';
          break;
        case 10:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Firmes.csv">Firmes</a>';
          break;
        case 11:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/No%20localizados.csv">No Localizados</a>';
          break;
        case 12:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Sentencias.csv">Sentencias</a>';
          break;
        case 13:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Eliminados.csv">Eliminados No Localizados</a>';
          break;
        case 1000:
          link = '<a href="http://omawww.sat.gob.mx/cifras_sat/Documents/Listado_Completo_69-B.csv">Listado Completo</a>'
          break;
        default:
          link = '';
      }
      return link;
    },

    initGrid: function (grid) {

      /*for (let i = 0; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }*/

      grid.columns[0].width = 100;
      grid.columns[grid.columns.length - 1].width = 60;

      this.selector = new Selector(grid, {
        itemChecked: () => {
          this.selectedItems = grid.rows.filter(r => r.isSelected);
        }
      });
    }, updateCheckall: function () {
      if (this.selected.length == this.items.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    cerrarDialogo() {
      this.showDialog = false;
      this.getReadBL(true);
    },
    verMasDatos(item) {
      this.selected = [];
      this.selected.push(item);
      let SourceType = item.Source === "1" ? "BL69B" : "BL69";

      var jsonData = {
        token: this.$store.state.token,
        searchType: SourceType,
        taxID: item.TaxID,
        source: item.Source1.toString(),
      };
      service
        .api()
        .BlackListDetail(jsonData)
        .then((response) => {
          if (response.result) {
            this.folioSelected = item.folio;
            this.serieSelected = item.serie;
            let payload = JSON.parse(response.payload);
            if (SourceType == "BL69B") {
              this.itemSelectedB = JSON.parse(payload.Dt69b);
              this.itemSelected = null;
            } else {
              this.itemSelected = JSON.parse(payload.Dt69);
              this.itemSelectedB = null;
            }
            this.currentContext = this.getContextoSub(item.Kind);
            this.currentRfc = item.TaxID;
            this.showDialog = true;
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    exportExcel69() {

      var headers = {
        Leida: "Leída",
        RFC: "RFC",
        Nombre: "Razón Social",
        Origen: "Origen",
        Estatus: "Estatus",
        Importado: "Importado",
        NombreLista: "Lista",
        TipoPersona: "Tipo Persona",
        Supuesto: "Supuesto",
        FechaCan: "Fecha Cancelación",
        Monto: "Monto",
        FechaPub: "Fecha Publicación",
        EntidadFed: "Entidad Federativa",
        FechaPrimera: "Fecha Primera Publicación",
        FechaPublicacion: "Fecha Publicación Con Monto",
        Anno: "Año",
        ImporteCondonado: "Importe Condonado",
        MotivoCon: "Motivo Condonación",
        ImportePesos: "Importe Pesos",
        Motivo: "Motivo",
      };
      var itemsFormatted = [];
      this.itemsCsv.forEach((item) => {
        itemsFormatted.push({
          Leida: helpers.ConvertToEmpty(item.Read === 0 ? "No" : "Si"),
          RFC: helpers.ConvertToEmpty(item.TaxID),
          Nombre: helpers.ConvertToEmpty(item.Name),
          Origen: helpers.ConvertToEmpty(this.getContexto(item.Kind)),
          Estatus: helpers.ConvertToEmpty(item.Active ? "Vigente" : "Eliminado"),
          Importado: helpers.ConvertToEmpty(item.Imported === 0 ? "No" : "Si"),
          NombreLista: helpers.ConvertToEmpty(item.ln69_Name),
          TipoPersona: helpers.ConvertToEmpty(item.ln69_TipoPersona),
          Supuesto: helpers.ConvertToEmpty(item.ln69_Supuesto),
          FechaCan: helpers.ConvertToEmpty(item.ln69_FechaCancelacion),
          Monto: helpers.ConvertToEmpty(item.ln69_Monto),
          FechaPub: helpers.ConvertToEmpty(item.ln69_FechaPublicacion),
          EntidadFed: helpers.ConvertToEmpty(item.ln69_EntidadFederativa),
          FechaPrimera: helpers.ConvertToEmpty(item.ln69_FechasPrimeraPublicacion),
          FechaPublicacion: helpers.ConvertToEmpty(item.ln69_FechaPublicacionConMonto),
          Anno: helpers.ConvertToEmpty(item.ln69_Anno),
          ImporteCondonado: helpers.ConvertToEmpty(item.ln69_ImporteCondonado),
          MotivoCon: helpers.ConvertToEmpty(item.ln69_MotivoCondonacion),
          ImportePesos: helpers.ConvertToEmpty(item.ln69_ImportePesos),
          Motivo: helpers.ConvertToEmpty(item.ln69_Motivo)
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Listas_Negras_69_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },

    exportExcel69B() {

      var headers = {
        Leida: "Leída",
        RFC: "RFC",
        Nombre: "Razón Social",
        Origen: "Origen",
        Estatus: "Estatus",
        Importado: "Importado",
        ln69b_Name: "Lista",
        ln69b_SituacionContribuyente: "Situacion Contribuyente",
        ln69b_NumeroFechaOficioGlobalPresuncionSat: "Número Fecha Oficio Global Presuncion Sat",
        ln69b_PublicacionPaginaSatPresuntos: "Publicación Pagina Sat Presuntos",
        ln69b_NumeroFechaOficioGlobalPresuncionDof: "Número Fecha Oficio Global Presunción Dof",
        ln69b_PublicacionDofPresuntos: "Publicación Dof Presuntos",
        ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronSat: "Número Fecha Oficio Global Contribuyente Desvirtuaron Sat",
        ln69b_PublicacionPaginaSatDesvirtuados: "Publicación Página Sat Desvirtuados",
        ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronDof: "Número Fecha Oficio Global Contribuyente Desvirtuaron Dof",
        ln69b_PublicacionDofDesvirtuados: "Publicación Dof Desvirtuados",
        ln69b_NumeroFechaOficioGlobalDefinitivosSat: "Número Fecha Oficio Global Definitivos Sat",
        ln69b_PublicacionPaginaSatDefinitivos: "Publicación Página Sat Definitivos",
        ln69b_NumeroFechaOficioGlobalDefinitivosDof: "Número Fecha Oficio Global Definitivos Dof",
        ln69b_PublicacionDofDefinitivos: "Publicación Dof Definitivos"
      };
      var itemsFormatted = [];
      this.itemsCsv.forEach((item) => {
        itemsFormatted.push({
          Leida: helpers.ConvertToEmpty(item.Read === 0 ? "No" : "Si"),
          RFC: helpers.ConvertToEmpty(item.TaxID),
          Nombre: helpers.ConvertToEmpty(item.Name),
          Origen: helpers.ConvertToEmpty(this.getContexto(item.Kind)),
          Estatus: helpers.ConvertToEmpty(item.Active ? "Vigente" : "Eliminado"),
          Importado: helpers.ConvertToEmpty(item.Imported === 0 ? "No" : "Si"),


          ln69b_Name: helpers.ConvertToEmpty(item.ln69b_Name),
          ln69b_SituacionContribuyente: helpers.ConvertToEmpty(item.ln69b_SituacionContribuyente),
          ln69b_NumeroFechaOficioGlobalPresuncionSat: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalPresuncionSat),
          ln69b_PublicacionPaginaSatPresuntos: helpers.ConvertToEmpty(item.ln69b_PublicacionPaginaSatPresuntos),
          ln69b_NumeroFechaOficioGlobalPresuncionDof: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalPresuncionDof),
          ln69b_PublicacionDofPresuntos: helpers.ConvertToEmpty(item.ln69b_PublicacionDofPresuntos),
          ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronSat: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronSat),
          ln69b_PublicacionPaginaSatDesvirtuados: helpers.ConvertToEmpty(item.ln69b_PublicacionPaginaSatDesvirtuados),
          ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronDof: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalContribuyenteDesvirtuaronDof),
          ln69b_PublicacionDofDesvirtuados: helpers.ConvertToEmpty(item.ln69b_PublicacionDofDesvirtuados),
          ln69b_NumeroFechaOficioGlobalDefinitivosSat: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalDefinitivosSat),
          ln69b_PublicacionPaginaSatDefinitivos: helpers.ConvertToEmpty(item.ln69b_PublicacionPaginaSatDefinitivos),
          ln69b_NumeroFechaOficioGlobalDefinitivosDof: helpers.ConvertToEmpty(item.ln69b_NumeroFechaOficioGlobalDefinitivosDof),
          ln69b_PublicacionDofDefinitivos: helpers.ConvertToEmpty(item.ln69b_PublicacionDofDefinitivos)

        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Listas_Negras_69B_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    start() {
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    getReport() {
      this.goPage = 1;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.items = [];
      this.getReporteItems();
    },

    getReadBL(leida) {
      let listaAlertas = [];

      let innerData = this.selectedItems.map(function (objeto) {
        return objeto._data;
      });

      innerData.forEach(function (entry) {


        entry.Read = leida ? "1" : "0";
        listaAlertas.push({
          idAlert: entry.IdAlert,
          idUser: entry.IdUser,
        });
      });
      var jsonData = {
        token: this.$store.state.token,
        readStatus: leida ? "1" : "0",
        alertList: listaAlertas,
      };

      service
        .api()
        .BlackListSetRead(jsonData)
        .then((response) => {
          if (response.result) {

            var idToReadMap = {};
            for (var i = 0; i < this.selectedItems.length; i++) {
              idToReadMap[this.selectedItems[i].IdAlert] = this.selectedItems[i].Read;
            }

            // Actualizar directamente la segunda lista con los valores mapeados
            for (var j = 0; j < this.items.length; j++) {
              var IdAlert2 = this.items[j].IdAlert;
              if (idToReadMap.hasOwnProperty(IdAlert2)) {
                this.items[j].Read = leida ? "1" : "0";
              }
            }


            let leido = "";
            switch (this.tipoLeida) {
              case "No Leída":
                leido = 0;
                break;
              case "Leída":
                leido = 1;
                break;
              default:
                leido = "";
                break;
            }
            if (leido !== "")
              this.items = this.items.filter((f) => f.Read == leido);
            this.totalDocumentos -= listaAlertas.length;
            this.doctosPagina -= listaAlertas.length;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteItems() {
      this.items = [];
      this.selected = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
        page: this.noPagina,
        pageSize: this.limit,
        order: this.orderF,
        filters: this.getFiltro(),
      };

      var jsonDataTo = {
        token: this.$store.state.token,
        page: this.noPagina,
        pageSize: this.limit,
        order: this.orderF,
        tipoLeida: this.tipoLeida,
        tipoDoc: this.tipoDoc,
        tipoStatus: this.tipoStatus,
        rfcSearch: this.rfcSearch,
        nombreSearch: this.nombreSearch
      };

      this.$store.commit("setBlData", jsonDataTo);
      service
        .api()
        .ReporteBlackList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = JSON.parse(payload.Lines);
            if (this.items.length > 0) {
              this.doctosPagina = this.items.length;
              this.totalDocumentos = payload.LinesCount;
              this.totalPaginas = Math.ceil(
                this.totalDocumentos / this.pageSize
              );
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No se encontraron alertas con el criterio de búsqueda seleccionado");
            }
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteItemsBl() {
      this.items = [];
      this.selected = [];
      this.goPage = this.jsonDataBL.page;
      var jsonData = {
        token: this.$store.state.token,
        page: this.noPagina,
        pageSize: this.limit,
        order: this.orderF,
        filters: this.getFiltro(),
      };

      service
        .api()
        .ReporteBlackList(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = JSON.parse(payload.Lines);
            if (this.items.length > 0) {
              this.doctosPagina = this.items.length;
              this.totalDocumentos = payload.LinesCount;
              this.totalPaginas = Math.ceil(
                this.totalDocumentos / this.pageSize
              );
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No se encontraron alertas con el criterio de búsqueda seleccionado.");
            }
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getCronologia() {
      var jsonData = {
        token: this.$store.state.token,
        context: this.currentContext,
        taxID: this.currentRfc,
      };
      service
        .api()
        .BlackListCrono(jsonData)
        .then((response) => {
          if (response.result) {
            this.itemsCrono = JSON.parse(response.payload);
            this.showDialogCrono = true;
            this.showDialog = false;
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },

    getReporteConciliatorioCSV() {




      var jsonData = {
        token: this.$store.state.token,
        page: this.noPagina,
        pageSize: this.limit,
        order: this.orderF,
        filters: this.getFiltro(),
      };



      service
        .api()
        .ReporteBlackListDetallado(jsonData)
        .then((response) => {
          if (response.result) {

            this.itemsCsv = JSON.parse(response.payload);

            if (this.origen == "0") {
              this.exportExcel69();
            }
            else {
              this.exportExcel69B();
            }


          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });



    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },
    getContexto(vkind) {
      let kind = "";
      if (vkind == "S") kind = "Emitido";
      else if (vkind == "R") kind = "Recibido";
      else if (vkind == "RET") kind = "Retención";
      else if (vkind == "O") kind = "Propio";

      return kind;
    },
    getContextoSub(vkind) {
      let kind = "";
      if (vkind == "S") kind = "E";
      else if (vkind == "R") kind = "R";

      return kind;
    },
    getFiltro() {
      let filtro = "<Filters>";

      filtro +=
        '<Filter Name="Source" Oper="=" Value1="' +
        this.origen +
        '" Value2=""/>';

      let contexto = "";

      let leido = "";

      switch (this.tipoLeida) {
        case "No Leída":
          leido = "0";
          break;
        case "Leída":
          leido = "1";
          break;
        default:
          leido = "";
          break;
      }

      switch (this.tipoDoc) {
        case "Seleccionar Todas":
          contexto = "";
          break;
        case "Emitidos":
          contexto = "S";
          break;
        case "Recibidos":
          contexto = "R";
          break;
        case "Retenciones":
          contexto = "RET";
          break;
        case "RFC Propio":
          contexto = "O";
          break;
        default:
          contexto = "";
          break;
      }
      let status = "";


      switch (this.tipoStatus) {
        case "Seleccionar Todas":
          status = "";
          break;
        case "Vigente":
          status = "1";
          break;
        case "Eliminado":
          status = "0";
          break;
        default:
          status = "";
          break;
      }

      if (!this.isEmpty(this.rfcSearch))
        filtro +=
          '<Filter Name="TaxID" Oper="LIKE" Value1="%' +
          this.rfcSearch +
          '%" Value2=""/>';
      if (!this.isEmpty(this.nombreSearch))
        filtro +=
          '<Filter Name="Name" Oper="LIKE" Value1="%' +
          this.nombreSearch +
          '%" Value2=""/>';
      if (!this.isEmpty(contexto))
        filtro +=
          '<Filter Name="Kind" Oper="=" Value1="' + contexto + '" Value2=""/>';
      if (!this.isEmpty(leido))
        filtro +=
          '<Filter Name="Read" Oper="=" Value1="' + leido + '" Value2=""/>';
      if (!this.isEmpty(status))
        filtro +=
          '<Filter Name="Active" Oper="=" Value1="' + status + '" Value2=""/>';

      filtro += '</Filters>'

      return helpers.Base64Encode(filtro);
    },
    getFiltroIn() {
      let filtro = "<Filters>";

      filtro +=
        '<Filter Name="Active" Oper="=" Value1="1" Value2=""/><Filter Name="Source" Oper="=" Value1="' +
        this.origen +
        '" Value2=""/>';

      let contexto = "";

      let leido = "";

      switch (this.tipoLeidaIn) {
        case "No Leída":
          leido = "0";
          break;
        case "Leída":
          leido = "1";
          break;
        default:
          leido = "";
          break;
      }

      switch (this.tipoDocIn) {
        case "Emitidos":
          contexto = "S";
          break;
        case "Recibidos":
          contexto = "R";
          break;
        default:
          contexto = "";
          break;
      }
      let status = "";

      switch (this.tipoStatusIn) {
        case "Seleccionar Todas":
          status = "";
          break;
        case "Vigente":
          status = "1";
          break;
        case "Eliminado":
          status = "0";
          break;
        default:
          status = "";
          break;
      }

      if (!this.isEmpty(this.rfcSearchIn))
        filtro +=
          '<Filter Name="TaxID" Oper="LIKE" Value1="%' +
          this.rfcSearch +
          '%" Value2=""/>';
      if (!this.isEmpty(this.nombreSearchIn))
        filtro +=
          '<Filter Name="Name" Oper="LIKE" Value1="%' +
          this.nombreSearch +
          '%" Value2=""/>';
      if (!this.isEmpty(contexto))
        filtro +=
          '<Filter Name="Kind" Oper="=" Value1="' + contexto + '" Value2=""/>';
      if (!this.isEmpty(leido))
        filtro +=
          '<Filter Name="Read" Oper="=" Value1="' + leido + '" Value2=""/>';
      if (!this.isEmpty(status))
        filtro +=
          '<Filter Name="Active" Oper="=" Value1="' + status + '" Value2=""/>';

      filtro += '</Filters>'

      return helpers.Base64Encode(filtro);
    },
    isEmpty(val) {
      return val === undefined || val === null || val.length <= 0
        ? true
        : false;
    },
    goCon(rfcC, yearC) {
      if (this.currentContext === 'E') {
        if (!this.allowIssued) {

          this.alert_errorR = true;
          this.errorMessageR = "No cuenta con permiso para ver documentos Emitidos";
          setTimeout(() => {
            this.alert_errorR = false;
            this.errorMessageR = "";
          }, 10000);
          return;
        }
      }
      if (this.currentContext === 'R') {
        if (!this.allowReceived) {

          this.alert_errorR = true;
          this.errorMessageR = "No cuenta con permiso para ver documentos Recibidos";
          setTimeout(() => {
            this.alert_errorR = false;
            this.errorMessageR = "";
          }, 10000);
          return;
        }
      }


      let pathL = "/conciliatorio/bl";
      this.$store.commit("setFromCrono", {
        rfc: rfcC,
        year: yearC,
        contexto: this.currentContext,
      });
      setTimeout(() => {
        this.$router.push({
          path: pathL,
        });
      }, 1000);
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },

    DescargarReporte(itemId) {
      return new Promise((resolve, reject) => {
        const jsonData = {
          id: itemId,
          token: this.$store.state.token,
        };

        service
          .api()
          .RetrieveOnDemandReport(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setOverlay", false);
              this.retrieveReporte(response.payload);
              resolve(response.payload); // Resolver con el payload
            } else {
              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
              reject(new Error(response.errorMessage)); // Rechazar con mensaje de error
            }
          })
          .catch((error) => {
            reject(error); // Rechazar si ocurre un error en el servicio
          });
      });
    },
    descargarMultiple() {
      this.descargarTodosEnParalelo(this.selectedItemsDescarga);

    },
    async descargarTodosEnParalelo(items) {
      try {
        const promesas = items.map((item) => this.DescargarReporte(item.id));
        await Promise.all(promesas);
        console.log("Todas las descargas han finalizado.");
      } catch (error) {
        console.error("Error en una o más descargas:", error);
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItemsDescarga = [...this.itemsGrandes];
      } else {
        this.selectedItemsDescarga = [];
      }
    },

    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: 'REPORT-CFDI-CONCILIATORIO-BL'
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteOnDemandReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    requestItems() {
      this.showDialogCrear = false;
      this.showDialogReports = false;
      let jsonDataInit = {
        token: this.$store.state.token,
        filters: this.getFiltroIn(),
        order: "EnrolledTimeStamp"
      }


      service
        .api()
        .ReporteBlackListOnDemand(jsonDataInit)
        .then((response) => {
          if (response.result) {

            let rfcs = response.payload;

            let jsonData = {
              reportType: 'REPORT-CFDI-CONCILIATORIO-BL',
              name: this.nombreIn,
              description: this.descripcionIn,
              commands: JSON.stringify({ context: this.tipoDocIn.substr(0, 1), rfcs: rfcs }),
              token: this.$store.state.token,
            };

            service
              .api()
              .RequestOnDemandReport(jsonData)
              .then((response) => {
                if (response.result) {
                  this.alert_okIn = true;
                  this.okMessageIn = "Reporte Solicitado Correctamente";
                  setTimeout(() => {
                    this.alert_okIn = false;
                    this.okMessageIn = "";
                  }, 10000);

                } else {
                  this.alert_errorIn = true;
                  this.errorMessageIn = response.errorMessage;
                  setTimeout(() => {
                    this.alert_errorIn = false;
                    this.errorMessageIn = "";
                  }, 10000);
                }
                this.showDialogReports = true;
              });

            this.descripcionIn = "";
            this.nombreIn = "";
            this.tipoDocIn = "Emitidos";
            this.tipoLeidaIn = "No Leída";
            this.tipoStatusIn = "Vigente";
            this.rfcSearchIn = "";
            this.nombreSearchIn = "";
            this.showDialogReports = true;
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
            this.showDialogReports = true;
          }

        });




    },

    abrirDialogCrear() {
      this.descripcionIn = "";
      this.nombreIn = "";
      this.tipoDocIn = "Emitidos";
      this.tipoLeidaIn = "No Leída";
      this.tipoStatusIn = "Vigente";
      this.rfcSearchIn = "";
      this.nombreSearchIn = "";
      this.showDialogCrear = true;
    },
    ObtenerReportes() {
      this.showDialogReports = true;
      var jsonData = {
        token: this.$store.state.token,
        reportType: 'REPORT-CFDI-CONCILIATORIO-BL'
      };

      service
        .api()
        .RetrieveOnDemandReports(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;

            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
  },
  computed: {
    mostrarDescargarSeleccionado() {
      return this.selectedItemsDescarga.length > 0;
    },
    showCreateButton() {
      //return this.selectedDatesIn.length > 1  && this.descripcionIn.length > 0;
      return this.descripcionIn.length > 0;
    },
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    showSearchButton() {
      return true;
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    lnSelect() {
      var opciones = [
        "Seleccionar Todas",
        "Retenciones",
        "RFC Propio",
      ];
      if (this.allowIssued)
        opciones.push("Emitidos");
      if (this.allowReceived)
        opciones.push("Recibidos");


      return opciones;
    },

    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    },

  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.normal {
  font-weight: regular;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
