<template>
    <div class="py-10">
        <v-container fluid class="px-10">
            <v-row justify="center">
                <v-dialog v-model="showDialogRelacionados" persistent max-width="1500px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogRelacionados = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Documentos Relacionados del documento :
                                {{ itemSelected.serie }} -
                                {{ itemSelected.folio }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-if="esRecibido">RFC Emisor</th>
                                            <th v-if="esRecibido">Razon Social Emisor</th>
                                            <th v-if="esEmitido">RFC Receptor</th>
                                            <th v-if="esEmitido">Razon Social Receptor</th>
                                            <th>Serie</th>
                                            <th>Folio</th>
                                            <th>Tipo Comprobante</th>
                                            <th>Moneda</th>
                                            <th>Total</th>
                                            <th style="white-space: nowrap">Fecha Emisión</th>
                                            <!-- <th>Fecha Cancelación</th> -->
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in itemsRel" :key="item.id" class="altura">
                                            <td v-if="esRecibido">{{ item.emisor.rfc }}</td>
                                            <td v-if="esRecibido">{{ item.emisor.nombre }}</td>
                                            <td v-if="esEmitido">{{ item.receptor.rfc }}</td>
                                            <td v-if="esEmitido">{{ item.receptor.nombre }}</td>
                                            <td>{{ item.serie }}</td>
                                            <td>{{ item.folio }}</td>
                                            <td>
                                                {{ getTipoComprobante(item.tipo_comprobante) }}
                                            </td>
                                            <td>{{ item.moneda }}</td>
                                            <td>{{ currencyFormat(item.total) }}</td>
                                            <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                                            <!-- <td>
                                            {{ item.fecha_cancelacion }}
                                        </td> -->
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                                                            <v-icon>mdi-xml</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar XML</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                                                            <v-icon>mdi-file-pdf-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar PDF</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="showDialogRelacionados = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="showDialogStatus" persistent max-width="700px">
                    <v-card dense>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="cerrarStatus">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Detalle documento : {{ itemSelected.serie }} -
                                {{ itemSelected.folio }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="ma-3">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Estado</th>
                                            <th>Cancelable</th>
                                            <th>Estado Cancelación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="altura">
                                            <td>{{ selectedStatus.estado }}</td>
                                            <td>{{ selectedStatus.cancelable }}</td>
                                            <td>{{ selectedStatus.estado_cancelacion }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <dialogo ref="dialog" :itemFind="itemFind"></dialogo>

            </v-row>
            <v-row>
                <v-col cols="12">
                    <h3>{{ titulo }}</h3>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <v-row>
                            <v-col cols="12" pa="4" md="2" class="mx-2" justify="center">
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="100px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="date" label="Fecha Inicial*"
                                            prepend-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on"
                                            :disabled="dateTs.length > 0"></v-text-field>
                                    </template>
                                    <v-date-picker :min="fechaInicial" v-model="date" @change="cambio = true" locale="es"
                                        @input="menu = false" :disabled="dateTs.length > 0"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" pa="4" md="2" justify="center">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="100px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="date2" label="Fecha Final*"
                                            :disabled="dateTs.length > 0 || date.length == 0"
                                            prepend-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker :min="date" v-model="date2" @change="cambio = true" locale="es"
                                        @input="menu2 = false" :disabled="dateTs.length > 0">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field :disabled="dateTs.length > 0" v-model="rfcSearch" @change="cambio = true"
                                    counter="0" :label="labelRfc" maxlength="13" class="my-1"></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="2">
                                <v-select :items="itemsSelect" v-model="tipoDoc" label="Tipo de Comprobante" outlined
                                    :disabled="dateTs.length > 0"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-container fluid>
                                    <div class="my-2">
                                        <v-btn color="primary" white large v-on:click="getReport"
                                            :disabled="!showSearchButton">Mostrar Listado</v-btn>
                                    </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
                            {{ totalDocumentos }}</v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>

                        <v-tooltip top class="d-none d-sm-flex">
                            <template v-slot:activator="{ on }">
                                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP">
                            </v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                                        mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline
                            </v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>



                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGrid" :allowResizing="'None'">

                            <wj-flex-grid-column v-if="esRecibido" :header="'RFC Emisor'" :binding="'emisor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esRecibido" :header="'Razón Social'" :binding="'emisor_nombre'"
                                width="4*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'receptor_rfc'"
                                width="2*" :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'receptor_nombre'"
                                width="4*" :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Descuento'" :binding="'descuento'" width="2*" format="c2"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
                            <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                                :isReadOnly="true" />

                            <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Estado del Documento</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarDocsRelacionados(cell.item)" icon color="primary" v-on="on">
                                                <v-icon>mdi-file-tree</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Documentos Relacionados</span>
                                    </v-tooltip>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        
                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Selector } from '@grapecity/wijmo.grid.selector';

import helpers from "../../scripts/helpers.js";
import Dialogo from '../Componentes/DialogoBuscar.vue';
import {
    JSONToCSV
} from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
    name: "Relacionados",
    components: {
        Dialogo
    },
    props: ["titulo", "contexto"],
    data: () => ({
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        selectedRows: [],
        date: "",
        date2: "",
        menu: false,
        menu2: false,
        showDialog: false,
        showDialogStatus: false,
        goPage: 1,
        selectedStatus: {
            estado: "",
            cancelable: "",
            estado_cancelacion: "",
        },
        cambio: false,
        folioDoc: "",
        modal: false,




        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalIngresos: 0,
        totalEgresos: 0,
        totalTraslados: 0,
        totalPagos: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        tipoDoc: "Seleccionar Todos",
        itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso"],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision",
        rfcRClass: false,
        serieClass: false,
        fechaEClass: true,
        rfcEClass: false,
        folioClass: false,
        monedaClass: false,
        tipo_comprobanteClass: false,
        metodo_pagoClass: false,
        itemSelected: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        rfcSearch: "",
        dateTs: "",
        showDialogRelacionados: false,
        itemsRel: [],
    }),
    created() {
        this.$store.commit("setIsRetencion", false);
        this.$store.commit("setBlData", null);
        let dataCronologia = this.$store.state.fromCrono;
        let dataInterval = this.$store.state.dateInterval;

        if (dataCronologia === null && dataInterval === null) return;

        if (dataCronologia !== null) {
            this.rfcSearch = dataCronologia.rfc;
            this.date = dataCronologia.year + "-01-01";
            this.date2 = dataCronologia.year + "-12-31";
            this.$store.commit("setFromCrono", null);
        }

        if (dataInterval !== null) {
            //alert(JSON.stringify(dataInterval));
            this.date = dataInterval.dateInit;
            this.date2 = dataInterval.dateEnd;
            this.dateTs = dataInterval.dateTs;
            this.tipoDoc =
                this.$store.state.tipoDocTs === "" ?
                    "Seleccionar Todos" :
                    this.$store.state.tipoDocTs;
            this.$store.commit("setTipoDocTs", "");
            this.$store.commit("setDateInterval", null);
        }

        this.getReport();
    },
    methods: {

        initGrid: function (grid) {

            for (let i = 0; i < grid.columns.length - 1; i++) {
                grid.autoSizeColumn(i);
                grid.columns[i].width += 20;
            }


            grid.columns[grid.columns.length - 1].width = 200;


        },
        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id,
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contexto,
                uuid: item.id,
                issuedDate: item.fecha_emision,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (item.tipo === "R")
                par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
            else par = item.tipo + "|" + item.pac + "|";
            let parameters = par + "|" + item.id + "|" + item.fecha_emision;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 3000);
        },
        verMasDatos(item) {
            this.itemSelected = item;
            this.showDialog = true;
        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        tipoRelacionFormat(value) {
            return helpers.TipoRelacion(value);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.date);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
            if (this.cambio) {
                this.cambio = false;
            }
        },
        getReporteItems() {
            this.items = [];
            this.selectedRows = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.date,
                dateEnd: this.date2,
                context: this.contexto,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
            };
            service
                .api()
                .RelacionadosList(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;

                        this.doctosPagina = this.items.length.toString();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.date,
                dateEnd: this.date2,
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                context: this.contexto,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
            };

            service
                .api()
                .RelacionadosCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReporteConciliatorioCSV() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.date,
                dateEnd: this.date2,
                context: this.contexto,
                rfc: this.rfcSearch,
                docType: this.getTipoDoc(),
                dateTs: this.dateTs,
            };
            service
                .api()
                .RelacionadosCSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        JSONToCSV(
                            this.csvData,
                            "Reporte_Relacionados_" + helpers.dateNowCSV(),
                            true
                        );
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },

        ConsultarDocsRelacionados(item) {
            let uuids = [];

            if (item.cfdi_relacionados) {

                item.cfdi_relacionados.forEach(function (element) {
                    uuids = [...uuids, ...element.uuids];
                });
            }
            else {
                uuids = item.uuids;
            }
            var jsonData = {
                token: this.$store.state.token,
                parentDate: item.fecha_emision.substr(0, 10),
                uuids: uuids,
            };

            service
                .api()
                .RelacionadosDocs(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.showDialogRelacionados = true;
                        this.itemSelected = item;
                        this.itemsRel = JSON.parse(response.payload);
                        if (response.payload == "[]") {
                            this.showDialogRelacionados = false;

                            this.$store.commit("setAlertOk", false);
                            this.$store.commit("setAlertError", true);
                            this.$store.commit("setAlertMessage", "El o los documentos relacionados a los que se hacen referencia no se encuentran en la base de datos");
                        }
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        ConsultarEstadoDoc(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: item.tipo,
                uuid: item.id,
                issuedDate: item.fecha_emision,
                issuer: item.emisor_rfc,
                receiver: item.receptor_rfc,
                total: item.total,
            };

            service
                .api()
                .Consult(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.selectedStatus = JSON.parse(response.payload);
                        this.itemSelected = item;
                        this.showDialogStatus = true;
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        cerrarStatus() {
            this.showDialogStatus = false;
            if (this.selectedStatus.estado == "Cancelado") this.getReport();
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.rfcRClass = false;
            this.serieClass = false;
            this.fechaEClass = false;
            this.rfcEClass = false;
            this.folioClass = false;
            this.monedaClass = false;
            this.tipo_comprobanteClass = false;
            this.metodo_pagoClass = false;

            switch (columnName) {
                case "receptor.rfc":
                    this.rfcRClass = true;
                    break;
                case "serie":
                    this.serieClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
                case "emisor.rfc":
                    this.rfcEClass = true;
                    break;
                case "folio":
                    this.folioClass = true;
                    break;
                case "moneda":
                    this.monedaClass = true;
                    break;
                case "tipo_comprobante":
                    this.tipo_comprobanteClass = true;
                    break;
                case "metodo_pago":
                    this.metodo_pagoClass = true;
                    break;
            }
        },

        getTipoDoc() {
            let firstLetter = this.tipoDoc.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
    },
    computed: {
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto === "E";
        },
        esRecibido() {
            return this.contexto === "R";
        },

        showSearchButton() {
            return this.date.length > 0 && this.date2.length > 0;
        },
        showSearchButtonGUID() {
            return this.guidSearch.length > 0;
        },
        labelRfc() {
            if (this.contexto === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        }
    },
};
</script>

<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}


.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}


.wj-flexgrid {
    max-height: 600px;
    margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
    background: rgb(255, 255, 125);
}</style>
