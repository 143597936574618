<template>
    <v-container fluid class="my-10">
        <v-row>


            <v-dialog v-model="showConfirmacion" max-width="400px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="showConfirmacion = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Confirmación de Solicitud </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <br />
                        <strong>Este proceso generará un costo adicional acorde a la cantidad de documentos
                            seleccionados para descargar.

                            <br />
                            ¿Desea continuar?
                        </strong>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>


                        <v-btn color="primary" @click="requestItems" text large>
                            Confirmar
                        </v-btn>
                        <v-btn color="danger" text @click="showConfirmacion = false">
                            Cancelar
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-col cols="12">

            </v-col>
            <v-col cols="12">
                <h3>Descarga Masiva</h3>
            </v-col>
            <v-col cols="11">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="11">
                <v-card class="paddingCard">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="4">
                                <date-range-picker ref="dateRangePickerRef" v-model="selectedDates"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="2" class="min-width-control-xxl">
                                <v-select :items="itemsOrigen" v-model="tipoDocM" label="Tipo de Comprobante" outlined
                                    multiple></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="min-width-control-xxl">
                                <v-select :items="itemsOrigenContexto" v-model="contexto" label="Contexto" outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4" justify="left" class="min-width-control-xxl">

                                <v-text-field v-model="descripcionDM" counter="0" label="Descripción*" class="my-1"
                                    maxlength="100">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn :disabled="!showCreateButton" color="primary" white large v-on="on"
                                            v-on:click="showConfirmacion = true">Solicitar
                                            Descarga</v-btn>
                                    </template>
                                    <span>Solicitar Descarga de Forma Masiva(zip)</span>
                                </v-tooltip>
                            </v-col>

                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="11">
                <DescargaMasivasList ref="descargaMasivaList" />
            </v-col>


        </v-row>
    </v-container>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import DescargaMasivasList from '../Componentes/DescargaMasivasList.vue';

export default {
    name: "DescargaMasiva",
    components: {
        DateRangePicker,
        DescargaMasivasList
    },

    data: () => ({
        showConfirmacion: false,
        descripcionDM: "",
        selectedDates: [],
        itemsSelect: [],
        itemsGrandes: [],
        tipoDocM: [],
        itemDesc: "",
        contexto: "Emitidos",
        modal: false,
        search: "",
        items: [],
        landscape: false,
        title: "",
    }),
    mounted() {
        this.ObtenerReportes();
        this.$store.commit("setIsDescargaMasiva", false);
    },

    methods: {

        ObtenerReportes() {
            if (this.$refs.descargaMasivaList) {
                this.$refs.descargaMasivaList.GetDescargas();
            }
        },
        requestItems() {
            this.items = [];

            this.checkboxes = [];


            let filters = {
                dateInit: this.checkDatesEquality() ? this.selectedDates[1] : this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substring(0, 1),
                rfc: "",
                docType: this.getFormatTipoComprobante(),
                tags: "",
                note: "",
                retenciones: "",
                traslados: "",
                tipo_factor: "",
                emisor_regimen_fiscal: "",
                receptor_regimen_fiscal: "",
                serie: "",
                folio: "",
                complementos: ""
            };

            let jsonData = {
                description: this.descripcionDM,
                filters: filters,
                token: this.$store.state.token,
            };
            service
                .api()
                .DescargaMasivaRequest(jsonData)
                .then((response) => {
                    if (response.result) {

                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertMessage", "Descarga Masiva solicitada correctamente");
                        this.showConfirmacion = false;
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },

        checkDatesEquality() {
            const dateRangePicker = this.$refs.dateRangePickerRef;
            if (dateRangePicker) {
                return dateRangePicker.areDatesEqual();

            }

            return false;
        },

        DescargarReporte(itemId) {
            var jsonData = {
                id: itemId,
                token: this.$store.state.token,
                reportType: 'REPORT-AGE-ANALYSIS'
            };

            service
                .api()
                .RetrieveOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.retrieveReporte(response.payload);
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        getFormatTipoComprobante() {

            let tipoText = "";
            if (this.tipoDocM.length == 0) {
                tipoText = "";
            }
            else {
                tipoText = this.tipoDocM.map(texto => texto.charAt(0)).join(',');
            }
            return tipoText
        },

    },
    computed: {
        showCreateButton() {
            return this.selectedDates.length > 1 && this.descripcionDM.length > 0;
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        hasNomina() {
            return this.$store.state.isPayroll === true;
        },

        itemsOrigenContexto() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
        itemsOrigen() {
            let elements = ["Ingreso", "Egreso", "Traslado", "Pago"];

            if (this.hasNomina)
                elements.push("Nomina");

            return elements;
        },
    },
};
</script>
<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}

.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}

.min-width-control-xxl {
    min-width: 250px;
}
</style>