<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <v-dialog v-model="showDialogStatus" persistent max-width="700px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarStatus">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title
                >Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Estado</th>
                      <th>Cancelable</th>
                      <th>Estado Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ selectedStatus.estado }}</td>
                      <td>{{ selectedStatus.cancelable }}</td>
                      <td>{{ selectedStatus.estado_cancelacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title
                >Detalle documento : {{ itemSelected.serie }} -
                {{ itemSelected.folio }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="ma-3">
                <template v-slot:default>
                  <tr>
                    <td>
                      <v-card
                        class="mx-auto pa-2 ma-2"
                        max-width="344"
                        min-width="420"
                        min-height="190"
                      >
                        <p class="display-1 text--primary">Emisor</p>
                        <div class="body-1">
                          Razon Social: {{ itemSelected.emisor_nombre }}
                        </div>
                        <div class="body-1">
                          RFC: {{ itemSelected.emisor_rfc }}
                        </div>
                        <div class="body-1">
                          Regimen: {{ itemSelected.regimen }}
                        </div>
                      </v-card>
                    </td>
                    <td>
                      <v-card
                        class="mx-auto pa-2 ma-2"
                        max-width="344"
                        min-width="420"
                        min-height="190"
                      >
                        <p class="display-1 text--primary">Receptor</p>
                        <div class="body-1">
                          Razon Social: {{ itemSelected.receptor_nombre }}
                        </div>
                        <div class="body-1">
                          RFC: {{ itemSelected.receptor_rfc }}
                        </div>
                        <div class="body-1">
                          Residencia: {{ itemSelected.residencia }}
                        </div>
                        <div class="body-1">
                          Uso CFDI: {{ itemSelected.usocfdi }}
                        </div>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </v-simple-table>
              <br />
              <h3>Folio Fiscal: {{ itemSelected.id }}</h3>
              <br />

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th>Tipo Comprobante</th>
                      <th>Método de Pago</th>
                      <th>Forma de Pago</th>
                      <th>Lugar Expedición</th>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <th>Fecha Cancelación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ itemSelected.serie }}</td>
                      <td>{{ itemSelected.folio }}</td>
                      <td>
                        {{ getTipoComprobante(itemSelected.tipo_comprobante) }}
                      </td>
                      <td>{{ itemSelected.metodo_pago }}</td>
                      <td>{{ itemSelected.forma_pago }}</td>
                      <td>{{ itemSelected.lugar_expedicion }}</td>
                      <td>{{ itemSelected.fecha_emision }}</td>
                      <td>{{ itemSelected.fecha_timbre }}</td>
                      <td>
                        {{ itemSelected.fecha_cancelacion }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="font-weight-black">Totales:</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">Tipo Cambio</th>
                      <th class="text-left">Moneda</th>
                      <th class="text-right">Total Retenciones</th>
                      <th class="text-right">Total Traslados</th>
                      <th class="text-right">Subtotal</th>
                      <th class="text-right">Descuento</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td align="right">
                        {{ itemSelected.tipo_cambio }}
                      </td>
                      <td>{{ itemSelected.moneda }}</td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_retenciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_traslados) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.subtotal) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.descuento) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>
            Conciliatorio -
            {{ this.contexto === "E" ? "Emitidos" : "Recibidos" }}
          </h3>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  disabled
                  v-model="date"
                  label="Fecha Inicial"
                  readonly
                  class="my-2 mx-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  disabled
                  v-model="date2"
                  label="Fecha Final"
                  readonly
                  class="my-2"
                ></v-text-field>
              </v-col>
               <v-col cols="12" md="1">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn
                      color="primary"
                      white
                      large
                      v-on:click="volver"
                      :v-show="this.blData != null"
                      >Volver al Listado</v-btn
                    >
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card v-if="showCantidades">
            <v-row>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title
                      >Cantidad Ingresos: {{ totalIngresos }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text v-if="totalIngresos > 0" class="text--primary">
                    <h2 v-if="!verIngreso">
                      <v-chip
                        class="ma-2"
                        color="primary"
                        @click="getReportConciliatorioImporte('I')"
                      >
                        Calcular Importe
                      </v-chip>
                    </h2>
                    <div v-else>
                      <v-simple-table>
                        <tr>
                          <td>
                            <h3>Total</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Subtotal</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.subtotal) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Retenciones</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total_retenciones) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Traslados</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidad.total_traslados) }} MXN
                          </td>
                        </tr>
                      </v-simple-table>
                    </div>
                  </v-card-text>
                  <v-card-text v-else class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title
                      >Cantidad Egresos: {{ totalEgresos }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text v-if="totalEgresos > 0" class="text--primary">
                    <h2 v-if="!verEgreso">
                      <v-chip
                        class="ma-2"
                        color="primary"
                        @click="getReportConciliatorioImporte('E')"
                      >
                        Calcular Importe
                      </v-chip>
                    </h2>
                    <div v-else>
                      <v-simple-table>
                        <tr>
                          <td>
                            <h3>Total</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Subtotal</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.subtotal) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Retenciones</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total_retenciones) }} MXN
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>Traslados</h3>
                          </td>
                          <td align="right">
                            ${{ currencyFormat(importesCantidadE.total_traslados) }} MXN
                          </td>
                        </tr>
                      </v-simple-table>
                    </div>
                  </v-card-text>
                  <v-card-text v-else class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title
                      >Cantidad Traslados: {{ totalTraslados }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card class="mx-5" max-width="300" title>
                  <v-app-bar dark color="second">
                    <v-toolbar-title
                      >Cantidad Pagos: {{ totalPagos }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h3>No aplica</h3>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex"
              >{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title
            >
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon
                  class="d-none d-sm-flex"
                  @click="getReporteConciliatorioCSV"
                  v-on="on"
                  :disabled="totalDocumentos > 5000"
                  color="second"
                  >mdi-file-export</v-icon
                >
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select
                v-model="pageSize"
                v-on:change="changePagination"
                :items="paginations"
                menu-props="auto"
                label="Total de Registros"
                hide-details
                class="ma-3 anchoP"
              ></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="d-none d-sm-flex mr-2"
                    @click="previous"
                    v-on="on"
                    large
                    color="second"
                    >mdi-arrow-up-bold-circle-outline</v-icon
                  >
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable"
                >mdi-arrow-up-bold-circle-outline</v-icon
              >

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="d-none d-sm-flex ml-2"
                    @click="next"
                    v-on="on"
                    large
                    color="second"
                    >mdi-arrow-down-bold-circle-outline</v-icon
                  >
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable"
                >mdi-arrow-down-bold-circle-outline</v-icon
              >

              <v-btn text>
                <v-text-field
                  type="number"
                   min=1
                   :max="totalPaginas"
                  v-model="goPage"
                  class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"
                ></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-simple-table
              v-if="esEmitido"
              min-height="600"
              fixed-header
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('serie')"
                        :class="{ negro: serieClass }"
                        >Serie</a
                      >
                    </th>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('folio')"
                        :class="{ negro: folioClass }"
                        >Folio</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Folio Fiscal
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('receptor.rfc')"
                        :class="{ negro: rfcEClass }"
                        >RFC Receptor</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Razón Social Receptor
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Fecha Cancelación
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('nfecha_emision')"
                        :class="{ negro: fechaEClass }"
                        >Fecha Emisión</a
                      >
                    </th>
                    <th class="text-right body-1">Retención ISR</th>
                    <th class="text-right body-1">Retención IVA</th>
                    <th class="text-right body-1">Retención IEPS</th>
                    <th class="text-right body-1">Traslado IVA</th>
                    <th class="text-right body-1">Traslado IEPS</th>
                    <th class="text-right body-1">Total Retenciones</th>
                    <th class="text-right body-1">Total Traslados</th>
                    <th class="text-right body-1">Subtotal</th>
                    <th class="text-right body-1">
                      <a @click="sortColumn('total')">Total</a>
                    </th>
                    <th class="text-right body-1">Descuento</th>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('moneda')"
                        :class="{ negro: monedaClass }"
                        >Moneda</a
                      >
                    </th>
                    <th class="text-right body-1" style="white-space: nowrap">
                      Tipo Cambio
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('tipo_comprobante')"
                        :class="{ negro: tipo_comprobanteClass }"
                        >Tipo Comprobante</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('metodo_pago')"
                        :class="{ negro: metodo_pagoClass }"
                        >Método Pago</a
                      >
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.name"
                    class="altura"
                    :style="{
                      backgroundColor:
                        item.fecha_cancelacion != null
                          ? '#FBE9E7'
                          : 'transparent',
                    }"
                    :class="selectedRows.indexOf(item.id) > -1 ? 'rowSel' : ''"
                  @click="rowClicked(item)"
                  >
                    <td>{{ item.serie }}</td>
                    <td>{{ item.folio }}</td>
                    <td style="white-space: nowrap">{{ item.id }}</td>
                    <td>{{ item.receptor_rfc }}</td>
                    <td>{{ item.receptor_nombre }}</td>
                    <td>
                      {{
                        item.fecha_cancelacion != null
                          ? item.fecha_cancelacion.substr(0, 10)
                          : ""
                      }}
                    </td>
                    <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_isr) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_iva) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_ieps) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.traslado_iva) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.traslado_ieps) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.total_retenciones) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.total_traslados) }}
                    </td>
                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                    <td align="right">{{ currencyFormat(item.total) }}</td>
                    <td align="right">{{ currencyFormat(item.descuento) }}</td>
                    <td>{{ item.moneda }}</td>
                    <td align="right">
                      {{ item.tipo_cambio }}
                    </td>
                    <td>{{ getTipoComprobante(item.tipo_comprobante) }}</td>
                    <td>{{ item.metodo_pago }}</td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="verMasDatos(item)"
                            icon
                            color="#4aa832"
                            v-on="on"
                          >
                            <v-icon>mdi-eye-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver Más</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="retrieveXML(item)"
                            icon
                            color="blue"
                            v-on="on"
                          >
                            <v-icon>mdi-xml</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar XML</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="retrievePDF(item)"
                            icon
                            color="red"
                            v-on="on"
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar PDF</span>
                      </v-tooltip>
                    </td>
                    <td v-if="item.fecha_cancelacion != null" class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="ConsultarEstadoDoc(item)"
                            icon
                            color="orange"
                            v-on="on"
                          >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Consultar Estado del Documento</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-simple-table
              v-if="esRecibido"
              min-height="600"
              fixed-header
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('emisor.rfc')"
                        :class="{ negro: rfcEClass }"
                        >RFC Emisor</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Razón Social Emisor
                    </th>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('serie')"
                        :class="{ negro: serieClass }"
                        >Serie</a
                      >
                    </th>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('folio')"
                        :class="{ negro: folioClass }"
                        >Folio</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Folio Fiscal
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      Fecha Cancelación
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('nfecha_emision')"
                        :class="{ negro: fechaEClass }"
                        >Fecha Emisión</a
                      >
                    </th>

                    <th class="text-right body-1">Retención ISR</th>
                    <th class="text-right body-1">Retención IVA</th>
                    <th class="text-right body-1">Retención IEPS</th>
                    <th class="text-right body-1">Traslado IVA</th>
                    <th class="text-right body-1">Traslado IEPS</th>
                    <th class="text-right body-1">Total Retenciones</th>
                    <th class="text-right body-1">Total Traslados</th>
                    <th class="text-right body-1">Subtotal</th>
                    <th class="text-right body-1">
                      <a @click="sortColumn('total')">Total</a>
                    </th>
                    <th class="text-right body-1">Descuento</th>
                    <th class="text-left body-1">
                      <a
                        @click="sortColumn('moneda')"
                        :class="{ negro: monedaClass }"
                        >Moneda</a
                      >
                    </th>
                    <th class="text-right body-1" style="white-space: nowrap">
                      Tipo Cambio
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('tipo_comprobante')"
                        :class="{ negro: tipo_comprobanteClass }"
                        >Tipo Comprobante</a
                      >
                    </th>
                    <th class="text-left body-1" style="white-space: nowrap">
                      <a
                        @click="sortColumn('metodo_pago')"
                        :class="{ negro: metodo_pagoClass }"
                        >Método Pago</a
                      >
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.name"
                    class="altura"
                    :style="{
                      backgroundColor:
                        item.fecha_cancelacion != null
                          ? '#FBE9E7'
                          : 'transparent',
                    }"
                    :class="selectedRows.indexOf(item.id) > -1 ? 'rowSel' : ''"
                  @click="rowClicked(item)"
                  >
                    <td>{{ item.emisor_rfc }}</td>
                    <td>{{ item.emisor_nombre }}</td>
                    <td>{{ item.serie }}</td>
                    <td>{{ item.folio }}</td>
                    <td>{{ item.id }}</td>
                    <td>
                      {{
                        item.fecha_cancelacion != null
                          ? item.fecha_cancelacion.substr(0, 10)
                          : ""
                      }}
                    </td>
                    <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_isr) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_iva) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.retencion_ieps) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.traslado_iva) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.traslado_ieps) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.total_retenciones) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(item.total_traslados) }}
                    </td>
                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                    <td align="right">{{ currencyFormat(item.total) }}</td>
                    <td align="right">{{ currencyFormat(item.descuento) }}</td>
                    <td>{{ item.moneda }}</td>
                    <td align="right">
                      {{ item.tipo_cambio }}
                    </td>
                    <td>{{ getTipoComprobante(item.tipo_comprobante) }}</td>
                    <td>{{ item.metodo_pago }}</td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="verMasDatos(item)"
                            icon
                            color="#4aa832"
                            v-on="on"
                          >
                            <v-icon>mdi-eye-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver Más</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="retrieveXML(item)"
                            icon
                            color="blue"
                            v-on="on"
                          >
                            <v-icon>mdi-xml</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar XML</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="retrievePDF(item)"
                            icon
                            color="red"
                            v-on="on"
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar PDF</span>
                      </v-tooltip>
                    </td>
                    <td v-if="item.fecha_cancelacion != null" class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="ConsultarEstadoDoc(item)"
                            icon
                            color="orange"
                            v-on="on"
                          >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Consultar Estado del Documento</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ConciliatorioBL",
  data: () => ({
    selectedRows: [],
     verIngreso: false,
    verEgreso: false,
    blData: null,
    date: "",
    date2: "",
    showCantidades: false,
    menu: false,
    menu2: false,
    showDialog: false,
    showDialogStatus: false,
    contexto: "E",

    goPage: 1,
    selectedStatus: {
      estado: "",
      cancelable: "",
      estado_cancelacion: "",
    },
    cambio: false,
    folioDoc: "",
    modal: false,
    
    
    
    
    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalIngresos: 0,
    totalEgresos: 0,
    totalTraslados: 0,
    totalPagos: 0,
    ImporteIngresos: 0,
    ImporteEgresos: 0,
    ImporteTraslados: 0,
    ImportePagos: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    tipoDoc: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Ingreso", "Egreso", "Traslado", "Pago"],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    verLimpiarFiltros: false,
    itemSelected: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    rfcSearch: "",
    dateTs: "",
    importesCantidad: {
      total: 0,
      subtotal: 0,
      total_retenciones: 0,
      total_traslados: 0
    },
    importesCantidadE: {
      total: 0,
      subtotal: 0,
      total_retenciones: 0,
      total_traslados: 0
    }
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    let dataCronologia = this.$store.state.fromCrono;
    let dataInterval = this.$store.state.dateInterval;
    this.blData = this.$store.state.blData;
    if (dataCronologia === null && dataInterval === null) {
      this.$store.commit("setBlData", null);
      return;
    }

    if (dataCronologia !== null) {
      this.rfcSearch = dataCronologia.rfc;
      this.date = dataCronologia.year + "-01-01";
      this.date2 = dataCronologia.year + "-12-31";
      this.contexto = dataCronologia.contexto;
      this.$store.commit("setFromCrono", null);
    }

    if (dataInterval !== null) {
      //alert(JSON.stringify(dataInterval));
      this.date = dataInterval.dateInit;
      this.date2 = dataInterval.dateEnd;
      this.dateTs = dataInterval.dateTs;
      this.tipoDoc =
        this.$store.state.tipoDocTs === ""
          ? "Seleccionar Todos"
          : this.$store.state.tipoDocTs;
      this.$store.commit("setTipoDocTs", "");
      this.$store.commit("setDateInterval", null);
      this.verLimpiarFiltros = true;
    }

    this.getReport();
    this.getReportConciliatorioCantidades();
  },
  methods: {
    rowClicked(row) {
      this.selectedRows = [];
      this.toggleSelection(row.id);
      console.log(row);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    CleanFilters() {
      this.dateTs = "";
      this.verLimpiarFiltros = false;
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto,
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor_rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 3000);
    },
    verMasDatos(item) {
      this.itemSelected = item;
      this.showDialog = true;
    },
    volver() {
      let pathL = this.$store.state.blRoute;
      setTimeout(() => {
        this.$router.push({
          path: pathL,
        });
      }, 1000);
    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: "RFC Emisor",
        NombreE: "Razon Social Emisor",
        RfcR: "RFC Receptor",
        NombreR: "Razon Social Receptor",
        CRegimen: "Código Regimen",
        NRegimen: "Regimen",
        ResidenciaR: "Residencia Receptor",
        CUsoCfdiR: "Código Uso CFDI",
        NUsoCfdiR: "Uso CFDI",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        FechaTimbre: "Fecha Timbre",
        Serie: "Serie",
        Folio: "Folio",
        LugarExpedicion: "Lugar de Expedicion",
        MetodoPago: "Método Pago",
        CFormaPago: "Código Forma de Pago",
        NFormaPago: "Forma de Pago",
        Moneda: "Moneda",
        TipoCambio: "Tipo Cambio",
        SubTotal: "Subtotal",
        Descuento: "Descuento",
        RetencionISR: "Retención ISR",
        RetencionIVA: "Retención IVA",
        RetencionIEPS: "Retención IEPS",
        TrasladoIVA: "Traslado IVA",
        TrasladoIEPS: "Traslado IEPS",
        TotalRetenciones: "Total Retenciones",
        TotalTraslados: "Total Traslados",
        Total: "Total",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.emisor_rfc),
          NombreE: helpers
            .ConvertToEmpty(item.emisor_nombre)
            .replace(/,/g, " "),
          RfcR: helpers.ConvertToEmpty(item.receptor_rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor_nombre),
          CRegimen: helpers.ConvertToEmpty(item.regimen),
          NRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.regimen))
            .replace(/,/g, " "),
          ResidenciaR: helpers.ConvertToEmpty(item.residencia),
          CUsoCfdiR: helpers.ConvertToEmpty(item.usocfdi),
          NUsoCfdiR: helpers
            .UsoCFDI(helpers.ConvertToEmpty(item.usocfdi))
            .replace(/,/g, " "),
          Tipo: helpers.ConvertToEmpty(item.tipo),
          TipoComprobante: helpers.ConvertToEmpty(item.tipo_comprobante),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
          NFormaPago: helpers
            .FormaPago(helpers.ConvertToEmpty(item.forma_pago))
            .replace(/,/g, " "),
          Moneda: helpers.ConvertToEmpty(item.moneda),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          Descuento: helpers.ConvertToEmpty(item.descuento),
          RetencionISR: helpers.ConvertToEmpty(item.retencion_isr),
          RetencionIVA: helpers.ConvertToEmpty(item.retencion_iva),
          RetencionIEPS: helpers.ConvertToEmpty(item.retencion_ieps),
          TrasladoIVA: helpers.ConvertToEmpty(item.traslado_iva),
          TrasladoIEPS: helpers.ConvertToEmpty(item.traslado_ieps),
          TotalRetenciones: helpers.ConvertToEmpty(item.total_retenciones),
          TotalTraslados: helpers.ConvertToEmpty(item.total_traslados),
          Total: helpers.ConvertToEmpty(item.total),
          PAC: helpers.ConvertToEmpty(item.pac),
        });
      });

       let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Fecha Final": this.date2,
        "Fecha Inicio": this.date,
        "Fecha de Solicitud":helpers.FechaActual(),
        "Reporte": "Listado Conciliatorio " + (this.contexto === "E" ? "Emitidos" : "Recibidos")
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Conciliatorio_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.limit = this.pageSize;
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
     this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
      this.verIngreso = false;
      this.verEgreso = false;
      this.getReportConciliatorioCantidades();
        
      
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
      };
      service
        .api()
        .ReporteConciliatorioBL(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
      };

      service
        .api()
        .ReporteConciliatorioCountBL(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConciliatorioCSV() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        rfc: this.rfcSearch,
        docType: this.getTipoDoc(),
      };

      service
        .api()
        .ReporteConciliatorioCSVBL(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido");
          }
        });
    },
    ConsultarEstadoDoc(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: item.tipo,
        uuid: item.id,
        issuedDate: item.fecha_emision,
        issuer: item.emisor_rfc,
        receiver: item.receptor_rfc,
        total: item.total,
      };

      service
        .api()
        .Consult(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.selectedStatus = JSON.parse(response.payload);
            this.itemSelected = item;
            this.showDialogStatus = true;
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReportConciliatorioImporte(tipoDoc) {
      
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        rfc: this.rfcSearch,
        docType: tipoDoc,
        dateTs: this.dateTs,
        dateTs1: "",
      };

      service
        .api()
        .ReporteConciliatorioTotalesPorTipo(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            switch (tipoDoc) {
              case "I":
                this.verIngreso = true;
                this.importesCantidad = JSON.parse(response.payload);
                //this.ImporteIngresos = response.payload;
                break;
              case "E":
                this.verEgreso = true;
                this.importesCantidadE = JSON.parse(response.payload);
                //this.ImporteEgresos = response.payload;
                break;
              case "P":
                //this.totalPagos = item.cantidad;
                //this.ImportePagos = item.importe;
                break;
              case "T":
                //this.totalTraslados = item.cantidad;
                //this.ImporteTraslados = item.importe;
                break;
            }
          }
        });
    },
    getReportConciliatorioCantidades() {
      this.totalIngresos = 0;
      this.totalEgresos = 0;
      this.totalPagos = 0;
      this.totalTraslados = 0;
      
      this.ImporteIngresos = 0;
      this.ImporteEgresos = 0;
      this.ImportePagos = 0;
      this.ImporteTraslados = 0;

      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        rfc: this.rfcSearch,
      };

      service
        .api()
        .ReporteConciliatorioConteoPorDocBL(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.showCantidades = true;
            let data = JSON.parse(response.payload);
            data.forEach((item) => {
              switch (item.tipo_comprobante) {
                case "I":
                  this.totalIngresos = item.cantidad;
                  //this.ImporteIngresos = item.importe;
                  break;
                case "E":
                  this.totalEgresos = item.cantidad;
                  //this.ImporteEgresos = item.importe;
                  break;
                case "P":
                  this.totalPagos = item.cantidad;
                  //this.ImportePagos = item.importe;
                  break;
                case "T":
                  this.totalTraslados = item.cantidad;
                  //this.ImporteTraslados = item.importe;
                  break;
              }
            });
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    cerrarStatus() {
      this.showDialogStatus = false;
      if (this.selectedStatus.estado == "Cancelado") this.getReport();
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
      }
    },

    getTipoDoc() {
      let firstLetter = this.tipoDoc.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto === "E";
    },
    esRecibido() {
      return this.contexto === "R";
    },
    showSearchButton() {
      return this.date.length > 0 && this.date2.length > 0;
    },
    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },
    labelRfc() {
      if (this.contexto === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}


.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
