<template>
  <div class="home">
    <ClientVendors titulo="Reporte de Proveedores" contexto="R" liga="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-proveedores" />
  </div>
</template>

<script>

import ClientVendors from "./ReportCVList";

export default {
  components: {
    ClientVendors
  }
};
</script>