<template>
  <div class="container-fluid">
    <wj-pivot-chart
      :items-source="ng"
      :showTitle="false"
      :initialized="initChart.bind(this)"
    ></wj-pivot-chart>
  </div>
</template>

<script>
import { ref } from 'vue';
import '@grapecity/wijmo.styles/wijmo.css';
import '@grapecity/wijmo.vue2.input';
import '@grapecity/wijmo.vue2.grid';

import '@grapecity/wijmo.vue2.olap';
import * as wjCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjChart from '@grapecity/wijmo.chart';
import * as wjcOlap from '@grapecity/wijmo.olap';
import { WjFlexGrid, WjFlexGridColumn } from '@grapecity/wijmo.vue2.grid';
import {
  WjPivotGrid,
  WjPivotPanel,
  WjPivotChart,
} from '@grapecity/wijmo.vue2.olap';
import { getData } from './data.js';

export default {
  props: {},
  components: {
    WjPivotPanel,
    WjPivotGrid,
    WjPivotChart,
    WjFlexGrid,
    WjFlexGridColumn,
  },
  data: () => ({
    data: null,
    ng: new wjcOlap.PivotEngine({
      itemsSource: getData(), // raw data
      fields: [
        {
          binding: 'anno',
          header: 'Año',
          format: 'yyyy',
        },
        {
          binding: 'mes',
          header: 'Mes',
        },
        {
          binding: 'contexto',
          header: 'Contexto',
        },
        {
          binding: 'riesgo',
          header: 'Riesgo',
        },
        {
          binding: 'montoRiesgo',
          header: 'Monto',
          format: 'c2',
        },
      ],
      showRowTotals: 'Subtotals',
      rowFields: ['Año'], //rowFields: ['Año','Mes'´]
      columnFields: ['Riesgo', 'Contexto'],
      valueFields: ['Monto'],
    }),
  }),
  methods: {
    initChart: function (chart) {
      // Set the position of the legends.
      chart.legendPosition = 'Bottom';
      // Enable toggling the series by clicking on the legends.
      chart.flexChart.legendToggle = true;
      // Make the legends scrollable.
      chart.flexChart.legend.scrollbar = true;
      // Set the orientation of the legends.
      chart.flexChart.legend.orientation = 'Vertical';
      // Flags to control renaming logic of series and scroll position of the legends container.
      let seriesNameChanged = false,
        resetScrollPosition = false;
      // To store the last scroll position of the legends container.
      let lastScrollPosition = 0;
      // Handle rendering event to reset the scroll position of the legends container.
      chart.flexChart.rendering.addHandler((sender, args) => {
        if (resetScrollPosition && sender.legend.scrollbar) {
          sender.hostElement.querySelector('foreignObject div').scrollTop =
            lastScrollPosition;
          resetScrollPosition = false;
        }
      });
      // Handle rendered event of the flexchart to convert the series name(only once) and to add workaround for toggling series by clicking on legends.
      chart.flexChart.rendered.addHandler((s, e) => {
        // Workaround to toggle the series when a legend is clicked and scrollbar is present in legends container.
        if (chart.flexChart.legend.scrollbar) {
          chart.flexChart.hostElement
            .querySelector('foreignObject div g')
            .addEventListener(
              'click',
              (e) => {
                // Get the series index that is clicked.
                let targetSeriesIndex = chart.flexChart.series.findIndex(
                  (item) => {
                    return item.name == e.target.textContent;
                  }
                );
                // If a series is found.
                if (targetSeriesIndex != -1) {
                  let targetSeries = chart.flexChart.series[targetSeriesIndex];
                  // Store the scroll position of the legends container.
                  lastScrollPosition =
                    chart.flexChart.hostElement.querySelector(
                      'foreignObject div'
                    ).scrollTop;
                  // Set the visibility of the series.
                  if (
                    targetSeries.visibility === wjChart.SeriesVisibility.Visible
                  )
                    chart.flexChart.series[targetSeriesIndex].visibility =
                      wjChart.SeriesVisibility.Legend;
                  else if (
                    targetSeries.visibility === wjChart.SeriesVisibility.Legend
                  ) {
                    targetSeries.visibility = wjChart.SeriesVisibility.Visible;
                  }

                  // Set the flag to restore the scroll position of the legends div.
                  resetScrollPosition = true;
                }
                // Prevent default behavior of toggling the series.
                e.stopImmediatePropagation();
              },
              true
            );
        }

        // To rename the series as per the getLegendText() method.
        if (!seriesNameChanged) {
          // Get all the series of the chart.
          let seriesCollection = chart.flexChart.series;
          for (let index = 0; index < seriesCollection.length; index++) {
            // Get the appropriate name for current series.
            let name = this.getLegendText(seriesCollection[index].name);
            // Reset the name (if a suitable corresponding name is returned by getLegendText() method).
            if (name) {
              seriesCollection[index].name = name;
            }
          }
          // Set the flag to ensure that the renaming is done only once.
          if (seriesCollection.length) seriesNameChanged = true;
          // Re-render the chart to show the legends with new names.
          if (seriesNameChanged) {
            chart.flexChart.refresh(true);
          }
        }
      });
    },
    getLegendText: function (text) {
      let legends = {
        'EXTEMPORANEOS; Emitidos': 'CFDIs de Pago extemporáneos - Emitidos',
        'EXTEMPORANEOS; Recibidos': 'CFDIs de Pago extemporáneos - Recibidos',
        'METODO VS FORMA DE PAGO; Emitidos':
          'CFDIs con Inconsistencias entre Método y Forma de Pago - Emitidos',
        'METODO VS FORMA DE PAGO; Recibidos':
          'CFDIs con Inconsistencias entre Método y Forma de Pago - Recibidos',
        'INEXISTENTES EN PAGOS; Emitidos':
          'CFDIs de Pago con documento relacionado inexistente - Emitidos',
        'INEXISTENTES EN PAGOS; Recibidos':
          'CFDIs de Pago con documento relacionado inexistente - Recibidos',
        'RELACIONADOS CANCELADOS EN PAGOS; Emitidos':
          'Facturas canceladas que están en Comprobantes de Pago - Emitidos',
        'RELACIONADOS CANCELADOS EN PAGOS; Recibidos':
          'Facturas canceladas que están en Comprobantes de Pago - Recibidos',
        'SALDO INSOLUTO; Emitidos':
          'Saldo pendiente en CFDIs con método de pago PPD - Emitidos',
        'SALDO INSOLUTO; Recibidos':
          'Saldo pendiente en CFDIs con método de pago PPD - Recibidos',

        'SUSTITUIDOS NO CANCELADOS; Emitidos':
          'CFDIs sustituidos que no han sido cancelados - Emitidos',
        'SUSTITUIDOS NO CANCELADOS; Recibidos':
          'CFDIs sustituidos que no han sido cancelados - Recibidos',
        'NO SUSTITUIDOS CANCELADOS; Emitidos':
          'CFDIs relacionados (no sustituidos) cancelados - Emitidos',
        'NO SUSTITUIDOS CANCELADOS; Recibidos':
          'CFDIs relacionados (no sustituidos) cancelados - Recibidos',
        'RELACIONADOS INEXISTENTES; Emitidos':
          'CFDIs relacionados que no existen en XData - Emitidos',
        'RELACIONADOS INEXISTENTES; Recibidos':
          'CFDIs relacionados que no existen en XData - Recibidos',
      };
      return legends[text];
    },
  },
};
</script>

<style>

body {
  margin-bottom: 24pt;
}

foreignObject div g {
  display: 'inline-block' !important;
}

.wj-flexchart {
  height: 460px;
}

.wj-pivotgrid {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
</style>
