<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row>
        
        <h3>{{titulo}}</h3>
        
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-2">
            <v-row>
              <v-col cols="12" pa="4" md="2" justify="center">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha Inicial"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" locale="es" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" pa="4" md="2" justify="center">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date2"
                      label="Fecha final"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date2" locale="es" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="rfcSearch"
                  counter="0"
                  :label="labelRfc"
                  maxlength="13"
                  class="my-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn
                      color="primary"
                      white
                      large
                      v-on:click="getReport"
                      :disabled="!showSearchButton"
                    >Mostrar Listado</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="items.length > 0" fluid class="px-10">
      <v-row>
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title
              class="d-none d-sm-flex"
            >{{ doctosPagina }} registros de {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer class="d-none d-sm-flex"></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-icon
                  class="d-none d-sm-flex"
                  @click="getReporteBreakdownCSV"
                  v-on="on"
                  color="second"
                  :disabled="totalDocumentos>5000" 
                >mdi-file-export</v-icon>
              </template>
              <span>Exportar tabla a CSV. Hasta 5,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select
                v-model="pageSize"
                v-on:change="changePagination"
                :items="paginations"
                menu-props="auto"
                label="Tamaño de Página"
                hide-details
                class="ma-3"
              ></v-select>
              <v-btn icon v-on:click="start" v-show="hasPrevious">
                <v-icon color="second">mdi-arrow-collapse-left</v-icon>
              </v-btn>
              <v-btn icon v-show="hasPrevious">
                <v-icon color="second" v-on:click="previous">mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn text>{{ noPagina }} de {{ totalPaginas }}</v-btn>
              <v-btn icon>
                <v-icon color="second" v-on:click="next" v-show="hasNext">mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="second" v-on:click="end" v-if="hasNext">mdi-arrow-collapse-right</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-simple-table height="600" fixed-header dense>
              <template v-slot:default>
                <thead v-if="esEmitido">
                  <tr>
                    <th class="body-1 text-left">
                      <a @click="sortColumn('serie')" :class="{ negro: serieClass }">Serie</a>
                    </th>
                    <th class="body-1 text-left">
                      <a @click="sortColumn('folio')" :class="{ negro: folioClass }">Folio</a>
                    </th>
                    <th class="body-1 text-left" style="white-space: nowrap;">
                      <a
                        @click="sortColumn('receptor.rfc')"
                        :class="{ negro: rfcRClass }"
                      >RFC Receptor</a>
                    </th>
                    <th class="body-1 text-left" style="white-space: nowrap;">Razón Social Receptor</th>

                    <th class="body-1 text-left" style="white-space: nowrap;">
                      <a
                        @click="sortColumn('nfecha_emision')"
                        :class="{ negro: fechaEClass }"
                      >Fecha Emisión</a>
                    </th>
                    <th class="text-right body-1">Subtotal</th>
                    <th class="text-right body-1">
                      <a @click="sortColumn('total')">Total</a>
                    </th>
                    <th class="text-right body-1">Descuento</th>
                    <th class="text-center body-1">
                      <a @click="sortColumn('moneda')" :class="{ negro: monedaClass }">Moneda</a>
                    </th>
                    <th class="text-right body-1" style="white-space: nowrap;">Tipo Cambio</th>

                    <th class="text-right body-1" style="white-space: nowrap;">Retención ISR</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Retención IVA</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Retención IEPS</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Traslado IVA</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Traslado IEPS</th>

                    <th class="body-1" style="white-space: nowrap;">
                      <a
                        @click="sortColumn('tipo_comprobante')"
                        :class="{ negro: tipo_comprobanteClass }"
                      >Tipo Comprobante</a>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <thead v-if="esRecibido">
                  <tr v-show="esRecibido">
                    <th class="body-1 text-left" style="white-space: nowrap;">
                      <a @click="sortColumn('emisor.rfc')" :class="{ negro: rfcEClass }">RFC Emisor</a>
                    </th>
                    <th class="body-1 text-left" style="white-space: nowrap;">Razón Social Emisor</th>
                    <th class="body-1 text-left" style="white-space: nowrap;">
                      <a
                        @click="sortColumn('nfecha_emision')"
                        :class="{ negro: fechaEClass }"
                      >Fecha Emisión</a>
                    </th>
                    <th class="body-1 text-left">
                      <a @click="sortColumn('serie')" :class="{ negro: serieClass }">Serie</a>
                    </th>
                    <th class="body-1 text-left">
                      <a @click="sortColumn('folio')" :class="{ negro: folioClass }">Folio</a>
                    </th>

                    <th class="text-right body-1">Subtotal</th>
                    <th class="text-right body-1">
                      <a @click="sortColumn('total')">Total</a>
                    </th>
                    <th class="text-right body-1">Descuento</th>
                    <th class="text-center body-1">
                      <a @click="sortColumn('moneda')" :class="{ negro: monedaClass }">Moneda</a>
                    </th>
                    <th class="text-right body-1" style="white-space: nowrap;">Tipo Cambio</th>

                    <th class="text-right body-1" style="white-space: nowrap;">Retención ISR</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Retención IVA</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Retención IEPS</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Traslado IVA</th>
                    <th class="text-right body-1" style="white-space: nowrap;">Traslado IEPS</th>

                    <th class="body-1" style="white-space: nowrap;">
                      <a
                        @click="sortColumn('tipo_comprobante')"
                        :class="{ negro: tipo_comprobanteClass }"
                      >Tipo Comprobante</a>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-show="esRecibido"
                    v-for="item in items"
                    :key="item.name"
                    class="altura"
                    :style="{backgroundColor: (item.fecha_cancelacion !== null ? '#FF4040' : 'transparent' ) }"
                  >
                    <td>{{ item.emisor_rfc }}</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ item.emisor_nombre.substring(0, 12) + "..."}}</span>
                        </template>
                        <span>{{ item.emisor_nombre }}</span>
                      </v-tooltip>
                    </td>
                    <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                    <td>{{ item.serie }}</td>
                    <td>{{ item.folio }}</td>
                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                    <td align="right">{{ currencyFormat(item.total) }}</td>
                    <td align="right">{{ currencyFormat(item.descuento) }}</td>
                    <td>{{ item.moneda }}</td>
                    <td align="right">{{ item.tipo_cambio }}</td>

                    <td align="right">{{ currencyFormat(item.retencion_isr) }}</td>
                    <td align="right">{{ currencyFormat(item.retencion_iva) }}</td>
                    <td align="right">{{ currencyFormat(item.retencion_ieps) }}</td>
                    <td align="right">{{ currencyFormat(item.traslado_iva) }}</td>
                    <td align="right">{{ currencyFormat(item.traslado_ieps) }}</td>

                    <td>{{ itemSelected.tipo_comprobante === "I" ? "Ingreso" : "Egreso" }}</td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                            <v-icon>mdi-xml</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar XML</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar PDF</span>
                      </v-tooltip>
                    </td>
                  </tr>

                  <tr
                    v-show="esEmitido"
                    v-for="item in items"
                    :key="item.name"
                    class="altura"
                    :style="{backgroundColor: (item.fecha_cancelacion !== null ? '#FF4040' : 'transparent' ) }"
                  >
                    <td>{{ item.serie }}</td>
                    <td>{{ item.folio }}</td>
                    <td>{{ item.receptor_rfc }}</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ item.receptor_nombre.substring(0, 12) + "..."}}</span>
                        </template>
                        <span>{{ item.receptor_nombre }}</span>
                      </v-tooltip>
                    </td>
                    <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                    <td align="right">{{ currencyFormat(item.subtotal) }}</td>
                    <td align="right">{{ currencyFormat(item.total) }}</td>
                    <td align="right">{{ currencyFormat(item.descuento) }}</td>
                    <td>{{ item.moneda }}</td>
                    <td align="right">{{ item.tipo_cambio }}</td>

                    <td align="right">{{ currencyFormat(item.retencion_isr) }}</td>
                    <td align="right">{{ currencyFormat(item.retencion_iva) }}</td>
                    <td align="right">{{ currencyFormat(item.retencion_ieps) }}</td>
                    <td align="right">{{ currencyFormat(item.traslado_iva) }}</td>
                    <td align="right">{{ currencyFormat(item.traslado_ieps) }}</td>

                    <td>{{ itemSelected.tipo_comprobante === "I" ? "Ingreso" : "Egreso" }}</td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                            <v-icon>mdi-xml</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar XML</span>
                      </v-tooltip>
                    </td>
                    <td class="pa-0 md-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Descargar PDF</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "BreakDown",
  props: ["titulo", "contexto"],

  data: () => ({
    date: "",
    date2: "",
    menu: false,
    menu2: false,
     guidSearch: "",
    folioDoc: "",
    modal: false,
    
    
    
    
    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    forma_PagoClass: false,
    itemSelected: {
      id: "",
      emisor: {
        nombre: "",
        regimen: "",
        rfc: "",
      },
      receptor: {
        nombre: "",
        residencia: "",
        rfc: "",
        usocfdi: "",
      },
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
    },
    rfcSearch: "",
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
  },
  methods: {
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto,
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par = item.tipo + "|" + item.pac + "|" + item.emisor.rfc;
      else par = item.tipo + "|" + item.pac + "|";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";


      this.$store.commit("setAlertError", false);
this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
      
      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    verMasDatos(item) {
      this.itemSelected = item;
      this.showDialog = true;
    },
    exportExcel() {
      var headers = {
        UUID: "Folio Fiscal",
        RfcE: "RFC Emisor",
        NombreE: "Razón Social Emisor",
        RfcR: "RFC Receptor",
        NombreR: "Razón Social Receptor",
        CRegimen: "Código Regimen",
        NRegimen: "Regimen",
        CUsoCfdi: "Código Uso CFDI",
        NUsoCfdi: "Uso CFDI",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaCancelacion: "Fecha Cancelación",
        FechaEmision: "Fecha Emisión",
        Serie: "Serie",
        Folio: "Folio",
        Descuento: "Descuento",
        MetodoPago: "Método Pago",
        CFormaPago: "Código Forma Pago",
        NFormaPago: "Forma Pago",
        LugarExpedicion: "Lugar Expedicion",
        Moneda: "Moneda",
        SubTotal: "Subtotal",
        TipoCambio: "Tipo Cambio",
        Total: "Total",
        RetencionISR: "Retención ISR",
        RetencionIVA: "Retención IVA",
        RetencionIEPS: "Retención IEPS",
        TrasladoIVA: "Traslado IVA",
        TrasladoIEPS: "Traslado IEPS",
        PAC: "PAC",
      };
      var itemsFormatted = [];
      this.csvData.forEach((item) => {
        itemsFormatted.push({
          UUID: helpers.ConvertToEmpty(item.id),
          RfcE: helpers.ConvertToEmpty(item.emisor_rfc),
          NombreE: helpers.ConvertToEmpty(item.emisor_nombre),
          RfcR: helpers.ConvertToEmpty(item.receptor_rfc),
          NombreR: helpers.ConvertToEmpty(item.receptor_nombre),
          CRegimen: helpers.ConvertToEmpty(item.regimen),
          NRegimen: helpers
            .RegimenFiscal(helpers.ConvertToEmpty(item.regimen))
            .replace(/,/g, " "),
          CUsoCfdi: helpers.ConvertToEmpty(item.usocfdi),
          NUsoCfdi: helpers.UsoCFDI(helpers.ConvertToEmpty(item.usocfdi)),
          Tipo: helpers.ConvertToEmpty(
            item.tipo === "E" ? "Emitido" : "Recibido"
          ),
          TipoComprobante: helpers.ConvertToEmpty(
            item.tipo_comprobante === "I" ? "Ingreso" : "Egreso"
          ),
          FechaCancelacion: helpers.ConvertToEmpty(item.fecha_cancelacion),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          Descuento: helpers.ConvertToEmpty(item.descuento),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          CFormaPago: helpers.ConvertToEmpty(item.forma_pago),
          NFormaPago: helpers.FormaPago(
            helpers.ConvertToEmpty(item.forma_pago)
          ),
          LugarExpedicion: helpers.ConvertToEmpty(item.lugar_expedicion),
          Moneda: helpers.ConvertToEmpty(item.moneda),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          Total: helpers.ConvertToEmpty(item.total),
          RetencionISR: helpers.ConvertToEmpty(item.retencion_isr),
          RetencionIVA: helpers.ConvertToEmpty(item.retencion_iva),
          RetencionIEPS: helpers.ConvertToEmpty(item.retencion_ieps),
          TrasladoIVA: helpers.ConvertToEmpty(item.traslado_iva),
          TrasladoIEPS: helpers.ConvertToEmpty(item.traslado_ieps),
          PAC: helpers.ConvertToEmpty(item.pac),
        });
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_IngresosEgresos_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    start() {
      this.offest = 0;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    changePagination() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
     this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
      };
      service
        .api()
        .ReporteBreakdown(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        context: this.contexto,
        rfc: this.rfcSearch,
      };

      service
        .api()
        .ReporteBreakdownCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteBreakdownCSV() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.date,
        dateEnd: this.date2,
        context: this.contexto,
        rfc: this.rfcSearch,
      };

      service
        .api()
        .ReporteBreakdownCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;
      this.forma_PagoClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
        case "forma_pago":
          this.forma_PagoClass = true;
          break;
      }
    }
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto === "E";
    },
    esRecibido() {
      return this.contexto === "R";
    },
    showSearchButton() {
      return this.date.length > 0 && this.date2.length > 0;
    },
    labelRfc() {
      if (this.contexto === "E") return "RFC Receptor";
      else return "RFC Emisor";
    }
  },
};
</script>
<style scoped>
.altura {
  height: 40px;
}
.negro {
  font-weight: bold;
}

.normal {
  font-weight: regular;
}
</style>
