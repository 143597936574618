<template>
    <div class="py-10">
        <v-container fluid class="px-10">
            <estatusdoc ref="estatusd" ></estatusdoc>
            <v-row justify="center">

                <dialogo ref="dialog" :itemFind="itemFind"></dialogo>

                <v-dialog v-model="showDialogPagos" persistent width="1700px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="showDialogPagos = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Pagos y sus características como documento relacionado:
                                {{ folioSelected }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <br />
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th colspan="2" style="background-color: #90caf9">
                                                Datos del CFDI de Pago
                                            </th>
                                            <th colspan="4" style="background-color: #bbdefb">
                                                Datos del Pago
                                            </th>
                                            <th colspan="11" style="background-color: #e3f2fd">
                                                Datos del Documento Relacionado
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style="white-space: nowrap">Folio Fiscal</th>
                                            <th style="white-space: nowrap">Fecha Emisión</th>
                                            <th style="white-space: nowrap">Fecha Pago</th>
                                            <th>Moneda</th>
                                            <th align="right" style="white-space: nowrap">
                                                Tipo Cambio
                                            </th>
                                            <th>Monto</th>
                                            <th>Serie</th>
                                            <th>Folio</th>
                                            <th style="white-space: nowrap">Método Pago</th>
                                            <th>Moneda</th>
                                            <th align="right" style="white-space: nowrap">
                                                Tipo Cambio
                                            </th>
                                            <th align="right" style="white-space: nowrap">
                                                Número Parcialidad
                                            </th>
                                            <th align="right" style="white-space: nowrap">
                                                Importe Pagado
                                            </th>
                                            <th align="right" style="white-space: nowrap">
                                                Saldo Anterior
                                            </th>
                                            <th align="right" style="white-space: nowrap">
                                                Saldo Insoluto
                                            </th>
                                            <th align="center" style="white-space: nowrap"></th>
                                            <th align="center" style="white-space: nowrap"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="altura" v-for="itemS in itemSelectedPago" :key="bKey">
                                            <td>
                                                {{ itemS.cfdi_pago_folio_fiscal }}
                                            </td>
                                            <td>
                                                {{ itemS.cfdi_pago_fecha_emision.substring(0, 10) }}
                                            </td>

                                            <td>{{ itemS.pago_fecha_pago.substring(0, 10) }}</td>
                                            <td>{{ itemS.pago_moneda }}</td>
                                            <td align="right">
                                                {{ currencyFormat6(itemS.pago_tipo_cambio) }}
                                            </td>
                                            <td align="right">
                                                {{ currencyFormat(itemS.pago_monto) }}
                                            </td>
                                            <td>{{ itemS.rel_serie }}</td>
                                            <td>{{ itemS.rel_folio }}</td>
                                            <td>{{ itemS.rel_metodo_pago }}</td>
                                            <td>{{ itemS.rel_moneda }}</td>
                                            <td align="right">{{ itemS.rel_tipo_cambio }}</td>
                                            <td align="right">{{ itemS.rel_num_parcialidad }}</td>
                                            <td align="right">
                                                {{ currencyFormat(itemS.rel_imp_pagado) }}
                                            </td>
                                            <td align="right">
                                                {{ currencyFormat(itemS.rel_imp_saldo_anterior) }}
                                            </td>
                                            <td align="right">
                                                {{ currencyFormat(itemS.rel_imp_saldo_insoluto) }}
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrievePagoXML(itemS)" icon color="blue" v-on="on">
                                                            <v-icon>mdi-xml</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar XML del CFDI de pago</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="retrievePagoPDF(itemS)" icon color="red" v-on="on">
                                                            <v-icon>mdi-file-pdf-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar PDF del CFDI de pago</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="showDialogPagos = false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
            <v-row>


                <v-col cols="12">
                    <h3>Facturas canceladas que están en Comprobantes de Pago

                        <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/documentos-relacionados-cancelados-en-comprobantes-de-pago" target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
                    </h3>
                </v-col>
                <v-col>
                    <v-card class="paddingCard">
                        <v-row>
                            <v-col cols="12" md="4" class="px-2 mx-md-10">
                                <date-range-picker v-model="selectedDates"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-text-field class="min-width-control-xl" v-model="rfcSearch" counter="0" :label="labelRfc"
                                    maxlength="13"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" class="px-2 mx-md-10">
                                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto"
                                    @change="items = []" label="Contexto" outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="1" class="px-2 mx-md-10">
                                <v-container fluid>
                                    <div class="my-2">
                                        <v-btn color="primary" white large v-on:click="getReport"
                                            :disabled="!showSearchButton">Mostrar Listado</v-btn>
                                    </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
                <v-col cols="12">
                    <v-toolbar>
                        <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} pagos de
                            {{ totalDocumentos }}</v-toolbar-title>
                        <v-spacer class="d-none d-sm-flex"></v-spacer>
                        <v-tooltip top class="d-none d-sm-flex">
                            <template class="d-none d-sm-flex" v-slot:activator="{ on }">
                                <v-icon class="d-none d-sm-flex" @click="getReportePaymentNFCSV" v-on="on"
                                    :disabled="totalDocumentos > 5000" color="second">mdi-file-export</v-icon>
                            </template>
                            <span class="d-none d-sm-flex">Exportar tabla a CSV. Hasta 5,000 registros</span>
                        </v-tooltip>

                        <v-toolbar-items class="pa-3">
                            <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations"
                                menu-props="auto" label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
                            <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large
                                        color="second">mdi-arrow-up-bold-circle-outline</v-icon>
                                </template>
                                <span>Página anterior</span>
                            </v-tooltip>
                            <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

                            <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large
                                        color="second">mdi-arrow-down-bold-circle-outline</v-icon>
                                </template>
                                <span>Página siguiente</span>
                            </v-tooltip>
                            <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

                            <v-btn text>
                                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage"
                                    class="mr-1 mb-0 anchoGuia" @keyup.enter.native="goToPage"></v-text-field>
                                <span class="tamPagina">/{{ totalPaginas }}</span>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-card>



                        <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false"
                            :allowDelete="false" :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true"
                            :validateEdits="false" :initialized="initGrid" :allowResizing="'None'">


                            <wj-flex-grid-column :header="'Folio Fiscal'" width="2*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ cell.item.id.split("|")[0] }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column :header="'Folio Fiscal del Comprobante de Pago'" width="2*"
                                :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <span>{{ cell.item.id.split("|")[1] }}</span>
                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>


                            <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />
                            <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*"
                                :isReadOnly="true" />



                            <wj-flex-grid-column :header="'Fecha Emisión del CFDI de Pago'" :binding="'fecha_emision_p'"
                                width="2*" :isReadOnly="true" />


                            <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                                                <v-icon>mdi-eye-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver Más</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                                                <v-icon>mdi-xml</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar XML</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                                                <v-icon>mdi-file-pdf-box</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Descargar PDF</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="verPagos(cell.item)" icon color="green" v-on="on">
                                                <v-icon>mdi-cash-multiple</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ver pagos y sus características como documento
                                            relacionado</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="ConsultarEstadoDoc(cell.item)" icon color="orange" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consultar Estado del Documento</span>
                                    </v-tooltip>

                                </wj-flex-grid-cell-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>



                    </v-card>
                    <br />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';
import Estatusdoc from '../Componentes/EstatusDocumento.vue';

export default {
    name: "PaymentRelCancelled",
    name: "PaymentVsCfdi",
    components: {
        DateRangePicker,
        Dialogo,
        Estatusdoc
    },
    data: () => ({
        itemFind: {
            id: "",
            emisor_rfc: "",
            emisor_nombre: "",
            regimen: "",
            receptor_nombre: "",
            residencia: "",
            receptor_rfc: "",
            usocfdi: "",
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
            retencion_isr: 0,
            retencion_iva: 0,
            retencion_ieps: 0,
            traslado_iva: 0,
            traslado_ieps: 0,
        },
        selectedDates: [],
        selectedRows: [],
        bKey: 0,
        contexto: "Emitidos",
        limiteDias: 10,
        showDialog: false,
        goPage: 1,
        showDialogPagos: false,
        folioDoc: "",
        modal: false,




        search: "",
        offest: 0,
        limit: 50,
        pageSize: "50",
        noPagina: 1,
        totalPaginas: 1,
        doctosPagina: 0,
        totalDocumentos: 0,
        paginations: ["25", "50", "100", "150", "200"],
        items: [],
        landscape: false,
        title: "",
        csvData: [],
        csvDataT: [],
        currentOrder: "DESC",
        currentOrderColumn: "nfecha_emision_p",
        rfcClass: false,
        fechaEClass: true,
        itemSelected: {
            id: "",
            emisor: {
                nombre: "",
                regimen: "",
                rfc: "",
            },
            receptor: {
                nombre: "",
                residencia: "",
                rfc: "",
                usocfdi: "",
            },
            fecha_cancelacion: "",
            fecha_carga: "",
            fecha_emision: "",
            fecha_timbre: "",
            folio: "",
            forma_pago: "",
            descuento: 0,
            lugar_expedicion: "",
            metodo_pago: "",
            moneda: "",
            serie: "",
            subtotal: 0,
            tipo: "",
            tipo_cambio: 1,
            tipo_comprobante: "",
            total: 0,
            total_retenciones: 0,
            total_traslados: 0,
        },
        rfcSearch: "",
        folioSelected: "",
        contextSelected: "",
        rfcSelected: "",
        itemSelectedPago: [{
            cfdi_pago_folio_fiscal: "",
            cfdi_pago_fecha_emision: "",
            pago_fecha_pago: "",
            pago_moneda: "",
            pago_tipo_cambio: 0,
            pago_monto: 0,
            rel_serie: "",
            rel_folio: "",
            rel_moneda: "",
            rel_tipo_cambio: 0,
            rel_num_parcialidad: 0,
            rel_imp_pagado: 0,
            rel_imp_saldo_anterior: 0,
            rel_imp_saldo_insoluto: 0,
        },],
        dateTs: ""
    }),
    created() {
        this.$store.commit("setIsRetencion", false);
        this.$store.commit("setBlData", null);
        let dataInterval = this.$store.state.dateInterval;

        if (dataInterval === null) return;

        this.selectedDates[0] = dataInterval.dateInit;
        this.selectedDates[1] = dataInterval.dateEnd;
        this.dateTs = dataInterval.dateTs;
        this.contexto = dataInterval.contexto;
        this.$store.commit("setDateInterval", null);

        this.getTotalCount();
    },
    methods: {
        openDialogConsult() {
            this.$refs.estatusd.showDialogStatus = true;
        },
        ConsultarEstadoDoc(item) {
            
            let contextoEstado = "";
            let rfcE = "";
            let rfcR = "";
            let fechaE = item.fecha_emision_p;
            let uuid = item.id.split('|')[1];;
            this.openDialogConsult();
            if(this.esEmitido){
                contextoEstado = "E";
                rfcE = this.$store.state.entity;
                rfcR = item.rfc;
             
            }
            else
            {
                contextoEstado = "R";
                rfcE = item.rfc;
                rfcR = this.$store.state.entity;
            }
            this.$refs.estatusd.ConsultarEstadoDoc(contextoEstado, uuid, fechaE, rfcE, rfcR, 0);

        },

        initGrid: function (grid) {




            grid.columns[grid.columns.length - 1].width = 200;


        },
        retrieveXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contexto.substr(0, 1),
                uuid: item.id.split("|")[0],
                issuedDate: item.fecha_emision_rel,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contexto.substr(0, 1) === "R")
                par =
                    this.contexto.substr(0, 1) +
                    "|" +
                    helpers.ConvertToEmpty(item.pac).trim() +
                    "|" +
                    item.rfc;
            else
                par =
                    this.contexto.substr(0, 1) + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
            let parameters =
                par + "|" + item.id.split("|")[0] + "|" + item.fecha_emision_rel;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);

                this.$store.commit("setOverlay", false);
            }, 3000);
        },

        openDialog() {
            this.$refs.dialog.showDialogGuid = true;
        },
        verMasDatos(item) {
            var jsonData = {
                token: this.$store.state.token,
                uuid: item.id.split("|")[0],
            };
            service
                .api()
                .SearchByGUID(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.itemFind = payload;
                        this.openDialog();
                    } else {

                        this.alert_errorR = true;
                        this.errorMessageR = response.errorMessage;
                        setTimeout(() => {
                            this.alert_errorR = false;
                            this.errorMessageR = "";
                        }, 10000);
                    }
                });

        },
        verPagos(item) {
            this.itemSelectedPago = [];
            var jsonData = {
                token: this.$store.state.token,
                uuid_rel: item.id.split("|")[0],
                uuid_payment: item.id.split("|")[1],
                payment_issuedDate: item.fecha_emision_p.substr(0, 10),
            };
            service
                .api()
                .ReportePagosyRel(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.itemSelectedPago = JSON.parse(response.payload);
                        this.showDialogPagos = true;
                        this.folioSelected = item.id.split("-")[1];
                        this.contextSelected = item.tipo;
                        this.rfcSelected = item.rfc;
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        exportExcel() {
            var headers = {
                RfcE: this.esEmitido ? "RFC Emisor" : "RFC Receptor",
                Rfc: this.esEmitido ? "RFC Receptor" : "RFC Emisor",
                Nombre: this.esEmitido ?
                    "Razon Social Receptor" :
                    "Razon Social Emisor",
                UUID: "Folio Fiscal Doc. Relacionado",
                FechaEmisionRel: "Fecha Emisión Doc. Relacionado",
                UUIDP: "Folio Fiscal CFDI Pago",
                FechaEmisionP: "Fecha Emisión CFDI Pago",
            };
            var itemsFormatted = [];
            this.csvData.forEach((item) => {
                let par = item.particion.split("-");
                itemsFormatted.push({
                    RfcE: helpers.ConvertToEmpty(par[0]),
                    Rfc: helpers.ConvertToEmpty(item.rfc),
                    Nombre: helpers
                        .ConvertToEmpty(item.nombre)
                        .replace(/'/g, " ")
                        .replace(/,/g, " "),
                    UUID: helpers.ConvertToEmpty(item.id.split("|")[0]),
                    FechaEmisionRel: helpers.ConvertToEmpty(item.fecha_emision_rel),
                    UUIDP: helpers.ConvertToEmpty(item.id.split("|")[1]),
                    FechaEmisionP: helpers.ConvertToEmpty(item.fecha_emision_p),
                });
            });

            this.csvDataT = itemsFormatted;
            var fileTitle =
                "Listado_DocRelacionados_Cancelados_EnPagos" +
                helpers.dateNowCSV().replace(/-/g, "_");
            helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
        },
        start() {
            this.offest = 0;
            this.goPage = this.noPagina = 1;
            this.limit = this.pageSize;
            this.getReporteItems();
        },
        previous() {
            if (this.noPagina === 1) return;

            this.limit = this.pageSize;
            this.noPagina--;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;
            this.getReporteItems();
        },
        next() {
            if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
                return;

            this.limit = this.pageSize;
            if (this.noPagina + 1 === this.totalPaginas) {
                let res = this.totalDocumentos % this.pageSize;
                if (res !== 0) this.limit = res;
            }
            this.noPagina++;
            this.goPage = this.noPagina;
            this.offest = (this.noPagina - 1) * this.pageSize;

            this.getReporteItems();
        },
        end() {
            this.limit = this.pageSize;
            let res = this.totalDocumentos % this.pageSize;
            if (res !== 0) this.limit = res;

            this.goPage = this.noPagina = this.totalPaginas;
            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
        },
        goToPage() {
            this.limit = this.pageSize;
            let actualPage = this.noPagina;
            this.noPagina = parseInt(this.goPage);

            this.offest = (this.noPagina - 1) * this.pageSize;
            let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

            if (this.noPagina > 0 && this.noPagina <= noPaginas)
                this.getReporteItems();
            else this.noPagina = this.goPage = actualPage;
        },
        changePagination() {
            this.getReport();
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },
        handleDate() {
            this.limit = this.pageSize;
            this.offest = 0;
            this.noPagina = 1;
            this.$refs.menu.save(this.selectedDates[0]);
            this.getReporteItems();
        },
        getReport() {
            this.goPage = 1;
            this.offest = 0;
            this.noPagina = 1;
            this.limit = this.pageSize;
            this.$store.commit("setAlertError", false);
            this.errorMessage = "";
            this.getTotalCount();
        },
        getReporteItems() {
            this.selectedRows = [];
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
                orderColumn: this.currentOrderColumn,
                order: this.currentOrder,
                offset: this.offest,
                limit: this.limit,
                rfc: this.rfcSearch,
                dateTs: this.dateTs
            };
            service
                .api()
                .ReporteRelCancelled(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.items = payload;
                        this.doctosPagina = this.items.length.toString();

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getTotalCount() {
            this.items = [];
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
                rfc: this.rfcSearch,
                dateTs: this.dateTs
            };

            service
                .api()
                .ReporteRelCancelledCount(jsonData)
                .then((response) => {
                    if (response.result) {
                        let payload = JSON.parse(response.payload);
                        this.totalDocumentos = payload;
                        this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
                        this.getReporteItems();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        getReportePaymentNFCSV() {
            var jsonData = {
                token: this.$store.state.token,
                dateInit: this.selectedDates[0],
                dateEnd: this.selectedDates[1],
                context: this.contexto.substr(0, 1),
                rfc: this.rfcSearch,
                dateTs: this.dateTs
            };

            service
                .api()
                .ReporteRelCancelledCSV(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.csvData = JSON.parse(response.payload);
                        this.exportExcel();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
                    }
                });
        },
        retrievePagoXML(item) {
            var jsonData = {
                token: this.$store.state.token,
                context: this.contextSelected,
                uuid: item.cfdi_pago_folio_fiscal,
                issuedDate: item.pago_fecha_pago,
            };
            service
                .api()
                .RetrieveXML(jsonData)
                .then((response) => {
                    if (response.result) {
                        let urlXML = response.payload;
                        this.$store.commit("setOverlay", true);
                        if (navigator.userAgent.indexOf("Firefox") > -1) {
                            window.open(urlXML, "_blank");
                        } else {
                            helpers.openNewBackgroundTab(urlXML);
                        }
                        setTimeout(() => {
                            this.$store.commit("setOverlay", false);
                        }, 1000);
                    } else {
                        this.$store.commit("setOverlay", false);
                    }
                });
        },
        retrievePagoPDF(item) {
            this.$store.commit("setOverlay", true);
            let par = "";
            if (this.contextSelected === "R") par = "R||" + this.selectedRfc;
            else par = "E||";
            let parameters =
                par + "|" + item.cfdi_pago_folio_fiscal + "|" + item.pago_fecha_pago;
            let urlPDF = "";

            this.$store.commit("setAlertError", false);
            this.$store.commit("setAlertOk", true);
            this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");
            urlPDF =
                service.api().MysuiteBaseUrl() +
                "/CFDI/PDF?parameters=" +
                helpers.Base64Encode(parameters) +
                "&token=" +
                helpers.Base64Encode(this.$store.state.token);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(urlPDF, "_blank");
            } else {
                helpers.openNewBackgroundTab(urlPDF);
            }
            setTimeout(() => {
                this.$store.commit("setAlertOk", false);
                this.$store.commit("setOverlay", false);
            }, 2000);
        },
        esOrdenamiento(columna) {
            return this.currentOrderColumn === columna;
        },
        sortColumn(columnName) {
            if (this.currentOrderColumn === columnName) {
                if (this.currentOrder === "DESC") {
                    this.currentOrder = "ASC";
                } else {
                    this.currentOrder = "DESC";
                }
            }
            this.currentOrderColumn = columnName;
            this.getReporteItems();

            this.fechaEClass = false;
            this.rfcClass = false;

            switch (columnName) {
                case "rfc":
                    this.rfcClass = true;
                    break;
                case "nfecha_emision":
                    this.fechaEClass = true;
                    break;
            }
        },

        esExtemporaneo(fechaE, fechaP) {
            let fecha1 = new Date(fechaE);
            let fecha2 = new Date(fechaP);
            let dia = helpers.LastDayMonth(fecha2.getFullYear, fecha2.getMonth);
            fecha2 = fecha2.setDate(dia);
            fecha2 = new Date(fecha2);
            let resta = fecha2.getTime() - fecha1.getTime();
            return resta > this.limiteDias;
        },
        getTipoComprobante(tipoDoc) {
            let tipo = "";

            switch (tipoDoc) {
                case "I":
                    tipo = "Ingreso";
                    break;
                case "E":
                    tipo = "Egreso";
                    break;
                case "T":
                    tipo = "Traslado";
                    break;
                case "P":
                    tipo = "Pago";
                    break;
            }
            return tipo;
        },
    },
    computed: {
        hasNext() {
            return this.noPagina !== this.totalPaginas;
        },
        hasPrevious() {
            return this.noPagina !== 1;
        },
        esEmitido() {
            return this.contexto.substr(0, 1) === "E";
        },
        esRecibido() {
            return this.contexto.substr(0, 1) === "R";
        },

        esCan() {
            return this.esCancelado === "true";
        },
        showSearchButton() {

            return this.selectedDates.length > 1;
        },
        labelRfc() {
            if (this.contexto.substr(0, 1) === "E") return "RFC Receptor";
            else return "RFC Emisor";
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        }
    },
};
</script>

<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoGuia {
    width: 50px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}


.paddingCard {
    padding-right: 25px;
    padding-left: 15px;
}


.min-width-control {
    min-width: 160px;
}

.min-width-control-xl {
    min-width: 200px;
}
</style>
