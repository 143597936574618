<template>

    <v-dialog v-model="showDialogStatus" persistent max-width="700px">
        <v-card dense>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="cerrarStatus">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Detalle documento : {{ uuidSel }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-simple-table class="ma-3">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Estado</th>
                                <th>Cancelable</th>
                                <th>Estado Cancelación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="altura">
                                <td>{{ selectedStatus.estado }}</td>
                                <td>{{ selectedStatus.cancelable }}</td>
                                <td>{{ selectedStatus.estado_cancelacion }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
    data() {
        return {
            showDialogStatus: false,
            uuidSel: "",
            selectedStatus: {
                estado: "",
                cancelable: "",
                estado_cancelacion: "",
            }
        };
    },
    methods: {
        tipoComp(value) {
            return helpers.getTipoComprobante(value);
        },
        formatFecha(fecha) {
            return helpers.formatFecha(fecha);
        },
        currencyFormat(value) {
            return helpers.formatMoney(value);
        },
        currencyFormat6(value) {
            return helpers.formatMoney(value, 6);
        },

        ConsultarEstadoDoc(contexto, uuid, fechaE, rfcE, rfcR, total) {
            this.uuidSel = uuid;
            var jsonData = {
                token: this.$store.state.token,
                context: contexto,
                uuid: uuid,
                issuedDate: fechaE,
                issuer: rfcE,
                receiver: rfcR,
                total: total,
            };

            this.selectedStatus = {
                estado: "",
                cancelable: "",
                estado_cancelacion: "",
            };

            service
                .api()
                .Consult(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.selectedStatus = JSON.parse(response.payload);
                        this.showDialogStatus = true;
                    } else {


                        this.showDialogStatus = false;
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        cerrarStatus() {
            this.showDialogStatus = false;

        },


    }
};
</script>