<template>
  <div class="home">
    <Conciliatorio titulo="Conciliatorio - Recibidos No Cancelados" contexto="R" />
  </div>
</template>

<script>

import Conciliatorio from "./ConciliatorioAll";

export default {
  components: {
    Conciliatorio
  }
};
</script>