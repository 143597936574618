<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center"> </v-row>
      <v-row>
        
        <v-col cols="12">
          <h3>Análisis de Impuestos de Nómina

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href=" https://soporte.mysuitemex.com/portal/es/kb/articles/analisis-impuesto-nomina"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <!-- <v-col cols="12" class="text-justify">
          Este análisis se solicita por mes. Muestra la sumatoria de cada tipo
          de impuesto que aparezca en los CFDI del período solicitado en los
          CFDI de ingreso o egreso. El análisis se presenta dividido en
          impuestos federales, locales, trasladados y retenidos. Los
          comprobantes cancelados no se toman en cuenta para este cálculo.
        </v-col> -->
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col class="mx-2" cols="12" md="2" justify="center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="100px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" type="month" label="Período" prepend-icon="mdi-calendar-month" readonly
                      v-bind="attrs" v-on="on" locale="es"></v-text-field>
                  </template>
                  <v-date-picker min="2020/12" v-model="date" locale="es" type="month" @input="menu = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="mx-2" cols="12" md="2" justify="center">

                  <v-radio-group class="min-width-control-xl" v-model="PorFecha" column>
                    <v-radio label="Por Fecha de Emisión" value="E"></v-radio>
                    <v-radio label="Por Fecha de Pago" value="P"></v-radio>
                  </v-radio-group>
                
              </v-col>
              <v-col cols="12" md="2">
                <v-container fluid>
                  <div class="my-2">
                    <v-btn color="primary" white large v-on:click="ObtenerMontos">Obtener Montos</v-btn>
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-if="verMontos" cols="12">
          <v-toolbar dark color="primary">
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" dark icon v-on="on" @click="exportExcel">
                  <v-icon dark> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <span>Exportar a CSV</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
        <v-col v-if="verMontos" cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="200">
            <v-card-title>Montos(Excepto Asimilados Honorarios) </v-card-title>
            <v-simple-table height="200" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Retención ISR por salarios</th>
                    <th class="text-right">Base de remuneración</th>
                    <th class="text-right">Cuota obrera del seguro social</th>
                    <th class="text-right">
                      Aportaciones a retiro, cesantía en edad avanzada y vejez
                    </th>
                    <th class="text-right">Subsidio Pagado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="altura">
                    <td align="right">
                      {{ currencyFormat(Montos.RetencionISRSalarios) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(Montos.BaseRemuneracion) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(Montos.CuotaSeguroSocial) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(Montos.Aportaciones) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(Montos.SubsidioPagado) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col v-if="verMontos" cols="12" md="6">
          <v-card class="mx-auto pa-2 ma-2" min-height="200">
            <v-card-title>Montos(Asimilados Honorarios) </v-card-title>
            <v-simple-table height="200" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">Retención ISR por salarios</th>
                    <th class="text-right">Base de remuneración</th>
                    <th class="text-right">Cuota obrera del seguro social</th>
                    <th class="text-right">
                      Aportaciones a retiro, cesantía en edad avanzada y vejez
                    </th>
                    <th class="text-right">Subsidio Pagado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="altura">
                    <td align="right">
                      {{ currencyFormat(MontosHonorarios.RetencionISRSalarios) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(MontosHonorarios.BaseRemuneracion) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(MontosHonorarios.CuotaSeguroSocial) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(MontosHonorarios.Aportaciones) }}
                    </td>
                    <td align="right">
                      {{ currencyFormat(MontosHonorarios.SubsidioPagado) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";

export default {
  name: "ImpuestosNomina",
  data: () => ({
    
    
    
    
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    Montos: [],
    MontosHonorarios: [],
    verMontos: false,
    PorFecha: "E"

  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);
  },
  methods: {
    ObtenerMontos() {

      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertMessage", "Procesando Solicitud");

      var jsonData = {
        token: this.$store.state.token,
        dateYear: this.date.substr(0, 4),
        dateMonth: this.date.substr(5, 2),
      };
      if (this.PorFecha === "E") {
        service
          .api()
          .PayrollAnalysis(jsonData)
          .then((response) => {
            if (response.result) {
              this.$store.commit("setAlertOk", false);
              this.verMontos = true;
              this.$store.commit("setOverlay", false);
              let payload = JSON.parse(response.payload);
              this.MontosHonorarios = payload.MontosHonorarios;
              this.Montos = payload.Montos;

              if (this.Montos.length === null && this.MontosHonorarios.length === null) {
                
                this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
                this.$store.commit("setAlertMessage", "No se encontraron Montos");
              }
            } else {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", response.errorMessage);
            }
          });
      } else {
        service
          .api()
        .PayrollAnalysisPago(jsonData)
        .then((response) => {
          if (response.result) {
            this.verMontos = true;
            this.$store.commit("setOverlay", false);
            let payload = JSON.parse(response.payload);
            this.MontosHonorarios = payload.MontosHonorarios;
            this.Montos = payload.Montos;

            if (this.Montos.length === null && this.MontosHonorarios.length === null) {
              this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "No se encontraron montos");
            }
          } else {
            this.$store.commit("setAlertOk", false);
this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
      }

    },
    exportExcel() {
      var headers = {
        Tipo: "Tipo",
        BaseRemuneracion: "Base Remuneración",
        RetencionISRSalarios: "Retención ISR Salarios",
        CuotaSeguroSocial: "Cuota Seguro Social",
        Aportaciones: "Aportaciones",
        SubsidioPagado: "Subsidio Pagado",
      };
      var itemsFormatted = [];


      itemsFormatted.push({
        Tipo: "Montos(Excepto Asimilados Honorarios)",
        BaseRemuneracion: this.Montos.BaseRemuneracion,
        RetencionISRSalarios: this.Montos.RetencionISRSalarios,
        CuotaSeguroSocial: this.Montos.CuotaSeguroSocial,
        Aportaciones: this.Montos.Aportaciones,
        SubsidioPagado: this.Montos.SubsidioPagado,
      });

      itemsFormatted.push({
        Tipo: "Montos(Asimilados Honorarios)",
        BaseRemuneracion: this.MontosHonorarios.BaseRemuneracion,
        RetencionISRSalarios: this.MontosHonorarios.RetencionISRSalarios,
        CuotaSeguroSocial: this.MontosHonorarios.CuotaSeguroSocial,
        Aportaciones: this.MontosHonorarios.Aportaciones,
        SubsidioPagado: this.MontosHonorarios.SubsidioPagado,
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "ListadoImpuestosNomina_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    getImpuesto(value) {
      return helpers.Impuesto(helpers.ConvertToEmpty(value));
    },
    getContexto() {
      let firstLetter = this.contextDoc.substr(0, 1);
      return firstLetter;
    },
  },
  computed: {
    showExportExcel() {
      return this.Montos.length > 0 || this.MontosHonorarios.length > 0;
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}


.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}
</style>
